import API from '../api'
import errorHandler from './errorHandler'

export const getTransactionStats = (data?: any) => async (dispatch: any) => {
  let response
  try {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/dashboard/transactions')
    data?.beg && url.searchParams.set('beg', data?.beg)
    data?.end && url.searchParams.set('end', data?.end)
    response = await API.get(url.toString())
    dispatch({ type: 'TRANSACTION_STATS_LOADED', payload: response.data })
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const getTemplateStats = (data?: any) => async (dispatch: any) => {
  let response
  try {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/dashboard/templates')
    data?.beg && url.searchParams.set('beg', data?.beg)
    data?.end && url.searchParams.set('end', data?.end)
    response = await API.get(url.toString())
    dispatch({ type: 'TEMPLATE_STATS_LOADED', payload: response.data })
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const getCustomerStats = (data?: any) => async (dispatch: any) => {
  let response
  try {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/dashboard/customers')
    data?.beg && url.searchParams.set('beg', data?.beg)
    data?.end && url.searchParams.set('end', data?.end)
    response = await API.get(url.toString())
    dispatch({ type: 'CUSTOMER_STATS_LOADED', payload: response.data })
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const getAdminStats = (data?: any) => async (dispatch: any) => {
  let response
  try {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/dashboard/admins')
    data?.beg && url.searchParams.set('beg', data?.beg)
    data?.end && url.searchParams.set('end', data?.end)
    response = await API.get(url.toString())
    dispatch({ type: 'ADMIN_STATS_LOADED', payload: response.data })
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const setEditingMode = (data: boolean) => async (dispatch: any) => {
  dispatch({ type: 'SET_EDIT_MODE', payload: data })
}
