import { Dialog, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, useState } from 'react';
import Button from '../Button'
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../../store'
import {Wallet} from '../../types'
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { closeModal } from '../../../actions/modals';
import InputField from '../Input';
import copyIcon from '../../assets/icons/copy-icon.svg'
import exclamationIcon from '../assets/exclamation-icon.svg'
import checkIcon from '../assets/check-icon.svg'

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(8),
        width: "100%",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "381px",
            width: "100%",
            padding: theme.spacing(8, 6),
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    tooltip: {
        borderRadius: "4px",
    },
    resetRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(4)
    },
    noFlex: {
        flexDirection: "column",
        alignItems: "flex-start",
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    setToken:{
        color: theme.palette.secondary[400]
    },
    tokenIcon: {
        marginLeft: theme.spacing(4)
    },
    btnContainer: {
        alignSelf: "flex-end"
    },
    tokenFlag: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4)
        }
    },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface WalletDetailsModalProps extends PropsFromRedux {}

const WalletDetailsModal: FC<WalletDetailsModalProps> = ({
    walletDetails,
    closeModal
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [copyTooltip, setCopyTooltip] = useState("")

    const handleCopyDid = (e: any) => {
        navigator.clipboard.writeText(walletDetails.data.did)
        setCopyTooltip("did")
        setTimeout(() => { setCopyTooltip("") }, 5000)
    }

    const handleCopyPublicKey = (e: any) => {
        navigator.clipboard.writeText(walletDetails.data.publicKey)
        setCopyTooltip("publicKey")
        setTimeout(() => { setCopyTooltip("") }, 5000)
    }

    return (
        <Dialog 
            open={walletDetails.open} 
            classes={{paper: classes.dialogContainer}}
        >
            <Typography
                variant="h6"
            >
                {t('wallet.walletDetails')}
            </Typography>
            <Typography
                variant="subtitle2"
                className={classes.label}
            >
                {t('wallet.id')}
            </Typography>
            <InputField
                disabled={true}
                value={walletDetails.data.id || ""}
            />
            <Typography
                variant="subtitle2"
                className={classes.label}
            >
                {t('wallet.did')}
            </Typography>
            <Tooltip
                title={<>{t('walletPage.copy')}</>}
                open={copyTooltip === "did"}
                placement="top-end"
                classes={{ popper: classes.tooltip }}
            >
                <div>
                    <InputField
                        disabled={true}
                        value={walletDetails.data.did || ""}
                        icon={copyIcon}
                        name="did"
                        onIconClick={handleCopyDid}
                    />
                </div>
            </Tooltip>
            <Typography
                variant="subtitle2"
                className={classes.label}
            >
                {t('settings.company.publicKey')}
            </Typography>
            <Tooltip
                title={<>{t('walletPage.copy')}</>}
                open={copyTooltip === "publicKey"}
                placement="top-end"
                classes={{ popper: classes.tooltip }}
            >
                <div>
                    <InputField
                        disabled={true}
                        value={walletDetails.data.publicKey || ""}
                        icon={copyIcon}
                        name="publicKey"
                        onIconClick={handleCopyPublicKey}
                    />
                </div>
            </Tooltip>
            <div className={clsx(classes.resetRow, classes.noFlex)} >
                <Typography
                    variant="subtitle2"
                    className={classes.label}
                >
                    {t('settings.company.token')}
                </Typography>
                <div className={clsx(classes.resetRow, classes.tokenFlag)}>
                    <Typography variant="subtitle1" 
                        className={
                            walletDetails.data.accessToken 
                            ?
                            classes.setToken
                            : 
                            classes.mediumEmphasis}
                    >
                        {walletDetails.data.accessToken ? t('token.set') : t('token.notSet')}
                    </Typography>
                    <Tooltip 
                        title={walletDetails.data.accessToken ? "" : <>{t('settings.company.tokenTooltip')}</>}
                        placement="bottom"
                    >
                        <img
                            src={walletDetails.data.accessToken ? checkIcon : exclamationIcon}
                            className={classes.tokenIcon}
                            alt={walletDetails.data.accessToken ? "check icon" : "exclamation icon"}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className={classes.btnContainer}>
                <Button 
                    text={t('general.Close')}
                    onClick={()=>closeModal({modalName: 'walletDetails'})}
                />
            </div>
        </Dialog>               
    )
}

const mapState = (state: RootState) => ({
    walletDetails: state.modals.walletDetails
})

const mapDispatch = {
    closeModal: (data: Object) => (closeModal(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(WalletDetailsModal)
