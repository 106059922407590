import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
    Paper,
    Typography,
    Divider,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { updateCompanySettings, getCompanyLogo } from '../actions/settings';
import { parseDateLongMonth, hasPermissions } from '../commons';
import clsx from 'clsx';
import { resetToken } from '../actions/wallets';
import { setEditingMode } from '../actions/dashboard';
import { getSettingsInfo } from '../actions/settings';

import editIcon from './assets/icons/edit-icon.svg'
import editIconDisabled from './assets/icons/edit-icon-disabled.svg'
import copyIcon from './assets/icons/copy-icon.svg'
import exclamationIcon from '../commons/components/assets/exclamation-icon.svg'
import checkIcon from '../commons/components/assets/check-icon.svg'

import IconBtn from '../commons/components/IconButton';
import Button from '../commons/components/Button'
import InputField from '../commons/components/Input';
import ImgSettings from './ImgSettings';
import WarningModal from '../commons/components/WarningModal';
import TokenModal from '../commons/components/TokenModal';

const useStyles = makeStyles((theme?: any) => ({
    formPaper: {
        width: "90%",
        padding: theme.spacing(7),
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        display: "flex",
        flexDirection: "column",
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: "unset",
            marginBottom: theme.spacing(6),
            marginRight: "unset",
            padding: theme.spacing(6),
        }
    },
    editMode: {
        borderColor: theme.palette.onSurface.highEmphasis,
    },
    formPaperAdmin: {
        minHeight: "unset",
        marginBottom: theme.spacing(6),
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
        }
    },
    title: {
        color: theme.palette.onSurface.highEmphasis,
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4)
        }
    },
    spaceBetweenRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },
    spaceBetweenRowUpdate: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        height: "100%",
        width: "47%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    inputWrap: {
        width: "70%",
        marginRight: "20px",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    inputWrapSmall: {
        [theme.breakpoints.down('sm')]: {
            width: "50%",
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(6)
        }
    },
    divider: {
        marginTop: theme.spacing(6)
    },
    lastUpdate: {
        color: theme.palette.onSurface.disabled,
        fontStyle: "italic",
        marginTop: theme.spacing(6)
    },
    logoTitle: {
        color: theme.palette.borderColor.onSurface
    },
    logoBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "180px",
        height: "130px",
        border: `1px dashed ${theme.palette.onSurface.highEmphasis}`,
        borderRadius: theme.shape.sharpBorderRadius,
        marginRight: theme.spacing(5)
    },
    logoContainer: {
        display: "flex",
        alignItems: "flex-end"
    },
    inputfile: {
        width: "0.1px",
        height: "0.1px",
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1
    },
    inputLabel: {
        minWidth: "207px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        marginBottom: 0,
        border: `1.6px solid ${theme.palette.borderColor.onSurface}`,
        color: theme.palette.onSurface.highEmphasis,
        "&:hover": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[800],
        },
        "&:focus": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.onSurface.highEmphasis,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
        }
    },
    billingEmails: {

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        }
    },
    questionIcon: {
        height: "16px",
        width: "16px",
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(4),
    },
    row: {
        display: "flex",
        alignItems: "center"
    },
    resetRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

    },
    noFlex: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-start",
        }
    },
    resetButton: {
        marginLeft: theme.spacing(5),
        width: "120px"
    },
    tooltip: {
        borderRadius: "4px",
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    tokenIcon: {
        marginLeft: theme.spacing(4)
    },
    tokenFlag: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4)
        }
    },
    btnContainer: {
        width: "196px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    emailDeleteIcon: {
        marginLeft: theme.spacing(5),
    },
    emailDeleteIconBlack: {
        filter: theme.palette.filter.toBlack,
    },
    emailRow: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    columns: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    verticalDivider: {
        margin: theme.spacing(0, 7)
    },
    setToken:{
        color: theme.palette.secondary[400]
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CompanySettingsProps extends PropsFromRedux {}

const CompanySettings: FC<CompanySettingsProps> = ({
    updateCompanySettings,
    permissions,
    companyLogo,
    getCompanyLogo,
    resetToken,
    setEditingMode,
    mainContact,
    settingsInfo,
    getSettingsInfo,
    customer,
    accessToken
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [disabledInput, setDisabledInput] = useState(true)
    const [fields, setFields] = useState({
        name: "",
        did: "",
        publicKey: "",
        token: ""
    })
    const [customerLogo, setCustomerLogo] = useState<string | null>(null)
    const [error, setError] = useState<any[]>()
    const [copyTooltip, setCopyTooltip] = useState("")
    const [openWarningModal, setOpenWarningModal] = useState(false)

    useEffect(() => {
        if (mainContact) {
          getSettingsInfo(mainContact.id)
        }
      }, [mainContact, getSettingsInfo, customer, accessToken])

    useEffect(() => {
        setFields({
            name: settingsInfo.maincontact?.Customer?.name || "",
            did: settingsInfo.maincontact?.Customer?.Wallet?.did || "",
            publicKey: settingsInfo.maincontact?.Customer?.Wallet?.password || "",
            token: settingsInfo.maincontact?.Customer?.Wallet?.accessToken || ""
        })
        setCustomerLogo(settingsInfo.maincontact?.Customer?.logo)
    }, [settingsInfo])

    useEffect(() => {
        if (customerLogo && hasPermissions(permissions, {customers: ["R"]})) {
            getCompanyLogo(settingsInfo.maincontact?.Customer?.id)
        }
    }, [customerLogo, getCompanyLogo, permissions])

    useEffect(() => {
        if (!disabledInput) {
            setEditingMode(true)
        }
        return () => {
            setEditingMode(false)
        }
    }, [disabledInput, setEditingMode])

    const handleInputChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleEdit = () => {
        setDisabledInput(false)
    }

    const handleSave = () => {
        updateCompanySettings({
            name: fields.name,
            PlanId: 1,
            CustomerId: settingsInfo.maincontact?.Customer?.id
        })
        setDisabledInput(true)
    }

    const handleCopyDid = (e: any) => {
        navigator.clipboard.writeText(fields.did)
        setCopyTooltip("did")
        setTimeout(() => { setCopyTooltip("") }, 5000)
    }

    const handleCopyPublicKey = (e: any) => {
        navigator.clipboard.writeText(fields.publicKey)
        setCopyTooltip("publicKey")
        setTimeout(() => { setCopyTooltip("") }, 5000)
    }

    const handleTokenReset = () => {
        resetToken({
            WalletId: settingsInfo.maincontact?.Customer?.Wallet.id,
        })
        setOpenWarningModal(false)
    }

    console.log("settingsInfo", settingsInfo)

    return (
        <Paper className={clsx(classes.formPaper, {
            [classes.formPaperAdmin]: hasPermissions(permissions, { admin: "O" }),
            [classes.editMode]: !disabledInput
        })}
            elevation={0}
        >
            <div className={classes.spaceBetweenRow}>
                <Typography variant="h5" className={classes.title}>
                    {t('settings.company.title')}
                </Typography>
                {((hasPermissions(permissions, {
                    admin: ["O"],
                    customers: ["E","R"],
                    wallets: ["R"]
                }) || 
                hasPermissions(permissions, {
                    mainContacts: ["E","R"],
                    customers: ["E","R"],
                    wallets: ["R"],
                    plans: ["R"]
                }))) && mainContact.main
                ?
                <>
                    {disabledInput ?
                        <IconBtn
                            icon={editIcon}
                            iconDisabled={editIconDisabled}
                            onClick={() => handleEdit()}
                            disabled={hasPermissions(permissions, {admin: ["O"]}) ? true : false}
                        />
                        :
                        <Button
                            size="medium"
                            width="110px"
                            onClick={() => handleSave()}
                            text={t('general.Save')}
                            disabled={error?.includes(true)}
                        />}
                </>
                : <></>
                }
            </div>
            <div className={classes.columns}>
                <div className={classes.column}>
                    <ImgSettings
                        imgSrc={customerLogo}
                        logoSrc={companyLogo}
                        imgPlaceholder={t('settings.company.imgPlaceholder')}
                        buttonText={t('settings.company.uploadButton')}
                        logo={true}
                        titlePreview={t('settings.company.previewTitle')}
                    />
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('settings.company.name')}
                            </Typography>
                            <InputField
                                disabled={disabledInput}
                                onChange={handleInputChange}
                                name="name"
                                value={fields.name}
                                placeholder={t('settings.company.name')}
                                helperText={!disabledInput ? t('settings.company.helperText') : ""}
                            />
                        </div>
                    <Divider className={classes.divider} />
                    {settingsInfo.lastUpdatedBy &&
                        <Typography
                            variant="body2"
                            className={classes.lastUpdate}
                        >
                            {t('settings.company.lastUpdated', {
                                name: settingsInfo.lastUpdatedBy?.name,
                                lastName: settingsInfo.lastUpdatedBy?.lastName,
                                updatedAt: parseDateLongMonth(settingsInfo.lastUpdatedBy.Customer?.updatedAt)
                            })
                            }
                        </Typography>
                    }
                </div>
                <Divider 
                    orientation='vertical' 
                    className={classes.verticalDivider}
                />
                <div className={classes.column}>
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('settings.company.did')}
                            </Typography>
                            <Tooltip
                                title={<>{t('walletPage.copy')}</>}
                                open={copyTooltip === "did"}
                                placement="top-end"
                                classes={{ popper: classes.tooltip }}
                            >
                                <div>
                                    <InputField
                                        disabled={true}
                                        value={fields.did}
                                        placeholder={t('settings.company.did')}
                                        icon={copyIcon}
                                        name="did"
                                        onIconClick={handleCopyDid}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('settings.company.publicKey')}
                            </Typography>
                            <Tooltip
                                title={<>{t('walletPage.copy')}</>}
                                open={copyTooltip === "publicKey"}
                                placement="top-end"
                                classes={{ popper: classes.tooltip }}
                            >
                                <div>
                                    <InputField
                                        disabled={true}
                                        value={fields.publicKey}
                                        placeholder={t('settings.company.publicKey')}
                                        icon={copyIcon}
                                        name="publicKey"
                                        onIconClick={handleCopyPublicKey}
                                        multiline={true}
                                        rows={4}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        {!hasPermissions(permissions, {"admin": "O"}) && <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('settings.company.token')}
                            </Typography>
                            <div className={clsx(classes.resetRow, classes.noFlex)} >
                                <div className={clsx(classes.resetRow, classes.tokenFlag)}>
                                    <Typography variant="subtitle1" 
                                    className={
                                        fields.token 
                                        ?
                                        classes.setToken
                                        : 
                                        classes.mediumEmphasis}>
                                        {fields.token ? t('token.set') : t('token.notSet')}
                                    </Typography>
                                    <Tooltip 
                                        title={fields.token ? "" : <>{t('settings.company.tokenTooltip')}</>}
                                        placement="bottom"
                                    >
                                        <img
                                            src={fields.token ? checkIcon : exclamationIcon}
                                            className={classes.tokenIcon}
                                            alt={fields.token ? "check icon" : "exclamation icon"}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={classes.btnContainer}>
                                    {hasPermissions(permissions, {wallets: ["E","R"], customers: ["R"]}) &&
                                        mainContact.main &&
                                            <Button
                                                version="tertiary"
                                                text={fields.token ? t('token.reset') : t('token.create')}
                                                width="100%"
                                                onClick={() => settingsInfo?.maincontact?.Customer?.Wallet?.accessToken ? setOpenWarningModal(true) : handleTokenReset()}
                                            />
                                    }
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            {openWarningModal && 
                hasPermissions(permissions, {transactions: ["R"]}) &&
                hasPermissions(permissions, {wallets: ["E","R"], customers: ["R"]}) &&
                    <WarningModal
                        open={openWarningModal}
                        onCancel={() => setOpenWarningModal(false)}
                        handleTokenReset={() => handleTokenReset()}
                    />
            }
            <TokenModal />
        </Paper>
    );
}

const mapState = (state: RootState) => ({
    permissions: state.login.permissions,
    companyLogo: state.settings.companyLogo,
    mainContact: state.login.mainContact,
    settingsInfo: state.settings.settingsInfo,
    customer: state.settings.customer,
    accessToken: state.wallets.accessToken
})

const mapDispatch = {
    updateCompanySettings: (data: any) => (updateCompanySettings(data)),
    getCompanyLogo: (data: number) => (getCompanyLogo(data)),
    resetToken: (data: Object) => resetToken(data),
    setEditingMode: (data: boolean) => (setEditingMode(data)),
    getSettingsInfo: (mainContactId: number) => (getSettingsInfo(mainContactId))
}

const connector = connect(mapState, mapDispatch)

export default connector(CompanySettings)
