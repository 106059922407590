import {
    Card,
    Typography,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "48px", 
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.onSurface.highEmphasis,
        borderRadius: 0,
        position: "absolute",
        left: 0,
        right: 0,
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        }
    },
    text: {
        color: theme.palette.text.secondary,
    },
    openDrawer: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2,5),
        }
    },
    link: {
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(5)
        }
    }
}))

interface TokenBannerProps {
    openDrawer: boolean
    globalAdmin: boolean
}

const TokenBanner: FC<TokenBannerProps> = ({
    openDrawer,
    globalAdmin
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Card className={clsx(classes.container, {
            [classes.openDrawer]: openDrawer
        })} elevation={0}>
            <Typography
                variant="subtitle2"
                className={classes.text}
            >
                {globalAdmin ? t('tokenBanner.GAtext') : t('tokenBanner.text')}
            </Typography>
            {globalAdmin && 
            <Link to="/settings/companysettings" className={classes.link}>
                <Typography
                    variant="button"
                    className={classes.text}
                >
                    {t('tokenBanner.link')}
                </Typography>
            </Link>}
        </Card>
    )
}

export default TokenBanner