import axios, { AxiosInstance, AxiosResponse } from 'axios'

interface WindowObject {
  location: Object
}


class API {
  service: AxiosInstance
  token: string | null

  constructor(url:string) {
    let token:string | null
    try {
      token = localStorage.getItem('token')
    } catch(e) {
      token = null
    }
    let service = axios.create({
      baseURL: url,
      headers: {'X-Token': token}
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.token = token
  }

  setAuthToken = (token: string) => {
    this.token = token
  }

  handleSuccess = (response: AxiosResponse) => {
    return response;
  }

  handleError = (error:string) => {
    return Promise.reject(error)
  }

  redirectTo = (document:WindowObject, path:string) => {
    document.location = path
  }

  get = (path: string) => {
    return this.service.get(path, {
      method: 'GET',
      responseType: 'json',
      headers: {'X-Token': this.token}
    })

  }

  patch = (path: string, payload: Object) => {
    return this.service.request({
      url: path,
      method: 'PATCH',
      responseType: 'json',
      data: payload,
      headers: {'X-Token': this.token}
    })
  }

  post = (path: string, payload: Object) => {
    return this.service.request( {
      url: path,
      method: 'POST',
      responseType: 'json',
      data: payload,
      headers: {'X-Token': this.token}
    })
  }

  downloadCSV = (path: string, payload: Object) => {
    return this.service.request( {
      url: path,
      method: 'POST',
      responseType: 'blob',
      data: payload,
      headers: {'X-Token': this.token}
    })
  }

  download = (path:string) => {
    return this.service.request({
      url: path,
      method: 'GET',
      responseType: 'blob',
      headers: {'X-Token': this.token}
    })
  }

  upload = (path:string , formData: FormData) => {
    return this.service.request({
      url: path,
      method: 'POST',
      responseType: 'json',
      data: formData,
      headers: {'X-Token': this.token, 'Content-Type': 'multipart/form-data'}
    })
  }

  put = (path:string , payload: Object) => {
    return this.service.request({
      url: path,
      method: 'PUT',
      responseType: 'json',
      data: payload,
      headers: {'X-Token': this.token}
    })
  }

  delete = (path: string, payload: Object) => {
    return this.service.request({
      url: path,
      method: 'DELETE',
      responseType: 'json',
      data: payload,
      headers: {'X-Token': this.token}
    })
  }
}

export default new API(process.env.REACT_APP_API_URL || "http://localhost:3200")
