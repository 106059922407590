import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC, Fragment } from 'react';
import {
  Typography,
  Divider,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { hasPermissions, parseDateAndTime } from '../commons';
import {
   deleteTemplate, 
   getTemplates
} from '../actions/credentials';
import { isFlag } from '../commons/utils';
import { Credential } from '../commons/types'
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sideosTheme } from "../themes/sideosTheme";
import { 
  allowedPermissions, 
  incomingRole 
} from '../commons/permissions';

import trashcanIcon from './assets/trashcan-icon-mediumE.svg'

import Search from '../commons/components/Search';
import Button from '../commons/components/Button'
import SimpleTable from '../commons/components/SimpleTable';
import TableDateAndTime from '../commons/components/TableDateAndTime'
import StatusBadge from '../commons/components/StatusBadge';
import Breadcrumbs from '../commons/components/Breadcrumbs';
import RadioBtns from '../commons/components/RadioBtns';
import CollapseTable from '../commons/components/CollapseTable'
import RepositoryModal from './RepositoryModal';
import DeleteModal from './DeleteModal';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  containerColumn: {
    height: "auto"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  btnRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  rightBtn: {
    marginLeft: theme.spacing(5),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginLeft: theme.spacing(0),
    }
  },
  leftBtn: {
    width: "200px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      width: "100%",
    }
  },
  searchRow: {
    width: "77%",
    paddingRight: theme.spacing(5)
  },
  disabled: {
    color: theme.palette.onSurface.disabled
  },
  titleRow: {
    height: "36px",
    background: theme.palette.primary[50],
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(6)
  },
  detailRow: {
    height: "66px",
    background: theme.palette.primary.hover,
    display: "flex",
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(3)
  },
  mediumEmphasis: {
    color: theme.palette.onSurface.mediumEmphasis,
    textDecoration: "none"
  },
  description: {
    color: theme.palette.onSurface.disabled
  },

}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CustomizedState {
  newTemplate: number
}

interface CredentialsProps extends PropsFromRedux { }

const Credentials: FC<CredentialsProps> = ({
  permissions,
  mainContact,
  templates,
  getTemplates,
  deleteTemplate,
}) => {
  const classes = useStyles()
  let history = useHistory()
  const { t } = useTranslation()
  const [matches, setMatches] = useState<Credential[]>([])
  const [input, setInput] = useState("")
  const [checked, setChecked] = useState<string>('all')
  const [openRepository, setOpenRepository] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [templateIdToDelete, setTemplateIdToDelete] = useState<any>(null)
  const [templateNameToDelete, setTemplateNameToDelete] = useState<any>(null)
  const [newTemplateId, setNewTemplateId] = useState<number | null>(null)
  const location = useLocation()
  const match = useMediaQuery(sideosTheme.breakpoints.down('sm'));
  const state = location.state as CustomizedState
  const radioGroupTitle = t('origin.sortTitle')
  const sideosBtnValues = [
    { value: 'all', translation: t('origin.all') },
    { value: 'public', translation: t('origin.public') },
    { value: 'private', translation: t('origin.private') },
  ]
  const customerBtnValues = [
    { value: 'all', translation: t('origin.all') },
    { value: 'created', translation: t('origin.created'), },
    { value: 'picked', translation: t('origin.picked') },
  ]
  const INUSE = 4
  const DRAFT = 1

  const columns = {
    createdAt: t('credentialsTable.createdOn'),
    id: t('credentialsTable.id'),
    type: t('credentialsTable.type'),
    name: t('credentialsTable.name'),
    status: t('credentialsTable.status')
  }

  useEffect(() => {
    if (state) {
      const { newTemplate } = state
      setNewTemplateId(newTemplate)
      setTimeout(() => {
        setNewTemplateId(0)
      }, 1000)
    }

    return () => setNewTemplateId(null)
  }, [state])

  useEffect(() => {
    getTemplates(mainContact?.Customer?.id, checked)
  }, [mainContact, checked,getTemplates])

  const renderStatus = (template: Credential) => {
    if (isFlag(template.status, DRAFT)) {
      return t('statusText.draft')
    } else if (isFlag(template.status, INUSE)) {
      const companiesNo = template.companies ? t('statusText.used') + t('statusText.companiesNo', { number: template.companies }) : t('statusText.used')
      return companiesNo
    } else {
      return t('statusText.unused')
    }
  }

  const renderSearchMatches = (matches: [], input: string) => {
    setInput(input)
    setMatches(matches)
  }

  const handleCellClick = (credential: any) => {
    hasPermissions(permissions, {
      templates: ["R"],
      proofs: ["R"],
      proof_template: ["R"],
      mainContacts: ["R"],
      customers: ["R"]
    }) && history.push(`/credentials/${credential.id}`)
  }

  const onRadioBtnChange = (e: any) => {
    setChecked(e.target.value)
  }

  const onDeleteCredential = (templateName: string, templateId: number) => {
    setTemplateIdToDelete(templateId)
    setTemplateNameToDelete(templateName)
    setShowModal(true)
  }

  const handleTemplateDelete = async (id: number, name: string) => {
    const response = await deleteTemplate({ TemplateId: id, name: name })
    if (response?.status === 200) {
      getTemplates(mainContact?.Customer?.id, checked)
    }
  }

  const rowAction = (item: any) => {
    return (
      <>
        {
          hasPermissions(permissions, {templates: ["D"]}) ? 
            <Tooltip
              title={<Fragment>{t('credentials.tooltipDelete')}</Fragment>}
              placement="bottom"
            >
              <img
                src={trashcanIcon}
                alt="trashcan icon"
                onClick={() => onDeleteCredential(item.name, item.id)}
              />
            </Tooltip>
        : <></>
        }
      </>
    )
  }

  const checkExpandableValue = (item: Credential) => {
    return isFlag(item.status, INUSE) ? true : false
  }

  const handleCreateNew = () => {
      history.push("/credentials/new")
  }

  const handleOpenRepo =()=>{
      setOpenRepository(true)
  }


  return (
    <div className={clsx(classes.container)}>

      <Breadcrumbs />
      <Divider />
      {match && !hasPermissions(permissions, { "admin": "O" }) && openRepository ? <RepositoryModal
        open={openRepository}
        setOpen={setOpenRepository}
        inMobile={true}
      />
      :
        <>
      <div className={clsx({
        [classes.row]: allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)
      })}>
        <Search
          rows={templates}
          renderSearchMatches={renderSearchMatches}
        />
        <div className={classes.btnRow}>
         {
          hasPermissions(permissions, {
            templates: ["W","R"],
            proof_template: ["R"],
            proofs: ["R"],
            mainContacts: ["R"],
            customers: ["R"]
          }) &&
              <div className={classes.leftBtn}>
                <Button
                  size="medium"
                  text={t('credentialsTable.create')}
                  version="secondary"
                  fullWidth
                  onClick={() => { handleCreateNew() }}
                />
              </div>
          }
          {(!hasPermissions(permissions, { "admin": "O" }) &&
            hasPermissions(permissions, {templates: ["R"], proofs: ["R"], proof_template: ["R"]})) && 
            allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) &&
              <div className={classes.rightBtn}>
                <Button
                  size="medium"
                  text={t('credentialsTable.repositoryBtn')}
                  version="secondary"
                  fullWidth
                  onClick={() => handleOpenRepo()}
                />

              </div>
          }
        </div>
      </div>
      <RadioBtns
        title={radioGroupTitle}
        btnValues={hasPermissions(permissions, { "admin": "O" }) ? sideosBtnValues : customerBtnValues}
        defaultValue={checked}
        onChange={(e) => onRadioBtnChange(e)}
      />
      <SimpleTable
        handleCellClick={(item) => handleCellClick(item)}
        highlight={newTemplateId}
        rowAction={(item: any) => rowAction(item)}
        expandable={true}
        checkExpandableValue={(item: Credential) => checkExpandableValue(item)}
        columns={columns}
        rows={input !== "" ? matches : templates}
        tableCells={{
          createdAt: (item: any) => {
            return (
              <TableDateAndTime
                dateAndTime={parseDateAndTime(item.createdAt)}
              />
            )
          },
          status: (item: any) => {
            return (
                <StatusBadge
                  status={renderStatus(item)}
                />
            )
          },
          type: (item: any) => {
            return (
              <div className={classes.nameContainer}>
                <Typography
                  variant="body2"
                  className={classes.name}
                  onClick={() => handleCellClick(item)}
                >
                  {item.type}
                </Typography>
              </div>
            )
          },
        }}
        collapseElement={(item) => {
          return (
            <CollapseTable
              titles={[
                { prop: "Proofs", title: t('credentialsTable.proofs') },
                { prop: "description", title: t('credentialsRepo.description') }
              ]}
              item={item}
              tableCells={{
                Proofs: (item: any) => {
                  return (item?.Proofs.map((proof: any) => {
                    return (
                      <p>
                        <Link
                          to={`/claims/${proof.id}`}
                          key={proof.id}
                          className={classes.mediumEmphasis}
                        >
                          <Typography
                            variant="button"
                            className={classes.mediumEmphasis}
                          >
                            {proof.name}
                          </Typography>
                        </Link>
                      </p>
                    )
                  })
                  )
                },
                description: (item: any) => {
                  return (
                    <Typography
                      variant="body1"
                      className={classes.description}
                    >
                      {item.description}
                    </Typography>
                  )
                }
              }}
            />
          )
        }
        }
      />
      <DeleteModal
        open={showModal}
        setShowModal={setShowModal}
        handleTemplateDelete={handleTemplateDelete}
        templateId={templateIdToDelete}
        credentialName={templateNameToDelete}
      />
      {!hasPermissions(permissions, { "admin": "O" }) && openRepository && <RepositoryModal
        open={openRepository}
        setOpen={setOpenRepository}
      />}
    </>}
    </div>
  );
}

const mapState = (state: RootState) => ({
  permissions: state.login.permissions,
  mainContact: state.login.mainContact,
  templates: state.credentials.templates,
})

const mapDispatch = {
  getTemplates: (CustomerId: number, param?: string) => getTemplates(CustomerId, param),
  deleteTemplate: (data: Object) => (deleteTemplate(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(Credentials)
