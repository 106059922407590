import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Button from '../commons/components/Button'
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: theme.spacing(8, 8),
        width: "523px",
        height: "276px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "276px",
            textAlign: "center",
            padding: theme.spacing(7, 8),
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        alignItems: "center",
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            marginRight: "unset",
            width: "100%"
        }
    },
    btnMargin: {
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    pinkLink: {
        textDecoration: "none",
    },
    linkTypography: {
        color: theme.palette.primary[600],
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: "1.25px",
        marginLeft: "3px",
        display: "inline"
    }
}))


interface ContextModalProps {
    open?: boolean,
    setShowModal: (arg: boolean)=> void
}

const ContextModal: FC<ContextModalProps> = ({
    open= true,
    setShowModal,
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog 
            open={open} 
            classes={{paper: classes.dialogContainer}}
        >
            <Typography variant="h6">
                {t('contextModal.title')}
            </Typography>
            <Typography 
                variant="subtitle1" 
                className={classes.mediumEmphasis}
            >
                {t('contextModal.text1')}
                <a 
                    href="https://schema.org/" 
                    className={classes.pinkLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Typography variant="subtitle1" className={classes.linkTypography}>
                        schema.org
                    </Typography>
                </a>
                {t('contextModal.text2')}
            </Typography>
            <div className={classes.btnRow}>
                <Button 
                    text={t('contextModal.btn')} 
                    version="primary"
                    onClick={() => setShowModal(false)}
                    width="100%"
                    size={matches ? "small" : "large"}
                />
            </div>
        </Dialog>               
    )
}

export default ContextModal