import API from '../api'
import { AppDispatch } from "../store";
import errorHandler from '../actions/errorHandler'
import successHandler from './successHandler';

export const getCustomers = () => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/companies')
    dispatch({ type: 'CUSTOMERS_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const updateCustomer = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/company', data)
    dispatch({ type: 'CUSTOMER_UPDATED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const onboardCustomer = (data: Object) => async (dispatch: any) => {
  let response
  try {
    response = await API.post('/api/company',  data)
    dispatch({type: 'CUSTOMER_ONBOARDED', payload: response.data})
    successHandler({ type: 'CUSTOMER_CREATED' }, dispatch)
    return response.data
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const getUnassignedWallets = () => async (dispatch: any) => {
    let response
    try {
      let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/wallets')
      url.searchParams.set('unassigned', 'true')
      response = await API.get(url.toString())
      dispatch({ type: 'UNASSIGNED_WALLETS_LOADED', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
      }
    }

  export const resetNewCustomer = () => async (dispatch: any) => {
    dispatch({ type: 'NEW_CUSTOMER_RESET' })
  }

  export const getCustomer = (data: number) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      response = await API.get('/api/company/' + data)
      dispatch({ type: 'CUSTOMER_LOADED', payload: response.data })
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
  }

  export const resetCustomer = () => async (dispatch: any) => {
    dispatch({ type: 'CUSTOMER_RESET' })
  }

  export const sendOnboardingOffer = (data: Object) => async (dispatch: any) => {
    let response
    try {
        response = await API.post('/api/onboarding/createoffer/'+ process.env.REACT_APP_API_VERSION, data)
        dispatch({ type: 'ONBOARDING_EMAIL_SENT', payload: response.data, data: data })
      } catch(e) {
        errorHandler(e, dispatch)
    }
  }

  export const resetDestinationEmail = () => async (dispatch: any) => {
    dispatch({ type: 'DESTINATION_EMAIL_RESET' })
  }

  export const resetQrCode = () => async (dispatch: any) => {
    dispatch({ type: 'QRCODE_RESET' })
  }

  export const deleteCustomer = (data: Object) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      response = await API.delete('/api/company', data)
      return response
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
  }

  export const updateCustomerStatus = (data: any) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      response = await API.patch('/api/company/disable', data)
      dispatch({ type: 'CUSTOMER_STATUS_UPDATED', payload: response.data })
      successHandler({ type: 'CUSTOMER_STATUS_UPDATED' }, dispatch, {name: data.name, disable: data.disable})
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
  }

  export const getOnboardingOffer = (data: Object) => async (dispatch: any) => {
    let response
    try {
      response = await API.post('/api/onboarding/createoffer/' + process.env.REACT_APP_API_VERSION,  data)
        dispatch({ type: 'ONBOARDING_OFFER_LOADED', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
    }
  }

  export const getAcceptedAdminsList = (CustomerId: number) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      response = await API.get('/api/admins/' + CustomerId + '/accepted')
      dispatch({ type: 'ACCEPTED_ADMINS_LOADED', payload: response.data })
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
  }

  export const enableDisableAdmin = (data: Object) => async (dispatch: any) => {    
    let response
    try {
      response = await API.patch('/api/admin',  data)
        dispatch({ type: 'ADMIN_ENABLED_OR_DISABLED', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
      }
    }

    export const getCustomerLogo = (data: number) => async (dispatch: any) => {
      let response
      try {
        response = await API.download('/api/logo/customer/' + data)
          dispatch({ type: 'CUSTOMER_LOGO_LOADED', payload: response.data })
        } catch(e) {
          errorHandler(e, dispatch)
        }
      }

      export const resendOffer = (data: Object) => async (dispatch: any) => {
        let response
        try {
          response = await API.patch('/api/company/status', data)
            dispatch({ type: 'OFFER_RESENT', payload: response.data })
          } catch(e) {
            errorHandler(e, dispatch)
          }
        }