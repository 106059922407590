import { createTheme } from '@material-ui/core/styles';

export const sideosTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            "*::-webkit-scrollbar": {
                color: "red"
              },
            toolbar: {
                backgroundColor: "#FFFFFF",
                height: "10px",
                minHeight: "40px",
                alignItems: "flex-end",
                "& h4": {
                    display: "none"
                },
                "& h6": {
                    color: "#A258FF",
                    marginLeft: "250px"
                }
            }
        },
        MuiInput: {
            input: {
                textAlign: "center",
            },
        },
    },
    spacing: [0, 2, 4, 8, 12, 16, 24, 32, 48, 56, 64, 96, 128, 160, 256, 296],
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 905,
            lg: 1240,
            xl: 1440
        }
    },
    palette: {
        common: {
            black: "#08141E",
            inputLabel: "#626782"
        },
        primary: {
            light: "#F0E3FF",
            main: "#A258FF",
            dark: "#291640",
            variant: "#7A42BF",
            contrastText: "#FFFFFF",
            50: "#F0E3FF",
            100: "#E4CFFF",
            200: "#D7B7FF",
            300: "#CA9FFF",
            400: "#BC88FF",
            500: "#AF70FF",
            600: "#A258FF",
            700: "#7A42BF",
            800: "#512C80",
            900: "#291640",
            hover: "rgba(162, 88, 255, 0.04)",
            selected: "rgba(162, 88, 255, 0.08)"
        },
        secondary: {
            light: "#CBFFEC",
            main: "#2FFFB4",
            dark: "#0C402D",
            variant: "#63FFC7",
            contrastText: "#08141E",
            50: "#CBFFEC",
            100: "#97FFDA",
            200: "#63FFC7",
            300: "#2FFFB4",
            400: "#29DF9E",
            500: "#23BF87",
            600: "#1D9F71",
            700: "#18805A",
            800: "#126044",
            900: "#0C402D"
        },
        onPrimary: {
            highEmphasis: "#FFFFFF",
            mediumEmphasis: "rgba(255, 255, 255, 0.74)",
            disabled: "rgba(255, 255, 255, 0.38)",
        },
        error: {
            main: "#FF365E",
            contrastText: "#FFFFFF"
        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF",
            gray: "#EDEDF1",
            richBlack: "#08141E",
            contentContainer: "#fcfcfe"
        },
        surface: {
            main: "#FFFFFF",
            overlay: "rgba(0, 0, 0, 0.08)",
            darkOverlay: "rgba(8, 20, 30, 0.8)"
        },
        onSurface: {
            highEmphasis: "#08141E",
            mediumEmphasis: "rgba(8, 20, 30, 0.6)",
            disabled: "rgba(8, 20, 30, 0.38)",
            btnDisabled: "rgba(0, 0, 0, 0.04)",
            tableDisabled: "rgba(161, 166, 170, 0.24)",
            iconBtnDisabled: "rgba(0, 0, 0, 0.12)",
            disabledRow: "rgba(33, 33, 33, 0.03)"
        },
        borderColor: {
            onSurface: "rgba(0, 0, 0, 0.12)",
            table: "#DDE1E6"
        },
        text: {
            primary: "#08141E",
            secondary: "#FFFFFF",
        },
        gradient: {
            purple: "radial-gradient(165.45% 165.45% at 0% -10%, #FC59FF 0.71%, #A258FF 43.27%, #3D7FFF 76.54%, #41A4FF 100%)",
            green: "radial-gradient(96% 96% at 9.7% 17.08%, #3FFF69 0%, #2FFFB4 44.27%, #03C3FF 100%)",
            magenta: "radial-gradient(124.32% 124.32% at -7.58% 0%, #FF6624 0%, #FF456A 52.6%, #FF28A9 100%)",
            lightPurple: "radial-gradient(165.45% 165.45% at 0% -10%, rgba(252, 89, 255, 0.1) 0.71%, rgba(162, 88, 255, 0.1) 43.27%, rgba(61, 127, 255, 0.1) 76.54%, rgba(65, 164, 255, 0.1) 100%)",
            purpleTrial: "radial-gradient(134.71% 152.78% at 0% 0%, rgba(122, 66, 191, 0.8) 0.71%, rgba(162, 88, 255, 0.8) 43.66%, rgba(61, 127, 255, 0.8) 100%)"
        },
        badge: {
            green: {
                bg: "#E0FFF4"
            },
            purple: {
                bg: "#EBDBFF"
            },
            yellow: {
                bg: "#FFFACF",
                color: "#F2DA00"
            },
            red: {
                bg: "#FFD0DA",
                color: "#FF456A"
            }
        },
        filter: {
            toBlack: "brightness(0) saturate(100%)",
            toWhite: "brightness(0) saturate(100%) filter: invert(99%) sepia(99%) saturate(0%) hue-rotate(294deg) brightness(107%) contrast(101%)",
            blackToPurple: "brightness(0) saturate(100%) invert(18%) sepia(16%) saturate(7409%) hue-rotate(248deg) brightness(95%) contrast(92%)",
            blackToLightPurple: "invert(37%) sepia(76%) saturate(2381%) hue-rotate(243deg) brightness(101%) contrast(103%)",
            whiteToLightPurple: "brightness(0) saturate(100%) invert(41%) sepia(20%) saturate(7488%) hue-rotate(243deg) brightness(101%) contrast(101%)"
        }
    },
    typography: {
        h1: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 300,
            fontSize: "96px",
            lineHeight: "112px",
            letterSpacing: "-1.5px",
        },
        h2: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 400,
            fontSize: "60px",
            lineHeight: "72px",
            letterSpacing: "-0.5px",
        },
        h3: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            fontSize: "48px",
            lineHeight: "56px",
        },
        h4: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            fontSize: "34px",
            lineHeight: "36px",
        },
        h5: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "24px",
            letterSpacing: "0.18px",
        },
        h6: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "24px",
            letterSpacing: "0.15px",
        },
        subtitle1: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.15px",
        },
        subtitle2: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
        },
        body1: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
        },
        body2: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.25px",
        },
        button: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "1.25px",
            textTransform: "none"
        },
        caption: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "16px",
            letterSpacing: "0.4px",
        },
        overline: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "16px",
            letterSpacing: "1.5px",
            textTransform: "uppercase"
        },
        bridge: {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "18px",
            letterSpacing: "1.5px",
        },
        bold: {
            fontWeight: 700,
        },
        planButton: {
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
        }
    },
    shape: {
        borderRadius: "8px",
        loginRadius: "10px",
        sharpBorderRadius: "4px",
        circle: "50%"
    },
    shadows: [
        "none",
        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)",
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
        "0px 4px 54px #7E17FF"
    ]
})