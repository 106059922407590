import { AnyAction } from 'redux'

const initialState = {
    wallets: [],
    wallet: {
      id: 0,
      name:"",
      password: "",
      did:"",
      accessToken:"",
      version:0,
      createdAt:"",
      updatedAt: "",
      CustomerId: 0,
      owner: 0,
      status: 0,
      disable: false,
      assignedOn: "",
      Customer: {
        id: 0,
        createdAt: "",
        name: "",
        type: "",
        disable: false,
        tenant: false,
        logo: "",
        status: 0,
        TenantId: 0,
        PlanId: 0,
        ThemeId: 0,
        MainContacts: []
      }
    },
    newWallet: {
      id: 0,
      name:"",
      password: "",
      did:"",
      accessToken:"",
      version:0,
      createdAt:"",
      updatedAt: "",
      CustomerId: 0,
      owner: 0,
      status: 0,
      disable: false,
      assignedOn: "",
      Customer: {
        id: 0,
        createdAt: "",
        name: "",
        type: "",
        disable: false,
        tenant: false,
        logo: "",
        status: 0,
        TenantId: 0,
        PlanId: 0,
        ThemeId: 0,
        MainContacts: []
      }
    },
    unassignedCustomers: [],
    accessToken: "",
    disabledTest: false
};

type WalletsReducer = typeof initialState
  
export default function walletsReducer(state=initialState, action: AnyAction): WalletsReducer {
    switch (action.type) {
      case 'WALLETS_LOADED': {
        return {
          ...state,
            wallets: action.payload,
          }
        }
      case 'WALLET_LOADED': {
          return {
            ...state,
              wallet: action.payload,
            }
          }
      case 'UNASSIGNED_CUSTOMERS_LOADED': {
             return {
               ...state,
               unassignedCustomers: action.payload,
               }
             }
      case 'WALLET_CREATED': {
             return {
               ...state,
                newWallet: action.payload,
               }
             }
      case 'WALLET_EDITED': {
             return {
               ...state,
                wallet: action.payload,
               }
             }
      case 'WALLET_ASSIGNED': {
              return {
                ...state,
                 wallet: action.payload,
                }
              }
      case 'TOKEN_RESET': {
                return {
                  ...state,
                   accessToken: action.payload.accessToken
                  }
                }
      case 'TOKEN_HIDDEN': {
                return {
                  ...state,
                  accessToken: initialState.accessToken,
                  }
                }
      case 'WALLET_STATUS_UPDATED': {
                  return {
                    ...state,
                     wallet: action.payload,
                    }
                  }
      case 'RESET_WALLET': {
                  return {
                    ...state,
                     wallet: initialState.wallet,
                    }
                  }
      case 'RESET_NEW_WALLET': {
            return {
                ...state,
                newWallet: initialState.newWallet,
                }
            }
      default:
        return state;
    }
  }
  