import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { validateEmail } from '../utils';
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import Button from './Button'
import InputField from "./Input";

const useStyles = makeStyles((theme?: any) => ({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(8),
        width: "532px",
        height: "350px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            border: `1px solid ${theme.palette.borderColor.onSurface}`,
            height: "381px",
            padding: theme.spacing(9,6),
            margin: theme.spacing(5),
            marginTop: theme.spacing(7),
            textAlign: 'center'
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "space-between",
            width: '100%'
        }
    },
    btnMargin: {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            width: "144px",
            marginTop: theme.spacing(4),
        }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    overlay: {
        position: 'relative',
        marginTop: "85px",
        "& .MuiDialog-container": {
            height: "auto",
        },
        "& .MuiDialog-root": {
        position: 'relative',

        }
    }
}))


interface SendOfferModalProps {
    open?: boolean
    email?: string
    onDestinationEmailChange?: (e: any) => void
    handleSendOffer?: (arg: Object) => void
    handleCancel?: () => void
    inMobile?: boolean
    loading?: boolean
}

const SendOfferModal: FC<SendOfferModalProps> = ({
    open = true,
    email,
    onDestinationEmailChange,
    handleSendOffer,
    handleCancel,
    inMobile, 
    loading
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [error, setError] = useState(false)

    useEffect(() => {
        if (email) {
            setError(!validateEmail(email))
        }
    }, [email])

    return (
        <Dialog
            open={open}
            disablePortal={inMobile?true:false}
            disableScrollLock={inMobile?true:false}
            hideBackdrop={inMobile?true:false}
            classes={{ paper: classes.dialogContainer,root: inMobile ? classes.overlay:undefined }}
        >
            <Typography variant="h6" className={classes.highEmphasis}>
                {t('sendofferModal.title')}
            </Typography>
            <Typography variant="subtitle1" className={classes.text}>
                {t('sendofferModal.text')}
            </Typography>
            <InputField
                name="email"
                value={email}
                error={error}
                helperText={t('sendofferModal.helperText')}
                errorText={t('error.email')}
                onChange={(e) => onDestinationEmailChange && onDestinationEmailChange(e)}
            />
            <div className={classes.btnRow}>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button
                        size="medium"
                        version="text"
                        text={t('general.Cancel')}
                        onClick={handleCancel}
                        width="100%"
                    />
                </div>
                <div className={classes.btn}>
                    <Button
                        size="medium"
                        text={t('sendofferModal.btnText')}
                        onClick={handleSendOffer}
                        disabled={error}
                        width="100%"
                        loading={loading}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default SendOfferModal