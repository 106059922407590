import {
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme?: any)=>({
    row: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.onSurface.highEmphasis,
        marginBottom: theme.spacing(4),
        marginTop: "-10px",
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(5),
            paddingLeft: theme.spacing(0),
        }
    },
    groupTitle: {
        color: theme.palette.common.inputLabel,
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(6),
        }
    },
    label: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginRight: theme.spacing(6)
    },
    checked: {
        fontFamily: theme.typography.subtitle2.fontFamily,
        fontWeight: theme.typography.subtitle2.fontWeight,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,
        letterSpacing: theme.typography.subtitle2.letterSpacing,
        color: theme.palette.onSurface.highEmphasis
    },
    buttonsContainer: {
        marginLeft: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(0),
        }
    },
    root: {
        color: theme.palette.onSurface.mediumEmphasis
    }
}))


interface RadioBtnProps {
    btnValues: {value: string, translation: string}[]
    defaultValue?: string
    title?: string
    onChange?: (e: any) => void
    btnValue?: string
    disabled?: boolean
}

const RadioBtn: FC<RadioBtnProps> = ({
    btnValues,
    title,
    btnValue,
    onChange,
    disabled,
    defaultValue
}) => {
    const classes = useStyles()

    return (
        <FormControl >
            <RadioGroup 
                row 
                name="row-radio-buttons-group" 
                className={classes.row} 
                value={btnValue}
                defaultValue={defaultValue}
            >
                <Typography 
                    variant="subtitle2" 
                    className={classes.groupTitle}
                >
                    {title}
                </Typography>
                <div className={classes.buttonsContainer}>
                    {btnValues.map((btnVal)=>{
                        return (
                            <FormControlLabel 
                                key={btnVal.value}
                                value={btnVal.value} 
                                control={<Radio color="primary" classes={{root: classes.root}}/>} 
                                label={btnVal.translation} 
                                classes={{label: clsx(classes.label, {
                                    [classes.checked]:btnValue === btnVal.value
                                })}}
                                onChange={(e)=>{onChange && onChange(e)}}
                                disabled={disabled}
                            />
                        )
                    })}
                </div>
            </RadioGroup> 
        </FormControl>         
    )
}

export default RadioBtn