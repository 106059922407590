import {
    Card,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from "react-i18next";
import background from './assets/Background.png'
import thumbsUp from './assets/thumbs-up.png'

const useStyles = makeStyles((theme?: any) => ({
    container: {
        boxSizing: "border-box",
        background: `no-repeat url(${background})`,
        height: "209px",
        width: "423px",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(6),
        marginRight: theme.spacing(8),
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
            width: "100%"
        } 
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "100%"
    },
    thumbsUp: {
        height: "100%",
        alignSelf: "flex-end"
    },
    secondaryText: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            lineHeight: "20px",
        }
    },
    theWelcome: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
            lineHeight: "25px",
            marginBottom: theme.spacing(3),
        }
    }
}))


interface WelcomeCardProps {
    firstTime: boolean
    name?: string
}

const WelcomeCard: FC<WelcomeCardProps> = ({
    firstTime,
    name
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Card className={classes.container}>
            <div className={classes.column}>
                <Typography 
                    variant="h5" 
                    className={clsx(classes.secondaryText, classes.theWelcome)}
                >
                    {firstTime ? 
                        t('dashboard.welcomeTitleFirst') 
                    : 
                        t('dashboard.welcomeTitle', { name: name })
                    }
                </Typography>
                {firstTime ?
                    <Typography 
                        variant="subtitle2" 
                        className={classes.secondaryText}
                    >
                        {t('dashboard.welcomeTextFirst')}
                    </Typography>
                    :
                    <Typography 
                        variant="subtitle2" 
                        className={classes.secondaryText}
                    >
                        {t('dashboard.welcomeText')}
                    </Typography>
                }
            </div>
            <div className={classes.column}>
                <img 
                    src={thumbsUp} 
                    className={classes.thumbsUp} 
                    alt="thumb up illustration" 
                />
            </div>
        </Card>
    )
}

export default WelcomeCard