import { AnyAction } from 'redux'

const proof = {
  id: 0, 
  name: "", 
  type: "", 
  description: "", 
  createdAt: "", 
  updatedAt: "", 
  status: 0,
  origin: 0,
  credentials: 0,
  MainContactId: 0,
  ContextId: 0,
  Context: {
    id: 0,
    type: ""
  }
}

const initialState = {
    proofs: [proof],
    proofRepository: [proof],
    proof: proof,
    proofTypes: [],
    contexts: [],
    newProof: proof
};

type ProofsReducer = typeof initialState
  
export default function proofsReducer(state=initialState, action: AnyAction): ProofsReducer {
    switch (action.type) {
      case 'PROOFS_LOADED': {
        return {
          ...state,
            proofs: action.payload,
          }
         }
      case 'PROOF_REPO_LOADED': {
          return {
            ...state,
              proofRepository: action.payload,
            }
        }
      case 'PROOF_LOADED': {
          return {
            ...state,
              proof: action.payload,
            }
           }
      case 'PROOF_TYPES_LOADED': {
            return {
              ...state,
                proofTypes: action.payload,
              }
             }
      case 'CONTEXTS_LOADED': {
              return {
                ...state,
                  contexts: action.payload,
                }
              }
      case 'PROOF_CREATED': {
                return {
                  ...state,
                    newProof: action.payload,
                  }
                }
      case 'NEW_PROOF_RESET': {
                return {
                  ...state,
                    newProof: initialState.newProof,
                  }
                }
      case 'SHARING_OPTION_UPDATED': {
                return {
                  ...state,
                    proof: action.payload,
                  }
                }
      case 'PROOF_UPDATED': {
                return {
                  ...state,
                    proof: action.payload,
                  }
                }
      default:
        return state;
    }
  }
  