import { FC, useState, useEffect, Fragment } from "react"
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
    Typography,
    Button,
    Divider,
} from '@material-ui/core';
import { useHistory, useLocation } from "react-router";
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { hasPermissions } from "../commons";

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        width: "100%",
        marginBottom: theme.spacing(6)
    },
    text: {
        textTransform: "unset",
        color: theme.palette.onSurface.mediumEmphasis,
        "& .MuiTypography-subtitle2": {
            marginLeft: theme.spacing(4)
        }
    },
    buttonExpanded: {
        height: "40px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    buttonCollapsed: {
        height: "40px",
        width: "45px",
        display: "flex",
        justifyContent: "flex-start",
        minWidth: "45px",
        marginBottom: theme.spacing(4),
        "& .MuiButton-startIcon": {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        },
        "& .MuiButton-label": {
            width: "auto",
        },
        "&:focus": {
            outline: "none",
            background: theme.palette.gradient.lightPurple,
        }
    },
    buttonRoot: {
        color: theme.palette.primary[600],
        paddingLeft: "10px"
    },
    divider: {
        width: "2px",
        marginLeft: "20px",
        marginRight: "31px"
    },
    subitemsContainer: {
        width: "100%"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface SubMenuProps extends PropsFromRedux {
    open?: boolean,
    items: any[],
    selectedItem?: boolean
    handleClick?: any
    transform?: boolean
}

const SubMenu: FC<SubMenuProps> = ({ 
    open, 
    items,
    permissions
}) => {
    const classes = useStyles()
    const [selected, setSelected] = useState<number>()
    const history = useHistory()
    const { pathname } = useLocation()

    useEffect(() => {
        let item: any
        items?.map((subitem) => {
            if (subitem?.link?.includes(pathname)) {
                item = subitem
            }
            return item
        })
        setSelected(item?.id)
    }, [pathname, items])

    const handleSelect = (item: any) => {
        setSelected(item?.id)
        history.push(item?.link[0])
    }

    return (
        <div className={classes.container}>
            <Divider orientation="vertical" className={classes.divider}/>
            <div className={classes.subitemsContainer}>
            {items?.map((item: any, index: number)=>{
                    return (
                        <Fragment key={index} >
                            {
                                item?.sidebarpermissions?.map((sidebarpermission: any, index: number)=> {

                                    return hasPermissions(permissions, sidebarpermission) ?
                                        <Fragment key={index} >
                                            <Button
                                                disableRipple
                                                onClick={() => handleSelect(item)}
                                                classes={{
                                                    text: classes.text,
                                                    root: clsx({
                                                        [classes.buttonRoot]: selected === item?.id,
                                                        [classes.text]: selected !== item?.id,
                                                        [classes.buttonExpanded]: open,
                                                        [classes.buttonCollapsed]: !open,
                                                    })
                                                }}
                                            >
                                                {
                                                    open &&
                                                    <Typography variant="subtitle2">
                                                        {item.text}
                                                    </Typography>
                                                }
                                            </Button>
                                        </Fragment> 
                                        : 
                                        null
                                    
                                })
                            }
                        </Fragment>
                    )
                })
                }
            </div>
        </div>
    )
}

const mapState = (state: RootState) => ({
    permissions: state.login.permissions,
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(SubMenu)
