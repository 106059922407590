import { AnyAction } from 'redux'

const customer = {
  id: 0,
  name: "",
  status: 0,
  tenant: false,
  createdAt: "",
  TenantId: 0,
  PlanId: 0,
  ThemeId: 0,
  logo: "",
}

const initialState = {
    transactions: [],
    companiesFilter: [customer]
  };

  type NotificationReducer = typeof initialState
  
export default function notificationReducer(state = initialState, action: AnyAction): NotificationReducer {
    switch (action.type) {
      case 'TRANSACTIONS_LOADED': {
        return {
          ...state,
          transactions: action.payload
          }
      }
      case 'COMPANIES_FILTER_LOADED': {
        return {
          ...state,
          companiesFilter: action.payload
          }
      }
      default:
        return state;
    }
  }
  