import React from "react";
import {
    useLocation
  } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return new URLSearchParams(search);
}

export default useQuery