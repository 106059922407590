import { connect, ConnectedProps } from 'react-redux'
import { 
    Typography,
    Divider,
    Dialog
 } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { closePortal, openPortal } from '../actions/settings';
import clsx from 'clsx';
import { hasPermissions, dateLongMonth } from '../commons';

import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any)=>({
    row: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0px",
        }
    },
    row2: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignSelf: "start",
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    label: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6),
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "47%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    divider: {
        height: "auto",
        margin: theme.spacing(0, 7)
    },
    columns: {
        display: "flex",
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignSelf: "start",
        columnGap: theme.spacing(10)
    },
    horizontalDivider: {
        width: "100%",
        margin: theme.spacing(6, 0)
    },
    lastUpdate: {
        color: theme.palette.onSurface.disabled,
        fontStyle: "italic",
        textAlign: "center",
        marginBottom: theme.spacing(6)
    },
    invoicesTitle: {
        fontWeight: 700,
        alignSelf: "start",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(7)
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: theme.spacing(6)
    },
    expand: {
        gridColumn: "span 2"
    },
    invoiceText: {
        maxWidth: "172px",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%"
        }
    },
    iframe: {
        height: "598px",
        width: "750px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6),
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "100%",
        }
    },
    btnWidth:{
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    }
}))

type BillingDataLabels = {
    first_name:  string
    last_name:  string,
    email:  string,
    company:  string,
    line1: string,
    country: string,
    city: string,
    zip: string,
    tax_id: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface BillingDetailsProps extends PropsFromRedux {}

const BillingDetails: FC<BillingDetailsProps> = ({
    billingDetails,
    openPortal,
    closePortal,
    mainContact,
    portalUrl,
    permissions
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const billingDataLabels: BillingDataLabels = {
    company:  t('billing.company'),
    first_name:  t('billing.name'),
    last_name:  t('billing.lastName'),
    email:  t('billing.email'),
    line1: t('billing.street'),
    country: t('billing.country'),
    city: t('billing.city'),
    zip: t('billing.postcode'),
    tax_id: t('billing.taxId')
  }

  const getDate = () => {
    const date = billingDetails?.updated_at
    if (date) {
        const newDate = new Date(date * 1000)
        return dateLongMonth(newDate)
    }
  }

  return (
      <div className={classes.container}>
          {hasPermissions(permissions, {chargebee: ["E"]}) && portalUrl && 
            <Dialog 
                open={portalUrl ? true : false} 
                classes={{paperScrollPaper: classes.iframe}}
                fullScreen={fullScreen}
            >
                <iframe 
                    style={{
                        border: "none", 
                        borderRadius: "8px", 
                        paddingTop: "0px", 
                        marginBottom: "10px"
                    }}
                    src={portalUrl}
                    height="100%"
                    width="100%"
                    title="chargebee portal"
                ></iframe>
                <Button 
                    text={t('general.Close').toUpperCase()} 
                    version="text"
                    onClick={()=>closePortal()}
                />
              </Dialog>
            }
          <Typography variant="h5">
            {t('billing.billingAddress')}
          </Typography>
          <div className={classes.columns}>
              <div className={classes.column}>
                  <div className={classes.grid}>
                    {
                        Object.keys(billingDataLabels).map((field: any, index: number)=>{
                            return (
                                <div 
                                    className={clsx({
                                        [classes.expand]: field === 'company' || field === 'email' || field === 'line1'
                                    })}
                                    key={index}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {/* @ts-ignore */}
                                        {billingDataLabels[field]}
                                    </Typography>
                                    <div className={classes.row}>
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            {/* @ts-ignore */}
                                            {field === "company" ? billingDetails[field] : billingDetails.billing_address[field]}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <Divider className={classes.horizontalDivider}/>
                <Typography
                    variant="body2"
                    className={classes.lastUpdate}
                >
                    {t('billing.lastUpdated')} {getDate()}
                </Typography>
                   {hasPermissions(permissions, {chargebee: ["E"]}) ?
                        <div className={classes.btnWidth}>
                            <Button 
                            width='100%'
                                text={t('billing.edit')} 
                                version="secondary"
                                onClick={()=>openPortal(mainContact.CustomerId)}
                            />
                        </div>
                        :
                        <></>
                    }
              </div> 
              <Divider 
                    className={classes.divider} 
                    orientation='vertical'
               />
            {hasPermissions(permissions, {chargebee: ["E"]}) ?
                <>
                    <div className={classes.column}>
                        <Typography 
                            variant="h6" 
                            className={classes.invoicesTitle}
                        >
                            {t('billing.invoices')}
                        </Typography>
                        <div className={classes.row2}>
                            <Typography
                                variant="subtitle1"
                                className={clsx(classes.mediumEmphasis, classes.invoiceText)}
                            >
                                {t('billing.download')}
                            </Typography>

                                <div className={classes.btnWidth}> 
                                    <Button 
                                        width="100%"
                                        text={t('billing.downloadBtn')} 
                                        version="tertiary"
                                        onClick={()=>openPortal(mainContact.CustomerId)}
                                    />
                                </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
          </div>
      </div>
  );
}

const mapState = (state: RootState) => ({
    billingDetails: state.settings.billingDetails,
    mainContact: state.login.mainContact,
    portalUrl: state.settings.portalUrl,
    permissions: state.login.permissions
})

const mapDispatch = {
    openPortal: (CustomerId: number) => (openPortal(CustomerId)),
    closePortal: () => (closePortal())
}

const connector = connect(mapState, mapDispatch)

export default connector(BillingDetails)
