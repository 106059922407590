import {
    Divider,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme?: any) => ({
    row: {
        display: "flex",
        width: "467px",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            justifyContent: "space-around",
          }
    },
    button: { 
        width: "36px",
        height: "36px",
        border: `2px solid ${theme.palette.onSurface.mediumEmphasis}`,
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
          }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(3)
          }
    },
    divider: {
        height: "2px",
        width: "30px",
        marginRight: 0,
        backgroundColor: theme.palette.onSurface.mediumEmphasis,
        [theme.breakpoints.down('sm')]: {
            width: "0",
          }
    },
    step: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
          }
    },
    buttonActive: {
        border: `2px solid ${theme.palette.primary[600]}`,
    },
    buttonPrev: {
        border: `2px solid ${theme.palette.primary[600]}`,
        backgroundColor: theme.palette.primary[600],
        "&:hover h6": {
            color: theme.palette.primary[600]
        }
    },
    textActive: {
        color: theme.palette.primary[600],
        cursor: "pointer",
    },
    textPrev: {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary[600]
        }
    },
    dividerActive: {
        backgroundColor: theme.palette.primary[600]
    },
    stepsRow:{
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
          }
    },
    downloadRow:{
        [theme.breakpoints.down('sm')]: {
          }
    },
    textMargin:{
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(3),
          }
    },
}))

interface WizardStepperProps {
    state: any
    send: any
    qrcode: string
    setQrcode: (arg: string)=>void
}

const WizardStepper: FC<WizardStepperProps> = ({
    state,
    send,
    qrcode,
    setQrcode
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.row}>
            <div className={clsx(classes.step, classes.downloadRow)} onClick={()=>send('DOWNLOADAPP')}>
            <IconButton className={clsx(classes.button, {
                [classes.buttonActive]: state.value === 'downloadApp',
                [classes.buttonPrev]: state.value !== 'downloadApp'
            })}>
                <Typography 
                    variant="subtitle2" 
                    className={clsx({
                        [classes.textActive]: state.value === 'downloadApp',
                        [classes.textPrev]: state.value !== 'downloadApp'
                    })}
                >
                    1
                </Typography>
            </IconButton>        
            <Typography 
                variant="body2" 
                className={clsx(classes.textActive, classes.textMargin)} 
            >
                {t('trial.downloadApp')}
            </Typography>
       </div>
       <div className={classes.stepsRow}>
        <div className={classes.step} onClick={()=>{setQrcode(''); send('PERSONALINFO')}}>
            <Divider 
                className={clsx(classes.divider, {
                    [classes.dividerActive]: state.value !== 'downloadApp'
                })} 
                variant="fullWidth"
            />
            <IconButton className={clsx(classes.button, {
                [classes.buttonActive]: state.value === 'personalInfo',
                [classes.buttonPrev]: state.value === 'scan'
            })}>
                <Typography 
                    variant="subtitle2"
                    className={clsx({
                        [classes.textActive]: state.value === 'personalInfo',
                        [classes.textPrev]: state.value === 'scan'
                    })}
                >
                    2
                </Typography>
            </IconButton>
           
        </div>
        <Typography 
                variant="body2" 
                className={clsx(classes.text, {
                    [classes.textActive]: state.value !== 'downloadApp',
                    
                })}
            >
                {t('trial.personalInfo')}  
            </Typography>
        </div>
        <div className={classes.stepsRow}>
        <div className={classes.step} onClick={()=>qrcode && send('SCAN')}>
            <Divider 
                className={clsx(classes.divider, {
                    [classes.dividerActive]: state.value === 'scan'
                })} 
                variant="fullWidth"
            />
            <IconButton className={clsx(classes.button, {
                [classes.buttonActive]: state.value === 'scan',
            })}>
                <Typography variant="subtitle2" className={clsx({
                    [classes.textActive]: state.value === 'scan',
                })}>
                    3
                </Typography>
            </IconButton>
        </div>
        <Typography 
                variant="body2" 
                className={clsx(classes.text, {
                    [classes.textActive]: state.value === 'scan'
                })}
            >
                {t('trial.scan')}  
            </Typography>
            </div>
        </div>
    )
}

export default WizardStepper