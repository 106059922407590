import { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import type { RootState } from '../store'
import { FC } from 'react';
import {
  onboardCustomer,
} from '../actions/onboarding';
import {
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { setEditingMode } from '../actions/dashboard';

import Breadcrumbs from '../commons/components/Breadcrumbs';
import CustomerStatusCard from './CustomerStatusCard';
import { plans } from '../commons/plans';

const useStyles = makeStyles((theme?: any) => ({
  divider: {
    marginBottom: theme.spacing(6)
  },
  mobileMargin: {
    [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(8),
    }
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface NewCustomerProps extends PropsFromRedux { }

const NewCustomer: FC<NewCustomerProps> = ({
  onboardCustomer,
  mainContact,
  setEditingMode
}) => {
  let history = useHistory()
  const classes = useStyles()
  const [fields, setFields] = useState({
    companyName: "",
    wallet: {
      id: 0
    },
    plan: plans[1],
    name: "",
    lastName: "",
    email: ""
  })

  const handleSave = async () => {
    setEditingMode(false)
    const customerToOnboard = {
      CustomerId: mainContact?.Customer?.id,
      companyName: fields.companyName,
      name: fields.name,
      lastName: fields.lastName,
      email: fields.email,
      WalletId: fields.wallet?.id,
      toUpgrade: fields.plan?.id ? fields.plan?.id : 1
    }
    const response = await onboardCustomer(customerToOnboard)
    if (response) {
      history.push(`/onboarding/${response?.id}`)
    }
  }

  const handleInputChange = (e: any) => {
    setFields({ ...fields, [e.target.name]: e.target.value })
  }

  const handleSelect = (name: string, value: any) => {
    setFields({ ...fields, [name]: value })
  }

  return (
    <div className={classes.mobileMargin}>

      <Breadcrumbs />
      <Divider className={classes.divider} />

      <CustomerStatusCard
        handleSave={handleSave}
        handleInputChange={handleInputChange}
        fields={fields}
        handleSelect={handleSelect}
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
  mainContact: state.login.mainContact,
})

const mapDispatch = {
  onboardCustomer: (data: Object) => (onboardCustomer(data)),
  setEditingMode: (data: boolean) => (setEditingMode(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(NewCustomer)
