import { hasPermissions } from "."

export const ROLES = {
  //roles
  sideos: {
    //subroles
    main: 1,
    admin: 6,
    viewer: 7
  },
  customer: {
    main: 2,
    admin: 3,
    viewer: 8,
    trial: 5,
    agent: 9
  },
  tenant: {
    main: 4,
    admin: 10,
    viewer: 11
  }
}

export const ASSIGNEBLE_SUBROLES = ['admin', 'viewer']

export const allowedPermissions = (subrole:string[], role:string, type:number, allowedSubrole?:boolean) => {
  const filteredKeys = subrole;
  //@ts-ignore
  const filtered = filteredKeys.reduce((obj, key) => ({ ...obj, [key]: ROLES[role][key] }), {});
  const allowed = Object.values(filtered).includes(type) ? allowedSubrole? false : true : allowedSubrole? true : false
  return allowed
}

export const incomingRole = (permissions:object) => {
  const role = !hasPermissions(permissions, {
    admin: ["O"],
  }) ? ((hasPermissions(permissions, {
    tenant: ["O"],
  })) ? 'tenant' : 'customer') : 'sideos'
  return role
}

export const roleNames: any = {
  main: {name: "Global Admin"},
  admin: {name: "Admin"},
  viewer: {name: "Viewer"}
}

export const getObjKey = (obj: any, value: any) => {
  return Object.keys(obj).find(key => obj[key] === value);
}
  