import API from '../api' 
import errorHandler from '../actions/errorHandler'
import { AppDispatch } from '../store'

  export const getSettingsInfo = (data: number) => async (dispatch: any) => {
  let response
  try {
    response = await API.get('/api/maincontact/' + data)
      dispatch({ type: 'SETTINGS_INFO_LOADED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const updateCompanySettings = (data: any) => async (dispatch: any) => {
    let response
    try {
      response = await API.patch('/api/customer/info', data)
      dispatch({ type: 'COMPANY_SETTINGS_UPDATED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const updateMainContactSettings = (data: any) => async (dispatch: any) => {
    let response
    try {
      response = await API.patch('/api/maincontact/info', data)
      dispatch({ type: 'MAINCONTACT_SETTINGS_UPDATED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  } 

  export const getCompanyLogo = (data: number) => async (dispatch: any) => {
    let response
    try {
      response = await API.download('/api/logo/customer/' + data)
        dispatch({ type: 'COMPANY_LOGO_LOADED', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
      }
  }

  export const uploadCompanyLogo = (data: any) => async (dispatch: any) => {
      let response
      try {
        response = await API.upload('/api/logo/customer/', data.file)
          dispatch({ type: 'COMPANY_LOGO_UPLOADED', payload: response.data })
        } catch(e) {
          errorHandler(e, dispatch)
        }
  }

  export const deleteCompanyLogo = (CustomerId: number) => async (dispatch: any) => {
    let response
    try {
      response = await API.patch('/api/logo/customer' , {CustomerId: CustomerId})
        dispatch({ type: 'COMPANY_LOGO__DELETE', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
      }
}

  export const getProfilePicture = (data: number) => async (dispatch: any) => {
        let response
        try {
          response = await API.download('/api/profile/maincontact/' + data)
            dispatch({ type: 'PROFILE_PICTURE_LOADED', payload: response.data })
          } catch(e) {
            errorHandler(e, dispatch)
          }
  }
    
  export const uploadProfilePicture = (data: any) => async (dispatch: any) => {
          let response
          try {
            response = await API.upload('/api/profile/maincontact', data.file)
              dispatch({ type: 'PROFILE_PICTURE_UPLOADED', payload: response.data })
            } catch(e) {
              errorHandler(e, dispatch)
            }
  }

  export const deteleProfilePicture = (MainContactId: number) => async (dispatch: any) => {
    let response
    try {
      response = await API.patch('/api/profile/maincontact' , {MainContactId: MainContactId})
        dispatch({ type: 'PROFILE_PICTURE_DELETE', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
      }
}

  export const getBillingDetails = (CustomerId: number) => async (dispatch: any) => {
    let response
    try {
      response = await API.get('/api/chargebee/' + CustomerId)
      dispatch({ type: 'BILLING_DETAILS_LOADED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const getPaymentInfo = (CustomerId: number) => async (dispatch: any) => {
    let response
    try {
      let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/chargebee/' + CustomerId)
        url.searchParams.set("paymentsources" , "true")
        response = await API.get(url.toString())
      dispatch({ type: 'PAYMENT_INFO_LOADED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  } 

  export const openPortalPayment = (CustomerId: number) => async (dispatch: any) => {
    let response
    try {
      let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/chargebee/' + CustomerId)
      url.searchParams.set('hosted', 'true')
      url.searchParams.set('paymentsources' , 'true')
      response = await API.get(url.toString())
      dispatch({ type: 'PORTAL_OPEN', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const openPortal = (CustomerId: number) => async (dispatch: any) => {
    let response
    try {
      let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/chargebee/' + CustomerId)
      url.searchParams.set('hosted', 'true')
      response = await API.get(url.toString())
      dispatch({ type: 'PORTAL_OPEN', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const closePortal = () => async (dispatch: any) => {
      dispatch({ type: 'PORTAL_CLOSE' })
  }

  export const delegateGlobalAdmin = (data: any) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      response = await API.patch('/api/company/admin', data)
      const parsed = JSON.parse(response.data)
      if (parsed.token) {
          API.setAuthToken(parsed.token)
          localStorage.setItem('token', parsed.token)
          localStorage.setItem('permissions', JSON.stringify(parsed.permissions))
          localStorage.setItem('mainContact', JSON.stringify(parsed.mainContact))
        }
        dispatch({ type: 'PERMISSIONS', payload: parsed.permissions })
        dispatch({ type: 'USER', payload: parsed.mainContact })
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
  }
