import { Dialog,  } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';

import CreateWallet from "../wallets/CreateWallet";

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "auto",
        color: theme.palette.onSurface.highEmphasis,
        [theme.breakpoints.down('sm')]: {
            zIndex: 1500,
            height: "auto",
            margin: theme.spacing(0, 0),
            width: '100%'
        }
    },
    overlay: {
        "& .MuiDialog-container": {
            height: "auto",
        },
    }
}))

interface CreateWalletModalProps {
    open: boolean
    onCancel: () => void
    inMobile?: boolean
}

const CreateWalletModal: FC<CreateWalletModalProps> = ({
    open=false,
    onCancel,
    inMobile
}) => {    
    const classes = useStyles()

    return (
        <>
        {open && <Dialog 
            open={open} 
            style={inMobile?{position: 'relative',zIndex: 1}:{}}
            disablePortal={inMobile?true:false}
            disableScrollLock={inMobile?true:false}
            hideBackdrop={inMobile?true:false}
            classes={{paper: classes.dialogContainer, root: inMobile ? classes.overlay:undefined}}
        >
            <CreateWallet 
                inModal={true} 
                handleCancel={onCancel}
            />
        </Dialog>}  
        </>             
    )
}

export default CreateWalletModal