import {
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { ButtonTypeMap } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme?: any)=>({
    root: {
        width: "48px",
        height: "48px",
        "&.MuiButtonBase-root": {
            position: "unset"
        }
    },
    primary: {
        background: theme.palette.onSurface.highEmphasis,
        color: theme.palette.text.secondary,
        "&:hover": {
            background: theme.palette.primary[800]
        },
        "&:focus": {
            background: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            background: theme.palette.onSurface.highEmphasis,
        }
    },
    secondary: {
        border: `2px solid ${theme.palette.onSurface.highEmphasis}`,
        color: theme.palette.text.primary,
        filter: theme.palette.filter.toBlack,
        "&:hover": {
            borderColor: theme.palette.primary[800],
            background: "transparent",
            filter: theme.palette.filter.blackToPurple
        },
        "&:focus": {
            borderColor: theme.palette.primary[700],
            filter: theme.palette.filter.blackToPurple,
            outline: "none"
        },
        "&:active": {
            borderColor: theme.palette.onSurface.highEmphasis,
            filter: theme.palette.filter.toBlack,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
            borderColor: theme.palette.onSurface.iconBtnDisabled
        }
    },
    tertiary: {
        border: `2px solid ${theme.palette.onSurface.iconBtnDisabled}`,
        color: theme.palette.text.primary,
        filter: theme.palette.filter.toBlack,
        "&:hover": {
            borderColor: theme.palette.primary[800],
            background: "transparent",
            filter: theme.palette.filter.blackToPurple
        },
        "&:focus": {
            borderColor: theme.palette.primary[700],
            filter: theme.palette.filter.blackToPurple,
            outline: "none"
        },
        "&:active": {
            borderColor: theme.palette.onSurface.highEmphasis,
            filter: theme.palette.filter.toBlack,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
            borderColor: theme.palette.onSurface.iconBtnDisabled
        }
    },
    disabled: {
        "&.Mui-disabled": {
            background: theme.palette.onSurface.iconBtnDisabled,
            color: theme.palette.onSurface.disabled,
            filter: "unset"
        }
    }
}))

type Version = "primary" | "secondary" |"tertiary"

interface ButtonProps {
    size?: ButtonTypeMap["props"]["size"]
    version?: Version
    icon?: string
    iconDisabled?: string
    iconSecondary?: string
    disabled?: boolean
    loading?: boolean
    onClick?: () => void
}

const IconBtn: FC<ButtonProps> = ({
    version="primary", 
    disabled=false,
    loading=false,
    icon="",
    iconDisabled="",
    iconSecondary="",
    onClick
}) => {
    const classes = useStyles()

    const whichIcon = () :string => {
        if (disabled) {
            return iconDisabled
        } else if (version === "secondary" || version === "tertiary") {
            return iconSecondary
        } else {
            return icon
        }
    }
    
    return (
        <IconButton 
             classes={{root: clsx(classes.root, {
                 [classes.primary]: version === "primary",
                 [classes.secondary]: version === "secondary",
                 [classes.tertiary]: version === "tertiary"
             }),
             disabled: classes.disabled
            }}
            disabled={disabled}
            onClick={onClick}
        >
            {
                loading ? 
                    <CircularProgress color="inherit" size={20}/>
                :
                    <img src={whichIcon()} alt="button icon" />
            }
        </IconButton>          
    )
}

export default IconBtn