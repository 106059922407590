import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Dialog, 
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { 
    getAcceptedAdminsList
} from '../actions/onboarding';
import { delegateGlobalAdmin } from '../actions/settings';
import { useTranslation } from 'react-i18next';
import { parseDateAndTime } from '../commons';
import clsx from 'clsx';
import { Customer } from '../commons/types';
import { closeModal } from '../actions/modals';

import SmallTable from '../commons/components/SmallTable'
import Button from '../commons/components/Button'
import Search from '../commons/components/Search';
import TableDateAndTime from '../commons/components/TableDateAndTime';

const useStyles = makeStyles((theme?: any)=>({
    modalContainer: {
        width: "622px",
        height: "568px",
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            border: `1px solid ${theme.palette.borderColor.onSurface}`,
            zIndex: 1500,
            height: "100%",
            margin: theme.spacing(0,2),
            padding: theme.spacing(6,6),
            width: "100%"
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        marginTop: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            position: 'relative',
            bottom: theme.spacing(5),
            flexDirection: "column"
        }
    },
    btn: {
        width: "180px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            width: "100%"
        }
    },
    tableContainer: {
        height: "68%"
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        height: "90%"
    },
    clickable: {
        color: theme.palette.onSurface.highEmphasis,
        cursor: "pointer"
    },
    text: {
        marginBottom: theme.spacing(5)
    },
    overlay: {
        "& .MuiDialog-container": {
            height: "auto",
        },
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface MainContactModalProps extends PropsFromRedux {
    customer: Customer
    inMobile?: boolean
}

const MainContactModal: FC<MainContactModalProps> = ({
    delegate,
    closeModal,
    acceptedAdmins,
    getAcceptedAdminsList,
    customer,
    delegateGlobalAdmin,
    inMobile
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [selectedId, setSelectedId] = useState(0)
    const [matches, setMatches] = useState<{[name:string]: string | number}[]>([])
    const [input, setInput] = useState("")

    const columns = {
        createdAt: t('mainContactModal.added'),
        did: t('mainContactModal.did'),
        email: t('mainContactModal.email')
    }

    useEffect(()=>{
        if (customer?.id !== 0 || customer?.id !== undefined) {
            getAcceptedAdminsList(customer?.id)
        }
    }, [customer, getAcceptedAdminsList])
    
    const handleCellClick = (item: any) => {
        setSelectedId(item.id)
    }

    const handleConfirm = () => {
        delegateGlobalAdmin({
            MainContactId: selectedId,
            CustomerId: customer?.id
        })
        closeModal({modalName: 'delegate'})
    }

    const renderSearchMatches = (matches: [], input: string) => {
        setInput(input)
        setMatches(matches)
      }
  
  return (
    <Dialog 
        open={delegate.open}
        style={inMobile?{position: 'relative',zIndex: 1}:{}}
        disablePortal={inMobile?true:false}
        disableScrollLock={inMobile?true:false}
        hideBackdrop={inMobile?true:false}
        classes={{paper: classes.modalContainer, root: inMobile ? classes.overlay:undefined}}
    >
        <div className={classes.contentContainer}>
        <Typography 
            variant="h5" 
            className={clsx(classes.highEmphasis, classes.text)}
        >
            {t('mainContactModal.title')}
        </Typography>
        <Typography 
            variant="subtitle1" 
            className={clsx(classes.mediumEmphasis, classes.text)}
        >
            {t('mainContactModal.subtitle')}
        </Typography>
        <Search 
            rows={acceptedAdmins}
            renderSearchMatches={renderSearchMatches}
        />
        <div className={classes.tableContainer}>
            <SmallTable 
                columns={columns}
                rows={input !== "" ? matches : acceptedAdmins}
                color={false}
                selected={selectedId}
                handleCellClick={(item)=>handleCellClick(item)}
                tableCells={{
                    createdAt: (item: any) => {
                      return (
                        <TableDateAndTime 
                            dateAndTime={parseDateAndTime(item.createdAt)} 
                        />
                      )
                    },
                    did: (item: any) => {
                        return (
                            <Typography 
                                variant="body2" 
                                className={classes.clickable}
                            >
                                {item.did}
                            </Typography>
                        )
                    }
                  }}
            />
        </div>
        </div>
        <div className={classes.btnRow}>
            <div className={classes.btn}>
                <Button 
                    text={t('general.Cancel')}
                    version="text"
                    width="100%"
                    onClick={()=>closeModal({modalName: 'delegate'})}
                />
            </div>
            <div className={classes.btn}>
                <Button                         
                    text={t('general.Confirm')}
                    version="primary"
                    width="100%"
                    disabled={selectedId === 0}
                    onClick={()=>handleConfirm()}
                />
            </div>
        </div>
    </Dialog>
  );
}

const mapState = (state: RootState) => ({
    acceptedAdmins: state.onboarding.acceptedAdmins,
    delegate: state.modals.delegate
})

const mapDispatch = {
    getAcceptedAdminsList: (CustomerId: number) => (getAcceptedAdminsList(CustomerId)),
    delegateGlobalAdmin: (data: object) => (delegateGlobalAdmin(data)),
    closeModal: (data: any) => (closeModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(MainContactModal)
