import { FC } from "react";
import { Typography, Box } from "@material-ui/core";
import Lottie from "lottie-react";

import loadingGray from "./assets/lotties/loading-gray.json";

type LoadingProps = {
	loadingMsg?: string;
};

// DAW styled loading component
const Loading: FC<LoadingProps> = ({ loadingMsg }) => {
	const style = {
		height: "50px",
		width: "50px",
        backgroundColor: "white",
        borderRadius: "8px"
	};

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<Lottie
				animationData={loadingGray}
				loop={true}
				autoplay={true}
				style={style}
			/>
			{loadingMsg && <Typography>{loadingMsg}</Typography>}
		</Box>
	);
};

export default Loading;
