import {
    Paper,
    Typography,
    Divider,
    Snackbar,
    Tooltip
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    resetQrCode,
    resetDestinationEmail,
    getOnboardingOffer,
    sendOnboardingOffer,
    getUnassignedWallets
} from '../actions/onboarding'
import { resetNewWallet } from '../actions/wallets';
import { validateEmail } from '../commons/utils';
import { Customer } from '../commons/types'
import { resetUniqueNameError } from '../actions/wallets';
import clsx from 'clsx';
import { setEditingMode } from '../actions/dashboard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sideosTheme } from "../themes/sideosTheme";
import { 
    allowedPermissions, 
    incomingRole 
} from '../commons/permissions';

import deleteIcon from '../commons/assets/icons/delete-icon.svg'
import plusIcon from './assets/plus-icon.svg'

import InputField from '../commons/components/Input';
import InputSelect from '../commons/components/InputSelect';
import Button from '../commons/components/Button'
import StatusBadge from '../commons/components/StatusBadge';
import IconBtn from '../commons/components/IconButton';
import VisualizeModal from '../commons/components/VisualizeModal';
import SendOfferModal from '../commons/components/SendOfferModal';
import CreateWalletModal from './CreateWalletModal';
import BackButton from '../commons/components/BackButton'
import { plans } from '../commons/plans';
import { hasPermissions } from '../commons';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "456px",
        padding: theme.spacing(7),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6)
        }
    },
    edit: {
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    column: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            width: "100%",
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(0),
        }
    },
    status: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        }
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
    },
    snackbar: {
        "& .MuiSnackbarContent-root": {
            height: "48px",
            width: "344px",
            backgroundColor: theme.palette.onSurface.highEmphasis,
            padding: theme.spacing(4, 'auto', 4, 5)
        }
    },
    secondaryTitle: {
        color: theme.palette.onSurface.highEmphasis,
        fontSize: "18px",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6),
        }
    },
    selectContainer: {
        position: "relative"
    },
    selectOther: {
        display: "flex",
    },
    plusIcon: {
        marginRight: theme.spacing(4),
    },
    iconBtns: {
        display: "flex",
    },
    row: {
        display: "flex",
        alignItems: "baseline"
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.disabled,
        marginLeft: theme.spacing(2)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CustomerStatusCardProps extends PropsFromRedux {
    handleSave?: () => void,
    handleInputChange?: (e: any) => void
    fields?: any,
    customer?: Customer
    disabledOffer?: boolean
    disabledInput?: boolean
    setDisabledInput?: (arg: boolean) => void
    handleDelete?: () => void
    handleSelect?: (name: string, value: any) => void
    inModal?: boolean
    closeOnboardingModal?: (arg: boolean) => void
}

const CustomerStatusCard: FC<CustomerStatusCardProps> = ({
    getUnassignedWallets,
    unassignedWallets,
    wallet,
    newWallet,
    handleSave,
    handleInputChange,
    resetDestinationEmail,
    fields,
    disabledOffer,
    customer,
    disabledInput,
    setDisabledInput,
    handleDelete,
    handleSelect,
    qrcode,
    emailSent,
    getOnboardingOffer,
    sendOnboardingOffer,
    resetQrCode,
    inModal,
    closeOnboardingModal,
    emailAddress,
    uniqueNameError,
    resetUniqueNameError,
    setEditingMode,
    resetNewWallet,
    permissions,
    mainContact
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [destinationEmail, setDestinationEmail] = useState<string | undefined>("")
    const [openSendOfferModal, setOpenSendOfferModal] = useState(false)
    const [openCreateWallet, setOpenCreateWallet] = useState(false)
    const [error, setError] = useState(false)
    const DRAFT = 1
    const matches = useMediaQuery(sideosTheme.breakpoints.down('sm'));
    const [loadingVisualizeBtn, setLoadingVisualizeBtn] = useState(false)
    const [loadingEmailBtn, setLoadingEmailBtn] = useState(false)

    const renderText = (item: number) => {
        const statusText = [
            t('statusText.none'),
            t('statusText.draft'),
            t('statusText.pending'),
            t('statusText.rejected'),
            t('statusText.accepted')
        ]
        let text = statusText[item]
        return text
    }

    useEffect(()=>{
        return ()=>{
            resetQrCode()
            setOpenSendOfferModal(false)
        }
    },[])

    useEffect(() => {
        {(
            hasPermissions(permissions, {admin: ["O"], wallets: ["RO"], customers: ["RO"]}) ||
            hasPermissions(permissions, {tenant: ["O"], wallets: ["R"], customers: ["R"]})
            ) && getUnassignedWallets()
        }
        return () => {
            resetUniqueNameError()
        }
    }, [getUnassignedWallets, wallet, resetUniqueNameError])

    useEffect(() => {
        if (fields?.email) {
            setError(!validateEmail(fields?.email))
        }
    }, [fields?.email])

    useEffect(() => {
        if (customer) {
            setDestinationEmail(customer?.MainAdmin?.[0]?.email)
        }
    }, [customer])

    useEffect(() => {
        setOpenSendOfferModal(false)
    }, [emailSent])

    useEffect(() => {
        if (!disabledInput) {
            setEditingMode(true)
        }
        return () => {
            setEditingMode(false)
        }
    }, [disabledInput])

    useEffect(()=>{
        if (newWallet?.id !== 0) {
            handleSelect && handleSelect("wallet", newWallet)
            resetNewWallet()
        }
    }, [newWallet])

    const handleEdit = () => {
        setDisabledInput && setDisabledInput(false)
    }

    const handleSaveCustomer = () => {
        handleSave && handleSave()
    }

    const onVisualizeOffer = async () => {
        setLoadingVisualizeBtn(true)
        await getOnboardingOffer({ MainContactId: customer?.MainAdmin?.[0]?.id })
        setLoadingVisualizeBtn(false)
    }

    const handleCloseVisualizationModal = () => {
        resetQrCode()
    }

    const onDestinationEmailChange = (e: any) => {
        setDestinationEmail(e.target.value)
    }

    const handleSendOffer = async () => {
        setLoadingEmailBtn(true)
        await sendOnboardingOffer({
            MainContactId: customer?.MainAdmin?.[0]?.id,
            email: destinationEmail
        })
        setLoadingEmailBtn(false)
        setOpenSendOfferModal(false)
    }

    const cancelSendOffer = () => {
        setOpenSendOfferModal(false)
    }

    const onCloseSnackbar = () => {
        resetDestinationEmail()
    }

    const isSaveDisabled = () => {
        if (!error && (fields?.companyName && fields?.name && fields?.lastName && fields?.email && fields?.plan)) {
            return false
        } else {
            return true
        }
    }

    const newWalletCTA = <div
        className={classes.selectOther}
    >
        <img
            src={plusIcon}
            alt="plus icon"
            className={classes.plusIcon}
        />
        <Typography variant="body2">
            {t('onboarding.createWallet')}
        </Typography>
    </div>

    return (
        <>
        {matches && (openSendOfferModal || qrcode.data || openCreateWallet) &&
        (hasPermissions(permissions, {admin: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}) ||
        hasPermissions(permissions, {tenant: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) ? 
            <>
                {openSendOfferModal && <SendOfferModal
                    open={openSendOfferModal}
                    email={destinationEmail}
                    onDestinationEmailChange={(e) => onDestinationEmailChange(e)}
                    handleSendOffer={handleSendOffer}
                    handleCancel={cancelSendOffer}
                    inMobile={true}
                />}
             
                {qrcode.data && <VisualizeModal
                    open={qrcode.data ? true : false}
                    qrcode={qrcode.data}
                    handleCloseVisualizationModal={handleCloseVisualizationModal}
                    contact={t('visualizeModal.maincontact')}
                    inMobile={true}
                />}

                {openCreateWallet && <CreateWalletModal
                    open={openCreateWallet}
                    onCancel={() => setOpenCreateWallet(false)}
                    inMobile={true}
                />}
            </>
            :
        <Paper elevation={0} className={clsx(classes.container, {
            [classes.edit]: !disabledInput
        })}>
            <div className={classes.titleRow}>
                <Typography
                    variant="h5"
                    className={classes.highEmphasis}
                >
                    {t('onboarding.info')}
                </Typography>
                <div className={classes.iconBtns}>
                    {
                        customer?.MainAdmin?.[0]?.status === DRAFT &&
                        (!customer?.TenantId && hasPermissions(permissions, {admin: ["O"], customers: ["DO"], mainContacts: ["DO"]}) ||
                        hasPermissions(permissions, {tenant: ["O"], customers: ["D"], mainContacts: ["D"]})) &&
                        <IconBtn
                            iconDisabled="false"
                            onClick={handleDelete}
                            iconSecondary={deleteIcon}
                            version="secondary"
                        />
                    }
                    {!inModal && <BackButton />}
                </div>
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('onboarding.companyName')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.companyName || ""}
                            onChange={handleInputChange}
                            name="companyName"
                            error={uniqueNameError ? true : false}
                            errorText={uniqueNameError?.msg}
                        />
                    </div>
                    {!inModal && <div className={classes.selectContainer}>
                        <div className={classes.row}>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('onboarding.wallet')}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.mediumEmphasis}
                            >
                                ({t('general.optional')})
                            </Typography>
                            </div>
                        <InputSelect
                            menuItems={unassignedWallets}
                            renderValue="name"
                            disabled={customer?.Wallet?.id ? true : false || disabledInput}
                            value={fields?.wallet || ""}
                            selectOther={true}
                            setValue={handleSelect}
                            name="wallet"
                            otherElement={newWalletCTA}
                            setOther={setOpenCreateWallet}
                        />
                    </div>}
                    <div className={classes.selectContainer}>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('onboarding.billingPlan')}
                        </Typography>
                        <InputSelect
                            menuItems={Object.values(plans)}
                            renderValue="name"
                            disabled={disabledInput}
                            value={fields?.plan || ""}
                            setValue={handleSelect}
                            name="plan"
                        />
                    </div>
                    {
                        customer?.MainAdmin?.[0]?.status &&
                        <>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('adminProfile.offer')}
                            </Typography>
                            <div className={classes.status}>
                                <StatusBadge
                                    status={renderText(customer?.MainAdmin?.[0]?.status)}
                                />
                            </div>
                        </>
                    }
                </div>
                <Divider />
                <div className={classes.statusColumn}>
                    <Typography
                        variant="h5"
                        className={classes.secondaryTitle}
                    >
                        {t('onboarding.mainContact')}
                    </Typography>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('onboarding.name')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.name || ""}
                            onChange={handleInputChange}
                            name="name"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('onboarding.lastName')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.lastName || ""}
                            onChange={handleInputChange}
                            name="lastName"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('onboarding.email')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.email || ""}
                            onChange={handleInputChange}
                            name="email"
                            error={error}
                            errorText={t('error.email')}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.btnRow}>
                {customer && allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) &&
                    (!customer?.TenantId && hasPermissions(permissions, {admin: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}) ||
                    customer?.tenant && hasPermissions(permissions, {admin: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}) || 
                    hasPermissions(permissions, {tenant: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}))  &&
                    <>
                        <Tooltip title={!fields?.wallet ? <>{t('onboarding.assignTooltip')}</> : ""}>
                            <div className={classes.btn}>
                                <Button
                                    text={t('onboarding.visualizeBtn')}
                                    version="secondary"
                                    disabled={disabledOffer || !disabledInput || !fields?.wallet}
                                    width="100%"
                                    onClick={onVisualizeOffer}
                                    loading={loadingVisualizeBtn}
                                />
                            </div>
                        </Tooltip>

                        <Tooltip title={!fields?.wallet ? <>{t('onboarding.assignTooltip')}</> : ""}>
                            <div className={classes.btn}>
                                <Button
                                    text={t('onboarding.sendBtn')}
                                    version="secondary"
                                    disabled={disabledOffer || !disabledInput || !fields?.wallet}
                                    width="100%"
                                    onClick={() => setOpenSendOfferModal && setOpenSendOfferModal(true)}
                                />
                            </div>
                        </Tooltip>
                    </>
                }
                {inModal &&
                    <div className={classes.btn}>
                        <Button
                            text={t('general.Cancel')}
                            width="100%"
                            version="text"
                            onClick={closeOnboardingModal}
                        />
                    </div>
                }
                {
                    (!customer || customer?.MainAdmin?.[0]?.status === DRAFT) &&
                    ((!customer?.TenantId && hasPermissions(permissions, {admin: ["O"], customers: ["EO","RO"], wallets: ["EO","RO"], mainContacts: ["EO","RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) ||
                    customer?.tenant && hasPermissions(permissions, {admin: ["O"], customers: ["EO","RO"], wallets: ["EO","RO"], mainContacts: ["EO","RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) || 
                    hasPermissions(permissions, {tenant: ["O"], customers: ["E","R"], wallets: ["E","R"], mainContacts: ["E","R"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}))) &&
                        <div className={classes.btn}>
                            <Button
                                text={disabledInput ? t('general.Edit') : t('general.Save')}
                                width="100%"
                                disabled={isSaveDisabled()}
                                onClick={disabledInput ? handleEdit : handleSaveCustomer}
                            />
                        </div>
                }
            </div>
            {qrcode.data && 
                (hasPermissions(permissions, {admin: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}) ||
                hasPermissions(permissions, {tenant: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) &&
                <VisualizeModal
                    open={qrcode.data ? true : false}
                    qrcode={qrcode.data}
                    handleCloseVisualizationModal={handleCloseVisualizationModal}
                    contact={t('visualizeModal.maincontact')}
                />
            }
            {openSendOfferModal && 
                (hasPermissions(permissions, {admin: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}) ||
                hasPermissions(permissions, {tenant: ["O"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) &&
                <SendOfferModal
                    open={openSendOfferModal}
                    email={destinationEmail}
                    onDestinationEmailChange={(e) => onDestinationEmailChange(e)}
                    handleSendOffer={handleSendOffer}
                    handleCancel={cancelSendOffer}
                    loading={loadingEmailBtn}
                />
            }
            {openCreateWallet && <CreateWalletModal
                open={openCreateWallet}
                onCancel={() => setOpenCreateWallet(false)}
            />}
            {emailSent && <Snackbar
                open={emailSent ? true : false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                message={<div>{t('onboarding.snackbar')}: <b>{emailAddress}</b></div>}
                autoHideDuration={3000}
                onClose={onCloseSnackbar}
                className={classes.snackbar}
            />}
        </Paper>
        }
        </>
    )
}

const mapState = (state: RootState) => ({
    qrcode: state.onboarding.qrcode,
    emailSent: state.onboarding.emailSent,
    unassignedWallets: state.onboarding.unassignedWallets,
    wallet: state.wallets.wallet,
    newWallet: state.wallets.newWallet,
    emailAddress: state.onboarding.emailAddress,
    uniqueNameError: state.errorReducer.uniqueNameError,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    getUnassignedWallets: () => (getUnassignedWallets()),
    getOnboardingOffer: (data: Object) => (getOnboardingOffer(data)),
    sendOnboardingOffer: (data: Object) => (sendOnboardingOffer(data)),
    resetDestinationEmail: () => (resetDestinationEmail()),
    resetQrCode: () => (resetQrCode()),
    resetUniqueNameError: () => (resetUniqueNameError()),
    setEditingMode: (data: boolean) => (setEditingMode(data)),
    resetNewWallet: () => (resetNewWallet()),
}

const connector = connect(mapState, mapDispatch)

export default connector(CustomerStatusCard)