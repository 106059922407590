import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect } from 'react';
import Button from './Button'
import DawBtn from "./DawButton";
import { useTranslation } from "react-i18next";
import QRCode from 'qrcode.react'

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(8),
        width: "408px",
        height: "493px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            border: `1px solid ${theme.palette.borderColor.onSurface}`,
            zIndex: 1500,
            height: "550px",
            padding: theme.spacing(9,6),
            margin: theme.spacing(5, 5),
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end"
    },
    btnMargin: {
        marginRight: theme.spacing(5)
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
        textAlign: "center"
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    overlay: {
        "& .MuiDialog-container": {
            height: "auto",
        },
    }
}))


interface VisualizeModalProps {
    open: boolean
    qrcode?: string,
    handleCloseVisualizationModal: () => void,
    contact: string
    inMobile?: boolean
}

const VisualizeModal: FC<VisualizeModalProps> = ({
    open=false,
    qrcode,
    handleCloseVisualizationModal,
    contact,
    inMobile
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()    

    return (
        <Dialog 
            style={inMobile?{position: 'relative',zIndex: 1}:{}}
            open={open} 
            disablePortal={inMobile?true:false}
            disableScrollLock={inMobile?true:false}
            hideBackdrop={inMobile?true:false}
            classes={{paper: classes.dialogContainer, root: inMobile ? classes.overlay:undefined}}
        >
            <Typography 
                variant="h6" 
                className={classes.highEmphasis}
            >
                {t('visualizeModal.title')}
            </Typography>
            <QRCode 
                value={qrcode || ""} 
                size={200}
            />
            <DawBtn 
                qrcode={qrcode ? qrcode : ""} 
                text="Save credential in DAW"
                id="dawOfferCredential"
            />
            <Typography 
                variant="subtitle1" 
                className={classes.text}
            >
                {t('visualizeModal.text', {contact: contact})}
            </Typography>
            <Button 
                text={t('visualizeModal.btnText')}
                onClick={handleCloseVisualizationModal}
                width="210px"
            />
        </Dialog>               
    )
}

export default VisualizeModal