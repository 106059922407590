import { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Paper,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isFlag } from '../commons/utils';
import { parseDateAndTime } from '../commons';
import { Customer } from '../commons/types'

import StatusBadge from '../commons/components/StatusBadge';
import Search from '../commons/components/Search';
import SmallTable from '../commons/components/SmallTable';
import TableDateAndTime from '../commons/components/TableDateAndTime';
import Toggle from '../commons/components/Toggle';
import DisableDialog from '../admins/DisableDialog';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    credentialsTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: theme.spacing(4),
        }
    },
    credentialsTitle: {
        fontWeight: theme.typography.bold.fontWeight,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
        }
    },
    searchContainer: {
        width: "25%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    disabled: {
        color: theme.palette.onSurface.disabled
    },
    tableContainer: {
        height: "340px",
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface AdminsSmallTableCardProps extends PropsFromRedux {
    customer: Customer | undefined
}

const AdminsSmallTableCard: FC<AdminsSmallTableCardProps> = ({
    customer,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [matches, setMatches] = useState<{ [name: string]: string | number }[]>([])
    const [input, setInput] = useState("")
    const [clickedRow, setClickedRow] = useState<any>("")
    const history = useHistory()
    const DISABLED = 1

    const columns = {
        createdAt: t('customerProfile.added'),
        name: t('customerProfile.name'),
        lastName: t('customerProfile.lastname'),
        did: t('customerProfile.did'),
        email: t('customerProfile.email'),
        status: t('customerProfile.offer'),
        disable: t('customerProfile.enabled')
    }

    const renderText = (item: number) => {
        const statusText = [
            t('statusText.none'),
            t('statusText.draft'),
            t('statusText.pending'),
            t('statusText.rejected'),
            t('statusText.accepted')
        ]
        let text = statusText[item]
        return text
    }

    const renderSearchMatches = (matches: [], input: string) => {
        setInput(input)
        setMatches(matches)
    }

    const onCancel = () => {
        setClickedRow("")
    }

    return (
        <div className={clsx(classes.container)}>
            <Paper elevation={0} >
                <div className={classes.credentialsTitleContainer}>
                    <Typography
                        variant="h6"
                        className={
                            clsx(classes.highEmphasis, classes.credentialsTitle)
                        }
                    >
                        {t('customerProfile.admins')}
                    </Typography>
                    <div className={classes.searchContainer}>
                        <Search
                            rows={customer?.Admins ? customer?.Admins : customer?.MainContacts || [{}]}
                            renderSearchMatches={renderSearchMatches}
                        />
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <SmallTable
                        rows={input !== "" ? matches : customer?.Admins ? customer?.Admins : customer?.MainContacts}
                        columns={columns}
                        tableCells={{
                            createdAt: (item: any) => {
                                return <TableDateAndTime dateAndTime={parseDateAndTime(item.createdAt)} />
                            },
                            status: (item: any) => {
                                return (
                                    <StatusBadge
                                        status={renderText(item.status)}
                                    />
                                )
                            },
                            disable: (item: any) => {
                                return (
                                    <Toggle
                                        checked={!item.disable}
                                        disabled={true}
                                    />
                                )
                            },
                        }}
                        disabled={isFlag(customer?.status, DISABLED) ? true : false}
                    />
                </div>
                <DisableDialog
                    open={clickedRow ? true : false}
                    onCancel={onCancel}
                    name={clickedRow.name}
                    lastName={clickedRow.lastName}
                    disabled={clickedRow.disable}
                />
            </Paper>
        </div>
    );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(AdminsSmallTableCard)
