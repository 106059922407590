import { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { 
    Typography,
    Tooltip,
    Divider
 } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import Button from '../commons/components/Button';
import { FC } from 'react';
import { getAdminList } from '../actions/admin';
import { getTemplates } from '../actions/credentials';
import { useTranslation } from 'react-i18next';
import { plans } from '../commons/plans';
import { dateLongMonth, hasPermissions, parseDate } from '../commons/index';
import { useHistory } from 'react-router';

import questionIcon from './assets/icons/icon-question-circle.png'

import ContactModal from './ContactModal';

const useStyles = makeStyles((theme?: any)=>({
    row: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(4)
    },
    label: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6),
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        width: "47%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    planImg: {
        height: "74px"
    },
    divider: {
        height: "100%",
        margin: theme.spacing(0, 7),
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    columns: {
        display: "flex",
        height: "100%"
    },
    plan: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    planName: {
        marginBottom: theme.spacing(4)
    },
    date: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    questionIcon: {
        height: "14px",
        width: "14px",
        marginLeft: theme.spacing(4),
        filter: theme.palette.filter.toBlack
    },
    upgradeBtn:{
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface OverviewProps extends PropsFromRedux {}

const Overview: FC<OverviewProps> = ({
    mainContact,
    admins,
    templates,
    getAdmins,
    getTemplates,
    permissions
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [openContactModal, setOpenContactModal] = useState(false)

  useEffect(()=>{
      if (mainContact) {
          getAdmins(mainContact?.CustomerId)
          getTemplates(mainContact?.CustomerId)
      }
  }, [mainContact, getAdmins, getTemplates])

  return (
      <div className={classes.container}>
        {
            openContactModal && 
                <ContactModal 
                    open={openContactModal} 
                    setOpen={setOpenContactModal}
                />
        }
        <Typography variant='h5' >
            {t('billing.yourPlan')}
        </Typography>
        <div className={classes.columns}>
            <div className={classes.column}>
            <Typography variant="subtitle2" className={classes.label}>
                {t('billing.plan')}
            </Typography>
            <div className={classes.plan}>
                <img 
                    src={hasPermissions(permissions, {tenant: ["O"]}) ? plans[5].image : plans[mainContact?.Customer?.PlanId]?.image}
                    className={classes.planImg}
                    alt="plan icon"
                />
                <Typography variant="subtitle1" className={classes.planName}>
                    {hasPermissions(permissions, {tenant: ["O"]}) ? plans[5].name : plans[mainContact?.Customer?.PlanId]?.name}
                </Typography>
                <div className={classes.upgradeBtn}>
                    <Button 
                        width="100%"
                        text={t('general.upgrade')} 
                        onClick={()=>(mainContact?.Customer?.PlanId === 4 || hasPermissions(permissions, {tenant: ["O"]})) ? setOpenContactModal(true) : history.push("/settings/billing/plans")} 
                    />
                </div>
            </div>
                {hasPermissions(permissions, {mainContacts: ["R"]}) && 
                <>
                <Typography variant="subtitle2" className={classes.label}>
                    {t('Sidebar.admins')}
                </Typography>
                <div className={classes.row}>
                    <Typography variant="h5">
                        {admins.length}
                    </Typography>
                    <Typography color="inherit" noWrap>
                        &nbsp;
                        &nbsp;
                    </Typography>
                </div>
                </>}
                {hasPermissions(permissions, {templates: ["R"],
                    proofs: ["R"],
                    mainContacts: ["R"],
                    customers: ["R"],
                    proof_template: ["R"]}) && 
                    <>
                        <Typography variant="subtitle2" className={classes.label}>
                            {t('billing.creds')}
                        </Typography>
                        <div className={classes.row}>
                            <Typography variant="h5">
                                {templates.length}
                            </Typography>
                            <Typography color="inherit" noWrap>
                                &nbsp;
                                &nbsp;
                            </Typography>
                        </div>
                    </>
                }
                <Typography variant="subtitle2" className={classes.label}>
                    {t('billing.renewal')}
                </Typography>
                <div className={classes.row}>
                    <Typography variant="subtitle1" className={classes.date}>
                        {dateLongMonth(mainContact?.Customer?.chargebee?.renewal)}
                    </Typography>
                    <Tooltip 
                        title={<>{t('billing.cancelTooltip')} <b>{t('billing.cancelTooltipLink')}</b></>}
                        placement="bottom-start"
                    >
                        <img 
                            src={questionIcon} 
                            alt="question mark in a circle icon"
                            className={classes.questionIcon}
                        />
                    </Tooltip>
                </div>
            </div>
            <Divider orientation="vertical" className={classes.divider}/>
        </div>
      </div>
  );
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    admins: state.admin.admins,
    templates: state.credentials.templates,
    permissions: state.login.permissions
})

const mapDispatch = {
    getAdmins: (id: number) => (getAdminList(id)),
    getTemplates: (id: number) => (getTemplates(id))
}

const connector = connect(mapState, mapDispatch)

export default connector(Overview)
