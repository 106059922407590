import {
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, useState, useEffect } from 'react';
import { setImageFromBlob } from '../utils';
import clsx from 'clsx';

const useStyles = makeStyles((theme?: any) => ({
    avatarContainer: {
        [theme.breakpoints.up('sm')]: {
            height: (styleProps: { size: string }) => styleProps.size,
            width: (styleProps: { size: string }) => styleProps.size,
            marginLeft: theme.spacing(8),
            cursor: "pointer",
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: "40px",
            maxHeight: "40px",
            maxWidth: "40px",
            minWidth: "40px",
        },
        position: "relative",
        background: theme.palette.gradient.purple,
        borderRadius: theme.shape.circle,
        color: theme.palette.primary.contrastText,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    avatarDisabled: {
        opacity: "60%"
    },
    img: {
        [theme.breakpoints.up('sm')]: {
            height: (styleProps: { size: string }) => styleProps.size,
            width: (styleProps: { size: string }) => styleProps.size,
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: "40px",
            maxHeight: "40px",
            maxWidth: "40px",
            minWidth: "40px",
        },
        borderRadius: theme.shape.circle,
    },
    initials: {
        fontSize: (styleProps: { fontSize: string }) => styleProps.fontSize,
    },
}))

interface AvatarProps {
    username: string
    lastname?: string
    profilePicture?: any
    size?: string
    fontSize?: string
    disabled?: boolean
}

const Avatar: FC<AvatarProps> = ({
    username = "",
    lastname = "",
    profilePicture,
    size = "40px",
    fontSize = "20px",
    disabled
}) => {
    let styleProps = { size: size, fontSize: fontSize }
    const classes = useStyles(styleProps)
    const [src, setSrc] = useState("")

    useEffect(() => {
        if (profilePicture) {
            setImageFromBlob(profilePicture, setSrc)
        }
    }, [profilePicture])

    return (
        <div className={clsx(classes.avatarContainer, {
            [classes.avatarDisabled]: disabled
        })}>
            {
                profilePicture
                    ?
                    <img src={src} alt="profile" className={classes.img} />
                    :
                    <Typography variant="overline" className={classes.initials}>
                        {username[0]}{lastname[0]}
                    </Typography>
            }
        </div>
    )
}

export default Avatar