import API from '../api'
import errorHandler from '../actions/errorHandler'

export const getNotifications = () => async (dispatch: any) => {
    let response
    try {
      response = await API.get('/api/notifications')
      dispatch({ type: 'NOTIFICATIONS_LOADED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const readNotification = (data: number) => async (dispatch: any) => {
    let response
    try {
      response = await API.patch('/api/notification', {notificationId: data})
      dispatch({ type: 'NOTIFICATION_READ', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }