import {
    Paper,
    Typography,
    Divider,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    createWallet,
    resetUniqueNameError,
    resetNewWallet
} from '../actions/wallets'
import { useHistory } from 'react-router';
import clsx from 'clsx';

import InputField from '../commons/components/Input';
import Button from '../commons/components/Button'
import Breadcrumbs from '../commons/components/Breadcrumbs';
import BackButton from '../commons/components/BackButton';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "auto",
        padding: theme.spacing(7),
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        marginBottom: theme.spacing(11),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    column: {
        width: "47%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    columnfullWidth: {
        width: "100%"
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            justifyContent: "space-between",
            left: theme.spacing(7),
            right: theme.spacing(7)
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            width: '100%',
            paddingLeft: theme.spacing(0),
        }
    },

    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing(6)
    },
    divider: {
        marginBottom: theme.spacing(6)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CreateWalletProps extends PropsFromRedux {
    inModal?: boolean
    handleCancel?: () => void
}

const CreateWallet: FC<CreateWalletProps> = ({
    createWallet,
    resetNewWallet,
    handleCancel,
    newWallet,
    inModal = false,
    uniqueNameError,
    errors,
    resetUniqueNameError
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const [fields, setFields] = useState({
        name: "",
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (newWallet.id !== 0) {
            setLoading(false)
            if (inModal) {
                handleCancel && handleCancel()
            } else {
                history.push(`/wallets/${newWallet.id}`)
            }
        }
        return () => {
            resetNewWallet()
            resetUniqueNameError()
        }
    }, [newWallet, history, resetNewWallet, handleCancel, inModal, resetUniqueNameError])

    useEffect(() => {
        if (uniqueNameError || errors.length !== 0) {
            setLoading(false)
        }
    }, [uniqueNameError, errors])

    const handleInputChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleSave = () => {
        setLoading(true)
        createWallet({
            name: fields.name
        })
    }

    return (
        <>
            {!inModal && 
                <>
                    <Breadcrumbs />
                    <Divider className={classes.divider} />
                </>
            }
            <Paper elevation={0} className={classes.container}>
                <div className={classes.titleRow}>
                    <Typography
                        variant="h5"
                        className={classes.highEmphasis}
                    >
                        {t('wallets.createTitle')}
                    </Typography>
                    {!inModal && <BackButton />}
                </div>
                <div className={classes.columnsRow}>
                    <div className={clsx(classes.column, {
                        [classes.columnfullWidth]: inModal
                    })}>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('wallets.name')}
                        </Typography>
                        <InputField
                            value={fields?.name || ""}
                            onChange={handleInputChange}
                            name="name"
                            error={uniqueNameError ? true : false}
                            errorText={uniqueNameError?.msg}
                            placeholder={t('wallets.createPlaceholder')}
                        />
                    </div>
                </div>
                <div className={classes.btnRow}>
                    {inModal && <div className={classes.btn}>

                        <Button
                            text={t('general.Cancel')}
                            version="text"
                            width="100%"
                            onClick={handleCancel}
                        />

                    </div>}
                    <div className={classes.btn}>
                        <Button
                            text={t('general.Save')}
                            version="primary"
                            width="100%"
                            disabled={!fields.name}
                            onClick={handleSave}
                            loading={loading}
                        />
                    </div>
                </div>
            </Paper>
        </>
    )
}

const mapState = (state: RootState) => ({
    newWallet: state.wallets.newWallet,
    uniqueNameError: state.errorReducer.uniqueNameError,
    errors: state.errorReducer.errors
})

const mapDispatch = {
    createWallet: (data: Object) => (createWallet(data)),
    resetNewWallet: () => (resetNewWallet()),
    resetUniqueNameError: () => resetUniqueNameError()
}

const connector = connect(mapState, mapDispatch)

export default connector(CreateWallet)