import {
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../commons/components/Button'

import DownloadAppBtns from '../commons/components/DownloadAppBtns';
import illustration from './assets/downloadAppIllustration.png';

const useStyles = makeStyles((theme?: any) => ({
    text: {
        textAlign: "center",
        color: theme.palette.onSurface.mediumEmphasis
    },
    illustration: {
        width: "200px",
height: "240px"
    }
}))

interface DownloadAppCardProps {
    label?: string
    send: any
}

const DownloadAppCard: FC<DownloadAppCardProps> = ({
    label,
    send,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <Typography variant="h5" >
                {t('trial.downloadTitle')}
            </Typography>
            <Typography variant="body2" className={classes.text}>
                {t('trial.downloadText')}
            </Typography>
            <img src={illustration} alt="" className={classes.illustration} />
            <DownloadAppBtns 
                label={t('trial.shortDownloadLabel')}
            />
            <Button 
                version="secondary"
                text={t('general.continue')}
                width="100%"
                onClick={()=>send('PERSONALINFO')}
                size="medium"
            />
        </>
    )
}

export default DownloadAppCard