import { AnyAction } from 'redux'

const initialState = {
  delete: {
      open: false,
      data: {
          name: "",
          handleDelete: () => {}
      }
  },
  disable: {
    open: false,
    data: {
      text: "",
      text2: "",
      name: "",
      lastName: "",
      handleAction: () => {},
      disabled: false
    }
  },
  limit: {
    open: false,
    data: {
      plan: "",
      limit: 0,
      feature: ""
    }
  },
  showOffer: {
    open: false,
    data: {
      qrcode: "",
      contact: ""
    }
  },
  sendOffer: {
    open: false,
    data: {
      emailFromVc: "",
      handleSendOffer: async (email: string)=> {}
    }
  },
  tokenWarning: {
    open: false,
    data: {
      customer: {id: 0, name: ""},
      handleTokenReset: ()=>{}
    }
  },
  tokenReSet: {
    open: false,
    data: {

    }
  },
  context: {
    open: false
  },
  welcome: {
    open: false,
    data: {
      active: false,
      toUpgrade: 0
    }
  },
  credentialRepo: {
    open: false,
    data: {}
  },
  changeMainContact: {
    open: false,
    data: {
      customer: {
        id: 0
      }
    }
  },
  processingUpgrade: {
    open: false,
    data: {}
  },
  walletDetails: {
    open: false,
    data: {
        id: 0,
        did: "",
        publicKey: "",
        accessToken: false
    }
  },
  delegate: {
    open: false,
    data: {}
  },
  askGlobal: {
    open: false,
    data: {}
  }
}

type AdminReducer = typeof initialState
  
export default function adminReducer(state=initialState, action: AnyAction): AdminReducer {
    switch (action.type) {
      case 'OPEN_MODAL': {
        return {
          ...state,
            [action.payload.modalName]: {
                open: true,
                data: action.payload.data
            },
          }
        }
        case 'CLOSE_MODAL': {
          return {
            ...state,
            [action.payload.modalName]: {
                open: false,
                data: {}
            },
        }
      }
      default:
        return state;
    }
  }