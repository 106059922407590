import {
    Divider,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import CredentialStatusCard from './CredentialStatusCard';
import Breadcrumbs from '../commons/components/Breadcrumbs';

import {
    getTemplate,
} from '../actions/credentials';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CredentialCardProps extends PropsFromRedux {
}

const CredentialCard: FC<CredentialCardProps> = ({
    getTemplate,
    mainContact,
    template,
}) => {
    const classes = useStyles()
    // @ts-ignore
    let { credentialId } = useParams()
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        getTemplate(credentialId)
    }, [mainContact, getTemplate, credentialId])

    return (
        <div className={classes.container}>

            <Breadcrumbs item={template} />
            <Divider />

            <CredentialStatusCard
                credential={template}
                disabled={disabled}
                setDisabled={setDisabled}
            />

        </div>
    )
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    template: state.credentials.template,
    proofs: state.proofs.proofs,
    permissions: state.login.permissions
})

const mapDispatch = {
    getTemplate: (TemplateId: number) => getTemplate(TemplateId)
}

const connector = connect(mapState, mapDispatch)

export default connector(CredentialCard)