import {
    Paper,
    Typography,
    Divider,
    Snackbar,
    Tooltip,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getOffer,
    sendOffer,
    resetQrCode,
    resetDestinationEmail
} from '../actions/admin';
import { validateEmail } from '../commons/utils';
import { Admin } from '../commons/types'
import clsx from 'clsx';
import { setEditingMode } from '../actions/dashboard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sideosTheme } from "../themes/sideosTheme";
import { 
    allowedPermissions, 
    incomingRole,
    roleNames
} from '../commons/permissions';

import deleteIcon from './assets/delete-icon.svg'
import questionIcon from '../commons/components/assets/question-icon.png'

import InputField from '../commons/components/Input';
import Button from '../commons/components/Button'
import StatusBadge from '../commons/components/StatusBadge';
import IconBtn from '../commons/components/IconButton';
import VisualizeModal from '../commons/components/VisualizeModal';
import SendOfferModal from '../commons/components/SendOfferModal';
import BackButton from '../commons/components/BackButton';
import DeleteModal from './DeleteModal';
import InputSelect from '../commons/components/InputSelect';
import { hasPermissions } from '../commons';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "456px",
        padding: theme.spacing(7),
        marginTop: theme.spacing(6),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    edit: {
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    column: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            marginTop: theme.spacing(5)
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm', 'md')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0)
        },

    },
    status: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(9),
        }
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    snackbar: {
        "& .MuiSnackbarContent-root": {
            height: "48px",
            width: "344px",
            backgroundColor: theme.palette.onSurface.highEmphasis,
            padding: theme.spacing(4, 'auto', 4, 5)
        }
    },
    iconBtns: {
        display: "flex"
    },
    tooltip: {
        borderRadius: theme.shape.sharpBorderRadius,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    inputSelect: {
        position: "relative"
    },
    row: {
        display: "flex",
        alignItems: "center"
    },
    questionIcon: {
        width: "16px",
        height: "16px",
        marginLeft: theme.spacing(4)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface AdminStatusCardProps extends PropsFromRedux {
    handleSave?: () => void,
    handleInputChange?: (e: any) => void
    fields?: { name: string, lastName: string, email: string, permission: any  }
    admin?: Admin
    disabledOffer?: boolean
    disabledInput?: boolean
    setDisabledInput?: (arg: boolean) => void
    handleDelete?: () => void
    admins?: Admin[]
    handleSelect?: (name: string, value: string) => void
}

const AdminStatusCard: FC<AdminStatusCardProps> = ({
    handleSave,
    handleInputChange,
    resetDestinationEmail,
    fields,
    disabledOffer,
    admin,
    disabledInput,
    setDisabledInput,
    handleDelete,
    qrcode,
    emailSent,
    getOffer,
    sendOffer,
    resetQrCode,
    emailAddress,
    permissions,
    handleSelect,
    mainContact
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [destinationEmail, setDestinationEmail] = useState("")
    const [openSendOfferModal, setOpenSendOfferModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [error, setError] = useState(false)
    const DRAFT = 1
    const REJECTED = 3
    const matches = useMediaQuery(sideosTheme.breakpoints.down('sm'));
    const [loadingVisualizeBtn, setLoadingVisualizeBtn] = useState(false)
    const [loadingEmailBtn, setLoadingEmailBtn] = useState(false)

    const renderText = (item: number) => {
        const statusText = [
            t('statusText.none'),
            t('statusText.draft'),
            t('statusText.pending'),
            t('statusText.rejected'),
            t('statusText.accepted')
        ]
        let text = statusText[item]
        return text
    }

    useEffect(()=>{
        return ()=>{
            setOpenSendOfferModal(false)
        }
    },[])

    useEffect(() => {
        if (fields?.email) {
            setError(!validateEmail(fields?.email))
        }
    }, [fields?.email])

    useEffect(() => {
        if (admin) {
            setDestinationEmail(admin?.email)
        }
    }, [admin])

    useEffect(() => {
        if (!disabledInput) {
            setEditingMode(true)
        }
        return () => {
            setEditingMode(false)
        }
    }, [disabledInput])

    const handleEdit = () => {
        setDisabledInput && setDisabledInput(false)
    }

    const onVisualizeOffer = async () => {
        setLoadingVisualizeBtn(true)
        await getOffer({ MainContactId: admin?.id })
        setLoadingVisualizeBtn(false)
    }

    const handleCloseVisualizationModal = () => {
        resetQrCode()
    }

    const onDestinationEmailChange = (e: any) => {
        setDestinationEmail(e.target.value)
    }

    const handleSendOffer = async () => {
        setLoadingEmailBtn(true)
        await sendOffer({
            MainContactId: admin?.id,
            email: destinationEmail
        })
        setLoadingEmailBtn(false)
        setOpenSendOfferModal(false)
    }

    const cancelSendOffer = () => {
        setOpenSendOfferModal(false)
    }

    const onCloseSnackbar = () => {
        resetDestinationEmail()
    }

    return (
        <>
        {(matches && openSendOfferModal) || (matches && qrcode.data) ? 
            <>
            {openSendOfferModal &&
                <SendOfferModal
                    open={openSendOfferModal}
                    email={destinationEmail}
                    onDestinationEmailChange={(e) => onDestinationEmailChange(e)}
                    handleSendOffer={handleSendOffer}
                    handleCancel={cancelSendOffer}
                    inMobile={true}
                />}

            {qrcode.data && <VisualizeModal
                    open={qrcode.data ? true : false}
                    qrcode={qrcode.data}
                    handleCloseVisualizationModal={handleCloseVisualizationModal}
                    contact={t('visualizeModal.maincontact')}
                    inMobile={true}
                />}
            </>
            :
        <Paper elevation={0} className={clsx(classes.container, {
            [classes.edit]: !disabledInput
        })}>
            <div className={classes.titleRow}>
                <Typography
                    variant="h5"
                    className={classes.highEmphasis}
                >
                    {t('adminsTable.add')}
                </Typography>
                <div className={classes.iconBtns}>
                    {
                        (admin?.status === DRAFT || admin?.status === REJECTED) &&
                        hasPermissions(permissions,{ mainContacts: ["D"]}) ?
                         <IconBtn
                            iconDisabled="false"
                            onClick={() => setShowDeleteModal(true)}
                            iconSecondary={deleteIcon}
                            version="secondary"
                        />
                        : <></>
                    }
                    <BackButton />
                </div>
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('adminsTable.name')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.name || ""}
                            onChange={handleInputChange}
                            name="name"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('adminsTable.lastName')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.lastName || ""}
                            onChange={handleInputChange}
                            name="lastName"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('adminProfile.email')}
                        </Typography>
                        <InputField
                            disabled={disabledInput}
                            value={fields?.email || ""}
                            error={error}
                            onChange={handleInputChange}
                            errorText={t('error.email')}
                            name="email"
                        />
                    </div>
                </div>
                <Divider
                    orientation="vertical"
                    flexItem
                />
                <div className={classes.statusColumn}>
                    <div className={classes.row}>
                        <Typography 
                            variant="subtitle2" 
                            className={classes.label}
                        >
                            {t('settings.admin.permissions')}                   
                        </Typography>
                        <Tooltip
                            title={<div>{t('adminStatus.questionIconTooltip')}</div>}
                            classes={{ tooltip: classes.tooltip }}
                            placement="right-end"
                        >
                            <img 
                                src={questionIcon} 
                                alt="questionmark icon" 
                                className={classes.questionIcon} 
                            />
                        </Tooltip>
                    </div>
                    <div >
                        <div className={classes.inputSelect}>
                            <InputSelect
                                menuItems={Object.values(roleNames).filter((roleName: any)=>{
                                    return roleName.name !== "Global Admin"
                                })}
                                renderValue="name"
                                disabled={disabledInput}
                                value={fields?.permission || ""}
                                name="permission"
                                placeholder={t('general.Select')}
                                setValue={handleSelect}
                            />
                        </div>
                        </div>

                    {
                        admin?.status &&
                        <>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('adminProfile.offer')}
                            </Typography>
                            <div className={classes.status}>
                                <StatusBadge
                                    status={renderText(admin?.status)}
                                />
                            </div>
                            <Divider />
                        </>
                    }
                </div>
            </div>
            {hasPermissions(permissions, {
                mainContacts: ["E"], 
                templates: ["R"], 
                proof_template: ["R"], 
                proofs: ["R"], 
                users: ["R"], 
                user_permission: ["R"], 
                permissions: ["R"]
            }) &&
                allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)
                ?
                <div className={classes.btnRow}>
                    <Tooltip
                        title={!admin?.status || !disabledInput ? <div>{t('adminStatus.offerTooltip')}</div> : ""}
                        classes={{ tooltip: classes.tooltip }}
                        placement="bottom-end"
                    >
                        <div className={classes.btn}>
                            <Button
                                text={t('adminStatus.visualizeBtn')}
                                version="secondary"
                                disabled={disabledOffer || !disabledInput}
                                onClick={onVisualizeOffer}
                                size="small"
                                width="100%"
                                loading={loadingVisualizeBtn}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={!admin?.status || !disabledInput ? <div>{t('adminStatus.offerTooltip')}</div> : ""}
                        classes={{ tooltip: classes.tooltip }}
                        placement="bottom-end"
                    >
                        <div className={classes.btn}>
                            <Button
                                text={t('adminStatus.sendBtn')}
                                version="secondary"
                                disabled={disabledOffer || !disabledInput}
                                fullWidth={true}
                                size="small"
                                width="100%"
                                onClick={() => setOpenSendOfferModal && setOpenSendOfferModal(true)}
                            />
                        </div>
                    </Tooltip>
                    {
                        (!admin || admin?.status === DRAFT) &&
                        <div className={classes.btn}>
                            <Button
                                size="small"
                                width="100%"
                                text={disabledInput ? t('general.Edit') : t('general.Save')}
                                fullWidth={true}
                                disabled={!fields?.email || !fields.name || !fields.lastName || !fields.permission || error }
                                onClick={disabledInput ? handleEdit : handleSave}
                            />
                        </div>
                    }
                </div>
                : <div className={classes.btnRow}></div>
            }
            {qrcode.data && <VisualizeModal
                open={qrcode.data ? true : false}
                qrcode={qrcode.data}
                handleCloseVisualizationModal={handleCloseVisualizationModal}
                contact={t('visualizeModal.newadmin')}
            />}
            {openSendOfferModal && <SendOfferModal
                open={openSendOfferModal}
                email={destinationEmail}
                onDestinationEmailChange={onDestinationEmailChange}
                handleSendOffer={handleSendOffer}
                handleCancel={cancelSendOffer}
                loading={loadingEmailBtn}
            />}
            {showDeleteModal && hasPermissions(permissions,{ mainContacts: ["D"]}) && <DeleteModal
                open={showDeleteModal}
                setShowModal={setShowDeleteModal}
                handleDelete={() => handleDelete && handleDelete()}
                id={admin && admin?.id}
                name={admin && admin?.name + " " + admin?.lastName}
            />}
            {emailSent && <Snackbar
                open={emailSent ? true : false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                message={<div>{t('adminStatus.snackbar')}: <b>{emailAddress}</b></div>}
                autoHideDuration={3000}
                onClose={onCloseSnackbar}
                className={classes.snackbar}
            />}
        </Paper>
        }
        </>
    )
}

const mapState = (state: RootState) => ({
    qrcode: state.admin.qrcode,
    emailSent: state.admin.emailSent,
    emailAddress: state.admin.emailAddress,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    getOffer: (data: Object) => (getOffer(data)),
    sendOffer: (data: Object) => (sendOffer(data)),
    resetDestinationEmail: () => (resetDestinationEmail()),
    resetQrCode: () => (resetQrCode()),
    setEditingMode: (data: boolean) => (setEditingMode(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(AdminStatusCard)
