import {
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme?: any)=>({
    date: {
        color: theme.palette.onSurface.highEmphasis
    },
    time: {
        color: theme.palette.onSurface.mediumEmphasis
    }
}))

interface TableDateAndTimeProps {
    dateAndTime: string
}

const TableDateAndTime: FC<TableDateAndTimeProps> = ({
    dateAndTime
}) => {
    const classes = useStyles()
    
    return (
        <>
            <Typography variant="body2" className={classes.date}>
                {dateAndTime ? dateAndTime.split(" ")[0] : "-"}
            </Typography>
            <Typography variant="overline" className={classes.time}>
                {dateAndTime ? dateAndTime.split(" ")[1] : "-"}
            </Typography>
        </>
    )         
}

export default TableDateAndTime