import { FC } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme?: any)=>({
    disabled: {
        color: theme.palette.onSurface.disabled
      },
      titleRow: {
          height: "36px",
          background: theme.palette.primary[50],
          paddingLeft: theme.spacing()
      },
      detailRow: {
          height: "66px",
          background: theme.palette.primary.hover,
          paddingLeft: theme.spacing(6),
          paddingTop: theme.spacing(3)
      },
      mediumEmphasis: {
          color: theme.palette.onSurface.mediumEmphasis,
          marginBottom: theme.spacing(3)
      },
      cell: {
          borderBottom: "none",
          padding: theme.spacing(0, 0, 0, 6),
          maxWidth: "10px",
          wordWrap: "break-word"
      },
      column: {
          display: "flex",
          flexDirection: "column",
          paddingTop: theme.spacing(3)
      }
}))

interface TransactionsCollapseTableProps {
    titles?: {[name: string]: string}
    item?: any
}

const TransactionsCollapseTable: FC<TransactionsCollapseTableProps> = ({
    titles, 
    item,
}) => {
    const classes = useStyles()
    const statuses = [null, "Offer", "Request", "Creation", "Offer-Request", "Multi-Offer", "Multi-Request", "Multi-Offer-Request", "Test"]

        return (
            <Table>
                <TableHead>
                    <TableRow className={classes.titleRow}>
                        {titles && Object.keys(titles).map((title, index)=>{
                            return (
                                <TableCell className={classes.cell} key={index}>
                                    <Typography 
                                        variant="overline" 
                                        className={classes.disabled}
                                    >
                                        {titles[title]}
                                    </Typography>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.detailRow}>   
                    {titles && Object.keys(titles).map((title, index)=>{
                            return (
                                <TableCell className={classes.cell} key={index}>
                                    <Typography 
                                        variant="body1" 
                                        className={classes.mediumEmphasis}
                                    >
                                        {typeof item[title] === "number" ? statuses[item[title]] : item[title]}
                                    </Typography>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        )
}

export default TransactionsCollapseTable