import { connect, ConnectedProps } from 'react-redux'
import {
    Typography,
    Dialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState, useEffect, FC, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor'
import Button from '../commons/components/Button'
import zoomin from './assets/icons/zoomin.svg'
import zoomout from './assets/icons/zoomout.svg'
import { uploadCompanyLogo, uploadProfilePicture, deteleProfilePicture, deleteCompanyLogo } from '../actions/settings';
import { setImageFromBlob } from '../commons/utils';
import picTrash from './assets/icons/trashcan-icon.svg'
import disabledPicTrash from './assets/icons/trashcan-icon-disabled.svg'
import { hasPermissions } from '../commons';
import IconBtn from '../commons/components/IconButton';

const useStyles = makeStyles((theme?: any) => ({
    inputfile: {
        width: "0.1px",
        height: "0.1px",
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    },
    inputLabel: {
        minWidth: "207px",
        height: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(0),
        border: `1.6px solid ${theme.palette.borderColor.onSurface}`,
        color: theme.palette.onSurface.highEmphasis,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        "&:hover": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[800],
        },
        "&:focus": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.onSurface.highEmphasis,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    logoTitle: {
        color: theme.palette.borderColor.onSurface,
        margin: theme.spacing(5)
    },
    logoBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "92px",
        height: "92px",
        border: `1px dashed ${theme.palette.onSurface.mediumEmphasis}`,
        borderRadius: theme.shape.circle,
        marginRight: theme.spacing(5),
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        }
    },
    logoContainer: {
        display: "flex",
        alignItems: "flex-end",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    btnTrashRow:{
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            justifyContent: "space-between",
        }
    },
    img: {
        width: "90px",
        height: "90px",
        borderRadius: theme.shape.circle,
        textAlign: "center",
    },
    dialogBox: {
        width: "400px",
        height: "auto",
        padding: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5, 0),
            margin: theme.spacing(3),
        }
    },
    canvas: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "300px",
        }
    },
    title: {
        color: theme.palette.onSurface.highEmphasis,
        alignSelf: "flex-start",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(5),
        }
    },
    btnRow: {
        alignSelf: "flex-end",
        marginTop: theme.spacing(5),
        width: "380px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            alignSelf: "unset",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }
    },
    zoomRow: {
        display: "flex",
        width: "90%",
        marginTop: "-35px",
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(6)
        }
    },
    icon: {
        margin: theme.spacing(0, 2)
    },
    iconPurple: {
        filter: theme.palette.filter.whiteToLightPurple
    },
    assistiveText: {
        color: theme.palette.onSurface.disabled,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    assistiveTextContainer: {
        alignSelf: "flex-start",
        marginTop: "15px",
    },
    btn: {
        width: "180px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(0),
            width: "75%"
        }
    },
    trshLogo:{
        marginLeft: theme.spacing(6)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface ImgSettingsProps extends PropsFromRedux {
    imgSrc: string | null
    imgPlaceholder: string
    buttonText: string
    logoSrc?: any
    profileSrc?: any
    logo: boolean
    titlePreview: string, 
}

const ImgSettings: FC<ImgSettingsProps> = ({
    imgSrc,
    imgPlaceholder = "",
    buttonText = "",
    logoSrc,
    profileSrc,
    logo,
    settingsInfo,
    uploadCompanyLogo,
    deleteCompanyLogo,
    uploadProfilePicture,
    deteleProfilePicture,
    imgError,
    titlePreview = "",
    permissions,
    mainContact
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [imgPreview, setImgPreview] = useState("")
    const [imgName, setImgName] = useState("")
    const [src, setSrc] = useState("")
    const [scale, setScale] = useState(1)
    const [target, setTarget] = useState()
    const [errorImgSize, setErrorImgSize] = useState(false)
    const [errorFileType, setErrorFileType] = useState(false)
    const editorRef = useRef<AvatarEditor>(null)

    const mainContactId = settingsInfo?.maincontact?.id 
    const customerId = settingsInfo?.maincontact?.CustomerId

    useEffect(() => {
        if (logoSrc) {
            setImageFromBlob(logoSrc, setSrc)
        }
        if (profileSrc) {
            setImageFromBlob(profileSrc, setSrc)
        }
    }, [logoSrc, profileSrc])

    const handleImgPreview = (e: any) => {
        e.target.files[0].size > 307200 ? setErrorImgSize(true) : setErrorImgSize(false)
        e.target.files[0].type.split("/").pop() !== "jpg" &&
            e.target.files[0].type.split("/").pop() !== "jpeg" &&
            e.target.files[0].type.split("/").pop() !== "png" ?
            setErrorFileType(true) : setErrorFileType(false)
        setTarget(e.target.id)
        setImgName(e.target?.files?.[0] ? e.target?.files?.[0].name : "")
        setImgPreview(e.target?.files?.[0] ? URL.createObjectURL(e.target.files[0]) : "")
        e.target.value = ""
    }
   

    const handleSaveLogo = () => {
        if (errorImgSize) {
            imgError({ msg: t('error.imgSize') })
        } else if (errorFileType) {
            imgError({ msg: t('error.fileType') })
        } else if (editorRef !== null) {
            editorRef?.current?.getImage()?.toBlob((blob) => {
                const fd = new FormData()
                if (blob) {
                    fd.append("blob", blob)
                    fd.append("name", imgName)
                    // @ts-ignore
                    fd.append("CustomerId", settingsInfo?.maincontact?.Customer?.id || "")
                    uploadCompanyLogo({
                        file: fd
                    })
                }
            })
        }
        setImgPreview("")
        setScale(1)
    }

    const handleSaveProfile = () => {
        if (errorImgSize) {
            imgError({ msg: t('error.imgSize') })
        } else if (errorFileType) {
            imgError({ msg: t('error.fileType') })
        } else if (editorRef !== null) {
            editorRef?.current?.getImage()?.toBlob((blob) => {
                const fd = new FormData()
                if (blob) {
                    fd.append("blob", blob)
                    fd.append("name", imgName)
                    // @ts-ignore
                    fd.append("MainContactId", settingsInfo?.maincontact?.id || "")
                    uploadProfilePicture({
                        file: fd
                    })
                }
            })
        }
        setImgPreview("")
    }
    
    return (
        <>
            <div className={classes.logoContainer}>
                <div className={classes.logoBox}>
                    {imgSrc ?
                        <img
                            src={src}
                            alt="logo"
                            className={classes.img}
                        />
                        :
                        <Typography variant="button" className={classes.logoTitle}>
                            {imgPlaceholder}
                        </Typography>
                    }
                </div>
                <div className={classes.btnTrashRow}>
                    {
                        (!logo && (hasPermissions(permissions, {
                            admin: ["O"],
                            mainContacts: ["E","R"],
                            customers: ["R"],
                            wallets: ["R"]
                        }) || 
                        hasPermissions(permissions, {
                            mainContacts: ["E","R"],
                            customers: ["R"],
                            plans: ["R"],
                            wallets: ["R"]
                        })))
                        ||
                        (logo && mainContact.main && (hasPermissions(permissions, {
                            admin: ["O"],
                            customers: ["E","R"],
                            wallets: ["R"]
                        }) || 
                        hasPermissions(permissions, {
                            customers: ["E","R"],
                            wallets: ["R"],
                            plans: ["R"]
                        })))
                        ?
                        <label htmlFor={logo ? "logo" : "profile"} className={classes.inputLabel}>
                            {buttonText}
                        </label>
                        : <></>
                    }
                    {
                        (!logo && (hasPermissions(permissions, {
                            admin: ["O"],
                            mainContacts: ["E","R"],
                            customers: ["R"],
                            wallets: ["R"]
                        }) || 
                        hasPermissions(permissions, {
                            mainContacts: ["E","R"],
                            customers: ["R"],
                            plans: ["R"],
                            wallets: ["R"]
                        })))
                        ||
                        (logo && mainContact.main && (hasPermissions(permissions, {
                            admin: ["O"],
                            customers: ["E","R"],
                            wallets: ["R"]
                        }) || 
                        hasPermissions(permissions, {
                            customers: ["E","R"],
                            wallets: ["R"],
                            plans: ["R"]
                        })))
                        ?
                        <div className={classes.trshLogo}>
                            <IconBtn 
                                iconSecondary={picTrash}
                                iconDisabled={disabledPicTrash}
                                disabled={!imgSrc}
                                version="tertiary"
                                onClick={()=> 
                                    logo 
                                    ? 
                                    deleteCompanyLogo(customerId)
                                    : 
                                    deteleProfilePicture(mainContactId)
                                }
                            />
                        </div>
                        :
                        <></>
                    }
                </div>
                <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    id={logo ? "logo" : "profile"}
                    className={classes.inputfile}
                    onChange={(e) => handleImgPreview(e)}>
                </input>
            </div>
            <>
                <Dialog open={imgPreview ? true : false} classes={{ paper: classes.dialogBox }} maxWidth='md'>
                    <Typography variant="h6" className={classes.title}>
                        {titlePreview}
                    </Typography>
                    <div className={classes.canvas}>
                        <AvatarEditor
                            ref={editorRef}
                            image={imgPreview ? imgPreview : ""}
                            width={300}
                            height={300}
                            border={50}
                            borderRadius={300}
                            color={[0, 0, 0, 0.35]}
                            scale={scale}
                            rotate={0}
                        />
                        <div className={classes.zoomRow}>
                            <img
                                src={zoomin}
                                alt="zoom-in icon"
                                className={clsx(classes.icon, {
                                    [classes.iconPurple]: scale > 1
                                })}
                                onClick={() => setScale(scale + 0.5)}
                            />
                            <img
                                src={zoomout}
                                alt="zoom-out icon"
                                className={clsx(classes.icon, {
                                    [classes.iconPurple]: scale < 1
                                })}
                                onClick={() => setScale(scale === 0.5 ? scale : scale - 0.5)}
                            />
                        </div>
                        <div className={classes.assistiveTextContainer}>
                            <Typography className={classes.assistiveText}>
                                {t('settings.subtitle')}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.btnRow}>
                        <div className={classes.btn}>
                            <Button
                                text={t('general.Cancel')}
                                version="text"
                                onClick={() => setImgPreview("")}
                                width="100%"
                            />
                        </div>
                        <div className={classes.btn}>
                            <Button
                                text={t('general.Save')}
                                onClick={() => target === "logo" ? handleSaveLogo() : handleSaveProfile()}
                                width="100%"
                            />
                        </div>
                    </div>
                </Dialog>
            </>
        </>
    );
}

const mapState = (state: RootState) => ({
    token: state.login.token,
    settingsInfo: state.settings.settingsInfo,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    uploadCompanyLogo: (data: any) => (uploadCompanyLogo(data)),
    deleteCompanyLogo: (CustomerId: number)=> (deleteCompanyLogo(CustomerId)),
    uploadProfilePicture: (data: any) => (uploadProfilePicture(data)),
    deteleProfilePicture: (MainContactId: number)=> (deteleProfilePicture(MainContactId)),
    imgError: (error: { msg: string }) => ({ type: 'IMG_ERROR', error: error })
}

const connector = connect(mapState, mapDispatch)

export default connector(ImgSettings)
