import { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import type { RootState } from '../store'
import { FC } from 'react';
import { createAdmin } from '../actions/admin';
import {
  Divider,
} from '@material-ui/core';
import { getObjKey, roleNames, ROLES } from '../commons/permissions';

import Breadcrumbs from '../commons/components/Breadcrumbs';
import AdminStatusCard from './AdminStatusCard';
import { setEditingMode } from '../actions/dashboard';
import { incomingRole } from '../commons/permissions';

type PropsFromRedux = ConnectedProps<typeof connector>

interface NewAdminProps extends PropsFromRedux { }

const NewAdmin: FC<NewAdminProps> = ({
  createAdmin,
  mainContact,
  setEditingMode,
  permissions
}) => {
  let history = useHistory()
  const [fields, setFields] = useState({
    name: "",
    lastName: "",
    email: "",
    permission: 0
  })

  const handleSave = async () => {
    setEditingMode(false)
    const response = await createAdmin({
        CustomerId: mainContact.Customer.id,
        name: fields.name,
        lastName: fields.lastName,
        email: fields.email,
        //@ts-ignore
        PermissionId: fields.permission && ROLES[incomingRole(permissions)][getObjKey(roleNames, fields.permission)]
    })
    if (response) {
        history.push(`/settings/admins/${response.id}`)
    }
  }

  const handleInputChange = (e: any) => {
    setFields({ ...fields, [e.target.name]: e.target.value })
  }

  const handleSelect = (name: string, value: string) => {
      setFields({ ...fields, [name]: value })
  }

  return (
    <div >
      <Breadcrumbs />
      <Divider />
      <AdminStatusCard
        handleSave={handleSave}
        handleInputChange={handleInputChange}
        fields={fields}
        disabledOffer={true}
        handleSelect={handleSelect}
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
  mainContact: state.login.mainContact,
  permissions: state.login.permissions
})

const mapDispatch = {
  createAdmin: (data: Object) => (createAdmin(data)),
  setEditingMode: (data: boolean) => (setEditingMode(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(NewAdmin)
