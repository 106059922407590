import {
    Card,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from "react-i18next";
import gradientOverlay from './assets/trial-card-gradient.png'
import arrow from './assets/arrowUp.svg'
import { Admin } from "../commons/types";
import { plans } from "../commons/plans";
import { useHistory } from "react-router";

import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any) => ({
    container: {
        position: "relative",
        boxSizing: "border-box",
        background: theme.palette.gradient.purpleTrial,
        height: "270px",
        width: "567px",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(8),
        marginRight: theme.spacing(8),
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7),
            height: "33.5vh",
            marginRight: theme.spacing(0),
            width: "100%"
        }
    },
    columnLeft: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        zIndex: 1
    },
    columnRight: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        zIndex: 1,
        textAlign: "right",

    },
    smallPrint: {
        color: theme.palette.onPrimary.mediumEmphasis,
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            lineHeight: "20px",
        }
    },
    text: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(3),
    },
    mobileText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
          marginTop: theme.spacing(0),
        }
    },
    gradientOverlay: {
        position: "absolute",
        width: "100%",
        left: 0,
        bottom: 0,

    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "170px",
        textAlign: "left"
    },
    clockIcon: {
        marginRight: "10px"
    },
    counterColumn: {
        maxWidth: "134px"
    }
}))


interface DashboardCardProps {
    mainContact: Admin
    transactions: number
}

const DashboardCard: FC<DashboardCardProps> = ({
    mainContact,
    transactions
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Card className={classes.container}>
            <img
                src={gradientOverlay}
                className={classes.gradientOverlay}
            />
            <div className={classes.columnLeft}>
                <div>
                <Typography 
                    variant="subtitle2" 
                    className={classes.smallPrint}
                >
                    { t('trial.billingPlan') }
                </Typography>

                {/* ********** add the plan's name + if it's free or pro ************* */}
                <Typography 
                    variant="h5" 
                    className={classes.text}
                >
                    {/* @ts-ignore */}
                    {plans[mainContact?.Customer?.PlanId]?.name}
                </Typography>
                 <Typography 
                    variant="subtitle2" 
                    className={classes.smallPrint}
                >
                    { t('trial.freePlan') }
                </Typography>
                </div>
                <div>
                <Typography 
                    variant="subtitle1" 
                    className={classes.text}
                >
                    <b>{ t('trial.transactions') }</b>
                </Typography>
                <Typography 
                    variant="h3" 
                    className={classes.text}
                >
                    {transactions}
                </Typography>
                </div>
            </div>
            <div className={classes.columnRight}>
                <Button 
                    text={ t('trial.upgrade') }
                    size="large"
                    onClick={()=>history.push("/settings/billing/plans")}
                />
                <div className={classes.counterColumn}>
                    <div className={classes.row}>
                        <img src={arrow} className={classes.clockIcon}/>
                    <Typography 
                        variant="overline" 
                        className={classes.text}
                    >
                        { t('trial.expiration') }
                    </Typography>
                    </div>
                    
                </div>
            </div>
        </Card>
    )
}

export default DashboardCard