import { AnyAction } from 'redux'

const initialState = {
    transactionStats: {
      transactions: 0,
      diff: null,
    },
    templateStats: {
      templates: 0,
      diff: null
    },
    customerStats: {
      customers: 0,
      diff: null
    },
    adminStats: {
      maincontacts: 0,
      diff: null
    },
    editMode: false
  };

  type DashboardReducer = typeof initialState
  
export default function dashboardReducer(state = initialState, action: AnyAction): DashboardReducer {
    switch (action.type) {
      case 'TRANSACTION_STATS_LOADED': {
        return {
          ...state,
          transactionStats: action.payload
          }
      }
      case 'TEMPLATE_STATS_LOADED': {
        return {
          ...state,
          templateStats: action.payload
          }
      }
      case 'CUSTOMER_STATS_LOADED': {
        return {
          ...state,
          customerStats: action.payload
          }
      }
      case 'ADMIN_STATS_LOADED': {
        return {
          ...state,
          adminStats: action.payload
          }
      }
      case 'SET_EDIT_MODE': {
        return {
          ...state,
          editMode: action.payload
          }
      }
      default:
        return state;
    }
  }
  