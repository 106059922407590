import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Button from './Button'
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

const useStyles = makeStyles((theme?: any) => ({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: theme.spacing(8, 8),
        width: "523px",
        height: "276px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "276px",
            textAlign: "center",
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6),
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
        }
    },
    btnMargin: {
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(3),
            marginRight: "unset"
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: theme.spacing(4),
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        wordBreak: "break-all"
    }
}))


interface DisableModalProps {
    open: boolean
    walletName?: string
    onCancel?: () => void
    onAffirmative?: () => void
    disabled: boolean
}

const DisableModal: FC<DisableModalProps> = ({
    open = false,
    walletName,
    onCancel,
    onAffirmative,
    disabled
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogContainer }}
        >
            <Typography variant="h6">
                {t('disableWallet.title')}
            </Typography>
            <Typography
                variant="subtitle1"
                className={classes.mediumEmphasis}
            >
                {t('disableWallet.text1', {
                    action:
                        disabled
                            ?
                            t('disableWallet.enable')
                            :
                            t('disableWallet.disable')
                })} <b>{walletName}</b>?
            </Typography>
            <div className={classes.btnRow}>
                <div className={classes.btn}>
                    <Button
                        size={matches ? "small" : "large"}
                        width="100%"
                        text={t('disableWallet.btnCancel')}
                        version="secondary"
                        onClick={onCancel}
                    />
                </div>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button
                        size={matches ? "small" : "large"}
                        width="100%"
                        text={
                            disabled
                                ?
                                t('disableWallet.btnEnable')
                                :
                                t('disableWallet.btnDisable')
                        }
                        onClick={onAffirmative}
                        fullWidth
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default DisableModal