import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../store'
import { FC, useEffect, useState } from 'react';
import {
    Dialog,
    Typography
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Lottie from "lottie-react";
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { closeModal } from '../../actions/modals';
import { pollingUpgradeStorage } from '../../actions/trial';
import useInterval from '../hooks/useInterval';
import API from '../../api'
import { storePermissions, storeUser, storeToken } from '../../actions/login';
import { hasPermissions } from '..';

import spacewoman from './assets/spacewoman.png'
import animation from '../../commons/lotties/loading.json'
import { plans } from '../plans';

const useStyles = makeStyles((theme?: any) => ({
    paper: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth:"100%",
        width: "704px",
        height: "443px",
        padding: theme.spacing(9),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(0),
        }
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginBottom: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'space-between',
            padding: theme.spacing(6,7),
        }
    },
    titleText: {
        width: "248px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginTop: theme.spacing(6),
        "& a": {
            color: theme.palette.onSurface.highEmphasis,
            textDecoration: "none",
            display: "inline"
        }
    },
    gif: {
        width: "174px",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6)
          }
    },
    loadingContainer: {
        display: "flex",
        paddingLeft:theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            paddingLeft:theme.spacing(7),
        }
    },
    loadingText: {
        color: theme.palette.onSurface.disabled,
        marginLeft: theme.spacing(5)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface ProcessingModalProps extends PropsFromRedux {}

const ProcessingModal: FC<ProcessingModalProps> = ({
    mainContact,
    closeModal,
    processingUpgrade,
    pollingUpgradeStorage,
    permissions,
    dispatchUpgradeError
}) => {
    const classes = useStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation()
    const history = useHistory()  
    const [isCalling, setIsCalling] = useState(true)
    const [processingPlan, setProcessingPlan] = useState("")

    useEffect(()=>{
        localStorage.removeItem("trialWelcome")
        const plan = localStorage.getItem("processingPlan")
        plan && setProcessingPlan(plan)
        hasPermissions(permissions, {mainContacts: ["R"]}) && upgradeStorage()
    }, [permissions])

    const upgradeStorage = async () => {
        let response = await pollingUpgradeStorage(mainContact.CustomerId, mainContact.Customer.PlanId)
        if (response?.status === 2) {
            setIsCalling(false)
            closeModal({modalName: "processingUpgrade"})
        } else if (response.status === 1) {
            const parsed = JSON.parse(response.data)
            if (parsed.token) {
                API.setAuthToken(parsed.token)
                localStorage.setItem('token', parsed.token)
                localStorage.setItem('permissions', JSON.stringify(parsed.permissions))
                localStorage.setItem('mainContact', JSON.stringify(parsed.mainContact))
                localStorage.setItem('newPlan', parsed.mainContact.Customer.PlanId)
                storePermissions(parsed.permissions)
                storeUser(parsed.mainContact)
                storeToken(parsed.token)
            }
            localStorage.removeItem("processingPlan")
            closeModal({modalName: "processingUpgrade"})
            history.push("/")
            window.location.reload()
        }
    }

    useInterval(async()=>{
        upgradeStorage()
    }, isCalling ? 5000 : null)

    setTimeout(()=>{
        if (isCalling) {
            setIsCalling(false)
            dispatchUpgradeError({
                msg: t('error.general'), 
                btnTxt: t('general.support').toUpperCase(),
                action: (e: any) => {
                    e.preventDefault()
                    window.location.href="mailto:support@sideos.io"
                }
            })
            closeModal({modalName: "processingUpgrade"})
        }
    }, 600000)

    return (
        <Dialog
            open={processingUpgrade.open}
            classes={{ paper: classes.paper}}
            fullScreen={fullScreen}
        >
            <div className={classes.contentContainer}>
                <div>
                    <img 
                        src={spacewoman} 
                        alt="spacewoman icon" 
                        className={classes.gif} 
                    />
                </div>
                <div className={classes.titleText}>
                    <Typography variant="h5">
                        {t('processingModal.thanks', 
                            {planName: mainContact?.Customer?.chargebee?.toUpgrade ? plans[mainContact?.Customer?.chargebee?.toUpgrade]?.name : plans[parseInt(processingPlan)]?.name}
                        )}
                    </Typography>
                    <Typography 
                        variant="subtitle1" 
                        className={classes.mediumEmphasis}
                    >
                        {t('processingModal.text')}
                    </Typography>
                </div>
            </div>
            <div className={classes.loadingContainer}>
                <Lottie 
                    animationData={animation} 
                    loop={true}
                    style={{
                        height: "24px",
                    }}
                />
                <Typography
                    variant="subtitle1"
                    className={classes.loadingText}
                >
                    {t('processingModal.loading', 
                        {planName: mainContact?.Customer?.chargebee?.toUpgrade ? plans[mainContact?.Customer?.chargebee?.toUpgrade]?.name : plans[parseInt(processingPlan)]?.name}
                    )}
                </Typography>
            </div>
        </Dialog>
    );
}

const mapState = (state: RootState) => ({
    processingUpgrade: state.modals.processingUpgrade,
    mainContact: state.login.mainContact,
    permissions: state.login.permissions
})

const mapDispatch = {
    closeModal: (data: Object) => (closeModal(data)),
    pollingUpgradeStorage: (CustomerId: number, PlanId: string) => (pollingUpgradeStorage(CustomerId, PlanId)),
    storePermissions: (data: string) => (storePermissions(data)),
    storeUser: (data: string) => (storeUser(data)),
    storeToken: (data: string) => (storeToken(data)),
    dispatchUpgradeError: (data: Object) => ({type: 'ERROR', payload: data})
}

const connector = connect(mapState, mapDispatch)

export default connector(ProcessingModal)
