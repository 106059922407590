import atom from '../planonboarding/assets/atomPlan.svg'
import dimension from '../planonboarding/assets/dimensionPlan.svg'

export const plans: any = { 
    1: {
        id: 1,
        name: "Free",
        price: 0,
        checks: [1,2,3,4,5,6]
    },
    2: {
        id: 5,
        name: "Corporate Custom",
        checks: [1,2,3,4,5,6]
    }
}