import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, VoidFunctionComponent } from 'react';
import Button from '../commons/components/Button'
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme?: any) => ({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(8, 8),
        width: "532px",
        height: "292px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "276px",
            textAlign: "center",
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            marginRight: "unset",
            flexDirection: "column",
            width: "100%"
        }
    },
    btnMargin: {
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        }
    },
    btn: {
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: theme.spacing(4),
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    }
}))


interface ResendOfferModalProps {
    open?: boolean,
    closeModal: () => void,
    resendOffer: () => void,
    loading: boolean
}

const ResendOfferModal: FC<ResendOfferModalProps> = ({
    open = true,
    closeModal,
    resendOffer,
    loading
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogContainer }}
        >
            <Typography variant="h6">
                {t('onBoardingCredentialModal.title')}
            </Typography>
            <Typography
                variant="subtitle1"
                className={classes.mediumEmphasis}
            >
                {t('onBoardingCredentialModal.text')}
              
            </Typography>
            <div className={classes.btnRow}>
                <div className={classes.btn}>
                    <Button
                        size={matches ? "small" : "large"}
                        text={t('general.Cancel')}
                        version="text"
                        width="180px"
                        onClick={closeModal}
                    />
                </div>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button
                        size={matches ? "small" : "large"}
                        text={t('onBoardingCredentialModal.sendBtn')}
                        width="180px"
                        onClick={resendOffer}
                        loading={loading}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default ResendOfferModal