import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Divider,
    Paper,
    Typography,
} from '@material-ui/core';
import { useParams } from 'react-router';
import {
    getWallet,
    getUnassignedCustomers,
    assignWallet
} from '../actions/wallets';
import {
    resetNewCustomer 
} from '../actions/onboarding';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Customer } from '../commons/types'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sideosTheme } from "../themes/sideosTheme";
import { hasPermissions } from '../commons';
import { isFlag } from '../commons/utils';

import plusIcon from './assets/plus-icon.svg'

import Breadcrumbs from '../commons/components/Breadcrumbs';
import Button from '../commons/components/Button'
import InputSelect from '../commons/components/InputSelect';
import WalletPage from './WalletPage';

import OnBoardingModal from './OnBoardingModal';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "auto",
        padding: theme.spacing(7),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(7),
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        marginBottom: theme.spacing(11),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginBottom: theme.spacing(5),
        }
    },
    column: {
        width: "47%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(3),
            flexDirection: "column",
            position: "relative",
            width: "100%"
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0),
        }
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing(6)
    },
    selectOther: {
        display: "flex"
    },
    plusIcon: {
        marginRight: theme.spacing(4)
    },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface ViewAndAssignWalletProps extends PropsFromRedux { }

const ViewAndAssignWallet: FC<ViewAndAssignWalletProps> = ({
    getWallet,
    assignWallet,
    wallet,
    unassignedCustomers,
    getUnassignedCustomers,
    newCustomer,
    resetNewCustomer,
    accessToken,
    permissions
}) => {
    // @ts-ignore
    const { walletId } = useParams()
    const { t } = useTranslation()
    const classes = useStyles()
    const [fields, setFields] = useState({
        name: ""
    })
    const [customerToAssign, setCustomerToAssign] = useState<Customer | undefined>()
    const [openOnboardCompany, setOpenOnboardCompany] = useState(false)
    const matches = useMediaQuery(sideosTheme.breakpoints.down('sm'));
    const DELETED = 8
    const DISABLED = 1

    useEffect(() => {
        if (walletId) {
            getWallet(walletId)
        }
    }, [walletId, getWallet])

    useEffect(()=>{
        if (accessToken) {
            getWallet(walletId)
        }
    }, [accessToken, getWallet, walletId])
    
    useEffect(()=>{
        if (
            wallet.id !== 0 && 
            !wallet.CustomerId && 
            (hasPermissions(permissions, {
                admin: ["O"],  
                customers: ["RO"],   
                wallets: ["RO"],        
                mainContacts: ["RO"],        
                templates: ["RO"],        
                proof_template: ["RO"],        
                proofs: ["RO"]
            }) ||
            hasPermissions(permissions, {
                tenant: ["O"],
                customers: ["R"],
                wallets: ["R"],
                mainContacts: ["R"],
                templates: ["R"],
                proof_template: ["R"],
                proofs: ["R"]
            })
            )
        ) {
            getUnassignedCustomers()
        }
    }, [wallet, getUnassignedCustomers, permissions])

    useEffect(()=>{
        if (newCustomer?.id !== 0) {
            handleSelect("name", newCustomer)
            resetNewCustomer()
        }
      }, [newCustomer, resetNewCustomer])

    const handleSelect = (name: string, value: any) => {
        setFields({
            name: value.name
        })
        setCustomerToAssign(value)
    }

    const handleAssign = () => {
        assignWallet({
            CustomerId: customerToAssign?.id,
            WalletId: parseInt(walletId)
        })
    }

    const newCompanyCTA = <div
        className={classes.selectOther}
    >
        <img
            src={plusIcon}
            alt="plus icon"
            className={classes.plusIcon}
        />
        <Typography variant="body2">
            {t('wallets.onboardNew')}
        </Typography>
    </div>

    return (
        <div>

            <Breadcrumbs item={wallet} />
            <Divider />
            {matches && openOnboardCompany && 
            (hasPermissions(permissions, {admin: ["O"], customers: ["WO","RO"], wallets: ["WO"], mainContacts: ["WO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) || 
            hasPermissions(permissions, {tenant: ["O"], customers: ["W","R"], wallets: ["W"], mainContacts: ["W"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) ? 
                <OnBoardingModal
                    open={openOnboardCompany}
                    setOpen={setOpenOnboardCompany}
                    inMobile={matches}
                    newCustomer={newCustomer}
                />
            :
            <>
            <WalletPage wallet={wallet} />
            {
                !wallet.CustomerId && 
                !isFlag(wallet.status, DELETED) &&
                !isFlag(wallet.status, DISABLED) &&
                (
                    hasPermissions(permissions, {admin: ["O"], wallets: ["EO","RO"], customers: ["RO"]}) ||
                    hasPermissions(permissions, {tenant: ["O"], wallets: ["E","R"], customers: ["R"]})
                ) &&
                <>
                    <Paper elevation={0} className={classes.container}>
                        <div className={classes.titleRow}>
                            <Typography
                                variant="h5"
                                className={classes.highEmphasis}
                            >
                                {t('wallets.assign')}
                            </Typography>
                        </div>
                        <div className={classes.columnsRow}>
                            <div className={classes.column}>
                                <div>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {t('wallets.company')}
                                    </Typography>
                                    <InputSelect
                                        menuItems={unassignedCustomers}
                                        placeholder={t('wallets.companyPlaceholder')}
                                        renderValue="name"
                                        selectOther={
                                            (hasPermissions(permissions, {admin: ["O"], customers: ["WO","RO"], wallets: ["WO"], mainContacts: ["WO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) || 
                                            hasPermissions(permissions, {tenant: ["O"], customers: ["W","R"], wallets: ["W"], mainContacts: ["W"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) ? 
                                            true 
                                            : 
                                            false
                                        }
                                        setValue={handleSelect}
                                        name="name"
                                        value={fields.name || ""}
                                        otherElement={newCompanyCTA}
                                        setOther={setOpenOnboardCompany}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.btnRow}>
                            <div className={classes.btn}>
                                <Button
                                    text={t('general.Assign')}
                                    version="primary"
                                    width="100%"
                                    disabled={!fields.name}
                                    onClick={handleAssign}
                                />
                            </div>
                        </div>
                    </Paper>
                </>
            }
            {openOnboardCompany && 
                (hasPermissions(permissions, {admin: ["O"], customers: ["WO","RO"], wallets: ["WO"], mainContacts: ["WO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) || 
                hasPermissions(permissions, {tenant: ["O"], customers: ["W","R"], wallets: ["W"], mainContacts: ["W"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) &&
                    <OnBoardingModal
                        open={openOnboardCompany}
                        setOpen={setOpenOnboardCompany}
                        inMobile={matches}
                        newCustomer={newCustomer}
                    />
            }
            </>
            }
        </div>
    );
}

const mapState = (state: RootState) => ({
    wallet: state.wallets.wallet,
    unassignedCustomers: state.wallets.unassignedCustomers,
    newCustomer: state.onboarding.newCustomer,
    accessToken: state.wallets.accessToken,
    permissions: state.login.permissions
})

const mapDispatch = {
    getWallet: (walletId: number) => (getWallet(walletId)),
    getUnassignedCustomers: () => (getUnassignedCustomers()),
    assignWallet: (data: Object) => (assignWallet(data)),
    resetNewCustomer: () => (resetNewCustomer()),
}

const connector = connect(mapState, mapDispatch)

export default connector(ViewAndAssignWallet)
