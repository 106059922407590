import { useState, useEffect, useRef, FC } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import QRCode from 'qrcode.react'
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { useMachine } from '@xstate/react';
import { trialWizard } from '../commons/statemachines/trialwizard'
import API from '../api';
import { storePermissions, storeUser, storeToken } from '../actions/login'
import { dispatchTrialError } from '../actions/trial';
import { RootState } from '../store';
import { useParams } from 'react-router';

import DownloadAppBtns from '../commons/components/DownloadAppBtns'
import DownloadAppCard from './DownloadAppCard';
import PersonalInfoCard from './PersonalInfoCard';
import TrialLogin from './PlanOnboardingLogin';
import WizardStepper from './WizardStepper';

const useStyles = makeStyles((theme: any) => ({
  wizardCard: {
     width: "424px",
    height: "auto", 
    minHeight: "574px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    border: `1px solid ${theme.palette.borderColor.onSurface}`,
    margin: "32px",
    padding: "36px",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "83%",
      padding: theme.spacing(7,5), 
      margin: theme.spacing(5), 
    }
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>
interface TrialWizardProps extends PropsFromRedux { }

const TrialWizard:FC<TrialWizardProps> = ({
  storePermissions,
  storeUser, 
  storeToken,
  dispatchTrialError, 
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  //@ts-ignore
  const [state, send] = useMachine(trialWizard);
  const [fields, setFields] = useState<any>({
    email: "",
    name: "",
    lastname: "",
    companyName: ""
})
const [error, setError] = useState(false)
const [counter, setCounter] = useState(0)
const [qrcode, setQrcode] = useState('')
const [checkboxes, setCheckboxes] = useState({
  checkedPrivacy: false,
  checkedNewsletter: false
})
const UPDATES = "updates"
const NEWSLETTER = "newsletter"
// @ts-ignore
const {planId} = useParams()

const countRef = useRef(counter);
countRef.current = counter

const openSocket = () => {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL + '/papi/newtrial')
  socket.onerror = (e: any) => {
    setError(e)
  }
  socket.onmessage = (e) => onMessage(e)

  
  socket.onopen = (e: any) => {
    const mailing = [UPDATES]
    checkboxes.checkedNewsletter && mailing.push(NEWSLETTER)
    const data: {[k: string]: any} = {
      email: fields.email,
      name: fields.name,
      lastName: fields.lastname,
      companyName: fields.companyName,
      mailing: mailing
    }
    if (planId === "2" || planId === "3") {
      data.toUpgrade = planId
    }
    socket.send(JSON.stringify(data))
  }
  return function cleanup() {
    clearTimeout(timeout)
    socket.close()
  }
}

let timeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000);

const decrement = (countRefCurrent: { current: number }) => {
  setCounter(countRefCurrent.current - 1)
  if (countRefCurrent.current > 0) {
    timeout = setTimeout(() => decrement(countRefCurrent), 1000)
  }
}

const onMessage = (message: any) => {
  try {
    const parsed = JSON.parse(message.data)
    if (parsed.errorMessage) {
        dispatchTrialError(parsed)
    }
    if (parsed.token) {
      API.setAuthToken(parsed.token)
      history.push("/")
      localStorage.setItem('token', parsed.token)
      localStorage.setItem('permissions', JSON.stringify(parsed.permissions))
      localStorage.setItem('mainContact', JSON.stringify(parsed.mainContact))
      localStorage.setItem('trialWelcome', "true")
      storePermissions(parsed.permissions)
      storeUser(parsed.mainContact)
      storeToken(parsed.token)
    }
    if (parsed.qrcode) {
      setQrcode(parsed.qrcode)
      setCounter(60)
      timeout = setTimeout(() => decrement(countRef), 1000)
    }
  } catch (e) {
  }
}

const handleInputChange = (e: any) => {
    setFields({ ...fields, [e.target.name]: e.target.value })
}

  return (
      <>
      <WizardStepper state={state} send={send} qrcode={qrcode} setQrcode={setQrcode}/>
      <Card 
        elevation={0}
        className={classes.wizardCard}
      >
        {state.value === 'downloadApp' && <DownloadAppCard send={send}/>}
        {state.value === 'personalInfo' && 
            <PersonalInfoCard 
                send={send} 
                fields={fields}
                handleInputChange={handleInputChange}
                qrcode={qrcode}
                setQrcode={setQrcode}
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                openSocket={openSocket}
            />
        }
        {state.value === 'scan' && 
            <TrialLogin 
                counter={counter}
                qrcode={qrcode}
                setQrcode={setQrcode}
                openSocket={openSocket}
            />
        }
      </Card>
      {state.value !== 'downloadApp' && <DownloadAppBtns label={t('trial.longDownloadLabel')}/>}
      </>
  );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
    storePermissions: (data: string) => (storePermissions(data)),
    storeUser: (data: string) => (storeUser(data)),
    storeToken: (data: string) => (storeToken(data)),
    dispatchTrialError: (data: Object) => (dispatchTrialError(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(TrialWizard)
