import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Paper,
    Typography,
    Divider,
    Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isFlag } from '../commons/utils';
import { getCustomerLogo } from '../actions/onboarding';
import { Customer } from '../commons/types'

import Avatar from '../commons/components/Avatar'
import InputField from '../commons/components/Input';
import Toggle from '../commons/components/Toggle';
import BackButton from '../commons/components/BackButton'
import Button from '../commons/components/Button'
import { plans } from '../commons/plans';
import { openModal } from '../actions/modals';
import { hasPermissions } from '../commons';
import { allowedPermissions, incomingRole } from '../commons/permissions';

const useStyles = makeStyles((theme?: any) => ({
    customerInfoContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
        width: "100%",
        height: "432px",
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        [theme.breakpoints.down('sm')]: {
            minHeight: "432px",
            height: "auto",
            marginTop: theme.spacing(0),
        }
    },
    border: {
        width: "100%",
        height: "13px",
        borderRadius: "8px 8px 0 0",
    },
    borderEnabled: {
        background: theme.palette.gradient.purple,
    },
    borderDisabled: {
        background: theme.palette.onSurface.disabled
    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(7, 8, 8, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7, 6, 8, 6)
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis,
        marginBottom: "8px"
    },
    titleColumn: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        marginLeft: theme.spacing(6),
        wordBreak: "break-all",
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(3),
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(0, 7, 8, 7),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
        }
    },
    column: {
        width: "47%",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    status: {
        paddingLeft: theme.spacing(5),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",

    },
    tooltip: {
        borderRadius: theme.shape.sharpBorderRadius,
        padding: theme.spacing(2)
    },
    icon: {
        marginLeft: theme.spacing(4)
    },
    nameInput: {
        color: theme.palette.onSurface.highEmphasis
    },
    left: {
        display: "flex",
        alignItems: "center",
    },
    walletRow: {
        display: "flex"
    },
    dividerMargin: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(5)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CustomerInfoCardProps extends PropsFromRedux {
    setOpenModal: (arg: boolean) => void
    customer: Customer | undefined
}

const CustomerInfoCard: FC<CustomerInfoCardProps> = ({
    customer,
    setOpenModal,
    customerLogo,
    getCustomerLogo,
    openModal,
    permissions,
    mainContact
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const DISABLED = 1

    useEffect(() => {
        // @ts-ignore
        if (customer && customer?.logo) {
            getCustomerLogo(customer?.id)
        }
    }, [customer, getCustomerLogo])

    const handleToggleClick = () => {
        setOpenModal(true)
    }

    return (
        <Paper className={classes.customerInfoContainer} elevation={0}>
            <div className={clsx(classes.border, {
                [classes.borderEnabled]: !isFlag(customer?.status, DISABLED),
                [classes.borderDisabled]: isFlag(customer?.status, DISABLED)
            })}></div>
            <div className={classes.row}>
                <div className={classes.left}>
                    <Avatar
                        username={customer?.name || ""}
                        size="60px"
                        fontSize="20px"
                        profilePicture={customerLogo}
                        disabled={isFlag(customer?.status, DISABLED) ? true : false}
                    />
                    <div className={classes.titleColumn}>
                        <Typography variant="h5" className={classes.highEmphasis}>
                            {customer?.name}
                        </Typography>
                    </div>
                </div>
                <BackButton />
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('onboarding.billingPlan')}
                    </Typography>
                    <InputField
                        disabled={true}
                        //@ts-ignore
                        value={plans[customer?.PlanId]?.name || ""}
                    />
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('onboarding.wallet')}
                    </Typography>
                    <div className={classes.walletRow}>
                        <InputField
                            disabled={true}
                            value={customer?.Wallet?.name || ""}
                        /> 
                        <Button 
                            text={t('onboarding.viewDetails')}
                            version="text"
                            onClick={()=>openModal({modalName: 'walletDetails', data: {
                                id: customer?.Wallet?.id,
                                accessToken: customer?.Wallet?.accessToken,
                                did: customer?.Wallet?.did,
                                publicKey: customer?.Wallet?.password
                            }})}
                        />
                    </div>
                </div>
                <Divider
                    orientation="vertical"
                    flexItem
                />
                <div className={classes.column}>
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('customerProfile.enableCompany')}
                    </Typography>
                    <div className={classes.status}>
                        <Typography
                            variant="subtitle1"
                            className={classes.label}
                        >
                            {
                                isFlag(customer?.status, DISABLED)
                                    ?
                                    t('customerProfile.disabled')
                                    :
                                    t('customerProfile.enabled')
                            }
                        </Typography>
                        <Tooltip
                            title={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) ?
                                <div>{t('customerProfile.companyStatusTooltip')}</div>
                                : 
                                ""
                            }
                            placement="top-end"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <div>
                                <Toggle
                                    checked={!isFlag(customer?.status, DISABLED)}
                                    onClick={handleToggleClick}
                                    disabled={
                                        // enabled for MT customer
                                        ((mainContact?.main && 
                                            hasPermissions(permissions, {admin: ["O"], customers: ["EO","RO"], wallets: ["EO","RO"], mainContacts: ["EO","RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) &&
                                            customer?.TenantId && 
                                            customer.tenant
                                        ) || 
                                        // enabled for Classic customer
                                        (mainContact?.main && 
                                            hasPermissions(permissions, {admin: ["O"], customers: ["EO","RO"], wallets: ["EO","RO"], mainContacts: ["EO","RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) &&
                                            !customer?.TenantId
                                        ) ||
                                        hasPermissions(permissions, {tenant: ["O"], customers: ["E","R"], wallets: ["E","R"], mainContacts: ["E","R"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) ?
                                        false
                                        : 
                                        true
                                    }
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <Divider className={classes.dividerMargin}/>
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('customerProfile.companyType')}
                    </Typography>
                    <div className={classes.status}>

                    <Typography
                        variant="subtitle1"
                        className={classes.label}
                    >
                        {customer?.tenant ? "Multi-tenant" : "Classic" || ""}
                    </Typography>
                </div>

                </div>
            </div>
        </Paper>
    );
}

const mapState = (state: RootState) => ({
    customerLogo: state.onboarding.customerLogo,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    getCustomerLogo: (data: number) => (getCustomerLogo(data)),
    openModal: (data: any) => (openModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(CustomerInfoCard)
