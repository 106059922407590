import { createMachine } from 'xstate';

type ChangeOfStateEvents = 
    | {type: 'PERSONALINFO'}
    | {type: 'SCAN'}
    | {type: 'DOWNLOADAPP'}

type ChangeOfState = 
| { value: "downloadApp"; context: undefined }
| { value: "personalInfo"; context: undefined }
| { value: "scan"; context: undefined };

export const trialWizard = createMachine<
  undefined,
  ChangeOfStateEvents,
  ChangeOfState
>({
  id: 'trialWizard',
  initial: 'downloadApp',
  states: {
    downloadApp: {
      on: { 
          PERSONALINFO: 'personalInfo',
          SCAN: 'scan'
        }
    },
    personalInfo: {
      on: { 
        DOWNLOADAPP: 'downloadApp',
        SCAN: 'scan'
      }
    },
    scan: {
        on: {
            PERSONALINFO: 'personalInfo',
            DOWNLOADAPP: 'downloadApp'
        }
    }
  }
});