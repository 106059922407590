import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import dropdownIcon from './assets/downward-arrow-icon.svg'
import clearIcon from './assets/close-icon.svg'

import InputField from './Input';
import Dropdown from './Dropdown';

const useStyles = makeStyles((theme?: any) => ({
    row: {
        width: "100%"
    }
}))

interface InputSelectFilterProps {
    menuItems: any[]
    placeholder?: string
    renderValue: string
    setValue?: (name: string, value: string, index?: number) => void
    name: string
    value: any
    index?: number
    disabled?: boolean
    readonly?: boolean
}

const InputSelectFilter: FC<InputSelectFilterProps> = ({
    menuItems,
    placeholder = "Select",
    renderValue,
    name,
    setValue,
    value,
    index,
    disabled,
    readonly,
}) => {
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()
    const [filter, setFilter] = useState("")
    const classes = useStyles()

    const handleOpen = (e: any) => {
        if (!disabled && value) {
            setValue && setValue(name, "")
        }
        setOpen(!open)
        setAnchorEl(e.target.parentElement)
    }

    const handleClear = () => {
        setValue && setValue(name, "")
    }

    const onSelect = (item: any) => {
        setFilter("")
        setValue && setValue(name, item, index)
        setOpen(false)
    }

    const inputFieldValue = () => {
        if (filter) {
            return filter
        } else {
            return typeof value === "object" ? value[renderValue] : value
        }
    }

    const handleChange = (e: any) => {
        setFilter(e.target.value)
    }

    const filterMenuItems = () => {
        const filteredArr: any[] = []
        menuItems.forEach((menuItem) => {
            if (menuItem[renderValue].toLowerCase().includes(filter)) {
                return filteredArr.push(menuItem)
            }
        })
        return filteredArr
    }

    const handleClickAway = () => {
        setFilter("")
        setOpen(false)
    }

    return (
        <div onClick={value ? handleClear : handleOpen} className={classes.row}>
            <InputField
                icon={value ? clearIcon : dropdownIcon}
                readonly={readonly}
                value={inputFieldValue()}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(e) => handleChange(e)}
                inDropdown={true}
                filter={value ? true : false}
            />
            <Dropdown
                open={!disabled ? open : false}
                menuItems={filter ? filterMenuItems() : menuItems}
                setOpen={() => setOpen(false)}
                anchorEl={anchorEl}
                handleClick={(item) => onSelect(item)}
                renderValue={renderValue}
                handleClickAway={handleClickAway}
            />
        </div>
    )
}

export default InputSelectFilter