export const tc = `
    Here you find our terms of service. If you any questions don’t hesitate to contact us using one
of the options below:
    Using our book a call form here.
    Contact us via email at: hello@sidoes.io
    Write us via post mail at: sidoes GmbH, Kopernikusstr. 35, 10243 Berlin, Germany (Attn: Legal)
    Sideos GmbH is a software and/or it’s affiliates provides organisations with a technology to
manage data with external parties in a secure and efficient way. We simplify the interactions
between businesses with customers and partners based on new web technologies, e.g.,
blockchain and self-sovereign identity technology. The service is offered
as cloud-based Software as a Service and can thus be integrated into existing IT environments
very quickly. The service supports many use cases in the e-commerce, financial industry and in
all organisations managing global data interactions. Find more information on our website
sideos.io.
    These are our terms of service. You are responsible to read and understand the legal contract.
In these Terms of Service (hereafter “Agreement” or “Terms”), “we,”“us,”“our” or “sideos” will
refer collectively to sideos GmbH, and the terms “you,”“your” and “Customer” will refer to you.
“Services” herein shall refer to any mobile or desktop software or web-based application,
software development kit (SDK) web-based application programming interface (API), or other
cloud-based services that sideos provides you with or hosts on your behalf.
    To use the Services, you must review and accept these Terms by checking the “I Accept” box
during account creation or other mechanism provided. If you are using the Services on behalf
of an organisation, then you are giving consent to these Terms on behalf of that organisation
and you ensure to us that you have the authority to bind that organisation to these Terms. In
the case you are representing an organisation the terms “you” and “your '' or “Customer” will
refer to that organisation. Any direct contractual relationship between sideos and your
organisation in a separate agreement (e.g. a Master Service Agreement) covering the use of the
Service replaces this Terms.
    PLEASE REVIEW THESE TERMS CAREFULLY. ONCE ACCEPTED, THESE TERMS BECOME A
BINDING LEGAL COMMITMENT BETWEEN YOU AND SIDEOS. IF YOU DO NOT AGREE TO BE
BOUND BY THESE TERMS, YOU SHOULD NOT CLICK THE “I ACCEPT” OR “GET STARTED”
BUTTON AND YOU SHOULD NOT USE THE SERVICES. THESE TERMS LIMIT OUR LIABILITY TO
YOU. IN ADDITION, DISPUTES RELATED TO TERMS OR RELATED TO YOUR USE OF THEE
SERVICES GENERALLY MUST BE RESOLVED BY BINDING ARBITRATION AND ON AN INDIVIDUAL
BASIS ONLY.
    Access and Use
    1. Use of the Service
    During the use covered by this Term, Customer will receive a nonexclusive, non-assignable,  
royalty free, worldwide right to access and use the Services solely for your internal operations
subject to these Terms and up to the number of Customer Administrators, Credential
Templates, Proofs, documented in these Terms. Customer acknowledges that these Terms
define a services agreement and sideos will not be delivering copies of the Software to
Customer as part of the Service.
    2. Access to the Service
    You are solely responsible for ensuring that only appropriate Authorised Users have access to
the Service, that such Authorised Users have been trained in proper use of the Service, and
proper usage of access credentials, tokens and access procedures with respect to access and
use the Service. sideos reserves the right to refuse registration of, or to cancel, account IDs that
it reasonably believes to violate the terms and conditions set forth in this Agreement, in which
case sideos will promptly inform Customer in writing of such refusal or cancellation. In
addition to the rights set forth in this Agreement, sideos may suspend Customer’s access and
use of the Service if there is an unusual and material spike or increase in Customer’s use of the
Service and sideos reasonably suspects or knows that such traffic or use is fraudulent or
materially and negatively impacting the operating capability of the Service. sideos will provide
notice prior to such suspension if permitted by applicable law or unless sideos reasonably
believes that providing such notice poses a risk to the security of the Service. sideos will
promptly reinstate Customer’s access and use once the issue has been resolved.
    3. Trial Services
    If you are using a free trial, a proof of concept version of the Service, a beta version of the
Service, or using the Service on any other free-of-charge basis as specified in an Order
including any related support services to the extent provided by sideos in its sole discretion
(collectively, “Trial Services''), sideos makes such Trial Services available to Customer until the
earlier of: the end of the free trial or proof of concept period or beta testing period as
communicated by sideos or specified in an Order; the start date of any purchased version of
such Service; or written notice of termination from sideos (“Trial Services Period”). sideos
grants Customer, during the Trial Services Period, a non-exclusive, non-transferable right to
access and use the Trial Services for Customer’s internal evaluation purposes in accordance
with the Documentation and subject to the access and use restrictions set forth in this
Agreement. You are authorised to use Trial Services only for evaluation and not for any
business or productive purposes, unless otherwise authorised by sideos in writing. Any data
Customer enters into the Trial Services will be permanently lost and any configurations made
to the Trial Services by or for Customer during the term of such Trial Services will be
permanently lost unless Customer: (a) has purchased a subscription to the same SaaS
Products as covered by the Trial Services; or (b) exports such data or configurations before the
end of such free period. There is no guarantee that features or functions of the Trial Services
will be available, or if available will be the same, in the general release version of the Service,
and you should review the Service features and functions before making a purchase. sideos
will be under no obligation to provide Customer any maintenance or support services with
respect to the Trial Services. Notwithstanding anything to the contrary, sideos provides the
Trial Services “as is” and “as available” without any warranties or representations of any kind.
To the extent permitted by law, sideos disclaims all implied warranties and representations,
including, without limitation, any implied warranty of merchantability, fitness for a particular
purpose and non-infringement. You assume all risks and all costs associated with its use of the
Trial Services. Your sole and exclusive remedy in case of any dissatisfaction or sideos’s breach
of the Agreement with respect to such Trial Services is termination of the Trial Services. Any
obligations on behalf of sideos to indemnify, defend, or hold harmless under this Agreement
are not applicable to you while using Trial Services.
    4. Ownership and Restrictions
    You retain ownership and intellectual property rights in and to your content. sideos or its
licensors retain all ownership and intellectual property rights to the services, Software
programs, and anything developed and delivered under the Agreement. Third party
technology that may be appropriate or necessary for use with some sideos programs is
specified in the program Documentation or ordering document as applicable. Your right to use
such third party technology is governed by the terms of the third party technology licence
agreement specified by sideos and not under the Agreement.
    5. Administrator Access
    You will be solely responsible for the acts and omissions of its Administrator Users. sideos shall
not be liable for any loss of data or functionality caused directly or indirectly by the
Administrator Users.
    6. Support
    As part of its provision of the Service, sideos will make available technical support to you in
accordance with sideos’ Service support terms. Upon notification from sideos, you will
promptly update any customer success managers on your systems that interact with the
Service. You acknowledge and agree that its failure to timely install such an update may result
in disruptions to or failures of the Service, security risks or suspension of your access to the
Service, without any liability on the part of sideos to you.
    7. Availability
    sideos undertakes all commercially reasonable efforts to make the Services available to
Customers 100% of the time. Nevertheless, the Services Availability (as defined below) should
not be less than 99.9% during each calendar year of the Subscription Term. “Service
Availability” means the number of minutes in a year that the key components of the Service are
operational as a percentage of the total number of minutes in such year, excluding downtime
resulting from (a) scheduled maintenance, (b) events of Force Majeure in the Terms), (c)
malicious attacks on the system, (d) issues associated with the Customer’s computing devices,
local area networks or internet service provider connections, or (e) inability to deliver services
because of acts or omissions of Customer or any sideos user. sideos reserves the right to take
the Service offline for scheduled maintenance for which Customer has been provided
reasonable notice and sideos reserves the right to change its maintenance window upon prior
notice to Customer.
    8. Service Extensions or Updates
    Customer further agrees that, unless explicitly stated otherwise, any new features that
augment or enhance the Service, and or any new service subsequently purchased by Customer
pursuant to an amendment accepted by Vendor referencing this Agreement will be subject to
this Agreement.
    9. System Maintenance
    sideos reserves the right to scheduled maintenance windows to deploy any defect corrections,
new enhancements or releases. The Customer will be notified within 10 business days notice
for all releases unless it resolves a “critical” or “high” error/defect.
    10. Mobile Applications
    With regard to Service components that require the use of mobile applications by an
Authorised User, you will ensure that all Authorised Users promptly download and install all
available updates for the mobile applications. You acknowledge and agree that the Service
may not properly operate should any Authorised User fail to do so, and that sideos is not liable
for any damages caused by a failure to update mobile applications accordingly.
    11. Restrictions
    You agree to not:
        ● Copy or republish the Service or sideo’s Software,
        ● Make the Service or Software available to any person other than authorised sideos
        users,
        ● Modify or create derivative works based upon the Service or Documentation,
        ● Remove, modify or obscure any copyright, trademark or other proprietary notices
        contained in the software used to provide the Service or in the Documentation,
        ● Reverse engineer, decompile, disassemble, or otherwise attempt to derive the source
        code of the Software used to provide the Service, except and only to the extent such
        activity is expressly permitted by applicable law, or
        ● Access the Service or use the Documentation in order to build a similar product or
        competitive product. Subject to the limited licences granted herein, sideos shall own
        all right, title and interest in and to the Software, services, Documentation, and other
        deliverables provided under these Terms, including all modifications, improvements,
        upgrades, derivative works and feedback related thereto and intellectual property
        rights therein. Customer agrees to assign all right, title and interest it may have in the
        foregoing to sideos.
    Payment and Taxes
    1. Fees
    Customer agrees to pay fees according to the rates listed at https://www.sideos.io/pricing
unless otherwise agreed in a contract or order confirmation between sideos and Customer.
    2. Invoicing and Payment
    Unless otherwise agreed upon in writing with you, sideos will invoice you for all fees on the
first day of each month. The amount of the invoice is payable at the date of receiving the
invoice. Except as expressly provided otherwise, fees are non-refundable. All fees are stated in
Euros, and must be paid by you to sideos in Euros.
    3. Taxes
Unless otherwise stated in an invoice sideos will bill you for applicable taxes as a separate line
item on each invoice. You will be responsible for payment of all sales and use taxes, value
added taxes (VAT), or similar charges relating to your purchase and use of the services. You will
not be liable for taxes based on sideos’s net income, capital or corporate franchise.
    4. Increase in Charges
    In case of an increase of the charges, you will be entitled to terminate the Service(s) affected by
the amended charges by Written Notice. Such termination will take effect on the date the
amended charges will come into force. The amended charges are deemed to be accepted by
you and the termination right will be forfeited, if you do not exercise such right within the
notice period mentioned above. sideos will inform you of this special termination right and the
consequences of non-execution of this right in the notice announcing the amendment.
    5. Change in Monthly Subscription Plan
    You may request to upgrade or downgrade your plan at any time. If you choose to downgrade,
this change will not go into effect until the first of the following month. If you upgrade, then we
will prorate your monthly charges to go into effect on the day of the upgrade.
    6. Governmental Charges
    sideos may adjust or introduce Governmental Charges in order to recover amounts it is
required or permitted by governmental or quasi-governmental authorities to collect from or
pay to others in support of statutory or regulatory programs.
    Rights and Intellectual Property
    1. Unauthorised Use; False Information
    You will:
        a. Notify sideos immediately of any unauthorised use of any access credential or user id
        or any other known or suspected breach of security,
        b. Report to sideos immediately and use reasonable efforts to stop any unauthorised use
        of the Service that is known or suspected by you or any sideos user, and
        c. Not provide false identity information to gain access to or use the Service.
    2. Customer Input
    You are solely responsible for collecting, inputting and updating all your content stored on the
Service, and for ensuring that the your content does not include anything that actually or
potentially infringes or misappropriates the copyright, trade secret, trademark or other
intellectual property right of any third party, or contain anything that is obscene, defamatory,
harassing, offensive or malicious.
    3. Licence from Customer
    You will grant to sideos a limited, non-exclusive and non-transferable licence, to copy, store,
configure, perform, display and transmit your content solely as necessary to provide the
Service to you.
    4. Ownership and Restrictions
    You retain ownership and intellectual property rights in and to it's content. sideos or its
licensors retain all ownership and intellectual property rights to the services, Software
programs, and anything developed and delivered under the Terms. Third party technology that
may be appropriate or necessary for use with some sideos programs is specified in the
program Documentation or ordering document as applicable. Your right to use such third party
technology is governed by the terms of the third party technology licence agreement specified
by sideos and not under the Agreement.
    Confidentiality
    1. Authorised Persons
    You and your employees and advisors along with any affiliated company within the meaning of
sec. 15 et seqq. of the German Stock Corporation act (AktG), (v) its organs and employees as
long as the persons listed in through are involved in the Project, require the relevant
information to accomplish their respective tasks in the Project (“need to know” principle) and
subject to a professional confidentiality obligation.
    2. Confidentiality.
    During the term of these Terms and for five (5) years thereafter (perpetually in the case of
software), each party shall treat as confidential all Confidential Information of the other party,
shall not use such Confidential Information except to exercise its rights and perform its
obligations under these Terms, and shall not disclose such Confidential Information to any
third party. Without limiting the foregoing, each party shall use at least the same degree of
care, but not less than a reasonable degree of care, it uses to prevent the disclosure of its own
confidential information to prevent the disclosure of Confidential Information of the other
party. Each party shall promptly notify the other party of any actual or suspected misuse or
unauthorised disclosure of the other party’s Confidential Information. Neither party shall
reverse engineer, disassemble or decompile any prototypes, software or other tangible objects
which embody the other party's Confidential Information and which are provided to the party
hereunder. Each party may disclose Confidential Information of the other party on a
need-to-know basis to its contractors who are subject to confidentiality agreements requiring
them to maintain such information in confidence and use it only to facilitate the performance
of their services on behalf of the receiving party.
    3. Exceptions
    Confidential Information excludes information that: is known publicly at the time of the
disclosure or becomes known publicly after disclosure through no fault of the yours, is known
to you, without restriction, at the time of disclosure or becomes known to you, without
restriction, from a source other than the disclosing party not bound by confidentiality
obligations to the disclosing party, or (c) is independently developed by you without use of the
Confidential Information as demonstrated by the written records of the receiving party. You
may disclose Confidential Information of the other party to the extent such disclosure is
required by law or order of a court or other governmental authority, provided that the receiving
party shall use reasonable efforts to promptly notify the other party prior to such disclosure to
enable the disclosing party to seek a protective order or otherwise prevent or restrict such
disclosure.
    Security and Personal Data
    1. Personal Data
    You have read and understand our Privacy Policy. You will comply with all applicable local,
state, national and foreign laws in connection with its use of the Service, including those laws
related to data privacy, international communications, and the transmission of technical or
personal data. Customer acknowledges that sideos exercises no control over the content of the
information transmitted by Customer or the sideos SSI users through the Service. You will not
upload, post, reproduce or distribute any information, software or other material protected by
copyright, privacy rights, or any other intellectual property right without first obtaining the
permission of the owner of such rights.
    2. sideos Personal Data Obligations
    In performing the Services, sideos will comply with the sideos Services Privacy Policy, which is
available at https://sideos.io/static/datenschutz-b5d3b2f5574a5933366a434a059eadad.pdf
and incorporated herein by reference. The sideos Services Privacy Policy is subject to change at
sideos’s discretion; however, sideos policy changes will not result in a material reduction in the
level of protection provided for your data during the period for which fees for the services have
been paid. The services policies referenced in these Terms specify our respective
responsibilities for maintaining the security of Customer data in connection with the Service.
sideos reserves the right to provide the Service from Host locations, and/or through use of
subcontractors, worldwide. sideos will only process Customer Personal Data in a manner that
is reasonably necessary to provide the Service and only for that purpose. sideos will only
process Customer Personal Data in delivering sideos Service. You agree to provide any notices
and obtain any consent related to sideos’s use of the data for provisioning the Service,
including those related to the collection, use, processing, transfer and disclosure of personal
information. You have sole responsibility for the accuracy, quality, integrity, legality, reliability,
appropriateness and retains ownership of all of Customer data.
    Warranties
    1. Warranty
    sideos represents and warrants that it will provide the Service in a professional manner
consistent with general industry standards and that the Services will perform substantially in
accordance with the Documentation. sidoes warrants that the Service will perform in all
material respects in accordance with the current release documentation. sideos does not
guarantee that the Service and the related components will be performed error/defect free or
uninterrupted, or that sideos will correct all Service or related components defects/errors. You
acknowledge that sideos does not control or transfer data over communications facilities,
including internet, and that Service may be subject to the limitations, delays, and other
problems inherent in the use of such communications facilities. This section sets forth the sole
and exclusive warranty given by sideos (express or implied) with respect to the subject matter
of this agreement. Neither sideos nor any of its licensors or other suppliers warrant or
guarantee that the operation of the subscription service will be uninterrupted, virus-free or
error-free, nor shall sideos or any of its service providers be liable for unauthorised alteration,
theft, or destruction of your’s or any user’s data, files, programs.
    2. Blockchain technology and 3rd Party Services
    You represent and warrant that, when utilising the Service to relay on third-party services
including any blockchain, ledger, or other identity network, public or private, that you are
acting in compliance with all the relevant service’s terms and agreements, and that you are
authorised to do so.
    3. Processing Sensitive Data
    SIDEOS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE,
EXCEPT AS MAY BE EXPLICITLY SET FORTH HEREIN, WHETHER EXPRESS OR IMPLIED,
INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
PURPOSE, TITLE AND NON-INFRINGEMENT. SIDEOS MAKES NO WARRANTY THAT ACCESS TO
THE SERVICE WILL BE UNINTERRUPTED, TIMELY, CURRENT, ACCURATE, COMPLETE OR
ERROR-FREE. THE SERVICE IS PROVIDED “AS IS” TO THE FULLEST EXTENT PERMITTED BY LAW.
TO THE EXTENT SUCH DISCLAIMER CONFLICTS WITH APPLICABLE LAW, THE SCOPE AND
DURATION OF ANY APPLICABLE WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH
LAW. SIDEOS WILL NOT BE LIABLE, AND WILL HAVE NO OBLIGATION TO INDEMNIFY YOU FOR
SENSITIVE DATA (AS DEFINED BELOW) SENT TO SIDEOS OR THROUGH THE SERVICES
PROVIDED THEREBY.
    “Sensitive Data” shall mean (i) “special categories of data” which includes personal data
revealing racial or ethnic origin, political opinions, religious or philosophical beliefs
trade-union membership, genetic or biometric data processed solely to identify a human
being, health-related data, data concerning a person’s sex life or sexual orientation, and (ii)
financial information, banking account numbers or banking credentials, credit or debit card
number at full length, (iii) criminal history or any (iv) other information or combinations of this
information that falls within the definition of “special categories of data” under GDPR or any
other applicable Law relating to privacy and data protection.
    Indemnification
    1. Indemnification by sideos
If a third party makes a claim against you that the Service infringes any related patent,
copyright or trademark, or misappropriate any trade secret, or that sideos’s negligence or
willful misconduct has caused bodily injury or death, sideos shall defend you and its directors,
officers and employees against the claim and provide supporting legal documentation. sideos
shall have no liability for any claim based on
    a. Your Content
    b. modification of the Service not authorised by sideos, or
    c. use of the Service other than in accordance with the Documentation and these Terms.
    sideos may, at its sole option and expense, procure for you the right to continue use of
    the Service, modify the Service in a manner that does not materially impair the
    functionality, or terminate the Subscription Term and repay to you any amount paid
    by you with respect to the Subscription Term following the termination date.
    2. Indemnification by You
    If a third party makes a claim against sideos that your content infringes any patent, copyright
or trademark, or misappropriate any trade secret, You will defend sideos and its directors,
officers and employees against the claim at your expense and You will pay all losses, damages
and expenses (including reasonable attorneys’ fees) finally awarded against such parties or
agreed to in a written settlement agreement signed by you to the extent arising from the claim.
    3. Conditions for Indemnification
    A party seeking indemnification under this section will promptly notify the other party of the
claim, give the other party sole control of the defence and settlement of the claim, and provide,
at the other party’s expense for out-of-pocket expenses, the assistance, information and
authority reasonably requested by the other party in the defence and settlement of the claim.
    Terms and Terminations
    1. Terms
    These Terms shall begin on the date you agree to the Terms, and shall continue until
terminated by either party as outlined in this Section. A Customer administrator may request
to terminate the subscription through the Management Console of the Service. sideos will
respond and if there is no response in ten (10) business days, then sideos will cancel all
services and transactions on the last day of the month at two ( 2) PM CET time.
    2. Suspension for Non-Payment
    sideos reserves the right to suspend delivery of the Services if you fail to timely pay any
undisputed amounts due to sideos under these Terms, but only after sideos notifies you of
such failure and such failure continues for fifteen (15) days. Suspension of the Services shall
not release you of its payment obligations under Terms. You agree that sideos shall not be
liable to you or to any third party for any liabilities, claims or expenses arising from or relating
to suspension of the Services resulting from your nonpayment.
    3. Suspension for Ongoing Harm
    sideos reserves the right to suspend delivery of the Services if sideos reasonably concludes
that you or a sideos user’s use of the Services is causing immediate and ongoing harm to
sideos or others. In the extraordinary case that sideos must suspend delivery of the Services,
sideos shall immediately notify you of the suspension and the parties shall diligently attempt
to resolve the issue. sideos shall not be liable to you or to any third party for any liabilities,
claims or expenses arising from or relating to any suspension of the Services.
    General
    1. Non-Exclusive Service
    You acknowledge that the Service is provided on a non-exclusive basis. Nothing shall be
deemed to prevent or restrict sideos’s ability to provide the Service or other technology,
including any features or functionality first developed for you, to other parties.
    2. Assignment
    Neither party may assign these Terms or any right under these Terms, without the consent of
the other party, which consent shall not be unreasonably withheld or delayed; provided
however, that either party may assign these Terms to an acquirer of all or substantially all of
the business of such party to which these TErms relates, whether by merger, asset sale or
otherwise. These Terms shall be binding upon and inure to the benefit of the parties’
successors and permitted assigns. Either party may employ subcontractors in performing its
duties under these Terms, provided, however, that such party shall not be relieved of any
obligation under these Terms.
    3. Notices
    Except as otherwise permitted in these Terms, notices under these Terms shall be in writing
and shall be deemed to have been given ten (10) business days after your administrator has
sent a message through the Management Console of the Service or via email.
    4. Force Majeure
    Each party will be excused from performance for any period during which, and to the extent
that, such party or any subcontractor is prevented from performing any obligation or Service,
in whole or in part, as a result of causes beyond its reasonable control, and without its fault or
negligence, including without limitation, strikes, lockouts, riots, acts of terrorism or war,
epidemics, communication line failures, and power failures.
    5. Waiver
    No waiver shall be effective unless it is in writing and signed by the waiving party. The waiver
by either party of any breach of these Terms shall not constitute a waiver of any other or
subsequent breach.
    6. Severability
    If any part of these Terms is held to be invalid or unenforceable, that term shall be reformed to
achieve as nearly as possible the same effect as the original term, and the remainder of these
Terms shall remain in full force.
    7. Entire Terms
    These Terms contain the entire agreement of the parties and supersedes all previous oral and
written communications by the parties, concerning the subject matter of these Terms. These
Terms may be amended solely in a writing signed by both parties. Standard or printed terms
contained in any purchase order or sales confirmation are deemed rejected and shall be void
unless specifically accepted in writing by the party against whom their enforcement is sought;
mere commencement of work or payment against such forms shall not be deemed acceptance
of the terms.
    8. Export Regulations
    Export laws and regulations of Germany and any other relevant local export laws and
regulations apply to the Service. You agree that such export control laws govern its use of the
Service (including technical data) and any services deliverables provided under this
Agreement, and you agree to comply with all such export laws and regulations. You agree that
no data, information, software programs and/or materials resulting from services (or direct
product thereof) will be exported, directly or indirectly, in violation of these laws.
    9. Statistical Information
    sideos may anonymously compile statistical information related to the performance of the
Services for purposes of improving the Service, provided that such information does not
identify your data or include your name.
    10. Governing Law
    These Terms shall be governed by the laws of Germany excluding its conflict of law principles.
The United Nations Convention on Contracts for the International Sale of Goods shall not
apply.
    11. Compliance with Laws
    sideos shall comply with all applicable local, state, national and foreign laws in connection
with its delivery of the Services, including those laws related to data privacy, international
communications, and the transmission of technical or personal data
    12. Dispute Resolution
    Your satisfaction is an important objective to sideos in performing its obligations under these
Terms. If there are legal concerns, please contact sideos to resolve any possible issue before
bringing a formal legal case. IF you prefer that an independent third party mediates we suggest
a conciliation proceeding as the following: Except with respect to intellectual property rights, in
the event of a dispute arising under or in relation to this contract, before commencing
proceedings in an ordinary court (or court of arbitration), the parties undertake to undergo
conciliation proceedings in accordance with the regulations of the Berlin business conciliation
board of the Berlin Chamber of Commerce and Industry, the Berlin Bar Association and the
Berlin Chamber of Crafts and Trades (or any other regional competent institution, e.g. Chamber
of Commerce and Industry, Chamber of Crafts and Trades etc.) that are valid at the time the
proceedings are commenced.
`