import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Dialog,
    Typography
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import Button from '../commons/components/Button'
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { pickRandomImage } from '../commons/utils';
import { useHistory } from 'react-router';
import { closeModal } from '../actions/modals';
import { plans } from '../commons/plans';

import SecurityImgModal from '../settings/SecurityImgModal';
import Step5 from './Step5';

import securityqr from "./assets/securityqr.png"
import gif from './assets/loginGif.gif'
import qrcode from './assets/qrcode.png'
import { hasPermissions } from '../commons';

const useStyles = makeStyles((theme?: any) => ({
    paper: {
        maxWidth:"100%",
        width: "704px",
        height: "fit-content",
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(7),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxSizing: "border-box"
        }
    },
    step5: {
        maxWidth: "100%",
        width: "480px",
        padding: theme.spacing(9),
    },
    contentContainer: {
        display: 'flex',
        height: "100%",
        justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }
    },
    titleText: {
        width: "248px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%",            
        }
    },
    title:{
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(5,0),
            width:"296px",
            lineHeight: "32px"
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        "& a": {
            color: theme.palette.onSurface.highEmphasis,
            textDecoration: "none",
            display: "inline"
        }
    },
    btn: {
        width: "180px",
        height:"40px",
        radius:"8px",
        borderRadius: "8px",
        margin: theme.spacing(6, 4, 0, 4),
        marginRight: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            margin: "0px"
        }
    },
    btnContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: "auto",
            flexDirection: "column",
        }
    },
    pickMeBtn:{
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5),
         }
    },
    gif: {
        width: "338px",
    },
    dot: {
        height: "10px",
        width: "10px",
        backgroundColor: theme.palette.surface.overlay,
        borderRadius: theme.shape.circle,
        margin: theme.spacing(6, 2, 0, 2)
    },
    activeDot: {
        backgroundColor: theme.palette.primary[500],
    },
    row: {
        display: "flex",
        alignSelf: "center",
        alignItems: "baseline"
    },
    illustration2: {
        width: "222px",
    },
    securityDemoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "222px",
        [theme.breakpoints.down('sm')]: { 
            marginBottom: theme.spacing(10)      
        }
    },
    illustrationQrcode: {
        position: "absolute",
        height: "230px",
    },
    illustrationSecurity: {
        height: "48px",
        width: "48px"
    },
    securityImageContainer: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
        borderRadius: theme.shape.loginRadius,
        filter: "drop-shadow(0px 0px 24px #BF8CFF)",
        height: "86px",
        width: "86px"
    },
    junoLink: {
        fontFamily: "Source Sans Pro",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "16px",
        letterSpacing: "1.25px",
        color: "#B6B9BB",
        textAlign: "center"
    },
    inputfile: {
        width: "0.1px",
        height: "0.1px",
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    },
    inputLabel: {
        width: "196px",
        height: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(0),
        border: `1.6px solid ${theme.palette.onSurface.highEmphasis}`,
        color: theme.palette.onSurface.highEmphasis,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        "&:hover": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[800],
        },
        "&:focus": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.onSurface.highEmphasis,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }   
    },
    planVisualColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(8)
        }
    },
    hypertext:{
        color: theme.palette.primary[400]
    },
    
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface WelcomeModalProps extends PropsFromRedux {}

const WelcomeModal: FC<WelcomeModalProps> = ({
    welcome,
    mainContact,
    closeModal,
    permissions
}) => {
    const classes = useStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation()
    const [step, setStep] = useState(1)
    const [securityImg, setSecurityImg] = useState("")
    const [imgPreview, setImgPreview] = useState("")
    const [errorImgSize, setErrorImgSize] = useState(false)
    const [errorFileType, setErrorFileType] = useState(false)
    const history = useHistory()
    const toUpgrade = mainContact?.Customer?.chargebee?.toUpgrade

    useEffect(()=>{
        if (securityImg) {
            setStep(3)
        } 
        if (welcome?.data?.active) {
            setStep(4)
        }
    },[securityImg, welcome])

    const closeWelcomeModal = () => {
        localStorage.removeItem("trialWelcome")
        closeModal({modalName: "welcome"})
    }

    const pickForMe = () => {
        const randomImage = pickRandomImage()
        localStorage.setItem("securityImage", randomImage)
        setSecurityImg(randomImage)
        setStep(3)
    }

    const handleImgPreview = (e: any) => {
        e.target.files[0].size > 307200 ? setErrorImgSize(true) : setErrorImgSize(false)
        e.target.files[0].type.split("/").pop() !== "jpg" &&
            e.target.files[0].type.split("/").pop() !== "jpeg" &&
            e.target.files[0].type.split("/").pop() !== "png" ?
            setErrorFileType(true) : setErrorFileType(false)
        setImgPreview(e.target?.files?.[0] ? URL.createObjectURL(e.target.files[0]) : "")
        e.target.value = ""
    }

    const step1 = <>
    <div className={classes.contentContainer}>
        <div>
            <img src={gif} alt="" className={classes.gif} />
        </div>
        <div className={classes.titleText}>
            <Typography variant="h5"
                className={classes.title}
            >
                {t('welcomeModal.welcome')}
            </Typography>
            <Typography variant="subtitle1" className={classes.mediumEmphasis}>
                {t('welcomeModal.text1')}
                <a href="https://juno.sideos.io/" target="_blank" rel="noopener noreferrer">
                    juno.sideos.io
                </a>
                {t('welcomeModal.text2')}
            </Typography>
        </div>
    </div>
    <div className={classes.btnContainer} >
        <div className={classes.btn}>
            <Button
                text={t('welcomeModal.btn')}
                version="secondary"
                width="100%"
                onClick={()=>setStep(2)}
                size="medium"
            />
        </div>
    </div>
    </>

    const step2 = <>
    <div className={classes.contentContainer}>
        <div>
            <Typography className={classes.junoLink}>
                www.juno.sideos.io
            </Typography>
            <img src={securityqr} alt="" className={classes.illustration2} />
        </div>
        <div className={classes.titleText}>
            <Typography variant="h5"
                className={classes.title}
            >
                {t('welcomeModal.card2Title')}
            </Typography>
            <Typography variant="subtitle1" className={classes.mediumEmphasis}>
                {t('welcomeModal.card2Text')}
            </Typography>
        </div>
    </div>
    <div className={classes.btnContainer} >
        <div className={classes.btn}>
            <label htmlFor={"securityImg"} className={classes.inputLabel}>
                {t('welcomeModal.card2Btn1')}
            </label>
            <input
                type="file"
                accept=".jpg, .jpeg, .png"
                id={"securityImg"}
                className={classes.inputfile}
                onChange={(e) => handleImgPreview(e)}>
            </input>
        </div>
        <div className={clsx(classes.btn, classes.pickMeBtn)}>
            <Button
                text={t('welcomeModal.card2Btn2')}
                version="secondary"
                width="100%"
                onClick={()=>pickForMe()}
                size="medium"
            />
        </div>
        <SecurityImgModal
            setSecurityImg={setSecurityImg}
            imgPreview={imgPreview}
            setImgPreview={setImgPreview}
            errorFileType={errorFileType}
            errorImgSize={errorImgSize}
        />
    </div>
    </>

    const step3 = <>
    <div className={classes.contentContainer}>
        <div className={classes.securityDemoContainer}>
            <img
                src={qrcode}
                alt=""
                className={classes.illustrationQrcode}
            />
            <div className={classes.securityImageContainer}>
                <img
                    src={securityImg}
                    alt=""
                    className={classes.illustrationSecurity}
                />
            </div>
        </div>
        <div className={classes.titleText}>
            <div className={classes.title}>
                <Typography variant="h5">
                    {t('welcomeModal.card3Title')}
                </Typography>
            </div>
            <Typography variant="subtitle1" className={classes.mediumEmphasis}>
                {t('welcomeModal.card3Text')}
            </Typography>
        </div>
    </div>
    <div className={classes.btnContainer} >
        <div className={classes.btn}>
            <Button
                text={t('welcomeModal.card3Btn')}
                version="secondary"
                width="100%"
                onClick={()=>mainContact.main && toUpgrade && toUpgrade !== 1 ? setStep(4) : closeWelcomeModal()}
                size="medium"
            />
        </div>
    </div>
    </>

    const step4 = <>
    <div className={classes.contentContainer}>
        <div className={classes.planVisualColumn}>
            <img
                src={plans[welcome?.data?.toUpgrade ? welcome?.data?.toUpgrade : toUpgrade]?.image}
                alt=""
            />
            <Typography variant="h6">
                {plans[welcome?.data?.toUpgrade ? welcome?.data?.toUpgrade : toUpgrade]?.name}
            </Typography>
            <div className={classes.row}>
                <Typography variant="h4">
                    € {plans[welcome?.data?.toUpgrade ? welcome?.data?.toUpgrade : toUpgrade]?.price}
                </Typography>
                <Typography variant="subtitle1" className={classes.mediumEmphasis}>
                    /{t('welcomeModal.month')}
                </Typography>
            </div>
        </div>
        <div className={classes.titleText}>
            <div className={classes.title}>
            <Typography variant="h5">
                {t('welcomeModal.card4Title', {planName: plans[welcome?.data?.toUpgrade ? welcome?.data?.toUpgrade : toUpgrade]?.name})}
            </Typography>
            </div>
            <Typography 
                variant="subtitle1" 
            >
                <span
                    className={classes.mediumEmphasis}
                >
                    {t('welcomeModal.card4Text')}
                </span>  
                <a href="https://www.chargebee.com/" 
                    target="_blank"
                    className={classes.hypertext}
                > 
                    {t('welcomeModal.chargebee')} 
                </a>
                <span
                    className={classes.mediumEmphasis}
                >
                    {t('welcomeModal.card4Text2')}
                </span>
            </Typography>
        </div>
    </div>
    <div className={classes.btnContainer} >
        <div className={classes.btn}>
            <Button
                text={t('welcomeModal.card4Btn1')}
                version="secondary"
                width="100%"
                onClick={()=>{history.push("/settings/billing/plans"); closeModal({modalName: "welcome"})}}
                size="medium"
            />
        </div>
        <div className={clsx(classes.btn, classes.pickMeBtn)}>
            <Button
                text={t('welcomeModal.card4Btn2')}
                version="secondary"
                width="100%"
                onClick={()=>setStep(5)}
                size="medium"
            />
        </div>
    </div>
    </>

    return (
        <Dialog
            open={welcome.open}
            fullScreen={fullScreen}
            classes={{ paper: step === 5 ? classes.step5 : classes.paper}}
        >
            {!welcome?.data?.active && step === 1 && step1}
            {!welcome?.data?.active && step === 2 && step2}
            {!welcome?.data?.active && step === 3 && step3}
            {(toUpgrade && toUpgrade !== 1 || welcome?.data?.active) 
            && hasPermissions(permissions, {chargebee: ["E","R"]}) 
            && step === 4 && step4}
            {(toUpgrade && toUpgrade !== 1 || welcome?.data?.active) 
            && hasPermissions(permissions, {chargebee: ["E","R"]}) 
            && step === 5 && 
                <Step5 
                    planId={welcome?.data?.toUpgrade ? welcome?.data?.toUpgrade?.toString() : toUpgrade?.toString()}
                    active={welcome?.data?.active}
                />
            }

            <div className={classes.row}>
                {!welcome?.data?.active && <>
                        <div
                            onClick={()=>setStep(1)}
                            className={clsx(classes.dot, {
                                [classes.activeDot]: step === 1
                        })}></div>
                        <div
                            onClick={()=>setStep(2)}
                            className={clsx(classes.dot, {
                                [classes.activeDot]: step === 2
                        })}></div>
                        <div
                            onClick={()=>setStep(3)}
                            className={clsx(classes.dot, {
                                [classes.activeDot]: step === 3
                        })}></div>
                    </>
                }
                {mainContact.main && (toUpgrade && toUpgrade !== 1 || welcome?.data?.active) && <>
                        <div
                            onClick={()=>setStep(4)}
                            className={clsx(classes.dot, {
                            [classes.activeDot]: step === 4
                        })}></div>
                        <div
                            onClick={()=>setStep(5)}
                            className={clsx(classes.dot, {
                            [classes.activeDot]: step === 5
                        })}></div>
                    </>
                }
            </div>
        </Dialog>
    );
}

const mapState = (state: RootState) => ({
    welcome: state.modals.welcome,
    mainContact: state.login.mainContact,
    permissions: state.login.permissions
})

const mapDispatch = {
    closeModal: (data: Object) => (closeModal(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(WelcomeModal)
