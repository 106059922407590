import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
  Typography,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { hasPermissions } from '../commons';
import { parseDateAndTime } from '../commons';
import {
  getTransactions,
  getCompaniesFilter,
  downloadCSV
} from '../actions/transactions';
import {
  DatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import filterIcon from './assets/filter-icon.svg'
import filterIconPurple from './assets/filter-icon-purple.svg'

import SearchBE from '../commons/components/SearchBE';
import Button from '../commons/components/Button'
import SimpleTable from '../commons/components/SimpleTable';
import TableDateAndTime from '../commons/components/TableDateAndTime'
import Breadcrumbs from '../commons/components/Breadcrumbs';
import TransactionsCollapseTable from './TransactionsCollapseTable'
import InputSelectFilter from '../commons/components/InputSelectFilter';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(10)
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
  row2: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(6),
    }
  },
  dateContainer: {
    width: "110px",
    margin: theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(4),
    }
  },
  row: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  filterContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  filter: {
    width: "346px",
    position: "relative",
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      right: "0",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(4),

    }
  },
  filterIcon: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    }
  },
  btnContainer: {
    width: "160px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      border: "0px solid #08141E",
      borderRadius: "11px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  }

}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface TransactionsProps extends PropsFromRedux { }

const Transactions: FC<TransactionsProps> = ({
  permissions,
  getTransactions,
  transactions,
  mainContact,
  getCompaniesFilter,
  companiesFilter,
  downloadCSV
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [queryParams, setQueryParams] = useState<any>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
    companyFilter: '',
    typeFilter: '',
    search: '',
    sort: '',
    order: ''
  })

  const OFFER = 1
  const REQUEST = 2
  const CREATION = 3
  const OFFERREQUEST = 4
  const OFFERMULTI = 5
  const REQUESTMULTI = 6
  const OFFERREQUESTMULTI = 7
  const TEST = 8

  const columnsAdmin = {
    createdAt: t('transactions.dateTime'),
    id: t('transactions.id'),
    CustomerName: t('transactions.companyName'),
    type: t('transactions.credType'),
    TemplateName: t('transactions.credName')
  }

  const columnsCustomer = {
    createdAt: t('transactions.dateTime'),
    id: t('transactions.id'),
    type: t('transactions.credType'),
    TemplateName: t('transactions.credName')
  }

  const transactionTypes = [
    { value: OFFER, name: 'Offer' },
    { value: REQUEST, name: 'Request' },
    { value: CREATION, name: 'Creation' },
    { value: OFFERREQUEST, name: 'Offer-Request' },
    { value: OFFERMULTI, name: 'Multi-Offer' },
    { value: REQUESTMULTI, name: 'Multi-Request' },
    { value: OFFERREQUESTMULTI, name: 'Multi-Offer-Request' },
    { value: TEST, name: 'Test' },
  ]

  const collapseTableTitles = {
    issuerDid: t('transactions.didSource'),
    recipientDid: t('transactions.didDestination'),
    status: t('transactions.transactionType')
  }

  useEffect(()=>{
    (hasPermissions(permissions, {admin: ["O"], customers: ["RO"]}) ||
      hasPermissions(permissions, {tenant: ["O"], customers: ["R"]})) && getCompaniesFilter()
  },[permissions, getCompaniesFilter])

  useEffect(() => {
    getTransactions(queryParams)
  }, [mainContact, getTransactions, queryParams])
  
  const handleSearch = (searchInput: string) => {
    setQueryParams({ ...queryParams, search: searchInput })
  }

  const handleSorting = (direction: string, columnName: string) => {
    setQueryParams({ ...queryParams, sort: columnName, order: direction })
  }

  return (
    <div className={clsx(classes.container)}>
      <Breadcrumbs />
      <Divider />
      <div className={classes.filterContainer}>
        <div className={classes.row}>
          <img
            src={queryParams.companyFilter || queryParams.typeFilter ? filterIconPurple : filterIcon}
            className={classes.filterIcon}
            alt="filter icon"
          />
          {(hasPermissions(permissions, {admin: ["O"], customers: ["RO"]}) ||
            hasPermissions(permissions, {tenant: ["O"], customers: ["R"]}))
            && 
              <div className={classes.filter}>
                <InputSelectFilter
                  menuItems={companiesFilter}
                  renderValue="name"
                  value={queryParams.companyFilter}
                  name={queryParams.companyFilter?.name}
                  setValue={(name, value) => setQueryParams({ ...queryParams, companyFilter: value })}
                />
              </div>
          }
          <div className={classes.filter}>
            <InputSelectFilter
              menuItems={transactionTypes}
              renderValue="name"
              value={queryParams?.typeFilter}
              name={queryParams?.typeFilter?.name}
              setValue={(name, value) => setQueryParams({ ...queryParams, typeFilter: value })}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.dateContainer}><DatePicker
            value={queryParams.startDate}
            format="MMM, do yyyy"
            onChange={(date: MaterialUiPickersDate) => setQueryParams({ ...queryParams, startDate: date })}
            variant="inline"
            disableFuture={true}
            PopoverProps={{
              elevation: 2,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }}
            autoOk={true}
          /></div>-
          <div className={classes.dateContainer}><DatePicker
            value={queryParams.endDate}
            format="MMM, do yyyy"
            onChange={(date: MaterialUiPickersDate) => setQueryParams({ ...queryParams, endDate: date })}
            variant="inline"
            disableFuture={true}
            PopoverProps={{
              elevation: 2,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }}
            autoOk={true}
          /></div>
        </div>
      </div>
      <div className={classes.row2}>
        <SearchBE
          handleSearch={handleSearch}
        />
        {hasPermissions(permissions, {transactions: ["R"]}) &&
          <div className={classes.btnContainer}>
            <Button
              text={t('transactions.downloadBtn')}
              version="secondary"
              size="medium"
              width="100%"
              onClick={() => downloadCSV({ data: transactions })}
              disabled={transactions.length === 0 ? true : false}
            />
          </div>
        }
      </div>
      <SimpleTable
        handleSortingBE={handleSorting}
        columns={hasPermissions(permissions, { admin: "O" }) ? columnsAdmin : columnsCustomer}
        rows={transactions}
        expandable={true}
        checkExpandableValue={() => { return true }}
        tableCells={{
          createdAt: (item: any) => {
            return (
              <TableDateAndTime
                dateAndTime={parseDateAndTime(item.createdAt)}
              />
            )
          },
          type: (item: any) => {
            return (
              <div className={classes.nameContainer}>
                <Typography
                  variant="body2"
                  className={classes.name}
                >
                  {item.type}
                </Typography>
              </div>
            )
          },
        }}
        collapseElement={(item) =>
          <TransactionsCollapseTable
            titles={collapseTableTitles}
            item={item}
          />
        }
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
  permissions: state.login.permissions,
  mainContact: state.login.mainContact,
  transactions: state.transactions.transactions,
  companiesFilter: state.transactions.companiesFilter
})

const mapDispatch = {
  getTransactions: (data?: any) => (getTransactions(data)),
  getCompaniesFilter: () => (getCompaniesFilter()),
  downloadCSV: (data?: any) => (downloadCSV(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(Transactions)
