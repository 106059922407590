import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Button from '../commons/components/Button'
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme?: any) => ({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(8, 8),
        minWidth: "523px",
        maxWidth: "700px",
        minHeight: "276px",
        height: "auto",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "276px",
            textAlign: "center",
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6),
            flexDirection: "column",
            width: "100%"
        }
    },
    btnMargin: {
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(0),
            marginRight: "unset"
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: theme.spacing(4),
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    textMargin: {
        marginTop: theme.spacing(6)
    }
}))


interface DisableCompanyModalProps {
    open: boolean
    companyName?: string
    onCancel?: () => void
    onAffirmative?: () => void
    disabled: boolean,
    showTAndC: boolean
}

const DisableCompanyModal: FC<DisableCompanyModalProps> = ({
    open = false,
    companyName,
    onCancel,
    onAffirmative,
    disabled,
    showTAndC
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogContainer }}
        >
            <Typography variant="h6">
                {t('disableCompany.title')}
            </Typography>
            <div className={classes.textMargin}>
                <Typography
                    variant="subtitle1"
                    className={classes.mediumEmphasis}
                    display="inline"
                >
                    {t('disableCompany.text1', {
                        action:
                            disabled
                                ?
                                t('disableCompany.enable')
                                :
                                t('disableCompany.disable')
                    })} <b>{companyName}</b>?
                </Typography>
                <Typography
                    variant="subtitle1"
                    className={classes.mediumEmphasis}
                    display="inline"
                >
                    {disabled ? t('disableCompany.textEnable') : t('disableCompany.textDisable')}
                </Typography>
            </div>
            {showTAndC ? 
                <div >
                    <Typography 
                        variant="subtitle1" 
                        className={clsx(classes.mediumEmphasis, classes.textMargin)}
                    >
                        {t('disableCompany.tandc')}
                    </Typography>
                    <Typography 
                        variant="subtitle2" 
                        className={clsx(classes.mediumEmphasis, classes.textMargin)}
                    >
                        {t('disableCompany.tandctitle')}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.mediumEmphasis}>
                        {t('disableCompany.tandctext')}
                    </Typography>
                </div>
                :
                null
            }
            <div className={classes.btnRow}>
                <div className={classes.btn}>
                    <Button
                        size={matches ? "small" : "large"}
                        text={t('disableCompany.noBtn')}
                        version="secondary"
                        onClick={onCancel}
                        width="100%"
                    />
                </div>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button
                        size={matches ? "small" : "large"}
                        text={t('disableCompany.yesBtn', {
                            action:
                                disabled
                                    ?
                                    t('disableCompany.enable')
                                    :
                                    t('disableCompany.disable')
                        })
                        }
                        onClick={onAffirmative}
                        width="100%"
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default DisableCompanyModal