import API from '../api'
import { AppDispatch } from "../store";
import errorHandler from '../actions/errorHandler'
import successHandler from './successHandler';

export const getTemplates = (CustomerId: number, checked?: string) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    if (checked) {
      let url = new URL(process.env.REACT_APP_API_URL +'/api/templates/' + CustomerId)
      url.searchParams.set(checked, 'true')
      response = await API.get(url.toString())
    } else {
      response = await API.get('/api/templates/' + CustomerId)
    }
    dispatch({ type: 'TEMPLATES_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  } 
}

export const getTemplateTypes = (data: number) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/templatetypes/' + data)
    dispatch({ type: 'TEMPLATE_TYPES_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getTemplate = (data: number) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/template/' + data)
    dispatch({ type: 'TEMPLATE_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getRepositoryTemplates = () => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/public/templates')
    dispatch({ type: 'REPO_TEMPLATES_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const deleteTemplate = (data: any) => async (dispatch: any) => {
  let response
  try {
    response = await API.delete('/api/template', data)
    successHandler({ type: 'TEMPLATE_DELETED' }, dispatch, {name: data.name})
    return response
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const createTemplate = (data: Object) => async (dispatch: any) => {
  let response
  try {
    response = await API.post('/api/template', data)
      dispatch({ type: 'TEMPLATE_CREATED', payload: response.data})
    } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const resetNewTemplate = () => async (dispatch: any) => {
  dispatch({ type: 'NEW_TEMPLATE_RESET' })
}

export const updateTemplateToDraft = (data: Object) => async (dispatch: any) => {
  let response
  try {
    response = await API.patch('/api/template/draft', data)
    dispatch({ type: 'TEMPLATE_TO_DRAFT_UPDATED', payload: response.data})
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const editTemplate = (data: Object) => async (dispatch: any) => {
  let response
  try {
    response = await API.patch('/api/template', data)
      dispatch({ type: 'TEMPLATE_EDITED', payload: response.data})
  } catch (e) {
    errorHandler(e, dispatch)
  }
}

export const editTemplateSharing = (data: Object) => async (dispatch: any) => {
  let response
  try {
    response = await API.patch('/api/template/share', data)
      dispatch({ type: 'TEMPLATE_SHARING_EDITED', payload: response.data})
  } catch (e) {
    errorHandler(e, dispatch)
  }
}



