import {
    Divider,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ProofStatusCard from './ProofStatusCard';
import Breadcrumbs from '../commons/components/Breadcrumbs';

import {
    getProof,
} from '../actions/proofs';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        marginBottom: theme.spacing(11),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    column: {
        width: "47%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom: theme.spacing(6)
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    btn: {
        width: "234px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        }
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing(6)
    },
    iconBtn: {
        marginTop: theme.spacing(6),
        width: "fit-content"
    },
    radio: {
        marginTop: theme.spacing(5)
    },
    sharing: {
        display: "flex",
        alignItems: "baseline"
    },
    tooltipIcon: {
        height: "16px",
        marginLeft: theme.spacing(4),
        filter: theme.palette.filter.toBlack
    },
    divider: {
        marginBottom: theme.spacing(6)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface ProofProfileProps extends PropsFromRedux {
}

const ProofProfile: FC<ProofProfileProps> = ({
    getProof,
    mainContact,
    proof,
}) => {
    const classes = useStyles()
    // @ts-ignore
    let { proofId } = useParams()
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        getProof(proofId)
    }, [mainContact, getProof, proofId])

    return (
        <div className={classes.container}>

            <Breadcrumbs item={proof} />
            <Divider className={classes.divider} />

            <ProofStatusCard
                proof={proof}
                disabled={disabled}
                setDisabled={setDisabled}
            />
        </div>
    )
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    proof: state.proofs.proof
})

const mapDispatch = {
    getProof: (proofId: number) => getProof(proofId)
}

const connector = connect(mapState, mapDispatch)

export default connector(ProofProfile)