import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { sideosTheme } from "./themes/sideosTheme";
import { hasPermissions } from "./commons";
import { allowedPermissions, incomingRole } from "./commons/permissions";

import Layout from './layout/Layout'
import Login from './login/Login'
import AdminProfile from './admins/AdminProfile';
import NewAdmin from './admins/NewAdmin';
import Credentials from './credentials/Credentials';
import NewCredential from './credentials/NewCredential';
import CredentialCard from './credentials/CredentialProfile'
import Wallets from './wallets/Wallets'
import CreateWallet from './wallets/CreateWallet'
import ViewAndAssignWallet from './wallets/ViewAndAssignWallet'
import Onboarding from "./onboarding/Onboarding";
import NewCustomer from "./onboarding/NewCustomer"
import CustomerProfile from "./onboarding/CustomerProfile"
import Proofs from "./proofs/Proofs";
import ProofRepositoryList from "./proofs/ProofRepositoryList";
import ProofProfile from "./proofs/ProofProfile"
import NewProof from "./proofs/NewProof";
import Transactions from "./transactions/Transactions";
import Dashboard from "./dashboard/Dashboard";
import TrialLayout from "./planonboarding/PlanOnboardingLayout";
import TrialWizard from "./planonboarding/PlanOnboardingWizard";
import PlanPage from "./planonboarding/PlanPage";
import TestTemplate from "./credentials/TestTemplate";
import MySettings from "./settings/MySettings";
import CompanySettings from "./settings/CompanySettings";
import Admins from "./admins/Admins";
import Billing from "./settings/Billing"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


function App({
  token,
  permissions,
  mainContact
}) {
  if (!token) { window.FreshworksWidget('hide'); }
  return (
    <BrowserRouter getUserConfirmation={() => {
      // empty callback to block the default browser prompt
    }}>
      <ThemeProvider theme={sideosTheme}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route 
              path="/transactions" 
              name="Transactions" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {transactions: ["R"]}) ?
                    <Transactions /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route
              path="/onboarding/new"
              name="Onboard Customer"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {hasPermissions(permissions, { "admin": "O" }) || 
                  hasPermissions(permissions, { "tenant": "O" }) ? 
                    <NewCustomer /> 
                    : 
                    <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route
              path="/onboarding/subtenant/:customerId"
              name="Subtenant Profile"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {hasPermissions(permissions, { "admin": "O" }) ? 
                    <CustomerProfile /> 
                    : 
                    <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route
              path="/onboarding/:customerId"
              name="Customer Profile"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {(hasPermissions(permissions, { admin: ["O"], customers: ["RO"], wallets: ["RO"], mainContacts: ["RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) ||
                  hasPermissions(permissions, { tenant: ["O"], customers: ["R"], wallets: ["R"], mainContacts: ["R"], templates: ["R"], proof_template: ["R"], proofs: ["R"] })) ? 
                    <CustomerProfile /> 
                    : 
                    <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route
              path="/onboarding"
              name="Onboarding"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {admin: ["O"], customers: ["RO"], wallets: ["RO"], mainContacts: ["RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) ||
                    hasPermissions(permissions, {tenant: ["O"], customers: ["R"], wallets: ["R"], mainContacts: ["R"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}) ? 
                    <Onboarding /> 
                    : 
                    <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route
              path="/wallets/new"
              name="New Wallet"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {admin: ["O"], wallets: ["WO","RO"]}) ||
                    hasPermissions(permissions, {tenant: ["O"], wallets: ["W","R"]}) ? 
                    <CreateWallet /> : <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route
              path="/wallets/:walletId"
              name="Wallet"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {admin: ["O"], wallets: ["RO"], customers: ["RO"]}) ||
                    hasPermissions(permissions, {tenant: ["O"], wallets: ["R"], customers: ["R"]}) ? 
                    <ViewAndAssignWallet /> : <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route
              path="/wallets"
              name="Wallets"
              render={propsInner => token ?
                <Layout {...propsInner}>
                  {(hasPermissions(permissions, {admin: ["O"], wallets: ["RO"], customers: ["RO"]}) ||
                    hasPermissions(permissions, {tenant: ["O"], wallets: ["R"], customers: ["R"]})) ? 
                    <Wallets /> : <Redirect to="/" />
                  }
                </Layout>
                :
                <Login {...propsInner} />
              }
            />
            <Route 
              path="/claims/repository" 
              name="Claims repository" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {proofs: ["R"], contexts: ["R"]}) &&
                  allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)
                  ? 
                  <ProofRepositoryList /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/claims/new" 
              name="New claim" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {proofs: ["W","R"]}) 
                  ? 
                  <NewProof /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/claims/:proofId" 
              name="Claim Profile" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {proofs: ["R"], mainContacts: ["R"], contexts: ["R"]}) ? <ProofProfile /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
                } 
            />
            <Route 
              path="/claims" 
              name="Claims" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {proofs: ["R"], mainContacts: ["R"], contexts: ["R"]}) ? 
                    <Proofs /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/credentials/new" 
              name="New Credential" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {templates: ["W","R"], proof_template: ["R"], proofs: ["R"], mainContacts: ["R"], customers: ["R"]}) ? 
                    <NewCredential /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/credentials/:credentialId/testoffer" 
              name="Test Credential Offer" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {templates: ["R"], proofs: ["R"], proof_template: ["R"], mainContacts: ["R"], customers: ["R"]}) &&
                  allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)
                  ?
                    <TestTemplate /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/credentials/:credentialId/testrequest" 
              name="Test Credential Request" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {templates: ["R"], proofs: ["R"], proof_template: ["R"], mainContacts: ["R"], customers: ["R"]}) &&
                  allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)
                  ?
                    <TestTemplate /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/credentials/:credentialId" 
              name="Credential" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {templates: ["R"], proofs: ["R"], proof_template: ["R"], mainContacts: ["R"], customers: ["R"]}) ?
                    <CredentialCard /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/credentials" 
              name="Credentials" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {templates: ["R"], proofs: ["R"], mainContacts: ["R"], customers: ["R"], proof_template: ["R"]}) ?
                    <Credentials /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/settings/admins/new" 
              name="New Admin" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {
                    hasPermissions(permissions, {mainContacts: ["W","R"]}) ?
                    <NewAdmin /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
              />
            <Route 
              path="/settings/admins/:adminId" 
              name="Admin Profile" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {
                    hasPermissions(permissions, {
                      mainContacts: ["R"],
                      customers: ["R"],
                      users: ["R"],
                      user_permission: ["R"],
                      permissions: ["R"],
                      templates: ["R"],
                      proof_template: ["R"],
                      proofs: ["R"]
                    }) ?
                    <AdminProfile /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/settings/admins" 
              name="Admins" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {
                    hasPermissions(permissions, {mainContacts: ["R"]}) ?
                    <Admins /> : <Redirect to="/" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/settings/profilesettings" 
              name="Profile Settings" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {
                    admin: ["O"],
                    mainContacts: ["RO","R"],
                    customers: ["RO","R"],
                    plans: ["RO"],
                    wallets: ["RO","R"]
                  }) ||
                  hasPermissions(permissions, {
                    mainContacts: ["R"],
                    customers: ["R"],
                    plans: ["R"],
                    wallets: ["R"]
                  })
                  ? <MySettings /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/settings/companysettings" 
              name="Company Settings" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {hasPermissions(permissions, {
                    admin: ["O"],
                    mainContacts: ["RO","R"],
                    customers: ["RO","R"],
                    plans: ["RO"],
                    wallets: ["RO","R"]
                  }) ||
                  hasPermissions(permissions, {
                    mainContacts: ["R"],
                    customers: ["R"],
                    plans: ["R"],
                    wallets: ["R"]
                  })
                  ? <CompanySettings /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
             <Route 
              path="/settings/billing/plans" 
              name="plans" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {!hasPermissions(permissions, {admin: ["O"]}) ? <PlanPage /> : <Redirect to="/" />}
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
            />
            <Route 
              path="/settings/billing" 
              name="Billing" 
              render={propsInner => token ? 
                <Layout {...propsInner}>
                  {!hasPermissions(permissions, { admin: ["O"] }) && 
                  !hasPermissions(permissions, {trial: ["O"]}) && 
                  mainContact?.Customer?.PlanId !== 1
                  ? 
                    <Billing /> 
                    : 
                    <Redirect to="/settings/billing/plans" />
                  }
                </Layout> 
                : 
                <Login {...propsInner} />
              } 
              />
             
            <Route path="/plan-onboarding/:planId" name="Plan Onboarding" render={propsInner => <TrialLayout {...propsInner}><TrialWizard /></TrialLayout>} />
            <Route path="/" name="Dashboard" render={propsInner => token ? <Layout {...propsInner}><Dashboard /></Layout> : <Login {...propsInner} />} />
          </Switch>
        </React.Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    token: state.login.token,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
