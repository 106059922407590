import {
    Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { FC } from 'react';

const useStyles = makeStyles((theme?: any)=>({
    checked: {
        color: theme.palette.primary[600],
        "&:hover": {
            color: theme.palette.primary[700]
        },
        "&:active": {
            color: theme.palette.primary[600]
        },
    },
    disabledChecked: {
        "& .MuiSwitch-thumb": {
            background: theme.palette.primary[200]
        },
        "& .Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.primary[300]
        }
    },
    off: {
        "& .MuiSwitch-thumb": {
            background: theme.palette.onSurface.highEmphasis
        },
        "& .MuiSwitch-track": {
            backgroundColor: theme.palette.onSurface.disabled
        }       
    }
}))

interface ToggleProps {
    checked?: boolean
    disabled?: boolean
    onClick?: (arg?: any) => void
}

const Toggle: FC<ToggleProps> = ({
    checked=false,
    disabled=false,
    onClick
}) => {
    const classes = useStyles()

    return (
        <Switch 
            color="primary" 
            className={clsx({
                [classes.disabledChecked]: checked && disabled,
                [classes.off]: !checked && !disabled
            })}
            classes={{checked: classes.checked}} 
            checked={checked} 
            disabled={disabled}
            onClick={onClick}
        />
    )         
}

export default Toggle