import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
  Typography,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getProofRepository
} from '../actions/proofs';
import { Proof } from '../commons/types';
import { hasPermissions } from '../commons';

import Search from '../commons/components/Search';
import Button from '../commons/components/Button'
import TableDateAndTime from '../commons/components/TableDateAndTime'
import Breadcrumbs from '../commons/components/Breadcrumbs';
import CollapseTable from '../commons/components/CollapseTable'
import SmallTable from '../commons/components/SmallTable';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: { 
      height: "100%"
    }
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, 8, 8, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      height: "100%"
    }
  },
  row: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "inline"
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  mediumEmphasis: {
    color: theme.palette.onSurface.mediumEmphasis,
    [theme.breakpoints.down('sm')]: {
      display: "inline"
    }
  },
  title: {
    color: theme.palette.onSurface.highEmphasis,
    marginTop: "24px",
    marginBottom: "24px"
  },
  pinkLink: {
    color: theme.palette.primary[600],
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "1.25px",
    marginLeft: "3px",
    textDecoration: "none",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      alignItems: "baseline",
      display: "inline"
    }
  },
  tableContainer: {
    height: "472px",
    [theme.breakpoints.down('sm')]: {
      height: "auto"
    }
  },
  modalTableContainer: {
    height: "316px",
    [theme.breakpoints.down('sm')]: {
      height: "auto"
    }
  },
  buttonContainer: {
    alignSelf: "flex-end",
    marginTop: "32px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      width: "100%"
    }
  },
  modalButtonContainer: {
    width: "450px",
    display: "flex",
    alignSelf: "flex-end",
    marginTop: "32px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      width: "100%"
    }
  },
  btn: {
    width: "210px",
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: theme.spacing(4)
    }
  },
  link: {
    textDecoration: "none"
  },
  description: {
    color: theme.palette.onSurface.disabled
  },
  inline: {
    [theme.breakpoints.down('sm')]: {
      display: "inline"
    }
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface ProofRepositoryListProps extends PropsFromRedux {
  inModal?: boolean
  onCancel?: (arg: boolean) => void
  setPickedProof?: (arg: Proof) => void
  setOpenCreateNew?: (arg: boolean) => void
}

const ProofRepositoryList: FC<ProofRepositoryListProps> = ({
  proofRepository,
  getProofRepository,
  inModal,
  onCancel,
  setPickedProof,
  setOpenCreateNew,
  permissions
}) => {
  const classes = useStyles()
  let history = useHistory()
  const { t } = useTranslation()
  const [matches, setMatches] = useState<Credential[]>([])
  const [input, setInput] = useState("")
  const columns = {
    name: t('proofs.name'),
    type: t('proofs.type'),
  }
  const [selectedProof, setSelectedProof] = useState<Proof | null>(null)

  useEffect(() => {
    hasPermissions(permissions, {proofs: ["R"], contexts: ["R"]}) && getProofRepository()
    return () => setSelectedProof(null)
  }, [getProofRepository])

  const renderSearchMatches = (matches: [], input: string) => {
    setInput(input)
    setMatches(matches)
  }

  const handleCellClick = (item: any) => {
    setSelectedProof(item)
  }

  const handleConfirm = () => {
    if (inModal) {
      selectedProof ? setPickedProof && setPickedProof(selectedProof)
        :
        setOpenCreateNew && setOpenCreateNew(true)
    } else {
      history.push({ pathname: "/claims/new", state: { pickedProof: selectedProof } })
    }
  }

  return (
    <div className={clsx({
      [classes.container]: !inModal,
      [classes.modalContainer]: inModal
    })}
    >
      {!inModal &&
        <>
            <Breadcrumbs />
            <Divider />
        </>
      }
      <Typography variant="h6" className={classes.title}>
        {t('proofs.pickTitle')}
      </Typography>
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.mediumEmphasis}>
          {t('proofs.pickSubtitle')}
        </Typography>
        {!inModal ? <Link to="/claims/new" className={classes.pinkLink}>
          <Typography variant="subtitle1" className={classes.inline}>
            {t('proofs.createLink')}.
          </Typography>
        </Link>
          :
          <div className={classes.pinkLink} onClick={handleConfirm}>
            <Typography variant="subtitle1" className={classes.inline}>
              {t('proofs.createLink')}.
            </Typography>
          </div>
        }
      </div>
      <Search
        rows={proofRepository}
        renderSearchMatches={renderSearchMatches}
      />
      <div className={clsx({
        [classes.tableContainer]: !inModal,
        [classes.modalTableContainer]: inModal
      })}
      >
        <SmallTable
          color={false}
          expandable={true}
          checkExpandableValue={() => true}
          columns={columns}
          selected={selectedProof?.id}
          rows={input !== "" ? matches : proofRepository}
          handleCellClick={handleCellClick}
          tableCells={{
            createdAt: (item: any) => {
              return (
                <TableDateAndTime
                  dateAndTime={item.createdAt}
                />
              )
            },
            type: (item: any) => {
              return (
                <div className={classes.nameContainer}>
                  <Typography
                    variant="body2"
                    className={classes.name}
                  >
                    {item.type}
                  </Typography>
                </div>
              )
            },
          }}
          collapseElement={(item) =>
            <CollapseTable
              titles={[
                { prop: "Context", title: t('proofs.context') },
                { prop: "description", title: t('proofs.description') }
              ]}
              item={item}
              tableCells={{
                Context: (item: any) => {
                  return (
                    <a
                      href={item.Context?.context?.split(' ')[1]}
                      key={item.Context.id}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                    >
                      <Typography
                        variant="button"
                        className={classes.mediumEmphasis}
                      >
                        {item.Context?.type}
                      </Typography>
                    </a>
                  )
                },
                description: (item: any) => {
                  return (
                    <Typography
                      variant="body1"
                      className={classes.description}
                    >
                      {item.description}
                    </Typography>
                  )
                }
              }}
            />
          }
        />
      </div>
      <div className={clsx({
        [classes.buttonContainer]: !inModal,
        [classes.modalButtonContainer]: inModal
      })}
      >
        {inModal &&
          <div className={classes.btn}>
            <Button
              text={t('general.Cancel')}
              version="text"
              width="100%"
              onClick={onCancel}
            />
          </div>}
          <div className={classes.btn}>
        <Button
          text={t('general.Confirm')}
          version="primary"
          width="100%"
          disabled={!selectedProof?.id || selectedProof.id === 0}
          onClick={() => handleConfirm()}
        />
        </div>
      </div>
    </div>
  );
}

const mapState = (state: RootState) => ({
  proofRepository: state.proofs.proofRepository,
  permissions: state.login.permissions
})

const mapDispatch = {
  getProofRepository: () => (getProofRepository()),
}

const connector = connect(mapState, mapDispatch)

export default connector(ProofRepositoryList)
