import { Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../store'
import { FC } from 'react';
import { useTranslation } from "react-i18next";
import errorIcon from './assets/error-icon.svg'

const useStyles = makeStyles((theme?: any)=>({
    snackbarContainer: {
        position: "fixed",
        bottom: 0,
        zIndex: 2147483648,
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(5),
            width: "95%"
        }
    },
    snackbar: {
        "& .MuiSnackbarContent-root": {
            height: "48px",
            backgroundColor: theme.palette.error.main,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(6),
            [theme.breakpoints.down('md')]: {
                height: "fit-content",
                display: "flex",
                padding: theme.spacing(5),
                flexWrap: "nowrap"
            }
        },    
    },
    root: {
        position: "relative",
        justifySelf: "flex-end",
        marginBottom: theme.spacing(4)
    },
    row: {
        display: "flex",
        justifyContent: "space-between"
    },
    icon: {
        marginRight: theme.spacing(4)
    },
    action: {
        display: "inline-block",
        margin: theme.spacing(0, 6),
        cursor: "pointer",
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0,3),
        }
    },
    msg: {
        overflowWrap: "normal"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

type Error = {
    code?: string,
    msg?: string,
    action?: (() => void) | null,
    btnTxt?: string
}

interface ErrorSnackbarProps extends PropsFromRedux {
    errors: Error[]
}

const ErrorSnackbar: FC<ErrorSnackbarProps> = ({
    errors,
    resetErr,
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClose = (e: any, error: Error) => {
        e.stopPropagation()
        resetErr(error)
    }

    // check if there is a custom action for that error,
    // if not, default is dismiss
    const renderActionElement = (error: Error) => {
        return (
            <Typography 
                variant="button" 
                className={classes.action}
                onClick={error.action ? error.action : (e)=>handleClose(e, error)}
            >
                {error.btnTxt ? error.btnTxt : t('error.dismiss')}
            </Typography>
        )                  
    }

    return (
        <div className={classes.snackbarContainer}>
            {errors.map((error, index)=>{
                return (
                    <Snackbar 
                        key={index}
                        open={error.msg ? true : false} 
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        message={
                            <div className={classes.row}>
                                <img 
                                    src={errorIcon} 
                                    alt="exclamation sign in circle icon" 
                                    className={classes.icon}
                                />
                                <Typography variant="body2" className={classes.msg}>
                                    {error.msg}
                                </Typography>
                            </div>
                        } 
                        className={classes.snackbar}
                        classes={{root: classes.root}}
                        action={renderActionElement(error)}
                        onClose={(e)=>handleClose(e, error)}
                    />  
                )             
            })}
        </div>
    )
}

const mapState = (state: RootState) => ({
    errors: state.errorReducer.errors
  })
  
  const mapDispatch = {
    resetErr: (error: Error) => ({ type: 'RESET_ERROR', error: error })
  }
  
  const connector = connect(mapState, mapDispatch)
  
  export default connector(ErrorSnackbar)