import {
    InputBase,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import searchIcon from './assets/search-icon.svg'

const useStyles = makeStyles((theme?: any)=>({
    input: {
        height: "40px",
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        borderRadius: theme.shape.sharpBorderRadius,
        margin: theme.spacing(6, 5, 6, 0),
        padding: theme.spacing(4),
        color: theme.palette.onSurface.mediumEmphasis,
        "&.MuiInputBase-root:focus-within": {
            border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.hover,
            border: `1px solid ${theme.palette.primary[600]}`
        },
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(6, 0, 6, 0),
        }
    },
    icon: {
        marginRight: theme.spacing(5)
    }
}))


interface SearchProps {
    rows: {}[]
    renderSearchMatches: (matches: any, input: string) => void
}

const Search: FC<SearchProps> = ({
    rows=[{}],
    renderSearchMatches
}) => {
    
    const classes = useStyles()
    const { t } = useTranslation()
    const [input, setInput] = useState("")

    useEffect(()=>{
        const matches: any = []
        rows?.map((row: any)=>{
            Object.keys(row).map((column: any)=>{
                if (typeof row[column] !== "object") {
                    let field = row[column]?.toString() 
                    let inputField = input?.toString()
                    return field?.toLowerCase().includes(inputField.toLowerCase()) 
                        && !matches.includes(row)
                    ? 
                    matches.push(row) 
                    : 
                    matches
                } else if (row[column]) {
                    Object.keys(row?.[column]).map((key)=>{
                        let field = row[column][key]?.toString() 
                        let inputField = input?.toString()
                        return field?.toLowerCase().includes(inputField.toLowerCase()) 
                            && !matches.includes(row)
                        ? 
                        matches.push(row) 
                        : 
                        matches
                    })
                }
                return matches
            })
            return matches
        })
        renderSearchMatches(matches, input)
    }, [input])

    return (
        <InputBase 
            className={classes.input}
            value={input}
            name="search"
            placeholder={t('general.search')}
            startAdornment={
                <img 
                    src={searchIcon} 
                    alt="magnifying glass icon" 
                    className={classes.icon}
                />
            }
            type="string"
            onChange={(e)=>setInput(e.target.value)}
            fullWidth
        />       
    )
}

export default Search