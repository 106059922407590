export const pp = `
    Wir haben diese Datenschutzerklärung (Fassung 27.08.2020-321206806) verfasst, um Ihnen gemäß
der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen
wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher
dieser Webseite haben.
    Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben
uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu
beschreiben.
    Automatische Datenspeicherung
    Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt
und gespeichert, so auch auf dieser Webseite.
Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf
dem diese Webseite gespeichert ist) automatisch Daten wie
    ● die Adresse (URL) der aufgerufenen Webseite
    ● Browser und Browserversion
    ● das verwendete Betriebssystem
    ● die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
    ● den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
    ● Datum und Uhrzeit
in Dateien (Webserver-Logfiles).
    In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch
gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten
beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
    Cookies
    Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die
folgende Datenschutzerklärung besser verstehen.
    Was genau sind Cookies?
    Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten
Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten
verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies
für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website
auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im
Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem
Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere
Attribute angegeben werden.
    Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die
„userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website,
wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes
Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen
Datei gespeichert.
    Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden
direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten
speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar
Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere
„Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
    So können zum Beispiel Cookie-Daten aussehen:
    ● Name: _ga
    ● Ablaufzeit: 2 Jahre
    ● Verwendung: Unterscheidung der Webseitenbesucher
    ● Beispielhafter Wert: GA1.2.1326744211.152321206806
    Ein Browser sollte folgende Mindestgrößen unterstützen:
    ● Ein Cookie soll mindestens 4096 Bytes enthalten können
    ● Pro Domain sollen mindestens 50 Cookies gespeichert werden können
    ● Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
    Welche Arten von Cookies gibt es?
    Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab
und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten
wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
Man kann 4 Arten von Cookies unterscheiden:
    Unbedingt notwendige Cookies
    Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel
braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen
Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht
gelöscht, selbst wenn der User sein Browserfenster schließt.
    Funktionelle Cookies
    Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen
bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website
bei verschiedenen Browsern gemessen.
    Zielorientierte Cookies
    Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
Standorte, Schriftgrößen oder Formulardaten gespeichert.
    Werbe-Cookies
    Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell
angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser
Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie
gespeichert.
    Wie kann ich Cookies löschen?
    Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem
Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu
löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von
Drittanbietern blockieren, aber alle anderen Cookies zulassen.
    Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen
finden:
    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
    Safari: Verwalten von Cookies und Websitedaten mit Safari
    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
    Internet Explorer: Löschen und Verwalten von Cookies
    Microsoft Edge: Löschen und Verwalten von Cookies
    Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass
er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen
Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser
verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies
löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers oder
tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.
    Wie sieht es mit meinem Datenschutz aus?
    Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern
von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch
sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die
Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung
dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
    Wenn Sie mehr über Cookies wissen möchten und technischen Dokumentationen nicht scheuen,
empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der Internet
Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.
Speicherung persönlicher Daten
    Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel
Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung
eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und
der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte
weitergegeben.
    Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die
Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen
Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter,
können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten
eingesehen werden.
    Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir
keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen,
vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung)
darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie
können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden
unsere Kontaktdaten im Impressum.
    Rechte laut Datenschutzgrundverordnung
    Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
● Recht auf Berichtigung (Artikel 16 DSGVO)
● Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
● Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
● Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung
oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung
(Artikel 19 DSGVO)
● Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
● Widerspruchsrecht (Artikel 21 DSGVO)
● Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich
Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder
Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich
an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.
Auswertung des Besucherverhaltens
In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres
Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel
anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.
Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in
der folgenden Datenschutzerklärung.
TLS-Verschlüsselung mit https
Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch
Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer
Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir
den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der
Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des
Schemas https (anstatt http) als Teil unserer Internetadresse.
Google Fonts Datenschutzerklärung
Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma
Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort
hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com
angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen
anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben,
dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt
werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten
Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden
wir uns noch im Detail ansehen.
Was sind Google Fonts?
Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google
seinen Nutzern kostenlos zu Verfügung stellt.
Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter
der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
Warum verwenden wir Google Fonts auf unserer
Webseite?
Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht
auf unseren eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität
unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert
und dies spart Datenvolumen und ist speziell für die Verwendung mit mobilen Endgeräten ein
großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle
Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen
Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten
optisch verzerren. Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts
keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google
Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten
modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad,
iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön
und einheitlich wie möglich darstellen können.
Welche Daten werden von Google gespeichert?
Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen.
Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google
auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde
entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu
reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für
„Application Programming Interface“ und dient unter anderem als Datenübermittler im
Softwarebereich.
Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch
die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften
ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise
Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um
festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der
BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das
Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.
Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und
Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch
gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
Wie lange und wo werden die Daten gespeichert?
Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich
außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die
Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B.
das Design bzw. die Schriftart einer Webseite ändern kann.
Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die
Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die
gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und
erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert
Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen
und das Design zu verbessern.
Wie kann ich meine Daten löschen bzw. die
Datenspeicherung verhindern?
Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht
werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten
vorzeitig löschen zu können, müssen Sie den Google-Support auf
https://support.google.com/?hl=de&tid=321206806 kontaktieren. Datenspeicherung verhindern
Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle
Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das
Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf
https://developers.google.com/fonts/faq?tid=321206806. Dort geht zwar Google auf
datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über
Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
Informationen über gespeicherten Daten zu bekommen.
Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden,
können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
Google Fonts Lokal Datenschutzerklärung
Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist
das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf
den Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und
somit auch keine Datenübertragung oder Speicherung.
Was sind Google Fonts?
Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives
Verzeichnis mit über 800 Schriftarten, die Google kostenlos bereitstellt. Mit Google Fonts könnte
man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede
Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf
unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden
keine Daten an Google Fonts weiter.
Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle
Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das
Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf
https://developers.google.com/fonts/faq?tid=321206806.
Google Analytics Datenschutzerklärung
Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des
amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen
Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn
Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an
Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir
unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir
näher auf das Tracking Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert
werden und wie Sie das verhindern können.
Was ist Google Analytics?
Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit
Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut.
Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie
auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die
Google-Analytics-Server gesendet und dort gespeichert.
Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es
sich unter anderem um folgende Berichte handeln:
● Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und
wissen genauer, wer sich für unser Service interessiert.
● Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter
analysieren und verbessern.
● Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber,
wie wir mehr Menschen für unser Service begeistern können.
● Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können
nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie
anklicken.
● Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer
Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von
einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden.
Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei
Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
● Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert.
Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.
Warum verwenden wir Google Analytics auf unserer
Webseite?
Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die
Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.
Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen
unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten
Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher
besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen,
um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und
Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur
Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.
Welche Daten werden von Google Analytics
gespeichert?
Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem
Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das
nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle
gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst
möglich pseudonyme Userprofile auszuwerten.
Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer
Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website
ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt
keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu
Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.
Folgende Cookies werden von Google Analytics verwendet:
Name: _ga
Wert: 2.1326744211.152321206806-5
Verwendungszweck: Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.
Ablaufdatum: nach 2 Jahren
Name: _gid
Wert: 2.1687193234.152321206806-1
Verwendungszweck: Das Cookie dient auch zur Unterscheidung der Webseitenbesucher.
Ablaufdatum: nach 24 Stunden
Name: _gat_gtag_UA_<property-id>
Wert: 1
Verwendungszweck: Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics
über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
<property-id>.
Ablaufdatum: nach 1 Minute
Name: AMP_TOKEN
Wert: keine Angaben
Verwendungszweck: Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst
abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder
einen Fehler hin.
Ablaufdatum: nach 30 Sekunden bis zu einem Jahr
Name: __utma
Wert: 1564498958.1564498958.1564498958.1
Verwendungszweck: Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die
Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics
gesendet werden.
Ablaufdatum: nach 2 Jahren
Name: __utmt
Wert: 1
Verwendungszweck: Das Cookie wird wie _gat_gtag_UA_<property-id> zum Drosseln der
Anforderungsrate verwendet.
Ablaufdatum: nach 10 Minuten
Name: __utmb
Wert: 3.10.1564498958
Verwendungszweck: Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird
jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.
Ablaufdatum: nach 30 Minuten
Name: __utmc
Wert: 167421564
Verwendungszweck: Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende
Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie, und es wird nur solange
gespeichert, bis Sie den Browser wieder schließen.
Ablaufdatum: Nach Schließung des Browsers
Name: __utmz
Wert: m|utmccn=(referral)|utmcmd=referral|utmcct=/
Verwendungszweck: Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf
unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website
gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.
Ablaufdatum: nach 6 Monaten
Name: __utmv
Wert: keine Angabe
Verwendungszweck: Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern.
Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.
Ablaufdatum: nach 2 Jahren
Anmerkung: Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die
Wahl seiner Cookies immer wieder verändert.
Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics
erhoben werden:
Heatmaps: Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche,
die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
Sitzungsdauer: Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen,
ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.
Absprungrate (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website
nur eine Seite ansehen und dann unsere Website wieder verlassen.
Kontoerstellung: Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen,
erhebt Google Analytics diese Daten.
IP-Adresse: Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige
Zuordnung möglich ist.
Standort: Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden.
Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
Technische Informationen: Zu den technischen Informationen zählen unter anderem Ihr
Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.
Herkunftsquelle: Google Analytics beziehungsweise uns, interessiert natürlich auch über welche
Website oder welche Werbung Sie auf unsere Seite gekommen sind.
Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie
ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das
Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur
zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.
Wie lange und wo werden die Daten gespeichert?
Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika
und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie
genau nachlesen wo sich die Google-Rechenzentren befinden:
https://www.google.com/about/datacenters/inside/locations/?hl=de
Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass
die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem
Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn
beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt
das Risiko einer Dienstunterbrechung bei Google dennoch gering.
Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten
eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die
Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur
Verfügung:
● Löschung nach 14 Monaten
● Löschung nach 26 Monaten
● Löschung nach 38 Monaten
● Löschung nach 50 Monaten
● Keine automatische Löschung
Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese
Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B.
Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten
Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine
Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.
Wie kann ich meine Daten löschen bzw. die
Datenspeicherung verhindern?
Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre
Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des
Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js)
verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter
https://tools.google.com/dlpage/gaoptout?hl=de runterladen und installieren. Beachten Sie bitte,
dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder
verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:
Chrome: Cookies in Chrome löschen, aktivieren und verwalten
Safari: Verwalten von Cookies und Websitedaten mit Safari
Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
Internet Explorer: Löschen und Verwalten von Cookies
Microsoft Edge: Löschen und Verwalten von Cookies
Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der
korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu
finden Sie auf https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=321206806.
Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von
Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen,
empfehlen wir diese beiden Links: http://www.google.com/analytics/terms/de.html und
https://support.google.com/analytics/answer/6004245?hl=de.
Google Analytics IP-Anonymisierung
Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics
implementiert. Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden
Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbehörden einhalten kann,
wenn diese eine Speicherung der vollständigen IP-Adresse untersagen. Die Anonymisierung bzw.
Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungs
Netzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
Mehr Informationen zur IP-Anonymisierung finden Sie auf
https://support.google.com/analytics/answer/2763052?hl=de.
Google Analytics Berichte zu demografischen
Merkmalen und Interessen
Wir haben in Google Analytics die Funktionen für Werbe Berichte eingeschaltet. Die Berichte zu
demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und
Interessen. Damit können wir uns – ohne diese Daten einzelnen Personen zuordnen zu können –
ein besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen erfahren Sie auf
https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad.
Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter
“Einstellungen für Werbung” auf https://adssettings.google.com/authenticated per Checkbox
beenden.
Google Analytics Zusatz zur Datenverarbeitung
Wir haben mit Google einen Direktkunden Vertrag zur Verwendung von Google Analytics
abgeschlossen, indem wir den “Zusatz zur Datenverarbeitung” in Google Analytics akzeptiert
haben.
Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden Sie hier:
https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad
Google Analytics Google-Signale
Datenschutzerklärung
Wir haben in Google Analytics die Google-Signale aktiviert. So werden die bestehenden
Google-Analytics-Funktionen (Werbeberichte, Remarketing, gerätübergreifende Berichte und
Berichte zu Interessen und demografische Merkmale) aktualisiert, um zusammengefasste und
anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte Anzeigen in Ihrem
Google-Konto erlaubt haben.
Das besondere daran ist, dass es sich dabei um ein Cross-Device-Tracking handelt. Das heißt Ihre
Daten können geräteübergreifend analysiert werden. Durch die Aktivierung von Google-Signale
werden Daten erfasst und mit dem Google-Konto verknüpft. Google kann dadurch zum Beispiel
erkennen, wenn Sie auf unsere Webseite über ein Smartphone ein Produkt ansehen und erst
später über einen Laptop das Produkt kaufen. Dank der Aktivierung von Google-Signale können
wir gerätübergreifende Remarketing-Kampagnen starten, die sonst in dieser Form nicht möglich
wären. Remarketing bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot zeigen
können.
In Google Analytics werden zudem durch die Google-Signale weitere Besucherdaten wie Standort,
Suchverlauf, YouTube-Verlauf und Daten über Ihre Handlungen auf unserer Webseite, erfasst. Wir
erhalten dadurch von Google bessere Werbeberichte und nützlichere Angaben zu Ihren Interessen
und demografischen Merkmalen. Dazu gehören Ihr Alter, welche Sprache sie sprechen, wo Sie
wohnen oder welchem Geschlecht Sie angehören. Weiters kommen auch noch soziale Kriterien
wie Ihr Beruf, Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen Google
Analytics Personengruppen bzw. Zielgruppen zu definieren.
Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und Interessen besser einschätzen zu
können. Dadurch können wir unsere Dienstleistungen und Produkte für Sie optimieren und
anpassen. Diese Daten laufen standardmäßig nach 26 Monaten ab. Bitte beachten Sie, dass diese
Datenerfassung nur erfolgt, wenn Sie personalisierte Werbung in Ihrem Google-Konto zugelassen
haben. Es handelt sich dabei immer um zusammengefasste und anonyme Daten und nie um Daten
einzelner Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw. auch löschen.
Google reCAPTCHA Datenschutzerklärung
Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns bestmöglich zu sichern und zu
schützen. Um das zu gewährleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. Für
den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow
Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit reCAPTCHA können wir
feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder eine
andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg, unerwünschte
Information, die uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur
Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von Google müssen wir Sie meist
nicht mit solchen Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie einfach ein
Häkchen setzen und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA
Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie das genau funktioniert und vor allem
welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.
Was ist reCAPTCHA?
reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den
Missbrauch durch nicht-menschliche Besucher schützt. Am häufigsten wird dieser Dienst
verwendet, wenn Sie Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art
automatischer Turing-Test, der sicherstellen soll, dass eine Handlung im Internet von einem
Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt nach
dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest.
Bei Captchas übernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas
arbeiten mit kleinen Aufgaben, die für Menschen leicht zu lösen sind, doch für Maschinen
erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel mehr lösen.
Das Tool verwendet moderne Risikotechniken, um Menschen von Bots zu unterscheiden. Hier
müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA
ist selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext
eingebunden und dann läuft das Tool im Hintergrund und analysiert Ihr Benutzerverhalten. Aus
diesen Useraktionen berechnet die Software einen sogenannten Captcha-Score. Google berechnet
mit diesem Score schon vor der Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein
Mensch sind. reCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn
Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen
könnten.
Warum verwenden wir reCAPTCHA auf unserer
Webseite?
Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder
Spam-Software unterschiedlichster Art dürfen getrost zuhause bleiben. Darum setzen wir alle
Hebel in Bewegung, uns zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie
anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma Google. So können wir
uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die Verwendung von
reCAPTCHA werden Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich ein
Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit
auch Ihrer Sicherheit. Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei einer
Registrierung ein Bot möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren oder
Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA können wir solche
Botangriffe vermeiden.
Welche Daten werden von reCAPTCHA gespeichert?
reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen
auf unserer Webseite auch wirklich von Menschen stammen. Es kann also die IP-Adresse und
andere Daten, die Google für den reCAPTCHA-Dienst benötigt, an Google versendet werden.
IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des
Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt, bevor die Daten
auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google
kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit Ihrem Google-Konto
angemeldet sind. Zuerst prüft der reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon
Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert sind. Anschließend
setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser und erfasst einen Schnappschuss Ihres
Browserfensters.
Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf
Vollständigkeit. Vielmehr sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google
verarbeitet werden.
● Referrer URL (die Adresse der Seite von der der Besucher kommt)
● IP-Adresse (z.B. 256.123.123.1)
● Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers ermöglicht.
Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)
● Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
● Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur
ausführen wird gespeichert)
● Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC
voreingestellt haben wird gespeichert)
● Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten
ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten
unter einem Namen sammeln)
● Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)
Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das
Häkchen „Ich bin kein Roboter“ klicken. Bei der Invisible reCAPTCHA-Version fällt sogar das
Ankreuzen weg und der ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche
Daten Google genau speichert, erfährt man von Google nicht im Detail.
Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns auf die reCAPTCHA
Demo-Version von Google unter https://www.google.com/recaptcha/api2/demo. All diese Cookies
benötigen zu Trackingzwecken eine eindeutige Kennung. Hier ist eine Liste an Cookies, die Google
reCAPTCHA auf der Demo-Version gesetzt hat:
Name: IDE
Wert: WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-321206806-8
Verwendungszweck: Dieses Cookie wird von der Firma DoubleClick (gehört auch Google) gesetzt,
um die Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu registrieren und
zu melden. So kann die Werbewirksamkeit gemessen und entsprechende
Optimierungsmaßnahmen getroffen werden. IDE wird in Browsern unter der Domain
doubleclick.net gespeichert.
Ablaufdatum: nach einem Jahr
Name: 1P_JAR
Wert: 2019-5-14-12
Verwendungszweck: Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst
Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem Käufer wird. Das Cookie wird
auch verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann man mit dem
Cookie vermeiden, dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.
Ablaufdatum: nach einem Monat
Name: ANID
Wert: U7j1v3dZa3212068060xgZFmiqWppRWKOr
Verwendungszweck: Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen. In der
Datenschutzerklärung von Google wird das Cookie im Zusammenhang mit „Werbecookies“ wie z.
B. “DSID”, “FLC”, “AID”, “TAID” erwähnt. ANID wird unter Domain google.com gespeichert.
Ablaufdatum: nach 9 Monaten
Name: CONSENT
Wert: YES+AT.de+20150628-20-0
Verwendungszweck: Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung
unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu
überprüfen, Betrügereien von Anmeldeinformationen zu verhindern und Userdaten vor
unbefugten Angriffen zu schützen.
Ablaufdatum: nach 19 Jahren
Name: NID
Wert: 0WmuWqy321206806zILzqV_nmt3sDXwPeM5Q
Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist eingegebenen
Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer
maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, um persönliche
Einstellungen des Users für Werbezwecke zu sammeln.
Ablaufdatum: nach 6 Monaten
Name: DV
Wert: gEAABBCjJMXcI0dSAAAANbqc321206806-4
Verwendungszweck: Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird
dieses Cookie gesetzt. Das Cookie wird von Google Analytics für personalisierte Werbung
verwendet. DV sammelt Informationen in anonymisierter Form und wird weiters benutzt, um
User-Unterscheidungen zu treffen.
Ablaufdatum: nach 10 Minuten
Anmerkung: Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google
erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
Wie lange und wo werden die Daten gespeichert?
Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen.
Wo genau diese Daten gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen,
nicht klar dar. Ohne eine Bestätigung von Google erhalten zu haben, ist davon auszugehen, dass
Daten wie Mausinteraktion, Verweildauer auf der Webseite oder Spracheinstellungen auf den
europäischen oder amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die Ihr
Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus weiteren
Google-Diensten zusammengeführt. Wenn Sie allerdings während der Nutzung des
reCAPTCHA-Plug-ins bei Ihrem Google-Konto angemeldet sind, werden die Daten
zusammengeführt. Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.
Wie kann ich meine Daten löschen bzw. die
Datenspeicherung verhindern?
Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden,
müssen Sie sich, bevor Sie unsere Webseite besuchen bzw. die reCAPTCHA-Software verwenden,
bei Google vollkommen ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die
Daten sobald Sie unsere Seite aufrufen automatisch an Google übermittelt. Um diese Daten wieder
zu löschen, müssen Sie den Google-Support auf
https://support.google.com/?hl=de&tid=321206806 kontaktieren.
Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und
deren Vertreter automatisch Daten erheben, bearbeiten und nutzen.
Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf
https://developers.google.com/recaptcha/. Google geht hier zwar auf die technische Entwicklung
der reCAPTCHA näher ein, doch genaue Informationen über Datenspeicherung und
datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute Übersicht über die
grundsätzliche Verwendung von Daten bei Google finden Sie in der hauseigenen
Datenschutzerklärung auf https://www.google.com/intl/de/policies/privacy/.
Kontakt
Zu Fragen zum Datenschutz erreichen Sie uns per Email unter pricavy@sideos.io .
Quelle: Erstellt mit dem Datenschutz Generator von AdSimple.


`