import { AnyAction } from 'redux'

const admin = {
  id: 0,
  CustomerId: 0,
  nickname: "",
  name: "",
  lastName: "",
  role: "",
  did: "",
  createdAt: "",
  updatedAt: "",
  email: "",
  disable: false,
  status: 0,
  Templates: [],
  image: "",
  assignedOn: "",
  colorTheme: false,
  main: false,
  User: {
    Permissions: [
      {id: 0}
    ]
  }
}

const initialState = {
  admins: [],
  admin: admin,
  adminProfilePicture: null,
  newAdmin: admin,
  qrcode: {data: ""},
  emailSent: "",
  emailAddress: "",
  deletedAdmin: false
}

type AdminReducer = typeof initialState
  
export default function adminReducer(state=initialState, action: AnyAction): AdminReducer {
    switch (action.type) {
      case 'ADMINS_LOADED': {
        return {
          ...state,
            admins: action.payload,
          }
        }
        case 'ADMIN_LOADED': {
          return {
            ...state,
            admin: action.payload
        }
      }
      case 'ADMIN_PROFILE_PICTURE_LOADED': {
        return {
          ...state,
          adminProfilePicture: action.payload
        }
      }
      case 'ADMIN_ENABLED_OR_DISABLED': {
        return {
          ...state,
          admin: action.payload
        }
      }
      case 'ADMIN_CREATED': {
        return {
          ...state,
          newAdmin: action.payload
        }
      }
      case 'ADMIN_DRAFT_EDITED': {
        return {
          ...state,
          admin: action.payload
        }
      }
      case 'ADMIN_DELETED': {
        return {
          ...state,
          deletedAdmin: !initialState.deletedAdmin
        }
      }
      case 'OFFER_LOADED': {
        return {
          ...state,
          qrcode: action.payload
        }
      }
      case 'EMAIL_SENT': {
        return {
          ...state,
          emailSent: action.payload,
          emailAddress: action.data.email
        }
      }
      case 'DESTINATION_EMAIL_RESET': {
        return {
          ...state,
          emailSent: initialState.emailSent,
          emailAddress: initialState.emailAddress
        }
      }
      case 'QRCODE_RESET': {
        return {
          ...state,
          qrcode: initialState.qrcode
        }
      }
      case 'ADMIN_PERMISSIONS_UPDATED': {
        return {
          ...state,
          admin: action.payload
        }
      }
      default:
        return state;
    }
  }
  
