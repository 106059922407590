import { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { 
    Paper,
    Tabs,
    Tab
 } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Overview from './Overview';
import BillingDetails from './BillingDetails'
import PaymentDetails from './PaymentDetails';
import {getPaymentInfo, getBillingDetails} from '../actions/settings'



const useStyles = makeStyles((theme?: any)=>({
    formPaper: {
        marginTop: theme.spacing(6),
        width: "90%",
        minHeight: "580px",
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
            marginRight: "unset",
            padding: theme.spacing(0),
        }
    },
    tabsContainer: {
        width: "100%",
        justifyContent: "space-around",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`
    },
    tab: {
        width: "100%",
        minWidth: "unset",
        color: theme.palette.onSurface.mediumEmphasis
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface BillingProps extends PropsFromRedux {}

const Billing: FC<BillingProps> = ({
    getPaymentInfo,
    getBillingDetails,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useState(0)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue)
    }
    //@ts-ignore
    let mainContact = JSON.parse(localStorage.getItem('mainContact'))
    let customerId = mainContact.CustomerId

  return (
    <Paper 
        className={classes.formPaper} 
        elevation={0}
    >
        <Tabs 
            classes={{flexContainer: classes.tabsContainer}}
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
        >
            <Tab 
                label={t('billing.overview')} 
                classes={{root: classes.tab}}
            />
            <Tab 
                label={t('billing.details')} 
                className={classes.tab}
                onClick={()=>getBillingDetails(customerId)}
            />
            <Tab 
                label={t('billing.payment')} 
                className={classes.tab}
                onClick={()=>getPaymentInfo(customerId)}

            />
        </Tabs>
        {activeTab === 0 && <Overview />}
        {activeTab === 1 && <BillingDetails />}
        {activeTab === 2 && <PaymentDetails />}
    </Paper>
  );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
    getPaymentInfo: (CustomerId: number) => (getPaymentInfo(CustomerId)),
    getBillingDetails:  (CustomerId: number) => (getBillingDetails(CustomerId)),
}

const connector = connect(mapState, mapDispatch)

export default connector(Billing)
