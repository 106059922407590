import { FC, Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { hasPermissions } from '../commons';
import {
    Divider,
    Drawer,
    SwipeableDrawer,
    Typography,
    Button
} from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import dashboardIcon from './assets/sidebarIcons/dashboard-icon.svg'
import dashboardIconPurple from './assets/sidebarIcons/dashboard-icon-purple.svg'
import walletsIcon from './assets/sidebarIcons/wallet-icon.svg'
import walletsIconPurple from './assets/sidebarIcons/wallet-icon-purple.svg'
import onboardingIcon from './assets/sidebarIcons/onboarding-icon.svg'
import onboardingIconPurple from './assets/sidebarIcons/onboarding-icon-purple.svg'
import credentialsIcon from './assets/sidebarIcons/credentials-icon.svg'
import credentialsIconPurple from './assets/sidebarIcons/credentials-icon-purple.svg'
import proofsIcon from './assets/sidebarIcons/proofs-icon.svg'
import proofsIconPurple from './assets/sidebarIcons/proofs-icon-purple.svg'
import transactionsIcon from './assets/sidebarIcons/transactions-icon.svg'
import transactionsIconPurple from './assets/sidebarIcons/transactions-icon-purple.svg'
import settingsIcon from './assets/sidebarIcons/settings-icon.svg'
import settingsIconPurple from './assets/sidebarIcons/settings-icon-purple.svg'
import menuIcon from './assets/sidebarIcons/menu-icon.svg'
import logoutIcon from './assets/sidebarIcons/logout-icon.svg'
import collapseIcon from './assets/sidebarIcons/collapse-icon.svg'
import expandIcon from './assets/sidebarIcons/expand-icon.svg'

import MenuItem from './MenuItem'
import MenuItemLogo from './MenuItemLogo'
import Avatar from '../commons/components/Avatar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme?: any) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "none",
        background: theme.palette.background.default
    },
    openDrawer: {
        padding: theme.spacing(2, 4),
        borderRight: `1px solid ${theme.palette.surface.overlay}`
    },
    closedDrawer: {
        padding: theme.spacing(2, 4),
        borderRight: `1px solid ${theme.palette.surface.overlay}`
    },
    text: {
        textTransform: "unset",
        color: theme.palette.onSurface.highEmphasis
    },
    button: {
        height: "48px",
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(0),
    },
    column: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    itemsColumn: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(4),
    },
    icon: {
        width: "60px",
        display: "flex",
        justifyContent: "center"
    },
    buttonRoot: {
        background: theme.palette.background.default,
    },
    logo: {
        marginBottom: theme.spacing(6),
        width: "100%"
    },
    personalization: {
        height: "20%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: theme.spacing(4, 5),
        paddingTop: theme.spacing(9)
    },
    personalizationTrial: {
        background: theme.palette.primary.variant,
    },
    personalizationClassic: {
        background: theme.palette.background.richBlack,
    },
    subtitle: {
        color: theme.palette.onPrimary.mediumEmphasis
    },
    mobileItemsColumn: {
        padding: theme.spacing(4),
        height: "70%",
        display: "flex",
        flexDirection: "column"
    },
    avatarMargin: {
        marginBottom: theme.spacing(5)
    },
    logout: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginLeft: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        }
    },
    btn: {
        justifyContent: "flex-start",
        padding: theme.spacing(4),
        "& .MuiButton-startIcon": {
            marginLeft: "unset"
        }
    },
    logoutContainer: {
        marginTop: "auto",
        width: "90%"
    },
    expandIcon: {
        padding: theme.spacing(2, 4),
        cursor: "pointer"
    },
    expandIconInvisible: {
        padding: theme.spacing(2, 4),
        height: "22px"
    },
    userName: {
        color: theme.palette.text.secondary,
        width: "90%"
    },
    upgradeBtn:{
        border: "2px solid rgba(255, 255, 255, 0.38)",
        borderRadius: "8px",
        width: "210px",
        height: "40px",
        marginTop: theme.spacing(4),
        textDecoration: "none",
        fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "white",
    },
    link:{
        textDecoration: "none",
    },
    hideOnSmall: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    hideMdUp: {
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface SidebarProps extends PropsFromRedux {
    openDrawer: boolean,
    setOpenDrawer: (openDrawer: boolean) => void
    openMobileDrawer: boolean
    setOpenMobileDrawer: (openMobileDrawer: boolean) => void
    username: string,
    lastname: string,
    companyLogo?: any,
    profilePicture?: any
}

const Sidebar: FC<SidebarProps> = ({
    openDrawer,
    setOpenDrawer,
    openMobileDrawer,
    setOpenMobileDrawer,
    username,
    lastname,
    permissions,
    companyLogo,
    profilePicture,
    mainContact
}) => {
    const classes = useStyles()
    const { pathname } = useLocation()
    const [selected, setSelected] = useState<number>()
    const history = useHistory()
    const { t } = useTranslation()

    const menuItems = [
        { id: 1, src: dashboardIcon, srcSelected: dashboardIconPurple, text: t('Sidebar.dashboard'), link: ["/"], sidebarpermissions: [{}] },
        { id: 2, src: walletsIcon, srcSelected: walletsIconPurple, text: t('Sidebar.wallets'), link: ["/wallets"], sidebarpermissions: [
            {admin: ["O"], wallets: ["RO"], customers: ["RO"]},
            {tenant: ["O"], wallets: ["R"], customers: ["R"]}
        ] },
        { id: 3, src: onboardingIcon, srcSelected: onboardingIconPurple, text: t('Sidebar.onboarding'), link: ["/onboarding"], sidebarpermissions: 
            [{admin: ["O"], customers: ["RO"], wallets: ["RO"], mainContacts: ["RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}, 
            {tenant: ["O"], customers: ["R"], wallets: ["R"], mainContacts: ["R"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}]
        },
        { id: 4, src: credentialsIcon, srcSelected: credentialsIconPurple, text: t('Sidebar.credentials'), link: ["/credentials"], sidebarpermissions: [{
            templates: ["R"], proofs: ["R"], mainContacts: ["R"], customers: ["R"], proof_template: ["R"]
        }] },
        { id: 5, src: proofsIcon, srcSelected: proofsIconPurple, text: t('Sidebar.proofs'), link: ["/claims"], sidebarpermissions: [{proofs: ["R"], mainContacts: ["R"], contexts: ["R"]}] },
        { id: 6, src: transactionsIcon, srcSelected: transactionsIconPurple, text: t('Sidebar.transactions'), link: ["/transactions"], sidebarpermissions: [{ transactions: ["R"] }] },
        { id: 7, src: settingsIcon, srcSelected: settingsIconPurple, text: t('Sidebar.settings'), link: ["/settings/profilesettings", "/settings/companysettings", "/settings/admins", "/settings/billing", "/settings/addons", "/settings/billing/plans"], sidebarpermissions: [{}], subItems: [
                {id: "1a", text: t('Sidebar.profSettings'), link: ["/settings/profilesettings"], sidebarpermissions: [
                    {admin: ["O"], mainContacts: ["RO","R"], customers: ["RO","R"], plans: ["RO"], wallets: ["RO","R"]},
                    {mainContacts: ["R"], customers: ["R"], plans: ["R"], wallets: ["R"]}
                ] },
                {id: "2a", text: t('Sidebar.compSettings'), link: ["/settings/companysettings"], sidebarpermissions: [
                    {admin: ["O"], mainContacts: ["RO","R"], customers: ["RO","R"], plans: ["RO"], wallets: ["RO","R"]},
                    {mainContacts: ["R"], customers: ["R"], plans: ["R"], wallets: ["R"]}
                ]},
                {id: "3a", text: t('Sidebar.admins'), link: ["/settings/admins"], sidebarpermissions: [{ "mainContacts": ["R"] }]},
                !hasPermissions(permissions, {admin: ["O"]}) ? 
                {id: "4a", text: t('Sidebar.billing'), link: ["/settings/billing", "/settings/billing/plans"], sidebarpermissions: [{}]} : {},
                // !hasPermissions(permissions, {admin: "O"}) && mainContact.Customer?.PlanId !== 1 ? {text: t('Sidebar.addons'), link: "/addons", sidebarpermissions: [{}]} : ""
            ] 
        },
    ]

    useEffect(() => {
        let item: any
        menuItems?.map((menuItem) => {
            if (menuItem?.link.includes(pathname)) {
                item = menuItem
            }
            return item
        })
        setSelected(item?.id)
    }, [pathname, menuItems])

    const handleSelect = (item: any) => {
        if (!item?.srcSelected) {
            setSelected(undefined)
        } else {
            setSelected(item?.id)
            history.push(item?.link[0])
        }
    }

    const logout = () => {
        localStorage.removeItem("mainContact")
        localStorage.removeItem("token")
        localStorage.removeItem("permissions")
        window.location.reload();
    }

    const handleMenuClick = (menuItem: any) => {
        if (menuItem.text === t('Sidebar.settings')) {
            setOpenDrawer(true)
        }
        handleSelect(menuItem)
    }

    return (
        <>
            <div className={classes.hideOnSmall}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.paper, {
                            [classes.openDrawer]: openDrawer,
                            [classes.closedDrawer]: !openDrawer,
                        }),
                    }}
                >
                    <div className={classes.column}>
                        <MenuItemLogo
                            icon={menuIcon}
                            logo={companyLogo}
                            open={openDrawer}
                            transform
                        />
                        {selected === 7 ?
                        <div className={classes.expandIconInvisible}></div>
                        :
                        <div
                            onClick={() => setOpenDrawer(!openDrawer)}
                            className={classes.expandIcon}
                        >
                            {
                                !openDrawer
                                    ?
                                    <img src={expandIcon} alt="expand icon" />
                                    :
                                    <img src={collapseIcon} alt="collapse icon" />
                            }
                        </div>}
                        <div className={classes.itemsColumn}>
                            {
                                menuItems.map((menuItem, index: number) => {
                                    return (
                                        <Fragment key={index} >
                                            {
                                                menuItem.sidebarpermissions.map((sidebarpermission, index)=>{

                                                   return hasPermissions(permissions, sidebarpermission) 
    
                                                        ?
                                                        <Fragment key={index}>
                                                            <MenuItem
                                                                menuItem={menuItem}
                                                                selectedItem={menuItem.id === selected ? true : false}
                                                                handleClick={() => { handleMenuClick(menuItem) }}
                                                                open={openDrawer}
                                                                subItems={menuItem.subItems ? true : false}
                                                            />
                                                        </Fragment>
                                                        :
                                                        null
                                                })
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Drawer>
            </div> 
            <div className={classes.hideMdUp}>
                {/* @ts-ignore */}
                <SwipeableDrawer
                    open={openMobileDrawer}
                    onClose={() => setOpenMobileDrawer(false)}
                    onOpen={() => setOpenMobileDrawer(true)}
                >
                    <div className={clsx(classes.personalization, {
                            [classes.personalizationTrial]: hasPermissions(permissions, { trial: "O" }) || mainContact?.Customer?.PlanId === 1,
                            [classes.personalizationClassic]: !hasPermissions(permissions, { trial: "O" }) && mainContact?.Customer?.PlanId !== 1
                        })}
                    >
                        <div className={classes.avatarMargin}>
                            <Avatar
                                username={username}
                                lastname={lastname}
                                profilePicture={profilePicture}
                                size="40px"
                                fontSize="20px"
                            />
                        </div>
                        <Typography variant="h6" className={classes.userName}>
                            {t('Header.greeting', { username: username })}
                        </Typography>
                        <Typography variant="body2" className={classes.subtitle}>
                            {t('Sidebar.greeting')}
                        </Typography>
                       {(hasPermissions(permissions, { trial: "O" }) || mainContact?.Customer?.PlanId === 1)
                        && <Link to="/settings/billing/plans" className={classes.link}>
                            <Button className={classes.upgradeBtn}>{t('trial.upgrade')}</Button>
                        </Link>}  
                    </div>
                    <div className={classes.mobileItemsColumn}>
                        {
                            menuItems.map((menuItem, index: number) => {
                                return (
                                    <Fragment key={index} >
                                        {
                                            menuItem.sidebarpermissions.map((sidebarpermission)=>{
                                                return hasPermissions(permissions, sidebarpermission)
                                                ?
                                                <MenuItem
                                                    key={index}
                                                    menuItem={menuItem}
                                                    selectedItem={menuItem.id === selected ? true : false}
                                                    handleClick={() => { handleSelect(menuItem); menuItem.text !== t('Sidebar.settings') && setOpenMobileDrawer(false) }}
                                                    open={openMobileDrawer}
                                                    subItems={menuItem.subItems ? true : false}
                                                />
                                                :
                                                null
                                            }
                                        )
                                    }
                                    </Fragment>


                                )
                            })
                        }
                        <div className={classes.logoutContainer}>
                            <Divider />
                            <Button
                                disableRipple
                                startIcon={
                                    <img
                                        src={logoutIcon}
                                        alt="menu icon"
                                    />
                                }
                                onClick={() => logout()}
                                classes={{ root: classes.btn }}
                            >
                                <Typography variant="subtitle2" className={classes.logout}>
                                    {t('headerDropdown.signout')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        </>
    )
}

const mapState = (state: RootState) => ({
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(Sidebar)