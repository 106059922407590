import { AnyAction } from 'redux'

type SuccessReducer = {
  successes: {
    msg?: string,
  }[]
}
const initialState: SuccessReducer = {
    successes: [{
      msg: "",
    }]
  };

export default function successReducer(state=initialState, action: AnyAction): SuccessReducer {
  switch (action.type) {
    case 'SUCCESS': {
        return {
          ...state,
          successes: state.successes?.[0]?.msg ? [...state.successes, action.payload] : [action.payload]
        }
      }
      case 'RESET_SUCCESS': {
        return {
          ...state,
          successes: state.successes.filter(success => success.msg !== action.success.msg)
        }
      }
      default:
        return state;
    }
  }
  