import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

import dropdownIcon from './assets/downward-arrow-icon.svg'

import InputField from './Input';
import Dropdown from './Dropdown';

const useStyles = makeStyles((theme?: any)=>({
    row: {
        width: "100%"
    }
}))

interface InputSelectProps {
    menuItems: any[]
    placeholder?: string
    renderValue: string
    selectOther?: boolean
    setOther?: (arg: boolean) => void
    other?: boolean
    setValue?: (name: string, value: string, index?: number) => void
    name: string
    value: any
    index?: number
    disabled?: boolean
    readonly?: boolean
    otherElement?: ReactElement
    disabledOptionsCheck?: [] | string[]
}

const InputSelect: FC<InputSelectProps> = ({
    menuItems, 
    placeholder="Select",
    renderValue,
    selectOther,
    other,
    name,
    setOther,
    setValue,
    value,
    index,
    disabled,
    readonly,
    otherElement,
    disabledOptionsCheck
}) => {
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()
    const { t } = useTranslation()
    const [filter, setFilter] = useState("")
    const classes = useStyles()

    const handleOpen = (e: any) => {
        if (!disabled && value) {
            setValue && setValue(name, "")
        }
        if (other === true) {
            setOther && setOther(false)
            setValue && setValue(name, "")
        }
        setOpen(!open)
        setAnchorEl(e.target.parentElement)
    }

    const onSelect = (item: any) => {
        if (item === t('general.other')) {
            setFilter("")
            setOther && setOther(true)
            setValue && setValue(name, "")
        } else if (checkDisabledOption(item.type)) {
            return
        } else {
            setFilter("")
            setOther && setOther(false)
            setValue && setValue(name, item, index)
        }
        setOpen(false)
    }

    const inputFieldValue = () => {
        if (other) {
            return t('general.other')
        } else if (filter) {
            return filter
        } else {
            return typeof value === "object" ? value[renderValue] : value
        }
    }

    const handleChange = (e: any) => {
        setFilter(e.target.value)
    }

    const filterMenuItems = () => {
        const filteredArr: any[] = []
        menuItems.forEach((menuItem)=>{
            if (menuItem[renderValue].toLowerCase().includes(filter.toLowerCase())) {
                return filteredArr.push(menuItem)
            }
        })
        return filteredArr
    }

    const handleClickAway = () => {
        setFilter("")
        setOpen(false)
    }

    const checkDisabledOption = (item: string) => {
        //@ts-ignore
        if (disabledOptionsCheck?.length !== 0 && disabledOptionsCheck?.includes(item)) {
            return true
        } else {
            return false
        }
    }

    return (
        <div onClick={handleOpen} className={classes.row}>
            <InputField 
                icon={dropdownIcon}
                readonly={readonly}
                value={inputFieldValue()}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(e)=>handleChange(e)}
                inDropdown={true}
            />  
            <Dropdown 
                open={!disabled ? open : false}
                menuItems={filter ? filterMenuItems() : menuItems}
                setOpen={()=>setOpen(false)}
                anchorEl={anchorEl}
                handleClick={(item)=>onSelect(item)}
                renderValue={renderValue}
                other={selectOther}
                otherElement={otherElement}
                handleClickAway={handleClickAway}
                disabledOptionCheck={(item)=>checkDisabledOption(item)}
            />
        </div>   
    )
}

export default InputSelect