import { AnyAction } from 'redux'

const initialState = {
    settingsInfo: {
      maincontact: {
        id: 0,
        nickname:"",
        name:"",
        lastName:"",
        email:"",
        role:"",
        colorTheme:false,
        did:"",
        status:0,
        main:true,
        image: "",
        disable:false,
        assignedOn:"",
        createdAt:"",
        updatedAt:"",
        CustomerId:0,
        Customer: {
            id: 0,
            name: "",
            status: 0,
            tenant: false,
            logo: "",
            info: {
              BillingEmails: [""],
              companyAddress: {
                street: "",
                number: "",
                city: "",
                zipcode: "",
                country: ""
              },
            },
            createdAt: "",
            updatedAt: "",
            TenantId: 0,
            PlanId: 0,
            Plan: {
              name: ""
            },
            ThemeId: 0,
            Wallet: {
              id: 0,
              name: "",
              password: "",
              did: "",
              accessToken: "",
              version: 0,
              owner: 0, 
              status: 0,
              assignedOn: "",
              createdAt: "",
              updatedAt: "",
              CustomerId: 0
            },
          }
      },
      lastUpdatedBy: {
        name: "",
        lastName: "",
        Customer: {
          updatedAt: ""
        }
      }
    },
    error: null,
    customer: null,
    companyLogo: null,
    profilePicture: null,
    billingDetails: {
        company: "",
        updated_at: 0,
        billing_address:{
          first_name: "",
          last_name: "",
          email: "",
          line1: "",
          country: "",
          city: "",
          zip: ""
        },
        first_name:  "",
        last_name:  "",
        email:  "",
        line1: "",
        unit: "",
        country: "",
        city: "",
        zip: "",
        tax_id: "t('billing.taxId')"
    },
    paymentInfo: {
      data:[{
        updated_at: 0,
        card:{
          first_name: "",
          last_name: "",
          masked_number: "",
          expiry_month:0,
          expiry_year:0,
          brand: ""
        }}
      ]
    },
    portalUrl: "",
    companyLogoDelete: null
  };

  type SettingsReducer = typeof initialState
  
export default function SettingReducer(state = initialState, action: AnyAction): SettingsReducer {
    switch (action.type) {
      case 'SETTINGS_INFO_LOADED': {
        return {
          ...state,
          settingsInfo: action.payload
          }
      }
      case 'COMPANY_SETTINGS_UPDATED': {
        return {
          ...state,
          customer: action.payload
        }
      }
      case 'MAINCONTACT_SETTINGS_UPDATED': {
        return {
          ...state,
          settingsInfo: action.payload
        }
      }
      case 'COMPANY_LOGO_LOADED': {
        return {
          ...state,
          companyLogo: action.payload
        }
      }
      case 'COMPANY_LOGO_UPLOADED': {
        return {
          ...state,
          customer: action.payload
        }
      }
      case 'COMPANY_LOGO__DELETE': {
        return {
          ...state,
          customer: action.payload
        }
      }
      case 'PROFILE_PICTURE_LOADED': {
        return {
          ...state,
          profilePicture: action.payload
        }
      }
      case 'PROFILE_PICTURE_UPLOADED': {
        return {
          ...state,
          settingsInfo: action.payload
        }
      }
      case 'PROFILE_PICTURE_DELETE': {
        return {
          ...state,
          settingsInfo:  action.payload
        }
      }
      case 'COMPANY_LOGO_RESET': {
        return {
          ...state,
          companyLogo: initialState.companyLogo
        }
      }
      case 'BILLING_DETAILS_LOADED': {
        return {
          ...state,
          billingDetails: action.payload.data
        }
      }
      case 'PAYMENT_INFO_LOADED': {
        return {
          ...state,
          paymentInfo: action.payload
        }
      }
      case 'PORTAL_OPEN': {
        return {
          ...state,
          portalUrl: action.payload.data
        }
      }
      case 'PORTAL_CLOSE': {
        return {
          ...state,
          portalUrl: initialState.portalUrl
        }
      }
      default:
        return state;
    }
  }
   