import { useEffect, useState } from 'react'
import {
    Dialog,
} from "@material-ui/core";
import type { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { 
    onboardCustomer, 
    resetNewCustomer 
} from '../actions/onboarding';
import { useHistory } from 'react-router';
import { plans } from '../commons/plans';

import CustomerStatusCard from '../onboarding/CustomerStatusCard'

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        height: "auto",
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            zIndex: 1500,
            height: "auto",
            margin: theme.spacing(6, 0),
            width: '100%'
        }
    },
    overlay: {
        "& .MuiDialog-container": {
            height: "auto",
        },
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface OnBoardingModalProps extends PropsFromRedux {
    open?: boolean,
    setOpen: (arg: boolean) => void
    inMobile: boolean,
    newCustomer: any
}

const OnBoardingModal: FC<OnBoardingModalProps> = ({
    open=false,
    setOpen,
    onboardCustomer,
    mainContact, 
    newCustomer,
    resetNewCustomer,
    inMobile
}) => {    
    const classes = useStyles()
    let history = useHistory()
    const [fields, setFields] = useState({
        companyName: "",
        wallet: {
            id: 0
        },
        plan: plans[1],
        name: "",
        lastName: "",
        email: ""
    })

  useEffect(()=>{
    if (newCustomer?.id !== 0) {
        setOpen(false)
        setFields({
            companyName: "",
            wallet: {
                id: 0
            },
            plan: plans[1],
            name: "",
            lastName: "",
            email: ""
        })
    }
  }, [newCustomer, history, resetNewCustomer, setOpen])

  const handleSave = async () => {
      const customer = await onboardCustomer({
          CustomerId: mainContact?.Customer?.id,
          companyName: fields.companyName,
          name: fields.name,
          lastName: fields.lastName,
          email: fields.email,
          WalletId: fields.wallet?.id,
          toUpgrade: fields.plan?.id ? fields.plan?.id : 1
      })
  }

  const handleInputChange = (e: any) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSelect = (name: string, value: any) => {
    setFields({...fields, [name]: value})
  }
      
    return (
            <Dialog 
                fullWidth
                open={open}
                style={inMobile?{position: 'relative',zIndex: 1}:{}}
                disablePortal={inMobile?true:false}
                disableScrollLock={inMobile?true:false}
                hideBackdrop={inMobile?true:false}
                classes={{paper: classes.dialogContainer, root: inMobile ? classes.overlay:undefined}}
            >
                <CustomerStatusCard 
                    inModal={true} 
                    closeOnboardingModal={()=>setOpen(false)}
                    handleSave={handleSave}
                    handleInputChange={handleInputChange}
                    fields={fields}
                    handleSelect={handleSelect}
                />
            </Dialog>        
    )
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
  })
  
  const mapDispatch = {
      onboardCustomer: (data: Object) => (onboardCustomer(data)),
      resetNewCustomer: () => (resetNewCustomer())
  }
  
  const connector = connect(mapState, mapDispatch)
  
  export default connector(OnBoardingModal)