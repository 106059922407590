import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Button from './Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme?: any) => ({
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(8, 8),
    width: "523px",
    height: "auto",
    color: theme.palette.onSurface.highEmphasis,
    boxSizing: "border-box",
    [theme.breakpoints.down('sm')]: {
      height: "auto",
      minHeight: "276px",
      textAlign: "center",
    }
  },
  btnRow: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    marginTop: "10%",
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
      flexDirection: "column",
      width: "100%"
    },
  },
  btnMargin: {
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(3),
      marginRight: "unset"
    },

  },
  btn: {
    width: "180px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: "100%",
    }
  },
  mediumEmphasis: {
    color: theme.palette.onSurface.mediumEmphasis
  }
}))

interface UnsavedWarningModalProps {
  when: boolean
  onOK: () => boolean
  onCancel: () => boolean
}

const UnsavedWarningModal: FC<UnsavedWarningModalProps> = ({
  when,
  onOK,
  onCancel,
}) => {
  const { t } = useTranslation()
  const history = useHistory();
  const classes = useStyles()
  const [showPrompt, setShowPrompt] = useState(when);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));


  return showPrompt ? (
    <Dialog
      open={showPrompt}
      classes={{ paper: classes.dialogContainer }}
    >
      <Typography
        variant="subtitle1"
        className={classes.mediumEmphasis}
      >
        {t('unsavedModal.text')}
      </Typography>
      <div className={classes.btnRow}>
        <div className={classes.btn}>
          <Button
            size={matches ? "small" : "large"}
            text={t('general.Cancel')}
            version="secondary"
            onClick={handleCancel}
            width="100%"
          />
        </div>
        <div className={clsx(classes.btnMargin, classes.btn)}>
          <Button
            size={matches ? "small" : "large"}
            text={t('general.Confirm')}
            onClick={handleOK}
            fullWidth
            width="100%"
          />
        </div>
      </div>
    </Dialog>
  ) : null;
}

export default UnsavedWarningModal