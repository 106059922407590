import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect } from 'react';
import Button from '../commons/components/Button'
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(8),
        width: "532px",
        height: "284px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "381px",
            width: "100%",
            textAlign: "center",
            padding: theme.spacing(8, 5),
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%"
        }
    },
    btnMargin: {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3), 
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            height: "58px"
        }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
}))

interface TestTokenModalProps {
    open: boolean
    onCancel?: () => void,
    handleTokenReset?: () => void,
    customer?: any
    mainContact?: any
}

const TestTokenModal: FC<TestTokenModalProps> = ({
    open=false,
    handleTokenReset,
    onCancel,
    mainContact
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog 
            open={open} 
            classes={{paper: classes.dialogContainer}}
        >
                <Typography 
                    variant="h6" 
                    className={classes.highEmphasis}
                >
                    {mainContact.main ? t('testCredential.modalTitle') : t('testCredential.modalTitle2')}
                </Typography>
                <Typography 
                    variant="subtitle1" 
                    className={classes.text}
                >{mainContact.main ? 
                   <>{t('testCredential.modalSubtitle')}<b>{t('testCredential.modalSubtitleBold')}</b></>
                    :
                    <>
                        {t('testCredential.modalSubtitle2firstPart')}
                        <b>{t('testCredential.modalSubtitleBold')}</b>
                        {t('testCredential.modalSubtitle2secondPart')}
                    </>

                }
                </Typography>
            <div className={classes.btnRow}>
                {mainContact.main && 
                    <div className={clsx(classes.btnMargin, classes.btn)}>
                        <Button 
                            text={t('testCredential.snackbarAction')}
                            onClick={handleTokenReset}
                            width="100%"
                            version="secondary"
                            size={matches ? "small" : "large"}
                        />
                    </div >
                }
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button 
                        text={mainContact.main ? t('general.Cancel') : t('general.Okay')}
                        onClick={onCancel}
                        width="100%"
                        version={mainContact.main ? "secondary" : "primary"}
                        size={matches ? "small" : "large"}
                    />
                </div>
            </div>
        </Dialog>               
    )
}



export default TestTokenModal
