import { connect, ConnectedProps } from 'react-redux'
import {
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { useTranslation } from 'react-i18next';
import { useState, useEffect, FC } from 'react';

import SecurityImgModal from './SecurityImgModal';

const useStyles = makeStyles((theme?: any) => ({
    inputfile: {
        width: "0.1px",
        height: "0.1px",
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    },
    inputLabel: {
        width: "196px",
        height: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(0),
        border: `1.6px solid ${theme.palette.borderColor.onSurface}`,
        color: theme.palette.onSurface.highEmphasis,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        "&:hover": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[800],
        },
        "&:focus": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.onSurface.highEmphasis,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    logoTitle: {
        color: theme.palette.borderColor.onSurface,
        margin: theme.spacing(5)
    },
    logoBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "128px",
        height: "128px",
        border: `1px dashed ${theme.palette.onSurface.mediumEmphasis}`,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(7),
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        }
    },
    logoContainer: {
        display: "flex",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    img: {
        width: "90px",
        height: "90px",
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
    },
    dialogBox: {
        width: "400px",
        height: "auto",
        padding: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5, 0),
            margin: theme.spacing(3),
        }
    },
    canvas: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "300px",
        }
    },
    title: {
        color: theme.palette.onSurface.highEmphasis,
        alignSelf: "flex-start",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(5),
        }
    },
    btnRow: {
        alignSelf: "flex-end",
        marginTop: theme.spacing(5),
        width: "380px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            alignSelf: "unset",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }
    },
    zoomRow: {
        display: "flex",
        width: "90%",
        marginTop: "-35px",
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(6)
        }
    },
    icon: {
        margin: theme.spacing(0, 2)
    },
    iconPurple: {
        filter: theme.palette.filter.whiteToLightPurple
    },
    assistiveText: {
        color: theme.palette.onSurface.disabled,
        maxWidth: "320px",
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    assistiveTextContainer: {
        alignSelf: "flex-start",
        marginTop: "15px",
    },
    btn: {
        width: "180px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(0),
            width: "100%"
        }
    },
    label: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    uploadContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface SecurityImgProps extends PropsFromRedux {
    imgPlaceholder: string
}

const SecurityImg: FC<SecurityImgProps> = ({
    imgPlaceholder = "",
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [imgPreview, setImgPreview] = useState("")
    const [errorImgSize, setErrorImgSize] = useState(false)
    const [errorFileType, setErrorFileType] = useState(false)
    const [securityImg, setSecurityImg] = useState("")

    useEffect(() => {
        const securityImage = localStorage.getItem("securityImage")
        if (securityImage) {
            setSecurityImg(securityImage)
        }
    }, [])

    const handleImgPreview = (e: any) => {
        e.target.files[0].size > 307200 ? setErrorImgSize(true) : setErrorImgSize(false)
        e.target.files[0].type.split("/").pop() !== "jpg" &&
            e.target.files[0].type.split("/").pop() !== "jpeg" &&
            e.target.files[0].type.split("/").pop() !== "png" ?
            setErrorFileType(true) : setErrorFileType(false)
        setImgPreview(e.target?.files?.[0] ? URL.createObjectURL(e.target.files[0]) : "")
        e.target.value = ""
    }

    return (
        <>
            <Typography variant="subtitle2" className={classes.label}>
                {t('billing.securityImg')}
            </Typography>
            <div className={classes.logoContainer}>
                <div className={classes.logoBox}>
                    {securityImg ?
                        <img
                            src={securityImg}
                            alt="logo"
                            className={classes.img}
                        />
                        :
                        <Typography variant="button" className={classes.logoTitle}>
                            {imgPlaceholder}
                        </Typography>
                    }
                </div>
                <div className={classes.uploadContainer}>
                    <Typography variant="body1" className={classes.assistiveText}>
                        {t('billing.imgAssistiveText')}
                    </Typography>
                    <label htmlFor={"securityImg"} className={classes.inputLabel}>
                        {t('billing.uploadNew')}
                    </label>
                    <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        id={"securityImg"}
                        className={classes.inputfile}
                        onChange={(e) => handleImgPreview(e)}>
                    </input>
                </div>
            </div>
            <SecurityImgModal 
                setSecurityImg={setSecurityImg}
                imgPreview={imgPreview}
                setImgPreview={setImgPreview}
                errorFileType={errorFileType}
                errorImgSize={errorImgSize}
            />
        </>
    );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
    imgError: (error: { msg: string }) => ({ type: 'IMG_ERROR', error: error })
}

const connector = connect(mapState, mapDispatch)

export default connector(SecurityImg)
