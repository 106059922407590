import { Route } from 'react-router';
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Typography,
  Breadcrumbs
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { RootState } from '../../store'
import chevronIcon from './assets/chevron-right.svg'

const useStyles = makeStyles((theme?: any) => ({
  breadcrumb: {
    color: theme.palette.onSurface.mediumEmphasis,
    wordBreak: "break-all"
  },
  active: {
    color: theme.palette.onSurface.highEmphasis,
    wordBreak: "break-all"
  },
  breadcrumbs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  link: {
    textDecoration: "none",
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface BreadCrumbsProps extends PropsFromRedux {
  text?: string
  item?: {id: number, name: string}
  mt?: {id: number, name: string}
  template?: boolean
}


const BreadCrumbs: FC<BreadCrumbsProps> = ({
  text,
  item,
  mt
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter((x) => x);
        if (pathnames.length === 0) {
          pathnames.push("dashboard")
        }
        return (
          <Breadcrumbs
            separator={<img src={chevronIcon} alt="separator arrow-right icon" />}
            className={classes.breadcrumbs}
          >
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const first = index === 0
              const to = `/${pathnames.slice(0, index +1).join('/')}`;
              const translateUrl = `${pathnames.slice(0, index + 1).join('/')}`;
              if (last) {
                return (
                      <Typography key={translateUrl} variant="body2" className={classes.active}>
                        {item ? `${text ? text : ""} ${item.name}` : t(`urls.${translateUrl}`)}
                      </Typography>
                )
              } else if (first && value === "settings") {
                return (
                  <Link to="/settings/profilesettings" key={translateUrl} className={classes.link}>
                    <Typography variant="body2" className={classes.breadcrumb}>
                      {t(`urls.${translateUrl}`)}
                    </Typography>
                  </Link>
                )
              } else if (first) {
                return (
                  <Link to={to} key={translateUrl} className={classes.link}>
                    <Typography variant="body2" className={classes.breadcrumb}>
                      {t(`urls.${translateUrl}`)}
                    </Typography>
                  </Link>
                )
              } else if (!last && !first && mt) {
                return (
                    <Typography variant="body2" className={classes.breadcrumb}>
                      {mt.name}
                    </Typography>
                )
              } else if (!last && !first) {
                return (
                  <Link to={to} key={translateUrl} className={classes.link}>
                    <Typography variant="body2" className={classes.breadcrumb}>
                      {item && text ? item.name : t(`urls.${translateUrl}`)}
                    </Typography>
                  </Link>
                )
              } else {
                return null
              }
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  )
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(BreadCrumbs)