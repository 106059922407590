import {
    Typography,
    Paper,
    ClickAwayListener,
    Popper,
    MenuList
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { readNotification } from '../../actions/notification';
import type { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'

import Notification from './Notification';

const useStyles = makeStyles((theme?: any)=>({
    notificationContainer: {
        zIndex: 100,
        width: "380px",
        marginTop: theme.spacing(4),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('sm')]: {
            width: "100vw",
            marginLeft: "-5px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    title: {
        color: theme.palette.onSurface.mediumEmphasis,
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "380px",
        height: "auto",
        background: theme.palette.background.default,
        color: theme.palette.onSurface.disabled,
        padding: theme.spacing(5, 0, 5, 6),
        [theme.breakpoints.down('sm')]: {
            width: "100vw"
        }
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface NotificationsProps extends PropsFromRedux {
    notifications: {
        id: number
        title: string
        text: string
        summary: string
        createdAt: string
        status: number
        type: number
    }[]
    open: boolean
    anchorEl: any
    setOpen: (open: boolean) => void
}

const Notifications: FC<NotificationsProps> = ({
    notifications, 
    open, 
    anchorEl, 
    setOpen, 
    readNotification
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            transition
            disablePortal
            placement='bottom-end'
        >
        <Paper className={classes.notificationContainer}>
            <ClickAwayListener
                onClickAway={()=>setOpen(false)}
            >
                <MenuList>
                <div className={classes.title}>
                    <Typography variant="subtitle2" >
                        {t('notifications.title')}
                    </Typography>
                </div>
                {notifications.length !== 0 ?
                <div>
                    {notifications.map((notification, index)=>{
                        return (
                            <div
                                key={notification.id}
                            >
                                <Notification 
                                    notification={notification} 
                                    handleRead={readNotification} 
                                />
                            </div>
                        )
                    })}
                </div>
                :
                <div className={classes.row}>
                    <Typography variant="subtitle2">
                        {t('notifications.nonew')}
                    </Typography>
                </div>
                }  
                </MenuList>
            </ClickAwayListener>          
        </Paper> 
        </Popper>        
    )
}

const mapState = (state: RootState) => ({
  })
  
  const mapDispatch = {
    readNotification: (id: number) => (readNotification(id))
  }
  
  const connector = connect(mapState, mapDispatch)
  
  export default connector(Notifications)