import { connect, ConnectedProps } from 'react-redux'
import { 
    Typography,
    Divider,
    Dialog
 } from '@material-ui/core';
 import useMediaQuery from '@material-ui/core/useMediaQuery';
 import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { closePortal, openPortalPayment } from '../actions/settings';
import { hasPermissions, dateLongMonth } from '../commons';

import visa from './assets/visa.png'
import mastercard from './assets/mastercard.png'
import amex from './assets/amex.png'

import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any)=>({
    row: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0px",          
        }
    },
    row2: {
        display: "flex",
        alignSelf: "start",
        marginTop: theme.spacing(5)
    },
    label: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(7)
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "47%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    divider: {
        height: "auto",
        margin: theme.spacing(0, 7)
    },
    columns: {
        display: "flex",
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        }
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignSelf: "start",
        columnGap: theme.spacing(8)
    },
    horizontalDivider: {
        width: "100%",
        margin: theme.spacing(6, 0)
    },
    lastUpdate: {
        color: theme.palette.onSurface.disabled,
        fontStyle: "italic",
        textAlign: "center",
        marginBottom: theme.spacing(6)
    },
    invoicesTitle: {
        fontWeight: 700,
        alignSelf: "start"
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    cardFlag: {
        width: "21px",
        marginLeft: theme.spacing(4)
    },
    iframe: {
        height: "598px",
        width: "750px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "100%",
        }
    },
    btnWidth:{
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface PaymentDetailsProps extends PropsFromRedux {}

const PaymentDetails: FC<PaymentDetailsProps> = ({
    paymentInfo,
    openPortalPayment,
    closePortal,
    mainContact,
    portalUrl,
    permissions
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const cardType = paymentInfo.data[0].card.brand

  const year = paymentInfo.data[0].card.expiry_year
  const lastTwoDigityear = String(year).slice(-2);

  const paymentData: any = {
    firstName: {
        label: t('billing.name'),
        value: paymentInfo.data[0].card.first_name
    },
    lastName: {
        label: t('billing.lastName'),
        value: paymentInfo.data[0].card.last_name
    },
    cardHolder: {
        label: t('billing.cardHolder'),
        value: paymentInfo.data[0].card.first_name,
        value2: paymentInfo.data[0].card.last_name
    },
    "": {},
    cardNumber: {
        label: t('billing.cardNr'),
        value: paymentInfo.data[0].card.masked_number
    },
    validDate: {
        label: t('billing.valid'),
        value: `${paymentInfo.data[0].card.expiry_month}/${lastTwoDigityear}`
    }
  }

  const cardTypeImg = () => {
    let src
    if (cardType === "visa") {
        src = visa
    } else if (cardType === "masterCard") {
        src = mastercard
    } else if (cardType === "amex") {
        src = amex
    }
    return (
        <img 
            src={src} 
            className={classes.cardFlag}
            alt="bubble icon with 'free' or 'pro' flag"
        />
    )
  }

  const getDate = () => {
    const date = paymentInfo?.data?.[0]?.updated_at
    if (date) {
        const newDate = new Date(date * 1000)
        return dateLongMonth(newDate)
    }
  }

  return (
      <div className={classes.container}>
            {hasPermissions(permissions, {chargebee: ["E"]}) && portalUrl && 
                <Dialog 
                    open={portalUrl ? true : false}
                    classes={{paperScrollPaper: classes.iframe}}
                    fullScreen={fullScreen}
                >
                    <iframe 
                        style={{border: "none", borderRadius: "8px", padding: 0}}
                        src={portalUrl}
                        height="100%"
                        width="100%"
                        title="chargebee portal"
                    ></iframe>
                    <Button 
                        text={t('general.Close').toUpperCase()} 
                        version="text"
                        onClick={()=>closePortal()}
                    />
                </Dialog>
            }
          <Typography variant="h5">
            {t('billing.method')}
          </Typography>
          <div className={classes.columns}>
              <div className={classes.column}>
                  <div className={classes.grid}>
                    {
                        Object.keys(paymentData).map((field: any, index: number)=>{
                            return (
                                <div key={index}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {paymentData[field]?.label}
                                    </Typography>
                                    <div className={classes.row}>
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            {paymentData[field]?.value}
                                            {paymentData[field]?.value2}
                                        </Typography>
                                        {field === "cardNumber" && cardTypeImg()}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Divider className={classes.horizontalDivider}/>
                <Typography
                    variant="body2"
                    className={classes.lastUpdate}
                >
                    {t('billing.lastUpdated')} {getDate()}
                </Typography>
                {hasPermissions(permissions, {chargebee: ["E"]}) ?
                    <div className={classes.btnWidth}> 
                        <Button 
                            width="100%"
                            text={t('billing.edit')} 
                            version="secondary"
                            onClick={()=>openPortalPayment(mainContact.CustomerId)}
                        />
                    </div>
                    : <></>
                }
              </div> 
            <Divider 
                className={classes.divider} 
                orientation='vertical'
            />
          </div>
      </div>
  );
}

const mapState = (state: RootState) => ({
    paymentInfo: state.settings.paymentInfo,
    mainContact: state.login.mainContact,
    portalUrl: state.settings.portalUrl,
    permissions: state.login.permissions
})

const mapDispatch = {
    openPortalPayment: (CustomerId: number) => (openPortalPayment(CustomerId)),
    closePortal: () => (closePortal())
}

const connector = connect(mapState, mapDispatch)

export default connector(PaymentDetails)
