import { ReactChild, FC, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Paper, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { plans } from '../commons/plans';
import clsx from 'clsx';
import type { RootState } from '../store'
import { useHistory, useParams } from 'react-router';

import headerGradient from './assets/header-gradient.png'

const useStyles = makeStyles((theme?: any) => ({
  paper:{
    width: "100%",
  },
  header: {
    height: "70px",
    background: theme.palette.primary[700],
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: theme.spacing(11),
    "&.MuiAppBar-positionFixed": {
      position: "relative",
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(6),
      width: "100%"
    }
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
    paddingBottom: "100px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: theme.spacing(6,0, 7,0) 

    }
  },
  headerIcon: {
    height: "100%",
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface LayoutProps extends PropsFromRedux {
  children: ReactChild
}

const TrialLayout: FC<LayoutProps> = ({
  children
}) => {
  const classes = useStyles()
  // @ts-ignore
  const {planId} = useParams()
  const history = useHistory()

  useEffect(()=>{
    if (!Object.keys(plans).includes(planId)) {
        history.push("/plan-onboarding/1")
    }
}, [planId])

  return (
    <>
      <Paper
        elevation={0}
        className="paper"
      >
        <AppBar
          className={clsx(classes.header)}
          elevation={0}
        >

          <img
            src={headerGradient}
            alt="header gradient"
            className={classes.headerIcon}
          />
        </AppBar>
        <div className={classes.contentContainer}>
          {children}
        </div>
      </Paper>
    </>
  );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(TrialLayout)
