import { 
    Dialog, 
    Typography,
    Tooltip
 } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../store'
import { FC, useState } from 'react';
import Button from './Button'
import { useTranslation } from "react-i18next";
import { hideToken } from "../../actions/wallets";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import warningIcon from './assets/warning-icon.svg'
import copyIcon from './assets/copy-icon.svg'

import InputField from "./Input";

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(8),
        width: "532px",
        height: "348px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "381px",
            width: "100%",
            textAlign: "center",
            padding: theme.spacing(8, 5),
            margin: theme.spacing(5),
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%"
        }
    },
    btnMargin: {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3), 
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            height: "58px"
        }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis,
        alignSelf: "flex-start",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            alignSelf: "center",
            margin: theme.spacing(3)
        }
    },
    tooltip: {
        borderRadius: "4px",
        margin: 0
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface TokenModalProps extends PropsFromRedux {
    accessToken: string
}

const TokenModal: FC<TokenModalProps> = ({
    accessToken,
    hideToken
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()
    const [copyTooltip, setCopyTooltip] = useState(false)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleCopy = (e: any) => {
        navigator.clipboard.writeText(accessToken)
        if (e.target.innerText !== t('token.copyBtn')) {
            setCopyTooltip(true)
            setTimeout(()=>{setCopyTooltip(false)}, 5000)
        }
    }

    const handleClose = () => {
        hideToken()
    }

    return (
        <Dialog 
            open={accessToken ? true : false} 
            classes={{paper: classes.dialogContainer}}
        >
            <img src={warningIcon} alt="warning icon" />
            <Typography 
                variant="h6" 
                className={classes.highEmphasis}
            >
                {t('token.tokenTitle')}
            </Typography>
            <Tooltip 
                title={<>{t('walletPage.copy')}</>}
                open={copyTooltip} 
                placement="top-end"
                classes={{popper: classes.tooltip}}
            >
                <div style={{width: "100%"}}>
                    <InputField 
                        disabled={true}
                        value={accessToken}
                        icon={copyIcon}
                        name="token"
                        onIconClick={handleCopy}
                        multiline={true}
                    />
                </div>
            </Tooltip>
            <Typography 
                variant="subtitle1" 
                className={classes.text}
            >
                {t('token.tokenText')}
            </Typography>
            <div className={classes.btnRow}>
                <div className={clsx(classes.btnMargin,classes.btn)}>
                    <Button 
                        text={t('general.Close')}
                        onClick={()=>handleClose()}
                        width="100%"
                        version="text"
                        size={matches ? "small" : "large"}
                    />
                </div>
                <div className={clsx(classes.btnMargin,classes.btn)}>
                <Button 
                    text={t('token.copyBtn')}
                    onClick={handleCopy}
                    width="100%"
                    size={matches ? "small" : "large"}
                />
                </div >
            </div>
        </Dialog>               
    )
}

const mapState = (state: RootState) => ({
    accessToken: state.wallets.accessToken
})

const mapDispatch = {
    hideToken: () => (hideToken())
}

const connector = connect(mapState, mapDispatch)

export default connector(TokenModal)