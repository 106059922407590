import API from '../api'
import { AppDispatch } from "../store";
import errorHandler from '../actions/errorHandler'
import successHandler from './successHandler';

export const getWalletList = () => async (dispatch: AppDispatch) =>  { 
    let response
    try {
      response = await API.get('/api/wallets')
      dispatch({ type: 'WALLETS_LOADED', payload: response.data })
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
  }

export const getWallet = (data: number) => async (dispatch: AppDispatch) => {
    let response 
    try {
      response = await API.get('/api/wallet/' + data)
      dispatch({ type: 'WALLET_LOADED', payload: response.data })
    } catch(e: any) {
      errorHandler(e, dispatch)
    }
}

export const getUnassignedCustomers = () => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    let url = new URL(process.env.REACT_APP_API_URL +'/api/companies')
    url.searchParams.set('unassigned', 'true')
    response = await API.get(url.toString())
    dispatch({ type: 'UNASSIGNED_CUSTOMERS_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const createWallet = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.post('/api/wallet', data)
    dispatch({ type: 'WALLET_CREATED', payload: response.data })
    successHandler({ type: 'WALLET_CREATED' }, dispatch)
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const editWallet = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/wallet', data)
    dispatch({ type: 'WALLET_EDITED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const assignWallet = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/wallet/assign', data)
    dispatch({ type: 'WALLET_ASSIGNED', payload: response.data })
    successHandler({ type: 'WALLET_ASSIGNED' }, dispatch)
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const resetToken = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/wallet/reset', data)
    dispatch({ type: 'TOKEN_RESET', payload: response.data })
    return response
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const hideToken = () => async (dispatch: AppDispatch) =>  {
    dispatch({ type: 'TOKEN_HIDDEN' })
}

export const updateWalletStatus = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/wallet/status', data)
    dispatch({ type: 'WALLET_STATUS_UPDATED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const resetWallet = () => async (dispatch: AppDispatch) =>  {
    dispatch({ type: 'RESET_WALLET' })
}

export const resetNewWallet = () => async (dispatch: AppDispatch) =>  {
  dispatch({ type: 'RESET_NEW_WALLET' })
}

export const resetUniqueNameError = () => async (dispatch: AppDispatch) =>  {
  dispatch({ type: 'RESET_UNIQUE_NAME_ERROR' })
}