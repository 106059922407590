import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermissions, parseDateDotFormat } from '../commons/index'
import { isFlag } from '../commons/utils'
import { useParams } from 'react-router';
import { Wallet } from '../commons/types'

import {
    Paper,
    Typography,
    Divider,
    Tooltip
} from '@material-ui/core';
import {
    editWallet,
    updateWalletStatus,
    resetToken,
    resetUniqueNameError
} from '../actions/wallets';
import { setEditingMode } from '../actions/dashboard';
import { 
    allowedPermissions, 
    incomingRole 
} from '../commons/permissions';

import copyIcon from '../admins/assets/copy-icon.svg';
import exclamationIcon from '../commons/components/assets/exclamation-icon.svg'
import checkIcon from './assets/check-icon.svg'

import InputField from '../commons/components/Input';
import Button from '../commons/components/Button';
import Toggle from '../commons/components/Toggle';
import StatusBadge from '../commons/components/StatusBadge';
import DisableModal from '../commons/components/DisableModal';
import BackButton from '../commons/components/BackButton';
import WarningModal from '../commons/components/WarningModal';
import TokenModal from '../commons/components/TokenModal';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "auto",
        padding: theme.spacing(7),
        marginTop: theme.spacing(6),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    edit: {
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    column: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(3),
            flexDirection: "column",
            position: "relative",
            width: "100%"
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0),
        }
    },
    status: {
        padding: theme.spacing(5),
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    wrappingTitle: {
        wordBreak: "break-word"
    },
    divider: {
        marginBottom: theme.spacing(4)
    },
    spacing: {
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    flexContainer: {
        display: "flex",

    },
    noFlex: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginLeft: theme.spacing(0)
        }
    },
    tokenBtn: {
        width: "196px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: theme.spacing(5)
        }
    },
    flexChild: {
        flex: "1",
        marginRight: theme.spacing(5),
    },
    tokenFlag: {
        display: "flex",
        alignItems: "center",
    },
    tokenIcon: {
        marginLeft: theme.spacing(4)
    },
    tooltip: {
        borderRadius: "4px",
        margin: 0
    },
    secondaryTypo: {
        color: theme.palette.secondary[400]
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface WalletPageProps extends PropsFromRedux {
    wallet: Wallet
}

const WalletPage: FC<WalletPageProps> = ({
    wallet,
    editWallet,
    updateWalletStatus,
    resetToken,
    uniqueNameError,
    setEditingMode,
    permissions,
    mainContact
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [copyTooltip, setCopyTooltip] = useState("")
    const [disabledInput, setDisabledInput] = useState(true)
    const [openDisableModal, setOpenDisableModal] = useState(false)
    const [openWarningModal, setOpenWarningModal] = useState(false)
    const [fields, setFields] = useState({
        name: "",
    })
    // @ts-ignore
    let { walletId } = useParams()
    const DISABLED = 1
    const INUSE = 4
    const DELETED = 8


    useEffect(() => {
        setFields({
            name: wallet.name || "",
        })
        return () => {
            resetUniqueNameError()
        }
    }, [wallet])

    useEffect(() => {
        if (!disabledInput) {
            setEditingMode(true)
        }
        return () => {
            setEditingMode(false)
        }
    }, [disabledInput, setEditingMode])

    const renderText = (status: number) => {
        let text
        if (isFlag(status, DELETED)) {
        text = t('statusText.deleted');
        } else if (isFlag(status, INUSE)) {
        text = t('statusText.used');
        } else {
        text = t('statusText.unused');
        }
        return text
    }

    const handleCopyDid = (e: any) => {
        navigator.clipboard.writeText(wallet.did)
        setCopyTooltip("did")
        setTimeout(() => { setCopyTooltip("") }, 5000)
    }

    const handleCopyPublicKey = (e: any) => {
        navigator.clipboard.writeText(wallet.password)
        setCopyTooltip("publicKey")
        setTimeout(() => { setCopyTooltip("") }, 5000)
    }

    const handleEdit = () => {
        setDisabledInput(false)
    }

    const handleChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleSave = () => {
        setDisabledInput(true)
        editWallet({
            WalletId: walletId,
            name: fields.name
        })
    }

    const handleToggleClick = () => {
        setOpenDisableModal(true)
    }

    const handleCancel = () => {
        setOpenDisableModal(false)
    }

    const handleAffirmative = () => {
        updateWalletStatus({
            WalletId: wallet.id,
            disable: isFlag(wallet.status, DISABLED) ? false : true
        })
        setOpenDisableModal(false)
    }

    const handleTokenReset = () => {
        resetToken({
            WalletId: wallet.id,
        })
        setOpenWarningModal(false)
    }

    return (
        <div>
            <Paper elevation={0} className={clsx(classes.container, {
                [classes.edit]: !disabledInput
            })}>
                <div className={clsx(classes.titleRow, classes.wrappingTitle)}>
                    <Typography
                        variant="h5"
                        className={classes.highEmphasis}
                    >
                        {wallet.name}
                    </Typography>
                    <BackButton />
                </div>
                <div className={classes.columnsRow}>
                    <div className={classes.statusColumn}>
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.name')}
                            </Typography>
                            <InputField
                                disabled={disabledInput}
                                value={fields.name}
                                name="name"
                                onChange={handleChange}
                                error={uniqueNameError ? true : false}
                                errorText={uniqueNameError?.msg}
                            />
                        </div>
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.id')}
                            </Typography>
                            <InputField
                                value={wallet.id}
                                name="id"
                                disabled={true}
                            />
                        </div>
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.did')}
                            </Typography>
                            <Tooltip
                                title={<>{t('walletPage.copy')}</>}
                                open={copyTooltip === "did"}
                                placement="top-end"
                                classes={{ popper: classes.tooltip }}
                            >
                                <div>
                                    <InputField
                                        value={wallet.did}
                                        name="did"
                                        disabled={true}
                                        icon={copyIcon}
                                        onIconClick={handleCopyDid}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        {!wallet.CustomerId && <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.publicKey')}
                            </Typography>
                            <Tooltip
                                title={<>{t('walletPage.copy')}</>}
                                open={copyTooltip === "publicKey"}
                                placement="top-end"
                                classes={{ popper: classes.tooltip }}
                            >
                                <div>
                                    <InputField
                                        value={wallet?.password}
                                        name="publicKey"
                                        disabled={true}
                                        icon={copyIcon}
                                        onIconClick={handleCopyPublicKey}
                                        multiline={true}
                                        rows={4}
                                    />
                                </div>
                            </Tooltip>
                        </div>}
                        {(wallet.CustomerId && wallet.CustomerId !== 0) &&
                            <div>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('walletPage.company')}
                                </Typography>
                                <InputField
                                    value={wallet.Customer?.name}
                                    name="company"
                                    disabled={true}
                                />
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('walletPage.assignedOn')}
                                </Typography>
                                <InputField
                                    value={parseDateDotFormat(wallet.assignedOn)}
                                    name="assigned On"
                                    disabled={true}
                                />
                            </div>
                        }
                    </div>
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    <div className={classes.statusColumn}>
                        {wallet.CustomerId && wallet.CustomerId !== 0 &&
                            <div>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('walletPage.publicKey')}
                                </Typography>
                                <Tooltip
                                    title={<>{t('walletPage.copy')}</>}
                                    open={copyTooltip === "publicKey"}
                                    placement="top-end"
                                    classes={{ popper: classes.tooltip }}
                                >
                                    <div>
                                        <InputField
                                            value={wallet?.password}
                                            name="publicKey"
                                            disabled={true}
                                            icon={copyIcon}
                                            onIconClick={handleCopyPublicKey}
                                            multiline={true}
                                            rows={4}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        }
                        {wallet.CustomerId && <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.token')}
                            </Typography>
                            <div className={clsx(classes.flexContainer, classes.spacing, classes.noFlex)} >
                                <div className={clsx(classes.flexChild, classes.tokenFlag)}>
                                    <Typography 
                                        variant="subtitle1" 
                                        className={wallet.accessToken ? classes.secondaryTypo : classes.mediumEmphasis}
                                    >
                                        {wallet.accessToken ? t('token.set') : t('token.notSet')}
                                    </Typography>
                                    <img
                                        src={wallet.accessToken ? checkIcon : exclamationIcon}
                                        className={classes.tokenIcon}
                                        alt={wallet.accessToken ? "check icon" : "exclamation icon"}
                                    />
                                </div>
                                {hasPermissions(permissions, {wallets: ["E","R"], customers: ["R"]}) &&
                                mainContact.main ?
                                    <div className={classes.tokenBtn}>
                                        <Button
                                            version="tertiary"
                                            text={wallet.accessToken ? t('token.reset') : t('token.create')}
                                            width="100%"
                                            onClick={() => setOpenWarningModal(true)}
                                        />
                                    </div>
                                    : 
                                    <></>
                                }
                            </div>
                        </div>}
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.enabled')}
                            </Typography>
                        </div>
                        <div className={(classes.flexContainer)}>
                            <Typography
                                variant="subtitle1"
                                className={clsx(classes.label, classes.spacing, classes.flexChild)}
                            >
                                {t('walletPage.enabled')}
                            </Typography>
                            <Toggle
                                checked={!isFlag(wallet.status, DISABLED)}
                                onClick={handleToggleClick}
                                disabled={
                                    (isFlag(wallet.status, INUSE) || isFlag(wallet.status, DELETED) || !disabledInput) ||
                                    !allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) ||
                                    (
                                        !hasPermissions(permissions, {admin: ["O"], wallets: ["EO","RO"], customers: ["RO"]}) &&
                                        !hasPermissions(permissions, {tenant: ["O"], wallets: ["E","R"],  customers: ["R"]}) 
                                    ) 
                                    ? 
                                    true : false
                                }
                            />
                        </div>
                        <Divider className={classes.divider} />
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('walletPage.status')}
                            </Typography>
                        </div>
                        <div className={classes.spacing}>
                            <StatusBadge
                                status={renderText(wallet?.status)}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.btnRow}>
                    {(hasPermissions(permissions, {admin: ["O"], wallets: ["EO","RO"], customers: ["RO"]}) ||
                    hasPermissions(permissions, {tenant: ["O"], wallets: ["E","R"], customers: ["R"]})) &&
                    !isFlag(wallet.status, DELETED) ?
                        <div className={classes.btn}>
                            {
                                disabledInput
                                    ?
                                    <Button
                                        version="secondary"
                                        onClick={() => handleEdit()}
                                        text={t('general.Edit')}
                                        width="100%"
                                        disabled={isFlag(wallet.status, INUSE) ? true : false}
                                    />
                                    :
                                    <Button
                                        version="primary"
                                        width="100%"
                                        onClick={() => handleSave()}
                                        text={t('general.Save')}
                                        fullWidth={true}
                                    />
                            }
                        </div>
                    : <></>
                    }
                </div>
            </Paper>
            {openDisableModal && <DisableModal
                open={openDisableModal}
                disabled={isFlag(wallet.status, DISABLED) ? true : false}
                onCancel={handleCancel}
                onAffirmative={handleAffirmative}
                walletName={wallet.name}
            />}
            {openWarningModal && 
            hasPermissions(permissions, {transactions: ["R"]}) &&
            hasPermissions(permissions, {wallets: ["E","R"], customers: ["R"]}) &&
                <WarningModal
                    open={openWarningModal}
                    onCancel={() => setOpenWarningModal(false)}
                    handleTokenReset={() => handleTokenReset()}
                    customer={wallet?.Customer}
                />
            }
            <TokenModal />
        </div>
    )
}

const mapState = (state: RootState) => ({
    customers: state.wallets.unassignedCustomers,
    uniqueNameError: state.errorReducer.uniqueNameError,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    editWallet: (data: Object) => (editWallet(data)),
    updateWalletStatus: (data: Object) => updateWalletStatus(data),
    resetToken: (data: Object) => resetToken(data),
    resetUniqueNameError: () => resetUniqueNameError(),
    setEditingMode: (data: boolean) => (setEditingMode(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(WalletPage)