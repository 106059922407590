import { FC, useState } from "react"
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
    Typography,
    Button,
} from '@material-ui/core';
import SubMenu from "./SubMenu";

import expandArrow from './assets/sidebarIcons/expandArrow.svg'
import smallArrow from './assets/sidebarIcons/smallArrow.svg'

const useStyles = makeStyles((theme?: any) => ({
    text: {
        textTransform: "unset",
        color: theme.palette.onSurface.mediumEmphasis,
        "& .MuiTypography-subtitle2": {
            marginLeft: theme.spacing(7)
        }
    },
    buttonExpanded: {
        height: "40px",
        width: "236px",
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: theme.spacing(4),
        "& .MuiButton-startIcon": {
            marginLeft: theme.spacing(2),
        },
        "&:focus": {
            outline: "none",
            background: theme.palette.gradient.lightPurple,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    buttonCollapsed: {
        height: "40px",
        width: "45px",
        display: "flex",
        justifyContent: "flex-start",
        minWidth: "45px",
        marginBottom: theme.spacing(4),
        "& .MuiButton-startIcon": {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        },
        "& .MuiButton-label": {
            width: "auto",
        },
        "&:focus": {
            outline: "none",
            background: theme.palette.gradient.lightPurple,
        }
    },
    buttonRoot: {
        background: theme.palette.gradient.lightPurple,
        color: theme.palette.primary[600]
    },
    endIcon: {
        position: "absolute",
        right: theme.spacing(4)
    },
    endIconSelected: {
        filter: theme.palette.filter.whiteToLightPurple
    },
    hideSub: {
        transform: "rotate(180deg)",
    },
    smallIcon: {
        width: "10px"
    }
}))

interface MenuItemType {
    src: string;
    srcSelected?: string | undefined;
    text?: string;
    link?: string[]
    subItems?: Object[]
}

interface MenuItemProps {
    open?: boolean
    menuItem: MenuItemType
    selectedItem?: boolean
    handleClick?: any
    transform?: boolean,
    subItems: boolean
}

const MenuItem: FC<MenuItemProps> = ({ 
    open, 
    menuItem, 
    selectedItem, 
    handleClick,
    subItems 
}) => {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    const handleBtnClick = () => {
        setExpanded(!expanded)
        handleClick(menuItem)
    }

    return (
        <>
        <Button
            disableRipple
            startIcon={
                <>
                    <img
                        src={selectedItem ? menuItem.srcSelected : menuItem.src}
                        alt="menu icon"
                    />
                    {subItems && !open && <img
                        src={smallArrow}
                        alt="dropdown icon"
                        className={clsx(classes.smallIcon, {
                            [classes.hideSub]: !expanded,
                            [classes.endIconSelected]: selectedItem
                        })}
                    />}
                </>
            }
            endIcon={
                subItems && open ? <img
                    src={expandArrow}
                    alt="dropdown icon"
                    className={clsx({
                        [classes.hideSub]: !expanded,
                        [classes.endIconSelected]: selectedItem
                    })}
                />
                :
                <></>
            }
            onClick={() => handleBtnClick()}
            classes={{
                text: classes.text,
                root: clsx({
                    [classes.buttonRoot]: selectedItem,
                    [classes.text]: !selectedItem,
                    [classes.buttonExpanded]: open,
                    [classes.buttonCollapsed]: !open,
                }),
                endIcon: classes.endIcon
            }}
        >
            {
                open &&
                <Typography variant="subtitle2">
                    {menuItem.text}
                </Typography>
            }
        </Button>
        {expanded && menuItem.subItems && open && <SubMenu open={expanded} items={menuItem.subItems}/>}
        </>
    )
}

export default MenuItem
