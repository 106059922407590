import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useInterval from '../commons/hooks/useInterval';
import { pollingUpgrade } from '../actions/trial';
import Lottie from "lottie-react";
import animation from '../commons/lotties/loading.json'

import Button from '../commons/components/Button'
import { closeModal } from '../actions/modals';
import { toNumber } from 'lodash';

const useStyles = makeStyles((theme?: any) => ({
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '530px',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    btn: {
        alignSelf: "center",
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Step5Props extends PropsFromRedux {
    planId: string,
    active: boolean
}

const Step5: FC<Step5Props> = ({
    pollingUpgrade,
    mainContact,
    planId,
    active,
    closeModal
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [iframe, setIframe] = useState("")
    const [isCalling, setIsCalling] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        localStorage.removeItem("trialWelcome")
        upgrade()
    }, [])

    const upgrade = async() => {
        let response = await pollingUpgrade(mainContact.CustomerId, planId, active)
    
        if (response.status === 1 && response.data) {
            setIframe(response.data)
            setIsCalling(false)
            setLoading(false)
        }
    }

    useInterval(async()=>{
        upgrade()
    }, isCalling ? 5000 : null)

    return (
        <>
        <div className={classes.contentContainer}>
        {iframe && <iframe 
                        style={{
                            border: "none", 
                            borderRadius: "8px", 
                            padding: 0, 
                            marginBottom: "10px"
                        }}
                        src={iframe}
                        height="510px"
                        width="100%"
                  >
              </iframe>}
        {loading && !iframe && 
            <Lottie 
                animationData={animation} 
                loop={true}
                style={{
                    height: "50px",
                }}
            />
        }
        </div>
        <div className={classes.btn}>
            <Button 
                version="text"
                text={mainContact?.Customer?.PlanId === 1 ? t('welcomeModal.Card5Btn') : t('general.Close').toUpperCase()}
                width="100%"
                onClick={()=>closeModal({modalName: 'welcome'})}
            />
        </div>
    </>
    );
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact
})

const mapDispatch = {
    pollingUpgrade: (CustomerId: number, PlanId: string, active: boolean) => (pollingUpgrade(CustomerId, PlanId, active)),
    closeModal: (data: Object) => (closeModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(Step5)
