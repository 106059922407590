import {
    Card,
    Typography,
    Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from "clsx";
import { useTranslation } from 'react-i18next';

import checkIcon from './assets/check-icon.svg'
import proIcon from './assets/pro.svg'

const useStyles = makeStyles((theme?: any) => ({
    container: {
        position: 'relative',
        width: "288px",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "31px",
        border:"1px solid #D4D2E3",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.09)",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6)
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        width: "100%",
        margin: theme.spacing(3, 0)
    },
    icon: {
        marginRight: theme.spacing(3)
    },
    selected: {
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`
    },
    title: {
        fontWeight: 400,
        color: theme.palette.onSurface.mediumEmphasis,
        textAlign: "center"
    },
    customTitle: {
        color: "#ffffff",
        lineHeight: "32px",
        marginTop: "4px"
    },
    price: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    subTitle:{
        marginBottom: theme.spacing(6)
    },
    customize:{
        width: "172px",
        textAlign: "center",
        marginBottom: theme.spacing(6)
    },
    cardBtn: {
        width: "100%",
        minHeight: "62px",
        borderRadius: "31px",
        textDecoration: "none",
        marginTop: "12px",
        "&.Mui-disabled": {
            backgroundColor: "transparent",
            color: theme.palette.common.black
        }
    },
    btnLink: {
        width: "100%",
        textDecoration: "none"
    },
    containedBtn: {
        backgroundColor: theme.palette.onSurface.highEmphasis,
        color: theme.palette.text.secondary,
        "&.Mui-disabled": {
            backgroundColor: theme.palette.onSurface.highEmphasis,
            color: theme.palette.text.secondary,
        }
    },
    check: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "14px",
    },
    fullWidth:{
        width: "100%",
    },
    checkImgDiv:{
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    planTransactions: {
        marginBottom: theme.spacing(7)
    },
    proCard: {
        background: theme.palette.gradient.purple,
        color: theme.palette.text.secondary,
        "& h5": {
            color: theme.palette.text.secondary,
        },
        "& h6": {
            color: theme.palette.text.secondary,
        },
        "& button": {
            color: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
        },
        "& p": {
            color: theme.palette.text.secondary,
        },
    },
    ulPadding: {
        paddingLeft: "inherit",
       
    },
    freeProIcons:{
        position: "absolute",
        right: 12,
        top: 12
    },
    disabledCard: {
        opacity: "50%"
    },
    pricingContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        backgroundColor: "#F2F1FA",
        position: "relative",
        width: "100%",
        padding: theme.spacing(7),
        boxSizing: "border-box",
        height: "255px",
        justifyContent: "space-between"
    },
    checks: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(7),
        boxSizing: "border-box",
        width: "100%",
        gap: "16px"
    },
    planButton: theme.typography.planButton,
    priceTitle: {
        fontWeight: 700
    },
    containerBg: {
        background: "#21282E",
        color: theme.palette.onPrimary.highEmphasis
    },
    customBtn: {
        backgroundColor: "#A258FF",
        color: theme.palette.onPrimary.highEmphasis,
        "&:hover": {
            backgroundColor: "#A258FF"
        }
    },
    disabled: {
        color: theme.palette.onPrimary.disabled
    },
    checkOpacity: {
        opacity: 0.4
    }
}))

interface StatsCardProps {
    planId: number
    plan: any
    selected: number,
    setSelected: (id: number) => void,
    openModal: (data: Object) => void,
    mt?: boolean,
    modalName: string
}

const PlanCard: FC<StatsCardProps> = ({
    planId,
    plan,
    selected,
    setSelected,
    openModal,
    mt,
    modalName
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const upgradePlan = async (id: number) => {
        localStorage.setItem("processingPlan", String(id))
        openModal({modalName: modalName, data: {active: true, toUpgrade: id}})
    }

    return (
        <>
        <Card className={clsx(classes.container, {
            [classes.selected]: selected === planId,
            [classes.disabledCard]: (mt && planId <= 4) || planId <= selected,
            [classes.proCard]: planId === 4
        })}
            elevation={0}
            onClick={() => setSelected(planId)}
        >
            <div className={`${classes.pricingContainer} ${planId === 2 && classes.containerBg}`}>
            {
                (planId === 2) &&
                <div className={classes.freeProIcons}>
                    <img src={proIcon} alt="" /> 
                </div>
            }          
            <Typography
                variant="h5"
                className={clsx(planId === 2 && classes.customTitle, classes.title)}
            >
                {plan.name}
            </Typography>
            <div className={clsx(classes.price)}>
                {planId === 2 ? 
                    <Typography variant="subtitle1" className={clsx(classes.mediumEmphasis, planId === 2 && classes.disabled)}>
                        {t(`plans.${planId.toString()}.custom`)}
                    </Typography>
                :
                    <>
                        <Typography variant="h3" className={classes.priceTitle}>
                            {plan.price}€
                        </Typography>
                        <Typography variant="subtitle1" className={classes.mediumEmphasis}>
                            /{t('plans.timespan')}
                        </Typography>
                    </>
                }

            </div> 
            {
                planId === 2 ? 
                    <a href="mailto:support@sideos.io" className={classes.btnLink}>
                        <Button
                            variant="contained"
                            className={`${classes.cardBtn} ${classes.customBtn}`}
                            disableElevation={true}
                            disabled={mt || planId <= selected}
                        >
                            <Typography className={classes.planButton}>
                                {t('plans.btnTextContact')}
                            </Typography>
                        </Button>
                    </a>
                    :
                    <Button
                        variant="outlined"
                        className={clsx(classes.cardBtn, {
                            [classes.containedBtn]: planId === 1
                        })}
                        disableElevation={true}
                        disabled={mt || planId <= selected}
                        onClick={()=>upgradePlan(planId)}
                    >
                        <Typography className={classes.planButton}>
                            {t('plans.btnText')}
                        </Typography>
                    </Button>
            }  
            </div>
            <div className={classes.checks}>
                {
                    plan.checks.map((check: number)=>{
                        return (
                            <div className={clsx(classes.check, planId != 2 && check > 3 && classes.checkOpacity)} key={check}>
                                <img 
                                    src={checkIcon} 
                                    className={classes.checkImgDiv}
                                />
                                <Typography 
                                    variant="body2" 
                                    className={classes.mediumEmphasis}
                                >
                                    {t(`plans.${planId.toString()}.check${check}`)}
                                </Typography>
                            </div>
                        )
                    })
                }
            </div>    
        </Card>
        </>
    )
}

export default PlanCard