import {
    Typography,
    Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react'
import { connect, ConnectedProps } from 'react-redux'
import DawBtn from '../commons/components/DawButton';

const useStyles = makeStyles((theme?: any) => ({
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
        textAlign: 'center'
    },
      qrCodeContainer: {
        transition: "transform .2s",
        background: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderRadius: theme.shape.loginRadius,
        height: "fit-content",
        width: "fit-content",
      },
      btnLink: {
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(3),
        }
      },
      link: {
        color: theme.palette.secondary.contrastText,
        width: "250px",
        height: "42px",
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      qrCode: {
        borderRadius: theme.shape.loginRadius
      },
      refreshIcon: {
        filter: theme.palette.filter.toBlack
      },
      title: {
        textAlign: "center"
      },
      dividerRow: {
        display: "flex",
        alignItems: "center"
      },
      divider: {
        backgroundColor: "rgba(193, 199, 205, 0.2)",
        width: "45%"
      }
}))

type PropsFromRedux = ConnectedProps<typeof connector>


interface TrialLoginProps extends PropsFromRedux {
  counter: number
  qrcode: string
  setQrcode: (arg: string) => void
  openSocket: ()=>void
}

const TrialLogin: FC<TrialLoginProps> = ({
  counter,
  qrcode,
  setQrcode,
  openSocket
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    useEffect(()=>{
      return () => {
        setQrcode('')
      }
    }, [setQrcode])

    return (
        <>
        <Typography variant="h5" className={classes.title}>
          {t('trial.loginTitle')}
        </Typography>
        <Typography 
            variant="subtitle1" 
            className={classes.text}
        >
          {t('trial.loginText')}
        </Typography>
        <div className={classes.qrCodeContainer}>
          <QRCode
            value={qrcode}
            includeMargin={true}
            size={300}
          />
          </div> 
        <a 
            href={"ssi.interaction://interaction?jwt=" + qrcode} 
            className={classes.btnLink} 
            target="_blank" 
            rel="noopener noreferrer"
        >
          <Typography 
              variant="subtitle1"
              className={classes.link} 
          >   
            {t('Login.link')}
          </Typography>
        </a>
        <div className={classes.dividerRow}>
					<Divider className={classes.divider} />
					<Typography
						variant="subtitle1"
						>
						{t("Login.or")}
					</Typography>
					<Divider className={classes.divider}/>
				</div>
				<DawBtn
					qrcode={qrcode}
					text={t('Login.daw')}
					id="dawRequestCredential"
					disabled={!counter || !qrcode ? true : false}
          version="outlined"
				/>
        </>
    )
}

interface RootState { }

const mapState = (state: RootState) => ({

})

const mapDispatch = {
    
  }
  
  const connector = connect(mapState, mapDispatch)
  
  export default connector(TrialLogin)