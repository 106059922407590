import {
    Badge
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import notificationIcon from '../assets/icons/notificationIcon.svg'
import notificationIconWhite from './assets/notification-white-icon.svg'
import { FC } from 'react';
import { hasPermissions } from '..';

const useStyles = makeStyles((content: number) => ({
    badge: {
        cursor: "pointer",
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            right: content => (content !== 0 ? "unset" : ""),
            transform: content => (content !== 0 ? "unset" : ""),
        },
        "& .MuiBadge-dot": {
            border: content => (content !== 0 ? "1px solid #FFFFFF" : "")
        }
    }

}))

interface NotificationsProps {
    content: number
    permissions?: any
    mainContact?: any
}

const NotificationsBell: FC<NotificationsProps> = ({
    content,
    permissions,
    mainContact
}) => {
    const classes = useStyles(content)

    return (
        <Badge
            color={hasPermissions(permissions, { "trial": "O" }) ||
            (mainContact?.Customer?.PlanId === 1 && !mainContact?.Customer?.tenant)
            ? 
            "secondary" : "primary"}
            badgeContent={content}
            className={classes.badge}
            variant="dot"
            invisible={content === 0 ? true : false}
            overlap="rectangular"
        >
            <img
                src={hasPermissions(permissions, { "trial": "O" }) ||
                (mainContact?.Customer?.PlanId === 1 && !mainContact?.Customer?.tenant)
                ? 
                notificationIconWhite : notificationIcon}
                alt="notification icon"
            />
        </Badge>
    )
}

export default NotificationsBell