import API from '../api'
import { AppDispatch } from "../store";
import errorHandler from '../actions/errorHandler'
import successHandler from './successHandler';

export const getProofs = (CustomerId: number, checked?: string) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      if (checked) {
        let url = new URL(process.env.REACT_APP_API_URL +'/api/proofs/' + CustomerId)
        url.searchParams.set(checked, 'true')
        response = await API.get(url.toString())
      } else {
        response = await API.get('/api/proofs/' + CustomerId)
      }
      dispatch({ type: 'PROOFS_LOADED', payload: response.data })
    } catch(e: any) {
      errorHandler(e, dispatch)
    } 
}

export const deleteProof = (data: any) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.delete('/api/proof', data)
    successHandler({ type: 'PROOF_DELETED' }, dispatch, {name: data.name})
    return response
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getProofRepository = () => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/proofs')
    dispatch({ type: 'PROOF_REPO_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getProof = (ProofId: number) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/proof/' + ProofId)
    dispatch({ type: 'PROOF_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getProofsTypes = (CustomerId: number) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/proofstypes/' + CustomerId)
    dispatch({ type: 'PROOF_TYPES_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getContexts = () => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/contextstypes')
    dispatch({ type: 'CONTEXTS_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const createProof = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.post('/api/proof', data)
    dispatch({ type: 'PROOF_CREATED', payload: response.data })
    successHandler({ type: 'PROOF_CREATED' }, dispatch)
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const resetNewProof = () => async (dispatch: any) => {
  dispatch({ type: 'NEW_PROOF_RESET' })
}

export const updateSharingOption = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/proof/share', data)
    dispatch({ type: 'SHARING_OPTION_UPDATED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const editProof = (data: Object) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.patch('/api/proof/', data)
    dispatch({ type: 'PROOF_UPDATED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}