import { Dialog,  } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { hasPermissions } from "../commons";
import { Proof } from "../commons/types";

import ProofStatusCard from "../proofs/ProofStatusCard";
import ProofRepositoryList from "../proofs/ProofRepositoryList";

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "1000px",
        height: "auto",
        overflow: "visible",
        color: theme.palette.onSurface.highEmphasis,
        [theme.breakpoints.down('sm')]: {
            zIndex: 1500,
            margin: theme.spacing(0, 0),
            width: '100%',
        }
    },
    overlay: {
        "& .MuiDialog-container": {
            height: "auto",
        },
    }
}))

interface CreateProofModalProps {
    open: boolean
    onCancel: () => void
    permissions: any
    inMobile?: boolean
}

const CreateProofModal: FC<CreateProofModalProps> = ({
    open=false,
    onCancel,
    permissions,
    inMobile
}) => {    
    const classes = useStyles()
    const [pickedProof, setPickedProof] = useState<Proof | undefined>()
    const [openCreateNew, setOpenCreateNew] = useState(false)

    useEffect(()=>{
        return () => {
            setPickedProof(undefined)
        }
    }, [])

    return (
        <Dialog 
            fullWidth
            open={open} 
            style={inMobile?{position: 'relative',zIndex: 1}:{}}
            disablePortal={inMobile?true:false}
            disableScrollLock={inMobile?true:false}
            hideBackdrop={inMobile?true:false}
            classes={{paper: classes.dialogContainer, root: inMobile ? classes.overlay:undefined}}
        >
            {
                hasPermissions(permissions, {admin: "O"}) || 
                pickedProof || 
                openCreateNew 
                ?
                <ProofStatusCard 
                    inModal={true} 
                    onCancel={onCancel}
                    pickedProof={pickedProof}
                />
                :
                <ProofRepositoryList 
                    inModal={true}
                    onCancel={onCancel}
                    setPickedProof={setPickedProof}
                    setOpenCreateNew={setOpenCreateNew}
                />
            }
        </Dialog>               
    )
}

export default CreateProofModal