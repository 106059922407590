import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableSortLabel,
    Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import upwardArrowIcon from './assets/upward-arrow-icon.svg'
import downwardArrowIcon from './assets/downward-arrow-icon.svg'
import expandRowIcon from './assets/gray-arrow-down.svg'
import collapseRowIcon from './assets/purple-arrow-up.svg'
import clsx from 'clsx';

import TCell from './TCellContent';

const useStyles = makeStyles((theme?: any)=>({
    container: {
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        borderRadius: theme.shape.borderRadius,
        "&::-webkit-scrollbar-track": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.borderColor.onSurface
        },
        "&::-webkit-scrollbar": {
            width: "5px",
            backgroundColor: theme.palette.borderColor.onSurface
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.onSurface.mediumEmphasis
        },
    },
    disabledTable: {
        backgroundColor: theme.palette.onSurface.tableDisabled
    },
    title: {
        color: theme.palette.onSurface.highEmphasis
    },
    column: {
        padding: theme.spacing(5, 6)
    },
    footer: {
        color: theme.palette.onSurface.mediumEmphasis,
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing,
        borderBottom: "none",
        "& .MuiTablePagination-spacer": {
            display: "none"
        },
        "& .MuiTablePagination-toolbar": {
            display: "flex",
            justifyContent: "space-between",           
        },
        "& p:last-of-type": {
            position: "absolute",
            right: theme.spacing(12)
        },
        "& .MuiTablePagination-selectRoot": {
            position: "absolute",
            left: theme.spacing(12),
        },
        "& .MuiToolbar-gutters": {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        },
        "& .MuiInputBase-input": {
            fontSize: theme.typography.subtitle2.fontSize,
            marginTop: theme.spacing(3)
        },
    },
    menuItem: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    sortingIconColumn: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(4),
        "&:hover $tableSortLabel": {
            opacity: 1
        }
    },
    tableSortLabel: {
        opacity: 0,
        cursor: "default"
    },
    row: {
        display: "flex",
        alignItems: "center",
    },
    cell: {
        padding: theme.spacing(3, 5, 3, 6),
        borderBottom: "none",
        overflowWrap: "anywhere",
        minWidth: "100px"
    },
    sortingIcon: {
        height: "7px",
        width: "10px",
    },
    activeIcon: {
        filter: theme.palette.filter.blackToLightPurple,
        opacity: 1
    },
    tableHeadColor: {
        zIndex: 100,
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.background.default,
        background: theme.palette.gradient.lightPurple,
        "& .MuiTableCell-stickyHeader": {
            background: theme.palette.background.default,     
            backgroundColor: "transparent",
        },
    },
    tableHead: {
        zIndex: 100,
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.background.default,
        background: theme.palette.background.default,
        "& .MuiTableCell-stickyHeader": {
            background: theme.palette.background.default,     
            backgroundColor: "transparent",
        },
    },
    tableBody: {
        overflowY: "scroll",
        backgroundColor: theme.palette.background.default
    },
    table: {
        position: "relative"
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        cursor: "pointer",
        zIndex: 1000,
        backgroundColor: theme.palette.onSurface.tableDisabled,
    },
    collapsedRow: {
        padding: theme.spacing(0),
        borderCollapse: "collapse"
    },
    expandedRow: {
        background: theme.palette.primary.hover
    },
    selected: {
        "&.MuiTableRow-root": {
            background: theme.palette.primary.selected
        }
    },
    tableRow: {
        height: "52px",
        "&.MuiTableRow-root": {
            cursor: "pointer",
            "&:hover": {
                background: theme.palette.primary.hover
            }
        }
    },
    icon: {
        width: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    emptyRow: {
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        height: "56px",
        width: "100%"
    }
}))

interface SmallTableProps {
    columns: any,
    rows: any,
    tableCells?: {[name: string]: (item: any) => ReactElement}
    handleCellClick?: (row: any) => any | void
    disabled?: boolean
    expandable?: boolean
    checkExpandableValue?: any
    collapseElement?: (item: any, items?: any) => ReactElement
    rowAction?: (item: any) => ReactElement
    color?: boolean
    selected?: number
}

const SmallTable: FC<SmallTableProps> = ({
    columns,
    rows,
    tableCells,
    handleCellClick,
    disabled,
    expandable=false,
    checkExpandableValue,
    collapseElement,
    color=true,
    rowAction,
    selected
}) => {
    
    const classes = useStyles()
    const [direction, setDirection] = useState<"asc" | "desc">("asc")
    const [orderedRows, setOrderedRows] = useState<any>([])
    const [activeIcon, setActiveIcon] = useState<{columnName: string, direction: "asc" | "desc" }>()
    const [rowExpanded, setRowExpanded] = useState<string | number>("")
    let emptyRow: any = {}
    Object.keys(columns).forEach((column: string)=>{
        emptyRow[column]=""
    })

    useEffect(()=>{
        setOrderedRows(rows)
    }, [rows])

    const handleSorting = (columnName: string, direction: "asc" | "desc", index: number) => {
        setDirection(direction)
        setActiveIcon({columnName: columnName, direction: direction})
        let rowsArray: {[columnName: string]: string | number}[] = orderedRows
        let rowsInOrder: {
            [columnName: string]: string | number
        }[] = orderBy(rowsArray, 
            [row => typeof row[columnName] === "string" 
            ? 
            // @ts-ignore
            row[columnName].toLowerCase() 
            : 
            row[columnName]], [direction]
        )
        setOrderedRows([...rowsInOrder])
    }

    return (
        <TableContainer 
            component={Paper} 
            elevation={0} 
            className={clsx(classes.container, {
                [classes.disabledTable]: disabled
            })}
        >
            <Table  
                className={classes.table}
            >
                {disabled && 
                    <div className={classes.overlay}></div>
                }
                <TableHead className={color ? classes.tableHeadColor : classes.tableHead}>
                    <TableRow >
                        {Object.keys(columns).map((column: string, index: number)=>{
                            return (
                                <TableCell key={index} className={classes.column}>
                                    <Typography 
                                        variant="subtitle2" 
                                        className={classes.title}
                                    >
                                        <div className={classes.row}>
                                            {columns[Object.keys(columns)[index]]}
                                            <div className={classes.sortingIconColumn}>
                                                <TableSortLabel
                                                    direction={direction} 
                                                    onClick={()=>{handleSorting(Object.keys(columns)[index], "asc", index)}}
                                                    IconComponent={()=>
                                                        <img 
                                                            src={upwardArrowIcon} 
                                                            alt="upward arrow icon" 
                                                            className={classes.sortingIcon}
                                                        />
                                                    } 
                                                    classes={{active: classes.activeIcon, root: classes.tableSortLabel}}  
                                                    active={
                                                        activeIcon?.columnName === Object.keys(columns)[index] && 
                                                        activeIcon?.direction === "asc" 
                                                        ? 
                                                        true 
                                                        : 
                                                        false
                                                    }                                      
                                                />
                                                <TableSortLabel
                                                    direction={direction} 
                                                    onClick={()=>{handleSorting(Object.keys(columns)[index], "desc", index)}}  
                                                    IconComponent={()=>
                                                        <img 
                                                            src={downwardArrowIcon} 
                                                            alt="downward arrow icon" 
                                                            className={classes.sortingIcon}
                                                        />
                                                    } 
                                                    classes={{active: classes.activeIcon, root: classes.tableSortLabel}}
                                                    active={
                                                        activeIcon?.columnName === Object.keys(columns)[index] && 
                                                        activeIcon?.direction === "desc" 
                                                        ? 
                                                        true 
                                                        : 
                                                        false
                                                    }                                      
                                                />
                                            </div>
                                        </div>
                                    </Typography>
                                </TableCell>
                            )
                        })}
                        {expandable && <TableCell padding="checkbox"/>}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {orderedRows?.length !== 0 ?
                        orderedRows?.map((row: {[name: string]: string | number}, index: number)=>{
                            return (
                                <Fragment key={index}>
                                <TableRow key={row.id} className={clsx(classes.tableRow, {
                                    [classes.expandedRow]: rowExpanded === row.id,
                                    [classes.selected]: selected === row.id
                                })}>
                                    {
                                        Object.keys(columns).map((column, index)=>{
                                                return ( 
                                                    <TableCell 
                                                        className={classes.cell}
                                                        onClick={()=>handleCellClick && handleCellClick(row)}
                                                        key={index}
                                                    >
                                                        <TCell 
                                                            row={row}
                                                            column={column}
                                                            tableCells={tableCells}
                                                        />
                                                    </TableCell>
                                                )
                                        })
                                    }   
                                {
                                    expandable && checkExpandableValue && checkExpandableValue(row) ?
                                    <TableCell 
                                        padding="checkbox" 
                                        className={classes.cell} 
                                    >
                                        <div className={classes.icon}>
                                        {
                                            rowExpanded === row.id
                                            ?
                                            <img 
                                                src={collapseRowIcon} 
                                                alt="purple arrow up"
                                                onClick={()=>setRowExpanded("")} 
                                            />
                                                :
                                            <img 
                                                src={expandRowIcon} 
                                                alt="gray arrow down" 
                                                onClick={()=>setRowExpanded(row.id)} 
                                            />
                                        }
                                        </div>
                                    </TableCell>
                                    : 
                                    expandable ?
                                            <TableCell className={classes.cell}>
                                                    { rowAction && rowAction(row) }
                                            </TableCell>
                                            : null
                                }                                 
                                </TableRow>   
                                <TableRow >
                                    <TableCell colSpan={Object.keys(columns).length + 1} className={classes.collapsedRow}>
                                        <Collapse in={rowExpanded === row.id} >
                                            {collapseElement && collapseElement(row)}
                                        </Collapse>
                                    </TableCell>
                                </TableRow> 
                            </Fragment> 
                            )
                        })
                        :
                        <TableRow className={classes.emptyRow}>
                            {Object.keys(emptyRow).map((column: any, index: number)=>{
                                return (
                                    <TableCell 
                                        className={classes.cell} 
                                        key={index} 
                                        colSpan={index === Object.keys(emptyRow).length -1 ? 2 : 1}
                                    >
                                        <TCell row={emptyRow} column={column}/>
                                    </TableCell>
                                )
                            })}                       
                        </TableRow>   
                    }
                </TableBody>
            </Table>
        </TableContainer>       
    )
}

export default SmallTable