import {
    Card,
    Typography,
    Input
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import clsx from "clsx";
import { Admin, Credential } from "../commons/types";

import increaseIcon from './assets/increase-icon.svg'
import decreaseIcon from './assets/decrease-icon.svg'
import dropdownIcon from './assets/downward-arrow-icon.svg'

import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any) => ({
    container: {
        boxSizing: "border-box",
        width: "423px",
        backgroundSize: "cover",
        padding: theme.spacing(7),
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.down(300)]: {
            padding: theme.spacing(5),
        },
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        position: "relative",
    },
    diffContainer: {
        width: "70%",
        alignSelf: "flex-end",
        marginTop: theme.spacing(6),
    },
    row: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    captionText: {
        display: "block",
        textAlign: "end"
    },
    icon: {
        marginRight: theme.spacing(3)
    },
    typeInput: {
        width: "50px",
        "& .MuiInputBase-input": {
            padding: 0,
            textAlign: "left",
            color: theme.palette.onSurface.disabled,
            width: "20px"
        }
    },
    withIcon: {
        display: "flex",
        alignItems: "center",
        "& img": {
            marginLeft: theme.spacing(4),       
        }
    },
    btnContainer: {
        marginLeft: theme.spacing(3)
    }
}))

interface StatsCardProps {
    firstTime?: boolean
    titleText: string
    btnText?: string
    link?: string
    numberOfNew?: number
    diff?: number | null
    typeDropdown?: boolean
    permissions: any
    showButton?: boolean
}

const StatsCard: FC<StatsCardProps> = ({
    titleText,
    btnText,
    link,
    numberOfNew,
    diff,
    typeDropdown,
    showButton
}) => {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()

    const handleClick = () => {
        if (link) {
            history.push(link)
        }
    }

    return (
        <Card className={classes.container} elevation={2}>
            <div>
                <div>
                    <Typography
                        variant="subtitle2"
                        className={classes.highEmphasis}
                    >
                        {titleText}
                    </Typography>
                    {
                        typeDropdown &&
                        <Input
                            endAdornment={<img src={dropdownIcon} alt="dropdown arrow" />}
                            value={"All"}
                            disabled={true}
                            disableUnderline={true}
                            className={classes.typeInput}
                        />
                    }
                </div>
                    <Typography
                        variant="h3"
                        className={clsx(classes.highEmphasis, classes.withIcon)}
                    >
                        {numberOfNew} 
                    </Typography>
            </div>
            <div className={classes.column}>
                <div className={classes.btnContainer}>
                    {btnText && link && showButton && <Button
                        width="150px"
                        text={btnText}
                        onClick={() => handleClick()}
                    />}
                </div>
                {diff !== null && diff !== undefined &&
                    <div className={classes.diffContainer}>
                        <div className={classes.row}>
                            {diff !== 0 &&
                                <img
                                    src={Math.sign(diff) === -1 ? decreaseIcon : increaseIcon}
                                    className={classes.icon}
                                    alt="increase / decrease icon"
                                />
                            }
                            <Typography variant="h5" className={classes.mediumEmphasis}>
                                {diff === 0 ? "0%" : Math.sign(diff) === -1 ? `${diff}%` : `+${diff}%`}
                            </Typography>
                        </div>
                        <Typography
                            variant="caption"
                            className={clsx(classes.mediumEmphasis, classes.captionText)}
                        >
                            {t('dashboard.compare')}
                        </Typography>
                    </div>
                }
            </div>

        </Card>
    )
}

export default StatsCard