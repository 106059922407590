import API from '../api'
import { AppDispatch } from "../store";
import errorHandler from '../actions/errorHandler'
import FileDownload from 'js-file-download';
import { format } from 'date-format-parse';

export const getTransactions = (data?: any) => async (dispatch: any) => {
    let response
    try {
      let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/transactions')
      data?.startDate && url.searchParams.set('beg', data.startDate)
      data?.endDate && url.searchParams.set('end', data.endDate)
      data?.companyFilter && url.searchParams.set('CustomerId', data.companyFilter.id)
      data?.typeFilter && url.searchParams.set('status', data.typeFilter.value)
      data?.search && url.searchParams.set('search', data.search)
      data?.sort && url.searchParams.set('sort', data.sort)
      data?.order && url.searchParams.set('order', data.order)
      response = await API.get(url.toString())
      dispatch({ type: 'TRANSACTIONS_LOADED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
}

export const getCompaniesFilter = () => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/transactions/companies')
    dispatch({ type: 'COMPANIES_FILTER_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const downloadCSV = (data: any) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.downloadCSV('/api/transactions/download', data)
    FileDownload(response.data, "transactions-" + format(new Date(), 'DD-MM-YYYY') + ".csv");
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}
