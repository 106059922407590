import { connect, ConnectedProps } from 'react-redux'
import {
    Typography,
    Dialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState, FC, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor'
import Button from '../commons/components/Button'
import zoomin from './assets/icons/zoomin.svg'
import zoomout from './assets/icons/zoomout.svg'

const useStyles = makeStyles((theme?: any) => ({
    inputfile: {
        width: "0.1px",
        height: "0.1px",
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    },
    inputLabel: {
        width: "196px",
        height: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(0),
        border: `1.6px solid ${theme.palette.borderColor.onSurface}`,
        color: theme.palette.onSurface.highEmphasis,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        "&:hover": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[800],
        },
        "&:focus": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.onSurface.highEmphasis,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    logoTitle: {
        color: theme.palette.borderColor.onSurface,
        margin: theme.spacing(5)
    },
    logoBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "128px",
        height: "128px",
        border: `1px dashed ${theme.palette.onSurface.mediumEmphasis}`,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(7),
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        }
    },
    logoContainer: {
        display: "flex",
        alignItems: "flex-end",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-start"
        }
    },
    img: {
        width: "90px",
        height: "90px",
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
    },
    dialogBox: {
        width: "400px",
        height: "auto",
        padding: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5, 0),
            margin: theme.spacing(3),
        }
    },
    canvas: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "300px",
        }
    },
    title: {
        color: theme.palette.onSurface.highEmphasis,
        alignSelf: "flex-start",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(5),
        }
    },
    btnRow: {
        alignSelf: "flex-end",
        marginTop: theme.spacing(5),
        width: "380px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            alignSelf: "unset",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }
    },
    zoomRow: {
        display: "flex",
        width: "90%",
        marginTop: "-35px",
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(6)
        }
    },
    icon: {
        margin: theme.spacing(0, 2)
    },
    iconPurple: {
        filter: theme.palette.filter.whiteToLightPurple
    },
    assistiveText: {
        color: theme.palette.onSurface.disabled,
        maxWidth: "320px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    assistiveTextContainer: {
        alignSelf: "flex-start",
        marginTop: "15px",
    },
    btn: {
        width: "180px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(0),
            width: "100%"
        }
    },
    label: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    uploadContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface SecurityImgModalProps extends PropsFromRedux {
    setSecurityImg: any,
    imgPreview: any,
    setImgPreview: any
    errorFileType: any
    errorImgSize: any
}

const SecurityImgModal: FC<SecurityImgModalProps> = ({
    imgError,
    setSecurityImg,
    imgPreview,
    setImgPreview,
    errorImgSize,
    errorFileType
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [scale, setScale] = useState(1)
    const editorRef = useRef<AvatarEditor>(null)

    const handleSave = () => {
        if (errorImgSize) {
            imgError({ msg: t('error.imgSize') })
        } else if (errorFileType) {
            imgError({ msg: t('error.fileType') })
        } else if (editorRef !== null) {
            const preview = editorRef?.current?.getImage().toDataURL()
            if (preview) {
                localStorage.setItem("securityImage", preview)
                setSecurityImg(preview)
            }
        }
        setImgPreview("")
        setScale(1)
    }

    return (
        <>
            <Dialog open={imgPreview ? true : false} classes={{ paper: classes.dialogBox }} maxWidth='md'>
                <Typography variant="h6" className={classes.title}>
                    {/* {titlePreview} */}
                </Typography>
                <div className={classes.canvas}>
                    <AvatarEditor
                        ref={editorRef}
                        image={imgPreview ? imgPreview : ""}
                        width={300}
                        height={300}
                        border={50}
                        borderRadius={300}
                        color={[0, 0, 0, 0.35]}
                        scale={scale}
                        rotate={0}
                    />
                    <div className={classes.zoomRow}>
                        <img
                            src={zoomin}
                            alt="zoom-in icon"
                            className={clsx(classes.icon, {
                                [classes.iconPurple]: scale > 1
                            })}
                            onClick={() => setScale(scale + 0.5)}
                        />
                        <img
                            src={zoomout}
                            alt="zoom-out icon"
                            className={clsx(classes.icon, {
                                [classes.iconPurple]: scale < 1
                            })}
                            onClick={() => setScale(scale === 0.5 ? scale : scale - 0.5)}
                        />
                    </div>
                    <div className={classes.assistiveTextContainer}>
                        <Typography className={classes.assistiveText}>
                            {t('settings.subtitle')}
                        </Typography>
                    </div>
                </div>
                <div className={classes.btnRow}>
                    <div className={classes.btn}>
                        <Button
                            text={t('general.Cancel')}
                            version="text"
                            onClick={() => setImgPreview("")}
                            width="100%"
                        />
                    </div>
                    <div className={classes.btn}>
                        <Button
                            text={t('general.Save')}
                            onClick={() => handleSave()}
                            width="100%"
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
    imgError: (error: { msg: string }) => ({ type: 'IMG_ERROR', error: error })
}

const connector = connect(mapState, mapDispatch)

export default connector(SecurityImgModal)
