import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Dialog,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import SmallTable from '../commons/components/SmallTable'
import Button from '../commons/components/Button'
import CollapseTable from '../commons/components/CollapseTable';
import {
    getRepositoryTemplates
} from '../actions/credentials';
import { useTranslation } from 'react-i18next';
import { Credential } from '../commons/types';

const useStyles = makeStyles((theme?: any) => ({
    modalContainer: {
        width: "622px",
        height: "592px",
        padding: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            zIndex: 1500,
            margin: theme.spacing(0, 0),
            width: '100%',
            padding: theme.spacing(4, 3),
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        textDecoration: "none"
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            position: "relative",
            marginTop: theme.spacing(5, 0)
        }
    },
    btn: {
        width: "234px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom: theme.spacing(4),
            paddingLeft: theme.spacing(0),
        }
    },
    tableContainer: {
        height: "75%",
        [theme.breakpoints.down('sm')]: {
            height: "auto"
        }
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        height: "90%",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(7)
        }
    },
    description: {
        color: theme.palette.onSurface.disabled
    },
    overlay: {
        "& .MuiDialog-container": {
            height: "auto",
            [theme.breakpoints.down('sm')]: {
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginTop: theme.spacing(5)
            }
        },
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface RepositoryModalProps extends PropsFromRedux {
    open: boolean
    setOpen: (arg: boolean) => void
    inMobile?: boolean
}

const RepositoryModal: FC<RepositoryModalProps> = ({
    open,
    mainContact,
    setOpen,
    getRepositoryTemplates,
    templateRepository,
    inMobile
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const [selectedTemplate, setSelectedTemplate] = useState<Credential | undefined>()

    const columns = {
        name: t('credentialsRepo.name'),
        type: t('credentialsRepo.type')
    }

    useEffect(() => {
        getRepositoryTemplates()
    }, [mainContact, getRepositoryTemplates])

    const handleCellClick = (item: any) => {
        setSelectedTemplate(item)
    }

    const handleConfirm = () => {
        history.push({ pathname: "/credentials/new", state: { pickedCredential: selectedTemplate } })
    }


    return (
        <Dialog
            open={open}
            style={inMobile ? { position: 'relative', zIndex: 1 } : {}}
            disablePortal={inMobile ? true : false}
            disableScrollLock={inMobile ? true : false}
            hideBackdrop={inMobile ? true : false}
            classes={{ paper: classes.modalContainer, root: inMobile ? classes.overlay : undefined }}
        >
            <div className={classes.contentContainer}>
                <Typography variant="h6" className={classes.highEmphasis}>
                    {t('credentialsRepo.title')}
                </Typography>
                <Typography variant="subtitle1" className={classes.mediumEmphasis}>
                    {t('credentialsRepo.text')}
                </Typography>
                <div className={classes.tableContainer}>
                    <SmallTable
                        columns={columns}
                        rows={templateRepository}
                        expandable={true}
                        checkExpandableValue={() => true}
                        color={false}
                        selected={selectedTemplate?.id}
                        handleCellClick={handleCellClick}
                        collapseElement={(item) => {
                            return (
                                <CollapseTable
                                    titles={[
                                        { prop: "Proofs", title: t('credentialsTable.proofs') },
                                        { prop: "description", title: t('credentialsRepo.description') }
                                    ]}
                                    item={item}
                                    tableCells={{
                                        Proofs: (item: any) => {
                                            return (item?.Proofs.map((proof: any) => {
                                                return (
                                                    <p>
                                                        <Link
                                                            to={`/claims/${proof.id}`}
                                                            key={proof.id}
                                                            className={classes.mediumEmphasis}
                                                        >
                                                            <Typography
                                                                variant="button"
                                                                className={classes.mediumEmphasis}
                                                            >
                                                                {proof.name}
                                                            </Typography>
                                                        </Link>
                                                    </p>
                                                )
                                            })
                                            )
                                        },
                                        description: (item: any) => {
                                            return (
                                                <Typography
                                                    variant="body1"
                                                    className={classes.description}
                                                >
                                                    {item.description}
                                                </Typography>
                                            )
                                        }
                                    }}
                                />
                            )
                        }
                        }
                        tableCells={{
                            name: (item: any) => {
                                return (
                                    <Typography
                                        variant="body2"
                                        className={classes.highEmphasis}
                                    >
                                        {item.name}
                                    </Typography>
                                )
                            },
                        }}
                    />
                </div>
            </div>
            <div className={classes.btnRow}>
                <div className={classes.btn}>
                    <Button
                        text={t('general.Cancel')}
                        version="secondary"
                        width="100%"
                        onClick={() => setOpen(false)}
                    />
                </div>
                <div className={classes.btn}>
                    <Button
                        text={t('general.Confirm')}
                        version="primary"
                        width="100%"
                        disabled={!selectedTemplate?.id || selectedTemplate?.id === 0}
                        onClick={() => handleConfirm()}
                    />
                </div>
            </div>
        </Dialog>
    );
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    templateRepository: state.credentials.templateRepository
})

const mapDispatch = {
    getRepositoryTemplates: () => (getRepositoryTemplates())
}

const connector = connect(mapState, mapDispatch)

export default connector(RepositoryModal)
