export const storePermissions = (data: string) => async (dispatch: any) => {
    dispatch({ type: 'PERMISSIONS', payload: data })
  }
  
  export const storeToken = (data: string) => async (dispatch: any) => {
    dispatch({ type: 'STORE_TOKEN', payload: data })
  }

  export const storeUser = (data: string) => async (dispatch: any) => {
    dispatch({ type: 'USER', payload: data })
  }