import {
    Chip, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme?: any)=>({
    green: {
        background: theme.palette.badge.green.bg,
        color: theme.palette.secondary[500]
    },
    purple: {
        background: theme.palette.badge.purple.bg,
        color: theme.palette.primary[600]
    },
    yellow: {
        background: theme.palette.badge.yellow.bg,
        color: theme.palette.badge.yellow.color
    },
    red: {
        background: theme.palette.badge.red.bg,
        color: theme.palette.badge.red.color
    },
    gray:{
        color: theme.palette.onSurface.disabled
    },
    label: {
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        textTransform: theme.typography.button.textTransform
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    }
}))

interface StatusBadgeProps {
    status: any
}

const StatusBadge: FC<StatusBadgeProps> = ({
    status,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    
    return (
        <>
        {status ? 
            <Chip 
                className={clsx({
                    [classes.yellow]: status === t('statusText.draft'),
                    [classes.purple]: status === t('statusText.unused') || status === t('statusText.pending'),
                    [classes.red]: status === t('statusText.rejected'),
                    [classes.green]: status === t('statusText.accepted') || status.includes(t('statusText.used')),
                    [classes.gray]: status === t('statusText.deleted'),

                })}
                label={status}
                size="small"
                classes={{
                    labelSmall: classes.label
                }}
            /> 
            : 
            <Typography variant="body2" className={classes.highEmphasis}>
                -
            </Typography>
        }
        </>
    )         
}

export default StatusBadge