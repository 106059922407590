import {
    InputBase,
    FormHelperText,
    FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme?: any)=>({
    inputBase: {
        minHeight: "36px",
        border: "1px solid",
        marginBottom: "8px",
        borderColor: theme.palette.borderColor.onSurface,
        borderRadius: theme.shape.sharpBorderRadius,
        background: theme.palette.background.default,
        outline: "none",
        color: theme.palette.onSurface.mediumEmphasis,
        padding: theme.spacing(2, 5),
        fontFamily: theme.typography.body2.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        "&:hover": {
            borderColor: theme.palette.onSurface.mediumEmphasis,
        },
        "& .MuiInputBase-input:focus": {
            color: theme.palette.onSurface.highEmphasis,
        },
    },
    disabled: {
        border: "none",
        background: theme.palette.surface.overlay,
        color: theme.palette.onSurface.disabled,
        "&:hover": {
            border: "none"
        },
        "&.MuiInputBase-input.Mui-disabled": {
            background: "none",
        }
    },
    error: {
        border: "1px solid",
        borderColor: theme.palette.error.main,
        marginBottom: "10px",
        "&:hover": {
            borderColor: theme.palette.error.main,
        },
    },
    helperText: {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: theme.spacing(4)
    },
    readonly: {
        cursor: "pointer",
        "& .MuiInputBase-input": {
            cursor: "pointer"
        }
    },
    icon: {
        cursor: "pointer",
        marginLeft: theme.spacing(5)
    },
    multiline: {
        paddingTop: "8px"
    },
    inputMarginDropdown: {
        marginBottom: "unset"
    },
    filter: {
        borderColor: theme.palette.primary.main
    }
}))


interface InputFieldProps {
    helperText?: string
    errorText?: string
    disabled?: boolean
    error?: boolean
    value?: string | number
    placeholder?: string
    icon?: string
    readonly?: boolean
    onChange?: (e: any) => void,
    name?: string
    onIconClick?: (arg: any) => void
    multiline?: boolean
    rows?: number
    inDropdown?: boolean
    filter?: boolean
    maxLength?: number
}

const InputField: FC<InputFieldProps> = ({
    helperText,
    errorText,
    disabled=false,
    error=false,
    value="",
    placeholder="",
    icon,
    readonly=false,
    onChange,
    name="",
    onIconClick,
    rows,
    multiline,
    inDropdown,
    filter,
    maxLength
}) => {
    
    const classes = useStyles()

    return (
        <FormControl 
            fullWidth 
        >
            <InputBase 
                className={(clsx({
                    [classes.error]: error,
                    [classes.inputBase]: true,
                    [classes.readonly]: readonly,
                    [classes.multiline]: multiline,
                    [classes.inputMarginDropdown]: inDropdown,
                    [classes.filter]: filter,
                }))}
                classes={{disabled: classes.disabled}}
                disabled={disabled}
                value={value}
                name={name}
                placeholder={placeholder}
                endAdornment={icon && 
                    <img 
                        src={icon} 
                        alt="icon" 
                        onClick={onIconClick}
                        className={classes.icon}
                    />
                }
                readOnly={readonly}
                onChange={onChange}
                multiline={multiline}
                rows={rows}
                inputProps={{
                    maxLength: maxLength
                }}
            />
            {helperText && <FormHelperText 
                variant="outlined" 
                disabled={disabled}
                className={classes.helperText}
            >
                {helperText}
            </FormHelperText>} 
            {error && <FormHelperText 
                variant="outlined" 
                error={error} 
                disabled={disabled}
                className={classes.helperText}
            >
                {errorText}
            </FormHelperText>}   
        </FormControl>       
    )
}

export default InputField