import {
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme?: any)=>({
    dropdown: {
        color: theme.palette.onSurface.highEmphasis,
        width: "200px",
        marginTop: theme.spacing(4),
        boxShadow: theme.shadows[2],
        borderRadius: `0px 0px ${theme.shape.sharpBorderRadius} ${theme.shape.sharpBorderRadius}`
    },
    item: {
        height: "32px",
        "&:hover": {
            background: theme.palette.primary.hover
        }
    },   
}))

interface SmallDropdownProps {
    open: boolean
    menuItems: {name: string, function?: ()=>any}[]
    setOpen: (open: boolean) => void
    anchorEl: any
}

const SmallDropdown: FC<SmallDropdownProps> = ({open, menuItems, setOpen, anchorEl}) => {
    const classes = useStyles()

    return (
        <Popper 
              open={open}
              anchorEl={anchorEl}
              transition
              disablePortal
              placement='bottom-end'
            >     
              <Paper className={classes.dropdown}>
                <ClickAwayListener 
                    onClickAway={()=>setOpen(false)}
                >
                  <MenuList>
                      {
                          menuItems.map((menuItem, index)=>{
                          return (
                              <MenuItem key={index} className={classes.item} onClick={()=>menuItem.function && menuItem.function()}>
                                  <Typography variant="body2">
                                    {menuItem.name}
                                  </Typography>
                              </MenuItem>
                          )
                          })
                      }
                  </MenuList>
                </ClickAwayListener >
              </Paper>
            </Popper>           
    )
}

export default SmallDropdown