import {
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from 'clsx';

import googlePlay from './assets/google-play.png'
import appleStore from './assets/apple-store.png'

const useStyles = makeStyles((theme?: any) => ({
    label: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: theme.spacing(5),
        
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "424px",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            marginBottom: "40px",
            alignItems: "center"
        }
    },
    btn: {
        width: "200px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: "150px"
          }
    },
    btnMargin:{
        marginRight: theme.spacing(5),
    }

}))

interface DownloadAppBtnsProps {
    label?: string
}

const DownloadAppBtns: FC<DownloadAppBtnsProps> = ({
    label,
}) => {
    const classes = useStyles()


    return (
        <div>
            <Typography
                variant="subtitle2"
                className={classes.label}
            >
                {label}
            </Typography>
            <div className={classes.row}>
                <a href="https://play.google.com/store/apps/details?id=com.sideosmobile" target="_blank" rel="noopener noreferrer">
                <img
                    src={googlePlay}
                    className={clsx(classes.btn, classes.btnMargin)}
                    alt="google play icon"
                />
                </a>
                <a href="https://apps.apple.com/de/app/sideos-transponder/id1611001158?l=en" target="_blank" rel="noopener noreferrer">
                <img
                    src={appleStore}
                    className={classes.btn}
                    alt="apple store icon"
                />
                </a>
            </div>
        </div>
    )
}

export default DownloadAppBtns