import { useEffect, useState, FC  } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import {
  getWalletList,
  updateWalletStatus,
} from '../actions/wallets';
import {
  Typography,
  Divider
} from '@material-ui/core';
import { hasPermissions, parseDateAndTime } from '../commons';
import { useTranslation } from 'react-i18next';
import { Wallet } from '../commons/types'
import { 
  allowedPermissions, 
  incomingRole 
} from '../commons/permissions';

import Search from '../commons/components/Search';
import Button from '../commons/components/Button'
import SimpleTable from '../commons/components/SimpleTable';
import Toggle from '../commons/components/Toggle';
import TableDateAndTime from '../commons/components/TableDateAndTime'
import StatusBadge from '../commons/components/StatusBadge';
import Breadcrumbs from '../commons/components/Breadcrumbs';
import DisableModal from '../commons/components/DisableModal';
import { isFlag } from '../commons/utils';

import CollapseTable from '../commons/components/CollapseTable'

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  containerColumn: {
    height: "auto"
  },
  row: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  searchMargin: {
    width: "100%",
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    }
  },
  search: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    }
  },
  title: {
    color: theme.palette.onSurface.highEmphasis
  },
  btnContainer: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: theme.spacing(6)
    }
  },
  display: {
    color: theme.palette.onSurface.mediumEmphasis
  },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface WalletsProps extends PropsFromRedux { }

const Wallets: FC<WalletsProps> = ({
  getWalletList,
  updateWalletStatus,
  wallets,
  wallet,
  permissions,
  mainContact
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [matches, setMatches] = useState<{ [name: string]: string | number }[]>([])
  const [input, setInput] = useState("")
  const [clickedRow, setClickedRow] = useState<any>("")
  const DISABLED = 1
  const INUSE = 4
  const DELETED = 8

  const columns = {
    createdAt: t('wallets.created'),
    id: t('wallets.id'),
    name: t('wallets.name'),
    Customer: t('wallets.company'),
    type: t('wallets.type'),
    disable: t('wallets.enabled'),
    status: t('wallets.status')
  }

  useEffect(() => {
      getWalletList()
  }, [getWalletList, wallet])

  const getText = (status: number) => {
    let text
    if (isFlag(status, DELETED)) {
      text = t('statusText.deleted');
    } else if (isFlag(status, INUSE)) {
      text = t('statusText.used');
    } else {
      text = t('statusText.unused');
    }
    return text
  }

  const renderSearchMatches = (matches: [], input: string) => {
    setInput(input)
    setMatches(matches)
  }

  const handleCellClick = (wallet: any) => {
    if (hasPermissions(permissions, {admin: ["O"], wallets: ["RO"], customers: ["RO"]}) ||
      hasPermissions(permissions, {tenant: ["O"], wallets: ["R"], customers: ["R"]})
    ) {
      history.push(`/wallets/${wallet.id}`)
    }
  }

  const handleToggleClick = (e: any, item: Wallet) => {
    e.stopPropagation()
    setClickedRow(item)
  }

  const handleCancel = () => {
    setClickedRow("")
  }

  const handleAffirmative = () => {
    updateWalletStatus({
      WalletId: clickedRow.id,
      disable: isFlag(clickedRow.status, DISABLED) ? false : true
    })
    setClickedRow("")
  }


  return (
    <div className={clsx(classes.container)}>

      <Breadcrumbs />
      <Divider />

      <div className={classes.row}>
        <div className={
          (hasPermissions(permissions, {admin: ["O"], wallets: ["WO","RO"]}) ||
          hasPermissions(permissions, {tenant: ["O"], wallets: ["W","R"]})) ?
            classes.searchMargin : classes.search
          }
        >
          <Search
            rows={wallets}
            renderSearchMatches={renderSearchMatches}
          />
        </div>
        {(hasPermissions(permissions, {admin: ["O"], wallets: ["WO","RO"]}) ||
          hasPermissions(permissions, {tenant: ["O"], wallets: ["W","R"]})) ? 
            <div className={classes.btnContainer}>
            <Button
              text={t('wallets.createBtn')}
              version="secondary"
              size="medium"
              onClick={() => { history.push("/wallets/new") }}
            />
          </div>
          : <></>
        }
      </div>
      <SimpleTable
        deleted={DELETED}
        handleCellClick={(item) => handleCellClick(item)}
        columns={columns}
        rows={input !== "" ? matches : wallets}
        expandable={true}
        checkExpandableValue={()=>{return true}}
        tableCells={{
          disable: (item: any) => {
            return (
              <Toggle
                checked={!isFlag(item.status, DISABLED)}
                onClick={(e) => handleToggleClick(e, item)}
                disabled={
                    (isFlag(item.status, INUSE) || isFlag(item.status, DELETED)) || 
                    !allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) ||
                    (
                        !hasPermissions(permissions, {admin: ["O"], wallets: ["EO","RO"], customers: ["RO"]}) &&
                        !hasPermissions(permissions, {tenant: ["O"], wallets: ["E","R"],  customers: ["R"]})  
                    )
                    ? 
                    true : false
                }
              />
            )
          },
          createdAt: (item: any) => {
            return (
              <TableDateAndTime
                dateAndTime={parseDateAndTime(item.createdAt)}
              />
            )
          },
          status: (item: any) => {
            return (
              <StatusBadge
                status={getText(item.status)}
              />
            )
          },
          Customer: (item: any) => {
            return (
              <Typography variant="body2" className={classes.title}>
                {item.Customer?.name}
              </Typography>
            )
          },
          name: (item: any) => {
            return (
              <div className={classes.nameContainer}>
                <Typography
                  variant="body2"
                  className={classes.name}
                  onClick={() => handleCellClick(item)}
                >
                  {item.name}
                </Typography>
              </div>
            )
          },
          type: (item: any) => {
            return (
              <Typography
                  variant="body2"
                  className={classes.name}
                  onClick={() => handleCellClick(item)}
                >
                  {item.did.includes(":sideos") ? t('wallets.public') : t('wallets.private')}
                </Typography>
            )
          }
        }}
        collapseElement={(item) =>
          <CollapseTable
              titles={[
                { prop: "did", title: t('wallet.did') },
                { prop: "password", title: t('wallet.publicKeyTitle') }
              ]}
              item={item}
              tableCells={{
                did: (item: any) => {
                  return (
                    <div
                    className={classes.display}
                  
                    >
                      <Typography
                        variant="body1"
                      >
                        {item.did}
                      </Typography>
                    </div>
                  )
                },
                password: (item: any) => {
                  return (
                    <Typography
                      variant="body1"
                      className={classes.display}
                    >
                     {item.password}
                    </Typography>
                  )
                }
              }}
            />
          }
      />
      <DisableModal
        open={clickedRow ? true : false}
        disabled={isFlag(clickedRow.status, DISABLED) ? true : false}
        onCancel={handleCancel}
        onAffirmative={handleAffirmative}
        walletName={clickedRow.name}
      />
     
    </div>
  );
}

const mapState = (state: RootState) => ({
  wallets: state.wallets.wallets,
  wallet: state.wallets.wallet,
  permissions: state.login.permissions,
  mainContact: state.login.mainContact
})

const mapDispatch = {
  getWalletList: () => (getWalletList()),
  updateWalletStatus: (data: Object) => (updateWalletStatus(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(Wallets)
