import { AnyAction } from 'redux'

const initialState = {
    token: localStorage.getItem('token'),
    permissions: JSON.parse(localStorage.getItem('permissions') || '{}' ),
    mainContact: JSON.parse(localStorage.getItem('mainContact') || '{}' ),
  };

  type LoginReducer = typeof initialState
  
export default function loginReducer(state = initialState, action: AnyAction): LoginReducer {
    switch (action.type) {
      case 'PERMISSIONS': {
        return {
          ...state,
          permissions: action.payload
          }
      }
      case 'USER': {
        return {
          ...state,
          mainContact: action.payload
          }
      }
      case 'STORE_TOKEN': {
        return {
          ...state,
          token: action.payload
        }
      }
      default:
        return state;
    }
  }
  