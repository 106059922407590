import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    TableFooter,
    TableSortLabel,
    Collapse,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import clsx from 'clsx';
import upwardArrowIcon from './assets/upward-arrow-icon.svg'
import downwardArrowIcon from './assets/downward-arrow-icon.svg'
import expandRowIcon from './assets/gray-arrow-down.svg'
import collapseRowIcon from './assets/purple-arrow-up.svg'

import TCell from './TCellContent';
import { isFlag } from '../utils';

const useStyles = makeStyles((theme?: any)=>({
    container: {
        width: "100%",
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        borderRadius: theme.shape.borderRadius,
    },
    title: {
        color: theme.palette.onSurface.highEmphasis
    },
    column: {
        borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0px 0px`,
        padding: theme.spacing(5, 6),
        "&:hover $tableSortLabel": {
            opacity: 1
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 4),
            minWidth: "100px"
        }
    },
    footer: {
        color: theme.palette.onSurface.mediumEmphasis,
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing,
        borderBottom: "none",
        position: "relative",
        "& .MuiTablePagination-spacer": {
            display: "none"
        },
        "& .MuiTablePagination-toolbar": {
            display: "flex",
            justifyContent: "space-between",           
        },
        "& p:last-of-type": {
            position: "absolute",
            right: theme.spacing(12)
        },
        "& .MuiTablePagination-selectRoot": {
            position: "absolute",
            left: theme.spacing(12),
        },
        "& .MuiToolbar-gutters": {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        },
        "& .MuiInputBase-input": {
            fontSize: theme.typography.subtitle2.fontSize,
            marginTop: theme.spacing(3)
        },
    },
    menuItem: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    row: {
        display: "flex",
        alignItems: "center",
        "&:hover $sortingIconColumn": {
            display: "flex"
        },
        "&:hover $tableSortLabel": {
            display: "flex"
        }
    },
    cell: {
        padding: theme.spacing(3, 8, 3, 6),
        borderBottom: "none",
        overflowWrap: "anywhere",
        minWidth: "100px",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 4),
        }
    },
    sortingIcon: {
        height: "12px",
        width: "10px",
    },
    sortingIconColumn: {
        flexDirection: "column",
        marginLeft: theme.spacing(4),
    },
    tableSortLabel: {
        display: "none",
        cursor: "default"
    },
    activeIcon: {
        filter: theme.palette.filter.blackToLightPurple,
        display: "flex",
        alignSelf: "center",
        top: "50%"
    },
    pointer: {
        cursor: "pointer"
    },
    collapsedRow: {
        padding: theme.spacing(0),
        borderCollapse: "collapse"
    },
    expandedRow: {
        background: theme.palette.primary.hover
    },
    tableRow: {
        "&.MuiTableRow-root": {
            cursor: "pointer",
            "&:hover": {
                background: theme.palette.primary.hover
            },
        }, 
    },
    icon: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "fit-content"
    },
    iconCell: {
        padding: theme.spacing(3, 8, 3, 6),
        borderBottom: "none",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 4),
        }
    },
    highlight: {
        animation: "$highlight 1s" 
    },
    "@keyframes highlight": {
        "0%": {
          backgroundColor: theme.palette.background.default,
        },
        "30%": {
            backgroundColor: theme.palette.primary[50],
        },
        "90%": {
            backgroundColor: theme.palette.primary[50],
        },
        "100%": {
            backgroundColor: theme.palette.background.default,
        }
      },
    emptyRow: {
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        height: "56px"
    },
    disabledRow: {
        backgroundColor: theme.palette.onSurface.disabledRow
    }
}))

interface SimpleTableProps {
    columns: any,
    rows: any[],
    tableCells?: {[name: string]: (row: any, item?: any) => ReactElement}
    handleCellClick?: (row: any) => any | void
    expandable?: boolean
    checkExpandableValue?: any
    collapseElement?: (item: any) => ReactElement
    rowAction?: (item: any) => ReactElement 
    highlight?: number | null
    columnTooltip?: {[name: string]: string}
    handleSortingBE?: (direction: string, columnName: string) => void,
    deleted?: number
}

const SimpleTable: FC<SimpleTableProps> = ({
    columns,
    rows,
    tableCells,
    expandable=false,
    checkExpandableValue,
    collapseElement,
    rowAction,
    highlight,
    columnTooltip,
    handleCellClick,
    handleSortingBE,
    deleted
}) => {
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [direction, setDirection] = useState<"asc" | "desc">("asc")
    const [orderedRows, setOrderedRows] = useState<{[name:string]: string | number}[]>([])
    const [activeIcon, setActiveIcon] = useState<{columnName: string, direction: "asc" | "desc" }>()
    const [rowExpanded, setRowExpanded] = useState<string | number>("")

    useEffect(()=>{
        setOrderedRows(rows)
        setPage(0)
    }, [rows])

    let emptyRow: any = {}
    Object.keys(columns).forEach((column: string)=>{
        emptyRow[column]=""
    })

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
      const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };
    
    const handleSorting = (columnName: string, direction: "asc" | "desc", index: number) => {
        setDirection(direction)
        setActiveIcon({columnName: columnName, direction: direction})
        let rowsArray: {[columnName: string]: string | number}[] = orderedRows
        let rowsInOrder: {
            [columnName: string]: string | number
        }[] = orderBy(rowsArray, 
            [row => typeof row[columnName] === "string" 
            ? 
            // @ts-ignore
            row[columnName].toLowerCase() 
            : 
            row[columnName]], [direction]
        )
        setOrderedRows([...rowsInOrder])
    }

    const handleBESorting = (direction: string, columnName: string) => {
        // @ts-ignore
        setActiveIcon({columnName: columnName, direction: direction.toLowerCase()})
        handleSortingBE && handleSortingBE(direction, columnName)
    }

    return (
        <TableContainer 
            component={Paper} 
            elevation={0} 
            className={classes.container}
        >
            <Table>
                <TableHead >
                    <TableRow>
                        {Object.keys(columns).map((column: string, index: number)=>{
                            return (
                                <TableCell key={index} className={classes.column} >
                                    <Tooltip 
                                        title={columnTooltip?.[column] ? <Fragment>{columnTooltip?.[column]}</Fragment> : ""}
                                        placement="bottom"
                                    >
                                    <Typography 
                                        variant="subtitle2" 
                                        className={classes.title}
                                    >
                                        <div className={classes.row}>
                                            {columns[Object.keys(columns)[index]]}                                            
                                            <div className={classes.sortingIconColumn}>
                                                <TableSortLabel
                                                    direction={direction} 
                                                    onClick={()=>{handleSortingBE ? 
                                                        handleBESorting("ASC", Object.keys(columns)[index]) 
                                                        : 
                                                        handleSorting(Object.keys(columns)[index], "asc", index)
                                                    }}
                                                    IconComponent={()=>
                                                        <img 
                                                            src={upwardArrowIcon} 
                                                            alt="upward arrow icon" 
                                                            className={classes.sortingIcon}
                                                        />
                                                    } 
                                                    classes={{active: classes.activeIcon, root: classes.tableSortLabel}}  
                                                    active={
                                                        activeIcon?.columnName === Object.keys(columns)[index] && 
                                                        activeIcon?.direction === "asc" 
                                                        ? 
                                                        true 
                                                        : 
                                                        false
                                                    }                                      
                                                />
                                                <TableSortLabel
                                                    direction={direction} 
                                                    onClick={()=>{handleSortingBE ? 
                                                        handleBESorting("DESC", Object.keys(columns)[index]) 
                                                        : 
                                                        handleSorting(Object.keys(columns)[index], "desc", index)
                                                    }}  
                                                    IconComponent={()=>
                                                        <img 
                                                            src={downwardArrowIcon} 
                                                            alt="downward arrow icon" 
                                                            className={classes.sortingIcon}
                                                        />
                                                    } 
                                                    classes={{active: classes.activeIcon, root: classes.tableSortLabel}}
                                                    active={
                                                        activeIcon?.columnName === Object.keys(columns)[index] && 
                                                        activeIcon?.direction === "desc" 
                                                        ? 
                                                        true 
                                                        : 
                                                        false
                                                    }                                      
                                                />
                                            </div>
                                        </div>
                                    </Typography>
                                </Tooltip>
                                </TableCell>
                            )
                        })}
                        {expandable && 
                            <TableCell 
                                padding="checkbox" 
                            />
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderedRows.length !== 0 ?
                        orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: {[name: string]: string | number}, index: number)=>{
                                 return (
                                        <Fragment key={row.id}>
                                            <TableRow key={row.id} className={clsx(classes.tableRow, {
                                                [classes.expandedRow]: rowExpanded === row.id,
                                                [classes.highlight]: highlight === row.id,
                                                [classes.disabledRow]: deleted && isFlag(row.status, deleted)
                                            })}>
                                                {
                                                    Object.keys(columns).map((column, index)=>{
                                                        return (
                                                            <TableCell 
                                                                className={classes.cell} 
                                                                onClick={()=>handleCellClick && handleCellClick(row)}
                                                                key={index}
                                                            >
                                                                <TCell 
                                                                    row={row} 
                                                                    column={column} 
                                                                    tableCells={tableCells}
                                                                />
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            {
                                                expandable && checkExpandableValue(row) ?
                                                <TableCell className={classes.iconCell}>
                                                    <div className={classes.icon}>
                                                    {
                                                        rowExpanded === row.id
                                                        ?
                                                        <img
                                                            src={collapseRowIcon}
                                                            alt="purple arrow up"
                                                            onClick={()=>setRowExpanded("")}
                                                        />
                                                            :
                                                        <img
                                                            src={expandRowIcon}
                                                            alt="gray arrow down"
                                                            onClick={()=>setRowExpanded(row.id)}
                                                        />
                                                    }
                                                    </div>
                                                </TableCell>
                                                : 
                                                rowAction ?
                                                <TableCell className={classes.iconCell}>
                                                    { rowAction(row) }
                                                </TableCell>
                                                : null
                                            }
                                            </TableRow>
                                            <TableRow >
                                                <TableCell colSpan={Object.keys(columns).length + 1} className={classes.collapsedRow}>
                                                    <Collapse in={rowExpanded === row.id} >
                                                        {collapseElement && collapseElement(row)}
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>  
                                    )
                        })
                        :
                        <TableRow className={classes.emptyRow}>
                            {Object.keys(emptyRow).map((column, index)=>{
                                return (
                                    <TableCell className={clsx(classes.cell)} key={index}>
                                        <TCell row={emptyRow} column={column} tableCells={tableCells}/>
                                    </TableCell>
                                )
                            })}                       
                        </TableRow>
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination 
                            rowsPerPageOptions={[5, 10, 25]}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{root: classes.footer, menuItem: classes.menuItem}}
                        />
                    </TableRow>
                </TableFooter >
            </Table>
        </TableContainer>       
    )
}

export default SimpleTable