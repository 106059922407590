import { ReactChild, useEffect, useState, FC } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Sidebar from './Sidebar';
import Header from './Header'
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { getCompanyLogo, getProfilePicture } from '../actions/settings';
import { hasPermissions } from '../commons';
import { useLocation } from 'react-router';
import { getSettingsInfo } from '../actions/settings';
import useExitPrompt from '../commons/hooks/useUnsavedWarning';
import useQuery from '../commons/hooks/useQuery';

import ErrorSnackbar from '../commons/components/ErrorSnackbar';
import SuccessSnackbar from '../commons/components/SuccessSnackbar';
import TokenBanner from '../dashboard/TokenBanner';
import WalletDetailsModal from '../commons/components/modals/WalletDetailsModal'
import UnsavedWarningModal from '../commons/components/UnsavedWarningModal';
import WelcomeModal from '../dashboard/WelcomeModal';
import ProcessingModal from '../commons/components/ProcessingModal';
import { openModal } from '../actions/modals';
import PlanUpgradeSuccessSnackbar from '../commons/components/PlanUpgradeSuccessSnackbar';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    minHeight: "100%",
    background: theme.palette.background.contentContainer,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5)
   }
  },
  lilaContainer: {
    backgroundColor: "#F4F3F8",
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "0px"
   }
  },
  contentContainer: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    maxWidth: "1440px",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7,5,5,5)
    }
  },
  openDrawer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(14),
    }
  },
  closedDrawer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(9),
    }
  },
  bannerDiv: {
    height: "48px"
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface LayoutProps extends PropsFromRedux {
  children: ReactChild
}

const Layout: FC<LayoutProps> = ({
  mainContact,
  getCompanyLogo,
  getProfilePicture,
  companyLogo,
  profilePicture,
  children,
  permissions,
  getSettingsInfo,
  settingsInfo,
  editMode,
  openModal,
  processingUpgrade
}) => {
  const [openDrawer, setOpenDrawer] = useState(true)
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false)
  const [username, setUsername] = useState('')
  const [lastname, setLastname] = useState('')
  const [companyName, setCompanyName] = useState('')
  const classes = useStyles()
  const location = useLocation()
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(editMode)
  const query = useQuery()
  const upgradeSuccess = query.get("state")
  const toUpgrade = mainContact?.Customer?.chargebee?.toUpgrade
  const [customerLogo, setCustomerLogo] = useState<string | null>(null)

  useEffect(()=>{
    getSettingsInfo(mainContact.id)
    setUsername(mainContact.name)
    setLastname(mainContact.lastName)
    setCompanyName(mainContact?.Customer?.name)
  }, [mainContact])

  useEffect(() => {
    setCustomerLogo(settingsInfo.maincontact?.Customer?.logo)
}, [settingsInfo])

  useEffect(() => {
    if (customerLogo && hasPermissions(permissions, {customers: ["R"]})) {
      getCompanyLogo(mainContact.Customer.id)
    }
    if (settingsInfo?.maincontact?.image && hasPermissions(permissions, {mainContacts: ["R"]})) {
      getProfilePicture(mainContact.id)
    }
  }, [mainContact, getCompanyLogo, getProfilePicture, customerLogo])

  useEffect(() => {
    // @ts-ignore
    setShowExitPrompt(editMode)
  }, [editMode])

  useEffect(()=>{
    //@ts-ignore
    window.FreshworksWidget('show');
    //@ts-ignore
    window.FreshworksWidget('disable', 'ticketForm', ['email', 'name'])

    //@ts-ignore
    window.FreshworksWidget('identify', 'ticketForm', {
      name: mainContact.name + " " + mainContact.lastName,
      email: mainContact.email
      });   
  }, [])

  useEffect(()=>{
    const welcomeTrial = localStorage.getItem("trialWelcome")
    const securityImg = localStorage.getItem("securityImage")
    if (welcomeTrial) {
      openModal({modalName: "welcome"})
    } else if (!securityImg) {
      openModal({modalName: "welcome"})
    } else if (!welcomeTrial && toUpgrade && toUpgrade !== 1 && upgradeSuccess !== "succeeded" && mainContact.main) {
      openModal({modalName: "welcome", data: {active: true}})
    }
  }, [mainContact])

  return (
    <>
      <Sidebar
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        openMobileDrawer={openMobileDrawer}
        setOpenMobileDrawer={() => setOpenMobileDrawer(!openMobileDrawer)}
        username={username}
        lastname={lastname}
        companyLogo={customerLogo && companyLogo}
        profilePicture={settingsInfo?.maincontact?.image ? profilePicture : ""}
      />
      <Paper
        elevation={0}
        className={clsx(classes.container, {
          [classes.openDrawer]: openDrawer,
          [classes.closedDrawer]: !openDrawer
        })}
      >
        <div className={location.pathname === "/settings/billing/plans" ? classes.lilaContainer : classes.contentContainer}>
          {!hasPermissions(permissions, { admin: "O" }) && 
            hasPermissions(permissions, {
              mainContacts: ["R"],
              customers: ["R"],
              plans: ["R"],
              wallets: ["R"]
            }) &&
            location.pathname === "/" &&
            !settingsInfo?.maincontact?.Customer?.Wallet?.accessToken &&
            <TokenBanner
              openDrawer={openDrawer}
              globalAdmin={mainContact.main}
            />
          }
          <Header
            username={username}
            lastname={lastname}
            companyName={companyName}
            setOpenMobileDrawer={setOpenMobileDrawer}
            openMobileDrawer={openMobileDrawer}
            profilePicture={settingsInfo?.maincontact?.image ? profilePicture : ""}
            companyLogo={companyLogo}
            openDrawer={openDrawer}
            counter={mainContact.assignedOn}
          />
          {!hasPermissions(permissions, { admin: "O" }) &&
            location.pathname === "/" &&
            !settingsInfo?.maincontact?.Customer?.Wallet?.accessToken &&
            <div className={classes.bannerDiv} />
          }
          {children}
        </div>
        <ErrorSnackbar />
        <SuccessSnackbar />
        <PlanUpgradeSuccessSnackbar />
        <WalletDetailsModal />
        <UnsavedWarningModal
          when={editMode}
          onCancel={() => false}
          onOK={() => true}
        />
        <WelcomeModal />
       {processingUpgrade.open && <ProcessingModal />}
      </Paper>
    </>
  );
}

const mapState = (state: RootState) => ({
  mainContact: state.login.mainContact,
  profilePicture: state.settings.profilePicture,
  companyLogo: state.settings.companyLogo,
  permissions: state.login.permissions,
  settingsInfo: state.settings.settingsInfo,
  editMode: state.dashboard.editMode,
  processingUpgrade: state.modals.processingUpgrade,
})

const mapDispatch = {
  getProfilePicture: (data: number) => (getProfilePicture(data)),
  getCompanyLogo: (data: number) => (getCompanyLogo(data)),
  getSettingsInfo: (data: number) => (getSettingsInfo(data)),
  openModal: (data: Object) => (openModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(Layout)
