import { FC, useState, useEffect } from "react"
import { makeStyles } from '@material-ui/styles';
import {
    Button,
} from '@material-ui/core';
import { setImageFromBlob } from "../commons/utils";
import clsx from "clsx";

const useStyles = makeStyles((theme?: any) => ({
    button: {
        height: "40px",
        width: "40px",
        borderRadius: theme.shape.circle,
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(0),
        minWidth: "40px",
        "& .MuiButton-startIcon": {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0)
        },
        "& .MuiButton-label": {
            width: "auto",
        }, 
        "&:focus": {
            outline: "none",
        }      
    },
    logo: {
        height: "40px",
        width: "40px",
        borderRadius: theme.shape.circle,
    },
    menuIcon: {
        height: "20px",
        width: "20px",
        // transform: "rotate(90deg)"
    }
}))

interface MenuItemButtonProps {
    open?: boolean
    handleClick?: any
    transform?: boolean
    icon: string
    logo?: any
    inMobile?: boolean
  }

const MenuItemLogo: FC<MenuItemButtonProps> = ({
    open, 
    handleClick, 
    transform, 
    icon, 
    logo,
    inMobile
}) => {
    const classes = useStyles()
    const [src, setSrc] = useState("")
    
        useEffect(()=>{       
            setImageFromBlob(logo, setSrc)    
        }, [logo])

    return (
        <Button
            disableRipple
            startIcon={
                    <img 
                        src={logo ? src : icon} 
                        alt="menu icon"
                        className={clsx(classes.logo, {
                            [classes.menuIcon]: !logo,
                        })}
                    />               
            }
            onClick={()=>handleClick && handleClick()}
            classes={{root: classes.button}} 
        />
    )
}

export default MenuItemLogo
