import { useState, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { storePermissions, storeUser, storeToken } from "../actions/login";
import API from "../api";
import sideosLogo from "../commons/assets/icons/logo-sideos-white.svg";
import refreshIcon from "../commons/assets/icons/refresh-icon-white.svg";
import { Divider, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router";
import { RootState } from "../store";
import { QRCode } from "react-qrcode-logo";
import DawBtn from "../commons/components/DawButton";

import googlePlay from "./assets/googlePlayBlack.png";
import appleStore from "./assets/appleStoreBlack.png";
import chromeStore from "./assets/chrome-webstore.png";
import ffStore from "./assets/ff-webstore.png";
import Loading from "../commons/components/Loading";

const useStyles = makeStyles((theme: any) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		background: theme.palette.gradient.purple,
		padding: theme.spacing(8),
		minHeight: "100%",
	},
	signincard: {
		[theme.breakpoints.up("sm")]: {
			width: "407px",
			height: "601px",
			padding: theme.spacing(7),
		},
		[theme.breakpoints.down("sm")]: {
			height: "90%",
			"& a h6": {
				width: "250px",
			},
		},
		padding: theme.spacing(7),
		background: theme.palette.onSurface.highEmphasis,
		boxShadow: theme.shadows[3],
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		justifyContent: "space-between",
		alignItems: "center",
		transition: "transform .2s",
		width: "auto",
		height: "auto",
		maxWidth: "90%",
		maxHeight: "90%",
		[theme.breakpoints.down("xs")]: {
			"& a h6": {
				width: "250px",
			},
		},
	},
	qrCodeContainer: {
		transition: "transform .2s",
		background: theme.palette.background.default,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		height: "fit-content",
		width: "fit-content",
		"& #react-qrcode-logo": {
			borderRadius: theme.shape.loginRadius,
		},
	},
	link: {
		color: theme.palette.primary.contrastText,
		width: "250px",
		height: "42px",
		borderRadius: theme.shape.borderRadius,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	btnLink: {
		textDecoration: "none",
	},
	barContainer: {
		display: "flex",
		width: "208px",
		alignItems: "baseline",
		justifyContent: "space-around"
	},
	counter: {
		fontWeight: theme.typography.subtitle2.fontWeight,
		color: theme.palette.text.secondary,
	},
	refresh: {
		textDecoration: "underline",
		cursor: "pointer",
		color: theme.palette.text.secondary,
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: theme.spacing(0),
		left: theme.spacing(0),
		right: theme.spacing(0),
		bottom: theme.spacing(0),
		backgroundColor: theme.palette.surface.darkOverlay,
		zIndex: 2,
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	lightOverlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: theme.spacing(0),
		left: theme.spacing(0),
		right: theme.spacing(0),
		bottom: theme.spacing(0),
		backgroundColor: theme.palette.surface.overlay,
		zIndex: 2,
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			fontSize: "17px",
		},
	},
	qrCode: {
		borderRadius: theme.shape.loginRadius,
	},
	secondaryTextColor: {
		color: theme.palette.text.secondary,
	},
	containerBtn: {
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "290px",
		},
	},
	label: {
		color: "rgba(255, 255, 255, 0.74)",
		textTransform: "uppercase",
		lineHeight: "50px",
	},
	row: {
		display: "flex",
		justifyContent: "space-around",
		maxWidth: "424px",
		gap: "24px"
	},
	btn: {
		width: "200px",
		cursor: "pointer",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "135px",
		},
	},
	sideosLink: {
		color: "rgba(255, 255, 255, 0.74)",
		textDecoration: "underline",
	},
	anchor: {
		lineHeight: "40px",
		fontWeight: 700,
		color: theme.palette.onPrimary.mediumEmphasis,
	},
	btnMargin: {
		marginRight: theme.spacing(5),
		[theme.breakpoints.down("sm")]: {
			marginRight: theme.spacing(5),
		},
	},
	dividerRow: {
		display: "flex",
		alignItems: "center"
	},
	divider: {
		backgroundColor: "rgba(193, 199, 205, 0.2)",
		width: "45%"
	}
}));

type PropsFromRedux = ConnectedProps<typeof connector>;
interface LoginProps extends PropsFromRedux {}

function Login(props: LoginProps) {
	const [qrcode, setQrcode] = useState("");
	const [error, setError] = useState("");
	const [counter, setCounter] = useState(0);
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const [securityImg, setSecurityImg] = useState("");
	const [loading, setLoading] = useState(false)

	const countRef = useRef(counter);
	countRef.current = counter;

	useEffect(() => {
		setLoading(true)
		const socket = new WebSocket(
			process.env.REACT_APP_SOCKET_URL +
				"/api/login/" +
				process.env.REACT_APP_API_VERSION || "ws://localhost:3200/api/login"
		);
		socket.onmessage = (e) => onMessage(e);
		socket.onerror = (e: any) => {
			setError(e);
		};
		socket.onopen = (e: any) => {
			socket.send(JSON.stringify({ login: "request" }));
		};
		return function cleanup() {
			clearTimeout(timeout);
			socket.close();
		};
	}, []);

	useEffect(() => {
		const securityImage = localStorage.getItem("securityImage");
		if (securityImage) {
			setSecurityImg(securityImage);
		}
	}, []);

	let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

	const decrement = (countRefCurrent: { current: number }) => {
		setCounter(countRefCurrent.current - 1);
		if (countRefCurrent.current > 0) {
			timeout = setTimeout(() => decrement(countRefCurrent), 1000);
		}
	};

	const onMessage = (message: any) => {
		try {
			const parsed = JSON.parse(message.data);
			if (parsed.token) {
				API.setAuthToken(parsed.token);
				history.push("/");
				localStorage.setItem("token", parsed.token);
				localStorage.setItem("permissions", JSON.stringify(parsed.permissions));
				localStorage.setItem("mainContact", JSON.stringify(parsed.mainContact));
				props.storePermissions(parsed.permissions);
				props.storeUser(parsed.mainContact);
				props.storeToken(parsed.token);
			}
			if (parsed.qrcode) {
				setLoading(false)
				setQrcode(parsed.qrcode);
				setCounter(60);
				timeout = setTimeout(() => decrement(countRef), 1000);
			}
		} catch (e) {}
	};

	const renderCounter = () => {
		let c = counter % 60;
		return c < 10 ? "0" + c : c;
	};

	return (
		<div className={classes.container}>
			<Paper className={classes.signincard}>
				<a
					href="https://www.sideos.io/"
					target="_blank">
					<img
						src={sideosLogo}
						alt="logo"
					/>
				</a>
				<Typography
					variant="h5"
					className={classes.title}>
					{t("Login.title")}
				</Typography>

				<Typography
					variant="subtitle1"
					className={classes.secondaryTextColor}>
					{t("Login.subtitle")}
				</Typography>
				{counter ? (
					<div className={classes.barContainer}>
						<Typography
							variant="body2"
							className={classes.secondaryTextColor}>
							{t("Login.expiration")}
						</Typography>
						<Typography
							variant="subtitle1"
							className={classes.counter}>
							{counter === 60 ? "01" : "00"}:{renderCounter()}
						</Typography>
					</div>
				) : (
					<div
						className={classes.refresh}
						onClick={() => {
							window.location.reload();
						}}>
						<Typography
							variant="subtitle1"
							className={classes.secondaryTextColor}>
							{t("Login.refresh")}
						</Typography>
					</div>
				)}
				<Paper className={classes.qrCodeContainer}>
					{!counter && (
						<div className={classes.overlay}>
							{!loading ?
							<img
								src={refreshIcon}
								alt="refresh-icon"
								onClick={() => {
									window.location.reload();
								}}
							/>
							:
							<Loading />
						}
						</div>
					)}
					<QRCode
						value={qrcode}
						size={200}
						quietZone={25}
						logoImage={securityImg}
						removeQrCodeBehindLogo={true}
					/>
				</Paper>
				<a
					href={"ssi.interaction://interaction?jwt=" + qrcode}
					className={classes.btnLink}
					target="_blank"
					rel="noopener noreferrer">
					<Typography
						variant="subtitle1"
						className={classes.link}>
						{t("Login.link")}
					</Typography>
				</a>
				<div className={classes.dividerRow}>
					<Divider className={classes.divider} />
					<Typography
						variant="subtitle1"
						className={classes.link}>
						{t("Login.or")}
					</Typography>
					<Divider className={classes.divider}/>
				</div>
				<DawBtn
					qrcode={qrcode}
					text={t('Login.daw')}
					id="dawRequestCredential"
					disabled={!counter || !qrcode ? true : false}
				/>
			</Paper>
			<Typography
					variant="caption"
					className={classes.label}>
					{t("Login.register")}{" "}
					<a
						href="https://juno.sideos.io/plan-onboarding/1"
						target="_blank"
						className={classes.anchor}>
						{t("Login.signup")}
					</a>
				</Typography>
			<div className={classes.containerBtn}>
				<Typography
					variant="caption"
					className={classes.label}>
					{t("Login.download")}:
				</Typography>
				<div className={classes.row}>
					<a
						href="https://play.google.com/store/apps/details?id=com.sideosmobile"
						target="_blank"
						rel="noopener noreferrer">
						<img
							src={googlePlay}
							className={clsx(classes.btn, classes.btnMargin)}
						/>
					</a>
					<a
						href="https://apps.apple.com/de/app/sideos-transponder/id1611001158?l=en"
						target="_blank"
						rel="noopener noreferrer">
						<img
							src={appleStore}
							className={classes.btn}
						/>
					</a>
				</div>
				<div className={classes.dividerRow}>
					<Divider className={classes.divider} />
					<Typography
						variant="subtitle1"
						className={classes.link}>
						{t("Login.or")}
					</Typography>
					<Divider className={classes.divider}/>
				</div>
				<div className={classes.row}>
					<a
							href="https://chrome.google.com/webstore/detail/sideos-wallet/jkbmiicjfjdcladaolihpchddapkejph"
							target="_blank"
							rel="noopener noreferrer">
							<img
								src={chromeStore}
								className={classes.btn}
							/>
						</a>
						<a
							href="https://addons.mozilla.org/en-US/firefox/addon/sideos-wallet/"
							target="_blank"
							rel="noopener noreferrer">
							<img
								src={ffStore}
								className={classes.btn}
							/>
						</a>
				</div>
			</div>
		</div>
	);
}

const mapState = (state: RootState) => ({});

const mapDispatch = {
	storePermissions: (data: string) => storePermissions(data),
	storeUser: (data: string) => storeUser(data),
	storeToken: (data: string) => storeToken(data),
};

const connector = connect(mapState, mapDispatch);

export default connector(Login);
