import { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { 
    Paper,
    Typography,
    Tooltip,
    Divider
 } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { FC } from 'react';
import { setEditingMode } from '../actions/dashboard';
import { useTranslation } from 'react-i18next';
import { getProfilePicture, updateMainContactSettings } from '../actions/settings';
import clsx from 'clsx';
import { getSettingsInfo } from '../actions/settings';
import { hasPermissions } from '../commons';
import InputSelect from '../commons/components/InputSelect';
import { 
    roleNames,
    getObjKey,
    ROLES,
    incomingRole
 } from '../commons/permissions';

import editIcon from './assets/icons/edit-icon.svg'
import editIconDisabled from './assets/icons/edit-icon-disabled.svg'
import lockIcon from './assets/icons/lock-icon.svg'

import IconBtn from '../commons/components/IconButton';
import Button from '../commons/components/Button';
import InputField from '../commons/components/Input';
import ImgSettings from './ImgSettings';
import SecurityImg from './SecurityImg';
import DelegateModal from './DelegateModal';
import { openModal } from '../actions/modals';


const useStyles = makeStyles((theme?: any)=>({
    formPaper: {
        marginTop: theme.spacing(6),
        width: "90%",
        padding: theme.spacing(7),
        border: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
            marginRight: "unset",
            padding: theme.spacing(6),
            height: "auto"
        }
    },
    editMode: {
        borderColor: theme.palette.onSurface.highEmphasis,
    },
    title: {
        color: theme.palette.onSurface.highEmphasis,
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4)
        }
    },
    spaceBetweenRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },
    column: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "47%",
        position: "relative", 
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginBottom: theme.spacing(3)
    },
    linkContainer: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        color: theme.palette.onSurface.highEmphasis
    },
    columns: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    divider: {
        margin: theme.spacing(0, 8)
    },
    field: {
         marginBottom: theme.spacing(5)
    },
    inputAndBtn:{
        width: "100%",
        marginRight: theme.spacing(5)
    },
    row:{
        display: "flex",
        justifyContent: "space-between",
    },
    spacingTop:{
        paddingTop: theme.spacing(6)
    },
    noMargin: {
        marginRight: theme.spacing(0),
        width: "100%"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CompanySettingsProps extends PropsFromRedux {}

const MySettings: FC<CompanySettingsProps> = ({
    mainContact,
    updateMainContactSettings,
    getProfilePicture,
    profilePicture,
    setEditingMode,
    settingsInfo,
    getSettingsInfo,
    permissions,
    delegate,
    openModal
}) => {
  const classes = useStyles()
  const [disabledInput, setDisabledInput] = useState(true)
  const { t } = useTranslation()
  const [fields, setFields] = useState({
      name: "",
      lastName: "",
      role: "",
      email: "",
      permission: null
  })
  const [mcProfile, setMcProfile] = useState<string|null>(null)
  const roles = [
      {role: "Business Development"},
      {role: "Customer Care"},
      {role: "Data & Analytics"},
      {role: "Design"},
      {role: "Engineering"},
      {role: "Finance"},
      {role: "Legal"},
      {role: "Marketing"},
      {role: "Office Management"},
      {role: "Operations"},
      {role: "Other"},
      {role: "People"},
      {role: "Product"},
      {role: "Sales"}
  ]

  useEffect(() => {
    if (mainContact) {
      getSettingsInfo(mainContact.id)
    }
  }, [mainContact, getSettingsInfo])

  useEffect(()=>{
    setFields({
        name: settingsInfo?.maincontact?.name || "",
        lastName: settingsInfo?.maincontact?.lastName || "",
        role: settingsInfo?.maincontact?.role || "",
        email: settingsInfo?.maincontact?.email || "",
        //@ts-ignore
        permission: hasPermissions(permissions, {trial: "O"}) ? roleNames?.main : roleNames[getObjKey(ROLES[incomingRole(permissions)], mainContact?.User?.Permissions?.[0]?.id)]
    })
    setMcProfile(settingsInfo?.maincontact?.image)
}, [settingsInfo, permissions, mainContact])

useEffect(()=>{
    if (mcProfile && mainContact && hasPermissions(permissions, {mainContacts: ["R"]})) {
        getProfilePicture(mainContact?.id)
    }
}, [mcProfile, mainContact, getProfilePicture, permissions])

useEffect(()=>{
    if (!disabledInput) {
        setEditingMode(true)
    }
    return () => {
        setEditingMode(false)
    }
}, [disabledInput, setEditingMode])

  const handleEdit = () => {
      setDisabledInput(false)
  }

  const handleInputChange = (e: any) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSelect = (name: string, value: any) => {
      setFields({...fields, [name]: value.role})
  }

  const handleSave = () => {
    updateMainContactSettings({
        MainContactId: mainContact?.id,
        name: fields.name,
        lastName: fields.lastName,
        role: fields.role
    })
    setDisabledInput(true)
   }

  return (
    <Paper className={clsx(classes.formPaper, {
            [classes.editMode]: !disabledInput
        })} 
        elevation={0}
    >
        <div className={classes.spaceBetweenRow}>
            <Typography variant="h5" className={classes.title}>
                {t('settings.admin.title')}
            </Typography>
            {(hasPermissions(permissions, {
                admin: ["O"],
                mainContacts: ["E","R"],
                customers: ["R"],
                wallets: ["R"]
            }) || 
            hasPermissions(permissions, {mainContacts: ["E","R"],
                customers: ["R"],
                plans: ["R"],
                wallets: ["R"]})) 
            ?
                <>
                    {disabledInput 
                    ?
                    <IconBtn 
                        icon={editIcon}
                        iconDisabled={editIconDisabled}
                        onClick={()=>handleEdit()}
                    />
                    :
                    <Button 
                        onClick={()=>handleSave()} 
                        text={t('general.Save')}
                        width="110px"
                        size="medium"
                    />}
                </>
            : 
            <></>
            }
        </div>
        <div className={classes.columns}>
            <div className={classes.column}>
            <ImgSettings 
                imgSrc={mcProfile}
                imgPlaceholder={t('settings.admin.imgPlaceholder')}
                buttonText={t('settings.admin.uploadButton')}
                profileSrc={profilePicture}
                logo={false}
                titlePreview={t('settings.admin.previewTitle')}
            />
                <div className={classes.field}>
                <Typography 
                    variant="subtitle2" 
                    className={classes.label}
                >
                    {t('settings.admin.name')}
                </Typography>
                <InputField 
                    disabled={disabledInput}
                    value={fields.name}
                    onChange={handleInputChange}
                    name="name"
                    placeholder={t('settings.admin.name')}
                />
                </div>
                <div className={classes.field}>
                <Typography 
                    variant="subtitle2" 
                    className={classes.label}
                >
                    {t('settings.admin.lastName')}           
                </Typography>
                    <InputField 
                        disabled={disabledInput}
                        value={fields.lastName}
                        onChange={handleInputChange}
                        name="lastName"
                        placeholder={t('settings.admin.lastName')}
                    />
                </div>
                <div className={classes.field}>
                <Typography 
                    variant="subtitle2" 
                    className={classes.label}
                >
                    {t('settings.admin.email')}                   
                </Typography>
                <Tooltip 
                    title="Email address can't be changed"
                    placement="top-end"
                >
                    <div >
                    <InputField 
                        disabled={true}
                        value={fields.email}
                        placeholder={t('settings.admin.email')}
                        icon={lockIcon}
                    />
                    </div>
                </Tooltip>
                </div>
                <div className={classes.field}>
                <Typography 
                    variant="subtitle2" 
                    className={classes.label}
                >
                    {t('settings.admin.role')}                   
                </Typography>
                <InputSelect 
                    disabled={disabledInput}
                    value={fields.role}
                    setValue={handleSelect}
                    name="role"
                    placeholder={t('settings.admin.role')}
                    menuItems={roles}
                    renderValue="role"
                /> 
                <div className={classes.spacingTop}>
                    <Typography 
                        variant="subtitle2" 
                        className={classes.label}
                    >
                        {t('settings.admin.permissions')}                   
                    </Typography>
                    <div className={classes.row}>
                        <div className={mainContact.main ? classes.inputAndBtn : classes.noMargin}>
                            <InputField 
                                //@ts-ignore
                                value={fields?.permission?.name || ""}
                                disabled={true}
                            />
                        </div>
                        {mainContact.main && <Button 
                            text={t('settings.admin.delegateBtn')} 
                            version="tertiary"
                            onClick={()=>{openModal({modalName: 'delegate'})}}
                        />}     
                    </div>
                </div>
             </div> 
            </div>
            <Divider orientation='vertical' className={classes.divider}/>
            <div className={classes.column}>
                <SecurityImg 
                    imgPlaceholder={t('billing.imgPlaceholder')}
                />
            </div>
        </div>
        {delegate.open && 
            (hasPermissions(permissions, {
                admin: ["O"],
                mainContacts: ["EO","RO"],
                wallets: ["RO"],
                customers: ["RO"],
                templates: ["RO"],
                proof_template: ["RO"],
                proofs: ["RO"]
            }) ||
            hasPermissions(permissions, {
                mainContacts: ["E","R"],
                wallets: ["R"],
                customers: ["R"],
                templates: ["R"],
                proof_template: ["R"],
                proofs: ["R"]
            })) && mainContact.main &&
            <DelegateModal customer={mainContact.Customer}/>
        }
    </Paper>
  );
}

const mapState = (state: RootState) => ({
    settingsInfo: state.settings.settingsInfo,
    mainContact: state.login.mainContact,
    permissions: state.login.permissions,
    profilePicture: state.settings.profilePicture,
    delegate: state.modals.delegate,
})

const mapDispatch = {
    updateMainContactSettings: (data: any) => (updateMainContactSettings(data)),
    getProfilePicture: (data: number) => (getProfilePicture(data)),
    setEditingMode: (data: boolean) => (setEditingMode(data)),
    getSettingsInfo: (mainContactId: number) => (getSettingsInfo(mainContactId)),
    openModal: (data: any) => (openModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(MySettings)
