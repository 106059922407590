import { initial } from 'lodash'
import { AnyAction } from 'redux'

const admin = {
  id: 0,
  CustomerId: 0,
  nickname: "",
  name: "",
  lastName: "",
  role: "",
  did: "",
  createdAt: "",
  updatedAt: "",
  email: "",
  disable: false,
  status: 0,
  Templates: [],
  image: "",
  assignedOn: "",
  colorTheme: false,
  main: false,
  User: {
    Permissions: [
      {id: 0}
    ]
  }
}

const customer = {
  id: 0,
  name: "",
  status: 0,
  tenant: false,
  createdAt: "",
  TenantId: 0,
  PlanId: 0,
  ThemeId: 0,
  logo: "",
  Wallet: {
    id: 0,
    name: "",
    password: "",
    did: "",
    accessToken: "",
    version: 0,
    owner: 0,
    status: 0,
    assignedOn: "",
    createdAt: "",
    updatedAt: "",
    CustomerId: 0
  },
  Tenant: null,
  MainAdmin: [admin],
  MainContacts: [admin],
  Admins: [admin]
}

const initialState = {
  newCustomer: customer,
  customers: [],
  unassignedWallets: [],
  acceptedAdmins: [],
  customer: customer,
  deletedCustomer: false,
  qrcode: {data: ""},
  emailSent: "",
  emailAddress: "",
  disabled: false,
  customerLogo: null,
  offerResent: false
}

type OnboardingReducer = typeof initialState
  
export default function onboardingReducer(state=initialState, action: AnyAction): OnboardingReducer {
    switch (action.type) {
      case 'CUSTOMERS_LOADED': {
             return {
               ...state,
                customers: action.payload,
               }
             }
      case 'CUSTOMER_ONBOARDED': {
              return {
                ...state,
                 newCustomer: action.payload,
                }
      }
      case 'UNASSIGNED_WALLETS_LOADED': {
              return {
                ...state,
                 unassignedWallets: action.payload,
                }
              }
      case 'ACCEPTED_ADMINS_LOADED': {
                return {
                  ...state,
                   acceptedAdmins: action.payload,
                  }
                }
      case 'CUSTOMER_UPDATED': {
              return {
                ...state,
                 customer: action.payload,
                }
              } 
      case 'CUSTOMER_LOADED': {
             return {
               ...state,
                customer: action.payload,
               }
             }
      case 'CUSTOMER_RESET': {
             return {
                 ...state,
                  customer: action.payload,
                }
            } 
      case 'ONBOARDING_OFFER_LOADED': {
            return {
              ...state,
              qrcode: action.payload
            }
          }
      case 'ONBOARDING_EMAIL_SENT': {
        return {
          ...state,
          qrcode: initialState.qrcode,
          emailSent: action.payload,
          emailAddress: action.data.email
        }
      }
      case 'DESTINATION_EMAIL_RESET': {
        return {
          ...state,
          emailSent: initialState.emailSent,
          emailAddress: initialState.emailAddress
        }
      }
      case 'QRCODE_RESET': {
        return {
          ...state,
          qrcode: initialState.qrcode
        }
      } 
      case 'CUSTOMER_STATUS_UPDATED': {
        return {
          ...state,
           customer: action.payload,
          }
        }
          case 'ADMIN_ENABLED_OR_DISABLED': {
            return {
              ...state,
              disabled: !state.disabled
            }
          }
      case 'CUSTOMER_LOGO_LOADED': {
            return {
              ...state,
              customerLogo: action.payload
            }
          }
      case 'OFFER_RESENT': {
        return {
          ...state,
          offerResent: !initialState.offerResent
        }
      }
      case 'NEW_CUSTOMER_RESET': {
        return {
          ...state,
          newCustomer: initialState.newCustomer
        }
      }
      default:
        return state;
    }
  }
  