import { AnyAction } from 'redux'

type ErrorReducer = {
  errors: {
    code?: string,
    msg?: string,
    action?: (() => void) | null,
    btnTxt?: string
  }[],
  uniqueNameError: {code: string, msg: string} | null
  trialError: {frontendCode: string, errorMessage: string} | null,
  upgradeError: {code: string, msg: string} | null
}
const initialState: ErrorReducer = {
    errors: [{
      code: "",
      msg: "",
    }],
    uniqueNameError: null,
    trialError: null,
    upgradeError: null
  };

export default function errorReducer(state=initialState, action: AnyAction): ErrorReducer {
  switch (action.type) {
    case 'ERROR': {
        return {
          ...state,
          errors: state.errors?.[0]?.code ? [...state.errors, action.payload] : [action.payload],
          uniqueNameError: action.payload.code === '0x0033' || action.payload.code === '0x0011' || action.payload.code === '0x0051' || action.payload.code === '0x2009' ? action.payload : null
        }
      }
      case 'IMG_ERROR': {
        return {
          ...state,
          errors: state.errors?.[0]?.code ? [...state.errors, action.error] : [action.error]
        }
      }
      case 'RESET_ERROR': {
        return {
          ...state,
          errors: state.errors.filter(error => error.code !== action.error.code)
        }
      }
      case 'RESET_UNIQUE_NAME_ERROR': {
        return {
          ...state,
          uniqueNameError: initialState.uniqueNameError
        }
      }
      case 'TRIAL_ERROR': {
        return {
          ...state,
          trialError: action.payload
        }
      }
      case 'RESET_TRIAL_ERROR': {
        return {
          ...state,
          trialError: null
        }
      }
      case 'UPGRADE_ERROR': {
        return {
          ...state,
          upgradeError: action.payload
        }
      }
      default:
        return state;
    }
  }
  