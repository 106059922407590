import { FC, useEffect, useState } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import DAWlogo from "../assets/icons/logomark-DAW.svg";

interface DawBtnProps {
	qrcode: string;
	text: string;
	id: string;
	disabled?: boolean
	version?: "contained" | "outlined"
}

const DawBtn: FC<DawBtnProps> = ({ qrcode, text, id, disabled, version = "contained" }) => {
	const [dawJwt, setDawJwt] = useState("");
	const theme = useTheme();

	useEffect(() => {
		if (qrcode) {
			axios.get(`${qrcode}`).then((res) => {
				setDawJwt(res.data.jwt);
			});
		}
	}, [qrcode]);

	const containedBtnStyle = {
		//@ts-ignore
		backgroundColor: theme.palette.onPrimary.highEmphasis,
		height: "42px",
		border: "none",
		borderRadius: "8px",
		width: "250px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "8px",
		cursor: "pointer",
		opacity: !dawJwt || disabled ? "30%" : "",
		color: "black"
	}

	const outlinedBtnStyle = {
		//@ts-ignore
		backgroundColor: theme.palette.onPrimary.highEmphasis,
		height: "42px",
		borderRadius: "8px",
		width: "288px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "8px",
		cursor: "pointer",
		opacity: !dawJwt || disabled ? "30%" : "",
		color: "black",
		border: "2px black solid",
		marginBottom: "12px"
	}

	return (
		<button
			data-jwt={dawJwt}
			id={id}
			disabled={!dawJwt || disabled ? true : false}
			style={
				version === "outlined" ? outlinedBtnStyle : containedBtnStyle
			}
		>
			<img src={DAWlogo} alt="daw logo" id={id} data-jwt={dawJwt}/>
			<Typography
				variant="button" id={id} data-jwt={dawJwt}>
					{text}
			</Typography>
		</button>
	);
};

export default DawBtn;
