import { useEffect, useState, FC, Fragment } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import {
  getCustomers,
  updateCustomerStatus
} from '../actions/onboarding';
import {
  Typography,
  Divider,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isFlag } from '../commons/utils';
import { hasPermissions, parseDateAndTime } from '../commons';
import { Customer } from '../commons/types'
import { allowedPermissions, incomingRole } from '../commons/permissions';

import Search from '../commons/components/Search';
import Button from '../commons/components/Button'
import SimpleTable from '../commons/components/SimpleTable';
import Toggle from '../commons/components/Toggle';
import TableDateAndTime from '../commons/components/TableDateAndTime'
import StatusBadge from '../commons/components/StatusBadge';
import Breadcrumbs from '../commons/components/Breadcrumbs';
import DisableModal from './DisableModal';
import CollapseTable from '../commons/components/CollapseTable'
import { plans } from '../commons/plans';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(10),
    }
  },
  containerColumn: {
    height: "auto"
  },
  row: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  search: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    }
  },
  searchMargin: {
    width: "100%",
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    }
  },
  btnContainer: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: theme.spacing(6)
    }
  },
  highEmphasis: {
    color: theme.palette.onSurface.highEmphasis
  },
  display: {
    color: theme.palette.onSurface.mediumEmphasis
  },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface WalletsProps extends PropsFromRedux { }

const Onboarding: FC<WalletsProps> = ({
  getCustomers,
  updateCustomerStatus,
  customers,
  permissions,
  mainContact
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [matches, setMatches] = useState<{ [name: string]: string | number }[]>([])
  const [input, setInput] = useState("")
  const [clickedRow, setClickedRow] = useState<any>("")
  const DISABLED = 1

  const columns = {
    createdAt: t('onboarding.added'),
    name: t('onboarding.company'),
    tenant: t('onboarding.type'),
    billingPlan: t('onboarding.billingPlan'),
    status: t('onboarding.offer'),
    disable: t('onboarding.enabled')
  }

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const getText = (item: number) => {
    const statusText = [
      t('statusText.none'),
      t('statusText.draft'),
      t('statusText.pending'),
      t('statusText.rejected'),
      t('statusText.accepted')
    ]
    let text = statusText[item]
    return text
  }

  const renderSearchMatches = (matches: [], input: string) => {
    setInput(input)
    setMatches(matches)
  }

  const handleCellClick = (customer: any) => {
    history.push(`/onboarding/${customer.id}`)
  }

  const handleToggleClick = (e: any, item: Customer) => {
    e.stopPropagation()
    setClickedRow(item)
  }

  const handleCancel = () => {
    setClickedRow("")
  }

  const handleAffirmative = async () => {
    await updateCustomerStatus({
      name: clickedRow.name,
      CustomerId: clickedRow.id,
      disable: isFlag(clickedRow.status, DISABLED) ? false : true
    })
    getCustomers()
    setClickedRow("")
  }

  return (
    <div className={clsx(classes.container)}>
      <Breadcrumbs />
      <Divider />
      <div className={classes.row}>
        <div className={
          (hasPermissions(permissions, {admin: ["O"], customers: ["WO","RO"], wallets: ["WO"], mainContacts: ["WO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) || 
          hasPermissions(permissions, {tenant: ["O"], customers: ["W","R"], wallets: ["W"], mainContacts: ["W"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) ?
          classes.searchMargin : classes.search
        }
        >
          <Search
            rows={customers}
            renderSearchMatches={renderSearchMatches}
          />
        </div>
        {
          (hasPermissions(permissions, {admin: ["O"], customers: ["WO","RO"], wallets: ["WO"], mainContacts: ["WO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) || 
          hasPermissions(permissions, {tenant: ["O"], customers: ["W","R"], wallets: ["W"], mainContacts: ["W"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) && 
            <div className={classes.btnContainer}>
              <Button
                text={t('onboarding.onboardBtn')}
                version="secondary"
                onClick={() => { history.push("/onboarding/new") }}
                size="medium"
              />
            </div>
        }
      </div>
      <SimpleTable
        handleCellClick={(item) => handleCellClick(item)}
        columns={columns}
        rows={input !== "" ? matches : customers}
        expandable={true}
        checkExpandableValue={()=>{return true}}
        columnTooltip={{
          disable: t('onboarding.statusTooltip')
        }}
        tableCells={{
          createdAt: (item: any) => {
            return (
              <TableDateAndTime
                dateAndTime={parseDateAndTime(item.createdAt)}
              />
            )
          },
          tenant: (item: any) => {
            return (
              <Typography
                className={classes.highEmphasis}
                variant="body2"
              >
                {item.tenant ? t('onboarding.multitenant') : item.TenantId ? t('onboarding.subtenant') : t('onboarding.classic')}
              </Typography>
            )
          },
          disable: (item: any) => {
            return (
              <Tooltip
              title={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) ?
                  <Fragment>
                    {t('onboarding.clickTooltip',
                      { status: isFlag(item.status, DISABLED) ? t('onboarding.enable') : t('onboarding.disable') }
                    )}
                  </Fragment>:""}
                placement="bottom"
              >
                <div>
                  <Toggle
                    checked={!isFlag(item.status, DISABLED)}
                    onClick={(e) => handleToggleClick(e, item)}
                    disabled={
                        ((mainContact?.main && hasPermissions(permissions, {admin: ["O"], customers: ["EO","RO"], wallets: ["EO","RO"], mainContacts: ["EO","RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]})) ||
                        hasPermissions(permissions, {tenant: ["O"], customers: ["E","R"], wallets: ["E","R"], mainContacts: ["E","R"], templates: ["R"], proof_template: ["R"], proofs: ["R"]})) ?
                        false
                        : 
                        true
                    }
                  />
                </div>
              </Tooltip>
            )
          },
          status: (item: any) => {
            let tableCell = <></>
            // change type to mainContact type
            item.MainContacts?.filter((mc: any) => {
              if (mc.main === true) {
                tableCell = <StatusBadge
                  status={getText(mc.status)}
                />
              }
              return tableCell
            })
            return tableCell
          },
          billingPlan: (item: any) => {
            return (
              <Typography
                className={classes.highEmphasis}
                variant="body2"
              >
                {plans[item.PlanId]?.name}
              </Typography>
            )
          }
        }}
        collapseElement={(item) =>
          <CollapseTable
              titles={[
                { prop: "MainContact", title: t('onboarding.mainContact') },
                { prop: "email", title: t('onboarding.email') },
                { prop: "Wallet", title: t('onboarding.wallet') }

              ]}
              item={item}
              tableCells={{
                MainContact: (item: any) => {
                  let tableCell = <></>
                  // change type to mainContact type
                  item.MainContacts?.filter((mc: any) => {
                    if (mc.main === true) {
                      tableCell = <div className={classes.nameContainer}>
                        <Typography
                          variant="body2"
                          className={classes.name}
                          onClick={() => handleCellClick(item)}
                        >
                          {mc.name} {mc.lastName}
                        </Typography>
                      </div>
                    }
                    return tableCell
                  })
                  return tableCell
                },
                email: (item: any) => {
                    let tableCell = <></>
                    // change type to mainContact type
                    item.MainContacts?.filter((mc: any) => {
                      if (mc.main === true) {
                        tableCell = <div className={classes.nameContainer}>
                          <Typography
                            variant="body2"
                            className={classes.name}
                          >
                            {mc.email}
                          </Typography>
                        </div>
                      }
                      return tableCell
                    })
                    return tableCell
                  },
                  Wallet: (item: any) => {
                    return (
                      <div className={classes.nameContainer}>
                        <Typography
                          variant="body2"
                          className={classes.name}
                        >
                          {item.Wallet?.name}
                        </Typography>
                      </div>
                    )
                  }
              }}
            />
          }
      />
      <DisableModal
        showTAndC={hasPermissions(permissions, {admin: ["O"]}) ? true : false}
        open={clickedRow ? true : false}
        disabled={isFlag(clickedRow.status, DISABLED) ? true : false}
        onCancel={handleCancel}
        onAffirmative={handleAffirmative}
        companyName={clickedRow.name}
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
  customers: state.onboarding.customers,
  customer: state.onboarding.customer,
  permissions: state.login.permissions,
  mainContact: state.login.mainContact
})

const mapDispatch = {
  getCustomers: () => (getCustomers()),
  updateCustomerStatus: (data: Object) => (updateCustomerStatus(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(Onboarding)
