import { Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import type { RootState } from "../store";
import { makeStyles } from "@material-ui/styles";
import { FC, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router";

import Button from "../commons/components/Button";
import DawBtn from "../commons/components/DawButton";

import successRequest from "./assets/successReq.svg";
import errorRequest from "./assets/errorReq.svg";
import Loading from "../commons/components/Loading";

const useStyles = makeStyles((theme?: any) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	btnRow: {
		position: "absolute",
		bottom: theme.spacing(7),
		display: "flex",
		flexDirection: "row",
		alignSelf: "flex-end",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(3),
			marginRight: "unset",
			flexDirection: "column",
			width: "100%",
			position: "unset",
		},
	},
	btn: {
		width: "180px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			marginTop: theme.spacing(4),
		},
	},
	mediumEmphasis: {
		color: theme.palette.onSurface.mediumEmphasis,
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
	textMargin: {
		marginBottom: theme.spacing(4),
	},
	link: {
		color: theme.palette.text.primary,
		width: "286px",
		height: "42px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: "12px"
	},
	btnLink: {
		textDecoration: "none",
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.spacing(3),
		},
	},
	centerContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		height: "100%",
		margin: theme.spacing(8, 0),
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(0),
		},
	},
	btnMargin: {
		marginRight: theme.spacing(6),
		[theme.breakpoints.down("sm")]: {
			marginRight: theme.spacing(0),
		},
	},
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

interface TestTemplateRequestProps extends PropsFromRedux {}

const TestTemplateRequest: FC<TestTemplateRequestProps> = ({
	template,
	token,
}) => {
	const classes = useStyles();
	const [qrcodeRequest, setQrcodeRequest] = useState("");
	const { t } = useTranslation();
	const [interactionRes, setInteractionRes] = useState({
		error: false,
		success: false,
		msg: "",
		subtitle: "",
	});
	const history = useHistory();

	useEffect(() => {
		if (template.id !== 0) {
			openRequestSocket();
		}
	}, [template]);

	const openRequestSocket = () => {
		setInteractionRes({
			error: false,
			success: false,
			msg: "",
			subtitle: "",
		});
		const socket = new WebSocket(
			process.env.REACT_APP_SOCKET_URL + "/papi/testrequest",
			// @ts-ignore
			token
		);
		socket.onerror = (e: any) => {
			setInteractionRes({
				error: true,
				success: false,
				msg: t("testCredential.error"),
				subtitle: t("testCredential.requestError"),
			});
		};
		socket.onmessage = (e) => onMessage(e);
		socket.onopen = (e: any) => {
			if (template?.id) {
				const data = {
					templateId: template?.id,
				};
				socket.send(JSON.stringify(data));
			}
		};
		return function cleanup() {
			socket.close();
		};
	};

	const onMessage = (message: any) => {
		try {
			const parsed = JSON.parse(message.data);
			if (parsed.errorMessage) {
				setInteractionRes({
					error: true,
					success: false,
					msg: t("testCredential.error"),
					subtitle: parsed.errorMessage,
				});
			}
			if (parsed.qrcode) {
				setQrcodeRequest(parsed.qrcode);
			}
			if (parsed.token) {
				setInteractionRes({
					error: false,
					success: true,
					msg: t("testCredential.success"),
					subtitle: t("testCredential.requestSuccess"),
				});
			}
		} catch (e) {
			setInteractionRes({
				error: true,
				success: false,
				msg: t("testCredential.error"),
				subtitle: t("testCredential.generalError"),
			});
		}
	};

	return (
		<div className={classes.container}>
			{!interactionRes.msg && (
				<div className={classes.centerContainer}>
					<Typography
						variant="h6"
						className={classes.textMargin}>
						{template.name}
					</Typography>
					<Typography
						variant="subtitle1"
						className={clsx(classes.mediumEmphasis, classes.textMargin)}>
						{t("testCredential.scanRequest")}
					</Typography>
					{
						qrcodeRequest ? 
					<>
						<QRCode
							value={qrcodeRequest}
							includeMargin={true}
							size={250}
						/>
						<a
							href={"ssi.interaction://interaction?jwt=" + qrcodeRequest}
							className={classes.btnLink}
							target="_blank"
							rel="noopener noreferrer">
							<Typography
								variant="button"
								className={classes.link}>
								{t("Login.link")}
							</Typography>
						</a>
						<DawBtn
							qrcode={qrcodeRequest ? qrcodeRequest : ""}
							text="Share credential from DAW"
							id="dawRequestCredential"
							version="outlined"
						/>
					</>
					: 
					<div style={{height: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}>
						<Loading />
					</div>
					}
				</div>
			)
		}
			{interactionRes.msg && (
				<div className={classes.centerContainer}>
					<img
						src={interactionRes.success ? successRequest : errorRequest}
						className={classes.textMargin}
					/>
					<Typography
						variant="h6"
						className={classes.textMargin}>
						{interactionRes.msg}
					</Typography>
					<Typography
						variant="subtitle1"
						className={classes.mediumEmphasis}>
						{interactionRes.subtitle}
					</Typography>
				</div>
			)}
			<div className={classes.btnRow}>
				{!interactionRes.msg && (
					<div className={classes.btn}>
						<Button
							text={t("general.continue")}
							width="100%"
							disabled={true}
						/>
					</div>
				)}
				{interactionRes.success && (
					<>
						<div className={clsx(classes.btn, classes.btnMargin)}>
							<Button
								text={t("testCredential.reload")}
								version="secondary"
								width="100%"
								onClick={() => openRequestSocket()}
							/>
						</div>
						<div className={classes.btn}>
							<Button
								text={t("general.done")}
								version="secondary"
								width="100%"
								onClick={() => history.push(`/credentials/${template.id}`)}
							/>
						</div>
					</>
				)}
				{interactionRes.error && (
					<>
						<div className={clsx(classes.btn, classes.btnMargin)}>
							<Button
								text={t("general.done")}
								version="secondary"
								width="100%"
								onClick={() => history.push(`/credentials/${template.id}`)}
							/>
						</div>
						<div className={classes.btn}>
							<Button
								text={t("general.retry")}
								version="primary"
								width="100%"
								onClick={() => openRequestSocket()}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const mapState = (state: RootState) => ({
	template: state.credentials.template,
	proofs: state.proofs.proofs,
	permissions: state.login.permissions,
	token: state.login.token,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connector(TestTemplateRequest);
