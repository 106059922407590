import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Button from '../Button'
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../../store'
import { closeModal } from '../../../actions/modals';

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(8),
        width: "100%",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "381px",
            width: "100%",
            textAlign: "center",
            padding: theme.spacing(8, 5),
        }
    },
    title:{
        fontWeight: 600
    },
    label: {
        color: theme.palette.onSurface.mediumEmphasis,
        padding: theme.spacing(6,0,8,0)
    },
    btnContainer: {
        alignSelf: "flex-end"
    },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface GlobalAdminUpgradeModalProps extends PropsFromRedux {}

const GlobalAdminUpgradeModal: FC<GlobalAdminUpgradeModalProps> = ({
    askGlobal,
    closeModal
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Dialog 
            open={askGlobal.open} 
            classes={{paper: classes.dialogContainer}}
        >
            <Typography
                variant="h6"
                className={classes.title}
            >
                 {t('globalAdminModal.title')}
            </Typography>
            <Typography
                variant="subtitle1"
                className={classes.label}
            >
                {t('globalAdminModal.text')}
            </Typography>
            <div className={classes.btnContainer}>
                <Button 
                    text={t('globalAdminModal.okayBtn')}
                    onClick={()=>closeModal({modalName: "askGlobal"})}
                />
            </div>
        </Dialog>               
    )
}

const mapState = (state: RootState) => ({
    askGlobal: state.modals.askGlobal
})

const mapDispatch = {
    closeModal: (data: Object) => (closeModal(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(GlobalAdminUpgradeModal)
