import { Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../store'
import { FC } from 'react';


const useStyles = makeStyles((theme?: any) => ({
    snackbarContainer: {
        position: "fixed",
        bottom: 0,
        zIndex: 2147483648,
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(5),
            width: "95%"
        }
    },
    snackbar: {
        "& .MuiSnackbarContent-root": {
            height: "48px",
            backgroundColor: theme.palette.common.black,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            [theme.breakpoints.down('md')]: {
                height: "fit-content",
                display: "flex",
                padding: theme.spacing(5),
                flexWrap: "nowrap"
            }
        },
    },
    root: {
        position: "relative",
        justifySelf: "flex-end",
        marginBottom: theme.spacing(4)
    },
    row: {
        display: "flex"
    },
    msg: {
        overflowWrap: "normal"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

type Success = {
    msg?: string,
}

interface SuccessSnackbarProps extends PropsFromRedux {
    successes: Success[]
}

const SuccessSnackbar: FC<SuccessSnackbarProps> = ({
    successes,
    resetSuccess,
}) => {
    const classes = useStyles()

    const handleClose = (success: Success) => {
        resetSuccess(success)
    }

    return (
        <div className={classes.snackbarContainer}>
            {successes.map((success: Success, index: number) => {
                return (
                    <Snackbar
                        key={index}
                        open={success.msg ? true : false}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        message={
                            <div className={classes.row}>
                                <Typography variant="body2" className={classes.msg}>
                                    {success.msg}
                                </Typography>
                            </div>
                        }
                        className={classes.snackbar}
                        classes={{ root: classes.root }}
                        autoHideDuration={5000}
                        onClose={() => handleClose(success)}
                    />
                )
            })}
        </div>
    )
}

const mapState = (state: RootState) => ({
    successes: state.successReducer.successes
})

const mapDispatch = {
    resetSuccess: (success: Success) => ({ type: 'RESET_SUCCESS', success: success })
}

const connector = connect(mapState, mapDispatch)

export default connector(SuccessSnackbar)