import { AnyAction } from 'redux'

const template = {
  id: 0,
  name: "",
  type: "",
  Proofs: [],
  description: "",
  public: false,
  MainContactId: 0,
  content: "",
  createdAt: "",
  origin: 0,
  status: 0,
  updatedAt: ""
}

const initialState = {
  deletedTemplate: false,
  templates: [],
  templateTypes: [],
  template: template,
  templateRepository: [],
  newTemplate: template
}

type AdminReducer = typeof initialState
  
export default function adminReducer(state=initialState, action: AnyAction): AdminReducer {
    switch (action.type) {
      case 'TEMPLATES_LOADED': {
        return {
          ...state,
            templates: action.payload,
          }
        }
        case 'TEMPLATE_TYPES_LOADED': {
          return {
              ...state,
                templateTypes: action.payload,
              }
          }
        case 'TEMPLATE_LOADED': {
          return {
              ...state,
                template: action.payload,
              }
          }
        case 'REPO_TEMPLATES_LOADED': {
          return {
              ...state,
                templateRepository: action.payload,
              }
            }
        case 'TEMPLATE_CREATED': {
          return {
            ...state,
            newTemplate: action.payload
          }
        }
        case 'NEW_TEMPLATE_RESET': {
          return {
            ...state,
            newTemplate: action.payload
          }
        }
        case 'TEMPLATE_TO_DRAFT_UPDATED': {
          return {
            ...state,
            template: action.payload
          }
        }
        case 'TEMPLATE_EDITED': {
          return {
            ...state,
            template: action.payload
          }
        }
        case 'TEMPLATE_SHARING_EDITED': {
          return {
            ...state,
            template: action.payload
          }
        }
      default:
        return state;
    }
  }
  