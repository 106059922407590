import { useState, useRef, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { openModal } from '../actions/modals';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { plans } from '../commons/plans';
import { hasPermissions } from '../commons';

import Breadcrumbs from '../commons/components/Breadcrumbs';
import PlanCard from './PlanCard';
import GlobalAdminUpgradeModal from '../commons/components/modals/GlobalAdminUpgradeModal';

const useStyles = makeStyles((theme?: any) => ({
    mainContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(7),
        backgroundColor: "#F4F3F8",
        minHeight: "100vh",
        overflowX: "visible",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5)
        }
    },
    modalContainer: {
        padding: theme.spacing(8),
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        marginRight: theme.spacing(7)
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        marginBottom: theme.spacing(5)
    },
    plansRow: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        columnGap: "24px",
        paddingBottom: "48px",
        scrollbarWidth: "thin",
        paddingRight: theme.spacing(7),
        "&::-webkit-scrollbar-track": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.borderColor.onSurface
        },
        "&::-webkit-scrollbar": {
            height: "5px",
            backgroundColor: theme.palette.borderColor.onSurface
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.onSurface.mediumEmphasis
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: "scroll",
        }
    },
    contextIcon: {
        height: "16px",
        marginLeft: theme.spacing(4),
        filter: theme.palette.filter.toBlack,
        cursor: "pointer"
    },
    row: {
        display: "flex",
        alignItems: "baseline",
    },
    padding: {
        paddingTop: theme.spacing(6),
    },
    legend:{
        color: theme.palette.onSurface.disabled,
        bottom: 0
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface PlanPageProps extends PropsFromRedux {

}

const PlanPage: FC<PlanPageProps> = ({
    mainContact,
    openModal,
    permissions
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [selectedId, setSelectedId] = useState(0)
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>
    const [intoView, setIntoView] = useState(1)

    useEffect(()=>{
        ref.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "center"})
    }, [intoView, ref])

    return (
        <div className={classes.mainContainer}>
            <div className={classes.contentContainer}>
                <Breadcrumbs />
                <Divider />
                <div className={clsx(classes.row, classes.padding)}>
                    <Typography
                        variant="h5"
                        className={clsx(classes.highEmphasis, classes.text)}
                    >
                        {t('trial.planSelect')}
                    </Typography>
                </div>

                <div
                    className={clsx(classes.plansRow, classes.padding)}
                >
                    {
                        Object.keys(plans).map((planId: string) => {
                            return (
                                <div key={planId} >
                                    <PlanCard
                                        planId={parseInt(planId)}
                                        plan={plans[planId]}
                                        selected={mainContact?.Customer?.PlanId}
                                        setSelected={setSelectedId}
                                        openModal={openModal}
                                        modalName={mainContact.main ? "welcome" : "askGlobal"}
                                        mt={hasPermissions(permissions, {tenant: ["O"]})}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            <GlobalAdminUpgradeModal />
            <Typography
                variant="subtitle2"
                className={classes.legend}
            >
                {t('legend.text')}
            </Typography>
            </div>

        </div>
    );
}

const mapState = (state: RootState) => ({
    acceptedAdmins: state.onboarding.acceptedAdmins,
    mainContact: state.login.mainContact,
    permissions: state.login.permissions
})

const mapDispatch = {
    openModal: (data: Object) => (openModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(PlanPage)
