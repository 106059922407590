import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import { getAdminList, enableDisableAdmin } from '../actions/admin';
import {
  Typography,
  Divider,
} from '@material-ui/core';
import { hasPermissions, parseDateAndTime } from '../commons';
import { useTranslation } from 'react-i18next';
import { Admin } from '../commons/types'
import { allowedPermissions, incomingRole } from '../commons/permissions';

import Search from '../commons/components/Search';
import Button from '../commons/components/Button'
import SimpleTable from '../commons/components/SimpleTable';
import Toggle from '../commons/components/Toggle';
import TableDateAndTime from '../commons/components/TableDateAndTime'
import StatusBadge from '../commons/components/StatusBadge';
import DisableDialog from './DisableDialog';
import Breadcrumbs from '../commons/components/Breadcrumbs';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  btn: {
    width: "160px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: theme.spacing(6),
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%"
  },
  searchMargin: {
    width: "100%",
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginRight: theme.spacing(0),
    }
  },
  search: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    }
  },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface AdminsProps extends PropsFromRedux { }

const Admins: FC<AdminsProps> = ({
  getAdminList,
  enableDisableAdmin,
  mainContact,
  admins,
  permissions
}) => {
  const classes = useStyles()
  let history = useHistory()
  const { t } = useTranslation()
  const [matches, setMatches] = useState<{ [name: string]: string | number }[]>([])
  const [input, setInput] = useState("")
  const [clickedRow, setClickedRow] = useState<any>("")

  const columns = {
    createdAt: t('adminsTable.addedOn'),
    name: t('adminsTable.name'),
    lastName: t('adminsTable.lastName'),
    email: t('adminsTable.email'),
    did: t('adminsTable.did'),
    status: t('adminsTable.offer'),
    disable: t('adminsTable.enabled')
  }

  useEffect(() => {
    getAdminList(mainContact?.Customer?.id)
  }, [mainContact, getAdminList])

  const getText = (item: number) => {
    const statusText = [
      t('statusText.none'),
      t('statusText.draft'),
      t('statusText.pending'),
      t('statusText.rejected'),
      t('statusText.accepted')
    ]
    let text = statusText[item]
    return text
  }

  const renderSearchMatches = (matches: [], input: string) => {
    setInput(input)
    setMatches(matches)
  }

  const handleCellClick = (admin: any) => {
    hasPermissions(permissions, {
      mainContacts: ["R"],
      customers: ["R"],
      users: ["R"],
      user_permission: ["R"],
      permissions: ["R"],
      templates: ["R"],
      proof_template: ["R"],
      proofs: ["R"]
    }) && history.push(`/settings/admins/${admin.id}`)
  }

  const handleToggleClick = (e: any, item: Admin) => {
    e.stopPropagation()
    setClickedRow(item)
  }

  const onCancel = () => {
    setClickedRow("")
  }

  const onAffirmative = async () => {
    await enableDisableAdmin({
      MainContactId: clickedRow.id,
      disable: !clickedRow.disable
    })
    getAdminList(mainContact?.Customer?.id)
    setClickedRow("")
  }

  const handleAddClick = () => {
      history.push("/settings/admins/new")
  }

  return (
    <div className={clsx(classes.container)}>

      <Breadcrumbs />
      <Divider />

      <div className={classes.row}>
        <div className={hasPermissions(permissions, {mainContacts: ["W","R"]}) ?
          classes.searchMargin : classes.search
        }
        >
          <Search
            rows={admins}
            renderSearchMatches={renderSearchMatches}
          />
        </div>
       {
        hasPermissions(permissions, {mainContacts: ["W","R"]})
        ?
        <div className={classes.btn}>
          <Button
            text={t('adminsTable.add')}
            version="secondary"
            onClick={() => { handleAddClick() }}
            size="medium"
          />
        </div> : <></>}
      </div>
      <SimpleTable
        handleCellClick={(item) => handleCellClick(item)}
        columns={columns}
        rows={input !== "" ? matches : admins}
        tableCells={{
          disable: (item: any) => {
            return (
              <Toggle
                checked={!item.disable}
                onClick={(e) => handleToggleClick(e, item)}
                disabled={item.disable === "" || 
                  !allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) ||
                  mainContact?.id === item.id ||
                  item.main
                  ? 
                  true : false}
              />
            )
          },
          createdAt: (item: any) => {
            return (
              <TableDateAndTime
                dateAndTime={parseDateAndTime(item.createdAt)}
              />
            )
          },
          status: (item: any) => {
            return (
              <StatusBadge
                status={getText(item.status)}
              />
            )
          },
          name: (item: any) => {
            return (
              <div className={classes.nameContainer}>
                <Typography
                  variant="body2"
                  className={classes.name}
                >
                  {item.name}
                </Typography>
              </div>
            )
          },
        }}
      />
      <DisableDialog
        open={clickedRow ? true : false}
        onCancel={onCancel}
        onAffirmative={onAffirmative}
        name={clickedRow.name}
        lastName={clickedRow.lastName}
        disabled={clickedRow.disable}
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
  mainContact: state.login.mainContact,
  admins: state.admin.admins,
  admin: state.admin.admin,
  permissions: state.login.permissions
})

const mapDispatch = {
  getAdminList: (CustomerId: number) => (getAdminList(CustomerId)),
  enableDisableAdmin: (data: Object) => (enableDisableAdmin(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(Admins)
