import { AppDispatch } from "../store";
import i18next from "i18next";

const SUCCESS = "SUCCESS";
const t = i18next.t.bind(i18next)

const successHandler = (success: {type: string}, dispatch: AppDispatch, payload?: any) =>{
    switch (success.type) {
        case 'WALLET_CREATED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.walletCreated'), 
                },
            });                   
        break
        case 'WALLET_ASSIGNED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.walletAssigned'), 
                },
            });                   
        break
        case 'TEMPLATE_DELETED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.templateDeleted', {template: payload.name}), 
                },
            });  
        break
        case 'CUSTOMER_STATUS_UPDATED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.companyStatusUpdated', {
                        name: payload.name, 
                        action: payload.disable 
                        ? 
                        t('disableCompany.disabled') 
                        : 
                        t('disableCompany.enabled')
                    }), 
                },
            });  
        break
        case 'CUSTOMER_CREATED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.companyOnboarded'), 
                },
            });                   
        break
        case 'PROOF_CREATED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.proofCreated'), 
                },
            });
        break
        case 'PROOF_DELETED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.proofDeleted', {proof: payload.name}), 
                },
            });
        break
        case 'ADMIN_DELETED': 
            dispatch({ 
                type: SUCCESS, 
                payload: {
                    msg: t('success.adminDeleted', {admin: payload.name}), 
                },
            });
        }   
    return;
};

export default successHandler;