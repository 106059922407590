import {
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
    Typography,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme?: any)=>({
    dropdown: {
        color: theme.palette.onSurface.highEmphasis,
        boxShadow: theme.shadows[2],
        borderRadius: theme.spacing(0, 0, 2, 2),
        marginBottom: theme.spacing(8)
    },
    item: {
        height: "48px",
        color: theme.palette.onSurface.highEmphasis,
        "&:hover": {
            background: theme.palette.primary.hover
        }
    },   
    popper: {
        zIndex: 100,
        width: "100%"
    },
    text: {
        color: theme.palette.onSurface.highEmphasis,
    },
    items: {
        maxHeight: "200px",
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.borderColor.onSurface, 
        overflowY: "scroll",
        "&::-webkit-scrollbar-track": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.borderColor.onSurface
        },
        "&::-webkit-scrollbar": {
            width: "5px",
            backgroundColor: theme.palette.borderColor.onSurface
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.onSurface.mediumEmphasis
        },
    },
    disabledItem: {
        color: theme.palette.onSurface.disabled
    }
}))

interface DropdownProps {
    open: boolean
    menuItems: any[]
    setOpen: (open: boolean) => void
    anchorEl: any
    handleClick?: (menuItem: any) => void
    offset?: string | number
    renderValue: string
    other?: boolean
    otherElement?: ReactElement
    handleClickAway?: () => void
    disabledOptionCheck?: (item: string) => boolean
}

const Dropdown: FC<DropdownProps> = ({
    open, 
    menuItems, 
    anchorEl,
    handleClick,
    offset,
    renderValue,
    other,
    otherElement,
    handleClickAway,
    disabledOptionCheck
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Popper 
              open={open}
              anchorEl={anchorEl}
              transition
              disablePortal
              placement='bottom'
              className={classes.popper}
              modifiers={{
                  offset: {
                      enabled: offset,
                      offset: offset
                  },
                  preventOverflow: {
                      enabled: false,
                      boundariesElement: 'scrollParent'
                  },
                  flip: {
                      enabled: false
                  }
              }}
            >     
              <Paper className={classes.dropdown}>
                <ClickAwayListener 
                    onClickAway={()=>handleClickAway && handleClickAway()}
                >
                  <MenuList>
                      <div className={classes.items}>
                      {
                          menuItems.map((menuItem, index)=>{
                          return (
                            <Tooltip 
                                title={disabledOptionCheck && disabledOptionCheck(menuItem.type) ? "You cannot use two claims of the same type in one template" : ""}
                                placement='top'
                                key={index}
                            >
                              <MenuItem 
                                key={index} 
                                className={classes.item} 
                                onClick={()=>handleClick && handleClick(menuItem)}
                              >
                                    <Typography variant="body2" className={clsx(classes.text, {
                                        [classes.disabledItem]: disabledOptionCheck && disabledOptionCheck(menuItem.type)
                                    })}>
                                        {menuItem[renderValue]}
                                    </Typography>
                              </MenuItem>
                            </Tooltip>
                          )
                          })
                      }
                      </div>
                      {other && 
                        <MenuItem 
                            className={classes.item} 
                            onClick={()=>handleClick && handleClick(t('general.other'))}
                        >
                            <Typography variant="body2" className={classes.text}>
                                {otherElement}
                            </Typography>
                        </MenuItem>
                      }
                  </MenuList>
                </ClickAwayListener >
              </Paper>
            </Popper>           
    )
}

export default Dropdown