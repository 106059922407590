import {
    Paper,
    Typography,
    Divider,
    Tooltip
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Proof } from '../commons/types';
import {
    getProofsTypes,
    getContexts,
    deleteProof,
    createProof,
    resetNewProof,
    updateSharingOption,
    editProof
} from '../actions/proofs';
import { isFlag } from '../commons/utils';
import { hasPermissions } from '../commons';
import ContextModal from './ContextModal';
import { resetUniqueNameError } from '../actions/wallets';
import clsx from 'clsx';
import { setEditingMode } from '../actions/dashboard';

import questionIcon from './assets/icon-question-circle.png'
import deleteIcon from './assets/trashcan-icon.svg'
import deleteIconDisabled from './assets/trashcan-icon-disabled.svg'

import InputField from '../commons/components/Input';
import Button from '../commons/components/Button'
import IconBtn from '../commons/components/IconButton';
import InputSelect from '../commons/components/InputSelect'
import RadioBtns from '../commons/components/RadioBtns';
import StatusBadge from '../commons/components/StatusBadge';
import DeleteModal from './DeleteModal';
import BackButton from '../commons/components/BackButton';


const useStyles = makeStyles((theme?: any, inModal?: boolean) => ({
    container: {
        minHeight: "456px",
        height: "auto",
        padding: theme.spacing(7),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    edit: {
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        marginBottom: theme.spacing(11),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginBottom: theme.spacing(4),
        }
    },
    column: {
        width: "47%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            bottom: theme.spacing(6),
            position: "relative",
            width: "100%"
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0),
        },
    },
    editBtn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0),
        }
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing(6)
    },
    iconBtn: {
        marginTop: theme.spacing(6),
        width: "fit-content"
    },
    radio: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0),
        }
    },
    sharing: {
        display: "flex",
        flexDirection: "column",
    },
    questionIcon: {
        display: "flex",
        alignItems: "baseline"
    },
    tooltipIcon: {
        height: "16px",
        marginLeft: theme.spacing(4),
        filter: theme.palette.filter.toBlack
    },
    contextIcon: {
        height: "16px",
        marginLeft: theme.spacing(4),
        filter: theme.palette.filter.toBlack,
        cursor: "pointer"
    },
    status: {
        padding: theme.spacing(5),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        }
    },
    origin: {
        color: theme.palette.onSurface.disabled
    },
    row: {
        display: "flex",
        alignItems: "baseline"
    },
    iconBtnRow: {
        display: "flex"
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.disabled,
        marginLeft: theme.spacing(2)
    }
}))

type CreateProof = {
    name: string
    type: string
    description?: string
    public?: boolean
    ProofId?: number
    ContextId: number
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface ProofStatusCardProps extends PropsFromRedux {
    proof?: Proof
    disabled?: boolean
    pickedProof?: Proof
    setDisabled?: (arg: boolean) => void
    inModal?: boolean
    onCancel?: (arg: boolean) => void
}

const ProofStatusCard: FC<ProofStatusCardProps> = ({
    getProofsTypes,
    mainContact,
    proofTypes,
    permissions,
    proof,
    disabled,
    pickedProof,
    getContexts,
    newProof,
    setDisabled,
    deleteProof,
    createProof,
    resetNewProof,
    updateSharingOption,
    editProof,
    contexts,
    uniqueNameError,
    resetUniqueNameError,
    inModal,
    onCancel,
    setEditingMode
}) => {
    const { t } = useTranslation()
    const classes = useStyles(inModal)
    const history = useHistory()
    const [fields, setFields] = useState({
        type: {
            type: ""
        },
        name: "",
        context: {
            id: 0,
            type: ""
        },
        description: "",
        public: false
    })
    const [disableSave, setDisableSave] = useState(false)
    const [other, setOther] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [idToDelete, setIdToDelete] = useState<any>(null)
    const [nameToDelete, setNameToDelete] = useState<any>(null)
    const [editMode, setEditMode] = useState(false)
    const [btnValue, setBtnValue] = useState<string>('private')
    const [openContextModal, setOpenContextModal] = useState(false)
    const [typeRestrictedError, setTypeRestrictedError] = useState({msg: ""})
    const btnValues = [
        { value: 'public', translation: t('proofs.public') },
        { value: 'private', translation: t('proofs.private') }
    ]

    const PUBLIC = 2
    const INUSE = 4

    useEffect(() => {
        hasPermissions(permissions, {proofs: ["R"], contexts: ["R"]}) && getProofsTypes(mainContact?.Customer?.id)
        hasPermissions(permissions, {contexts: ["R"]}) && getContexts()
    }, [mainContact, getProofsTypes, getContexts])

    useEffect(() => {
        if (typeof fields?.type === "string" && 
        //@ts-ignore
            (fields.type.toLowerCase() === "id" || fields.type.toLowerCase() === "witness")
        ) {
            setTypeRestrictedError({msg: t('error.typeRestrictedError', {type: fields.type})})
        }  else {
            setTypeRestrictedError({msg: ''})
        }
        if (
            !fields.type || 
            !fields.name || 
            !fields.context || 
            //@ts-ignore
            (typeof fields?.type === "string" && fields?.type?.toLowerCase() === "id") ||
            //@ts-ignore
            (typeof fields?.type === "string" && fields?.type?.toLowerCase() === "witness")
        ) {
            setDisableSave(true)
        } else {
            setDisableSave(false)
        }
    }, [fields])

    useEffect(() => {
        if (proof || pickedProof) {
            let inputValues = proof ? proof : pickedProof
            if (inputValues) {
                setFields({
                    type: { type: inputValues.type },
                    name: inputValues.name,
                    context: {
                        id: inputValues.Context?.id,
                        type: inputValues.Context?.type
                    },
                    description: inputValues.description,
                    public: isFlag(inputValues.status, PUBLIC) ? true : false
                })
            }
        }
    }, [proof, pickedProof])

    useEffect(() => {
        if (newProof?.id) {
            inModal ?
                // close the modal
                onCancel && onCancel(false)
                :
                history.push({ pathname: "/claims", state: { newProof: newProof.id } })
        }
        return () => {
            resetNewProof()
            resetUniqueNameError()
        }
    }, [newProof, history, resetNewProof, resetUniqueNameError, inModal, onCancel])

    useEffect(() => {
        if (!disabled) {
            setEditingMode(true)
        }
        return () => {
            setEditingMode(false)
        }
    }, [disabled])

    const handleInputChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleSelect = (name: string, value: any) => {
        if (name === 'type') {
            setFields({ ...fields, type: value, context: value?.Context })
        } else {
            setFields({ ...fields, context: value })
        }
    }


    const onRadioBtnChange = (e: any) => {
        if (proof) {
            updateSharingOption({ ProofId: proof?.id, public: e.target.value === 'public' ? true : false })
        } else {
            setBtnValue(e.target.value)
            setFields({ ...fields, public: e.target.value === 'public' ? true : false })
        }
    }

    const onDeleteProof = (templateName: string, templateId: number) => {
        setIdToDelete(templateId)
        setNameToDelete(templateName)
        setShowModal(true)
    }

    const handleDelete = async (id: number, name: string) => {
        const response = await deleteProof({ ProofId: id, name: name })
        if (response?.status === 200) {
            history.push("/claims")
        }
    }

    const handleSave = () => {
        setEditingMode(false)
        const proofToCreate: CreateProof = {
            name: fields.name,
            type: typeof fields.type === "string" ? fields.type : fields.type.type,
            ContextId: fields.context.id,
            description: fields.description
        }
        if (pickedProof) {
            proofToCreate.ProofId = pickedProof.id
        }
        if (hasPermissions(permissions, { "admin": "O" }) && fields.public === true) {
            proofToCreate.public = true
        }
        createProof(proofToCreate)
    }

    const handleEdit = (e: any, draft?: boolean) => {
        const proofToEdit: CreateProof = {
            name: fields.name,
            type: typeof fields.type === "string" ? fields.type : fields.type.type,
            ContextId: fields.context.id,
            description: fields.description,
            ProofId: proof?.id
        }
        if (hasPermissions(permissions, { "admin": "O" }) && fields.public === true) {
            proofToEdit.public = true
        }
        editProof(proofToEdit)
        setOther(false)
        setDisabled && setDisabled(true)
        setEditMode(false)
    }

    const enableEdit = () => {
        setDisabled && setDisabled(false)
        setEditMode(true)
    }

    const renderStatus = () => {
        if (proof) {
            return isFlag(proof?.status, INUSE) ? t('statusText.used') : t('statusText.unused')
        }
    }

    return (
        <Paper elevation={0} className={clsx(classes.container, {
            [classes.edit]: !disabled
        })}>
            <div className={classes.titleRow}>
                <Typography
                    variant="h5"
                    className={classes.highEmphasis}
                >
                    {proof ? proof.name : t('proofs.createTitle')}
                </Typography>
                <div className={classes.iconBtnRow}>
                    {
                        proof && !isFlag(proof?.status, INUSE) &&
                        hasPermissions(permissions, {proofs: ["D"]}) &&
                        <IconBtn
                            iconDisabled={deleteIconDisabled}
                            onClick={() => onDeleteProof(proof.name, proof.id)}
                            iconSecondary={deleteIcon}
                            version="secondary"
                            disabled={isFlag(proof?.status, INUSE) ? true : false}
                        />
                    }
                    {!inModal && <BackButton />}
                </div>
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('proofs.name')}
                        </Typography>
                        <InputField
                            placeholder="Insert name here"
                            name="name"
                            value={fields.name || ""}
                            disabled={disabled}
                            onChange={(e) => { handleInputChange(e) }}
                            error={uniqueNameError ? true : false}
                            errorText={uniqueNameError?.msg}
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('proofs.type')}
                        </Typography>
                        <InputSelect
                            menuItems={proofTypes}
                            renderValue="type"
                            value={fields?.type || ""}
                            name="type"
                            disabled={disabled || pickedProof ? true : false}
                            placeholder="Select type"
                            selectOther={true}
                            otherElement={<>{t('proofs.other')}</>}
                            setValue={handleSelect}
                            other={other}
                            setOther={setOther}
                        />
                        {
                            other &&
                            <>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('proofs.newtype')}
                                </Typography>
                                <InputField
                                    value={typeof fields?.type === "string" ? fields?.type || "" : ""}
                                    onChange={handleInputChange}
                                    name="type"
                                    error={typeRestrictedError.msg ? true : false}
                                    errorText={typeRestrictedError.msg}
                                />
                            </>
                        }
                    </div>
                    <div>
                        <div className={classes.row}>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('proofs.contextLower')}
                            </Typography>
                            <img
                                src={questionIcon}
                                alt="question mark inside of a circle icon"
                                className={classes.contextIcon}
                                onClick={() => setOpenContextModal(true)}
                            />
                        </div>
                        <InputSelect
                            menuItems={other ? contexts : []}
                            placeholder="Select"
                            renderValue="type"
                            setValue={handleSelect}
                            name="context"
                            value={fields.context || ""}
                            disabled={!other || disabled}
                        />
                    </div>
                </div>
                <Divider
                    orientation="vertical"
                    flexItem
                />
                <div className={classes.statusColumn}>
                    <div className={classes.row}>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('proofs.descript')}
                        </Typography>
                        <Typography
                            variant="caption"
                            className={classes.mediumEmphasis}
                        >
                            ({t('general.optional')})
                        </Typography>
                    </div>
                    <InputField
                        multiline={true}
                        rows={5}
                        onChange={handleInputChange}
                        name="description"
                        value={fields?.description || ""}
                        disabled={disabled}
                        placeholder="Insert description here"
                        maxLength={256}
                        helperText={t('general.maxDescSize')}
                    />
                    <div>
                        {proof &&
                            <>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('proofs.status')}
                                </Typography>
                                <div className={classes.status}>
                                    <StatusBadge
                                        status={renderStatus()}
                                    />
                                </div>
                                <Divider />
                            </>
                        }
                        {!hasPermissions(permissions, { "admin": "O" }) &&
                            <>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('proofs.origin')}
                                </Typography>
                                <div className={classes.status}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.origin}
                                    >
                                        {proof?.origin || pickedProof ? t('origin.picked') : t('origin.created')}
                                    </Typography>
                                </div>
                                <Divider />
                            </>
                        }
                    </div>
                    {hasPermissions(permissions, { "admin": "O" }) &&
                        <>
                            <div className={classes.sharing}>
                                <div className={classes.questionIcon}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {t('proofs.share')}
                                    </Typography>
                                    <Tooltip
                                        title={<Fragment>{t('proofs.tooltipSharing')}</Fragment>}
                                        placement="bottom-start"
                                    >
                                        <img
                                            src={questionIcon}
                                            alt="question mark inside of a circle icon"
                                            className={classes.tooltipIcon}
                                        />
                                    </Tooltip>
                                </div>
                                    <div className={classes.radio}>
                                        <RadioBtns
                                            btnValues={btnValues}
                                            onChange={(e) => onRadioBtnChange(e)}
                                            btnValue={fields.public ? 'public' : btnValue}
                                            disabled={editMode || !hasPermissions(permissions, {admin: ["O"], proofs: ["E"], mainContacts: ["R"], contexts: ["R"]})}
                                        />
                                    </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {!isFlag(proof?.status, INUSE) && hasPermissions(permissions, {proofs: ["E","R"], mainContacts: ["R"], contexts: ["R"]}) ?
                <div className={classes.btnRow}>
                    {disabled ?
                        <div className={classes.editBtn}>
                            <Button
                                text={t('general.Edit')}
                                version="secondary"
                                width="100%"
                                onClick={enableEdit}
                                disabled={isFlag(proof?.status, INUSE) ? true : false}
                            />
                        </div>
                        :
                        <>
                            {inModal && <div className={classes.btn}>
                                <Button
                                    text={t('general.Cancel')}
                                    version="text"
                                    fullWidth={true}
                                    onClick={onCancel}
                                    width="100%"
                                />
                            </div>}
                            <div className={classes.btn}>
                                <Button
                                    text={t('general.Save')}
                                    version="primary"
                                    width="100%"
                                    disabled={disableSave}
                                    onClick={proof ? handleEdit : handleSave}
                                />
                            </div>
                        </>
                    }
                </div>
                :
                <></>
            }
            <DeleteModal
                open={showModal}
                setShowModal={setShowModal}
                handleDelete={handleDelete}
                id={idToDelete}
                name={nameToDelete}
            />
            <ContextModal
                open={openContextModal}
                setShowModal={setOpenContextModal}
            />
        </Paper>
    )
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    proofTypes: state.proofs.proofTypes,
    permissions: state.login.permissions,
    proofs: state.proofs.proofs,
    contexts: state.proofs.contexts,
    newProof: state.proofs.newProof,
    uniqueNameError: state.errorReducer.uniqueNameError
})

const mapDispatch = {
    getProofsTypes: (CustomerId: number) => getProofsTypes(CustomerId),
    getContexts: () => getContexts(),
    createProof: (data: Object) => (createProof(data)),
    resetNewProof: () => (resetNewProof()),
    deleteProof: (data: Object) => (deleteProof(data)),
    updateSharingOption: (data: Object) => (updateSharingOption(data)),
    editProof: (data: Object) => editProof(data),
    resetUniqueNameError: () => (resetUniqueNameError()),
    setEditingMode: (data: boolean) => (setEditingMode(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(ProofStatusCard)