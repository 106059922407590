import { AnyAction } from 'redux'

const initialState = {
    notifications: [],
    error: null
  };

  type NotificationReducer = typeof initialState
  
export default function notificationReducer(state = initialState, action: AnyAction): NotificationReducer {
    switch (action.type) {
      case 'NOTIFICATIONS_LOADED': {
        return {
          ...state,
          notifications: action.payload
          }
      }
      case 'NOTIFICATION_READ': {
        return {
          ...state,
          notifications: action.payload
          }
      }
      default:
        return state;
    }
  }
  