import { FC, ReactElement } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import TCell from './TCellContent';

const useStyles = makeStyles((theme?: any)=>({
    disabled: {
        color: theme.palette.onSurface.disabled
      },
      titleRow: {
          height: "36px",
          background: theme.palette.primary[50],
          paddingLeft: theme.spacing()
      },
      detailRow: {
          height: "66px",
          background: theme.palette.primary.hover,
          paddingTop: theme.spacing(3)
      },
      mediumEmphasis: {
          color: theme.palette.onSurface.mediumEmphasis,
          marginBottom: theme.spacing(3)
      },
      cell: {
          borderBottom: "none",
          padding: theme.spacing(0, 0, 0, 6),
      },
      column: {
          display: "flex",
          flexDirection: "column",
          paddingTop: theme.spacing(3)
      }
}))

interface CollapseTableProps {
    titles?: {prop: string, title: string}[]
    item?: any
    tableCells?: {[name: string]: (row: any, item?: any) => ReactElement}
}

const CollapseTable: FC<CollapseTableProps> = ({
    titles, 
    item,
    tableCells
}) => {
    const classes = useStyles()

        return (
            <Table>
                <TableHead>
                    <TableRow className={classes.titleRow}>
                        {titles?.map((title, index)=>{
                            return (
                                <TableCell className={classes.cell} key={index}>
                                    <Typography 
                                        variant="overline" 
                                        className={classes.disabled}
                                    >
                                        {title.title}
                                    </Typography>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.detailRow}>  
                        {titles?.map((title, index)=>{
                            return (
                                    <TableCell 
                                        key={index} 
                                        className={classes.cell}
                                    >
                                        <TCell 
                                            row={item} 
                                            column={title.prop} 
                                            tableCells={tableCells}
                                        />
                                    </TableCell>
                                )
                            })
                        }   
                    </TableRow>
                </TableBody>
            </Table>
        )
}

export default CollapseTable