import { Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import type { RootState } from "../store";
import { makeStyles } from "@material-ui/styles";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { getTemplate } from "../actions/credentials";
import { useLocation } from "react-router";
import { useHistory } from "react-router";

import InputField from "../commons/components/Input";
import Button from "../commons/components/Button";
import DawBtn from "../commons/components/DawButton";

import successOffer from "./assets/success.svg";
import errorOffer from "./assets/error.svg";

const useStyles = makeStyles((theme?: any) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	btnRow: {
		position: "absolute",
		bottom: theme.spacing(7),
		display: "flex",
		flexDirection: "row",
		alignSelf: "flex-end",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(3),
			marginRight: "unset",
			flexDirection: "column",
			width: "100%",
			position: "unset",
		},
	},
	btn: {
		width: "180px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			marginTop: theme.spacing(4),
		},
	},
	mediumEmphasis: {
		color: theme.palette.onSurface.mediumEmphasis,
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
	columns: {
		columns: "2 auto",
		// columnCount: 2,
		[theme.breakpoints.down("md")]: {
			columns: "unset",
		},
	},
	labelInput: {
		breakInside: "avoid",
		pageBreakInside: "avoid",
	},
	column: {
		width: "47%",
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
	},
	textMargin: {
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("md")]: {
			textAlign: "center",
		},
	},
	link: {
		color: theme.palette.text.primary,
		width: "286px",
		height: "42px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: "12px"
	},
	btnLink: {
		textDecoration: "none",
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.spacing(3),
		},
	},
	centerContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		height: "100%",
		margin: theme.spacing(8, 0),
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(0),
		},
	},
	btnMargin: {
		marginRight: theme.spacing(6),
		[theme.breakpoints.down("sm")]: {
			marginRight: theme.spacing(0),
		},
	},
	row: {
		display: "flex",
		alignItems: "baseline",
	},
	caption: {
		marginLeft: theme.spacing(2),
	},
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

interface TestTemplateOfferProps extends PropsFromRedux {
	step: number;
	setStep: (step: number) => void;
	disabled: boolean;
}

const TestTemplateOffer: FC<TestTemplateOfferProps> = ({
	template,
	token,
	step,
	setStep,
	disabled,
}) => {
	const classes = useStyles();
	const [qrcodeOffer, setQrcodeOffer] = useState("");
	const { t } = useTranslation();
	const [templateFields, setTemplateFields] = useState({});
	const [loading, setLoading] = useState(false)
	const [interactionRes, setInteractionRes] = useState({
		error: false,
		success: false,
		msg: "",
		subtitle: "",
	});
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		let fields = {};
		let storedTemplate = window.localStorage.getItem(template.name);
		let parsedTemplate = storedTemplate && JSON.parse(storedTemplate);
		template?.Proofs?.map((proof: any) => {
			fields = {
				...fields,
				[proof.type]: parsedTemplate ? parsedTemplate[proof.type] : "",
			};
			return fields;
		});
		setTemplateFields(fields);
	}, [template]);

	useEffect(() => {
		if (
			//@ts-ignore
			location?.state?.step === 2 &&
			Object.values(templateFields).length !== 0 &&
			Object.values(templateFields).every(
				(value) => value !== undefined && value !== ""
			)
		) {
			//@ts-ignore
			setStep(2);
		} else {
			setInteractionRes({
				error: false,
				success: false,
				msg: "",
				subtitle: "",
			});
			setStep(1);
		}
	}, [location.state]);

	useEffect(() => {
		if (step === 2) {
			openOfferSocket();
			window.localStorage.setItem(
				template.name,
				JSON.stringify(templateFields)
			);
		}
	}, [step]);

	const openOfferSocket = () => {
		setInteractionRes({
			error: false,
			success: false,
			msg: "",
			subtitle: "",
		});
		const socket = new WebSocket(
			process.env.REACT_APP_SOCKET_URL + "/papi/testoffer",
			// @ts-ignore
			token
		);
		socket.onerror = (e: any) => {
			setInteractionRes({
				error: true,
				success: false,
				msg: t("testCredential.error"),
				subtitle: t("testCredential.offerError"),
			});
		};
		socket.onmessage = (e) => onMessage(e);
		socket.onopen = (e: any) => {
			const data = {
				templateId: template?.id,
				dataset: {
					...templateFields,
				},
			};
			socket.send(JSON.stringify(data));
		};
		return function cleanup() {
			socket.close();
		};
	};

	const onMessage = (message: any) => {
		try {
			const parsed = JSON.parse(message.data);
			if (parsed.errorMessage) {
				setInteractionRes({
					error: true,
					success: false,
					msg: t("testCredential.error"),
					subtitle: parsed.errorMessage,
				});
			}

			if (parsed.qrcode) {
				setQrcodeOffer(parsed.qrcode);
				setLoading(false); 
				setStep(2);
			}
			if (parsed.token) {
				setInteractionRes({
					error: false,
					success: true,
					msg: t("testCredential.success"),
					subtitle: t("testCredential.offerSuccess"),
				});
			}
		} catch (e) {
			setInteractionRes({
				error: true,
				success: false,
				msg: t("testCredential.error"),
				subtitle: t("testCredential.generalError"),
			});
		}
	};

	const handleInputChange = (e: any) => {
		setTemplateFields({ ...templateFields, [e.target.name]: e.target.value });
	};

	return (
		<div className={classes.container}>
			{step === 1 && (
				<>
					<Typography
						variant="h6"
						className={classes.textMargin}>
						{t("testCredential.inputTitle")}
					</Typography>
					<Typography
						variant="subtitle1"
						className={clsx(classes.mediumEmphasis, classes.textMargin)}>
						{t("testCredential.formText")}
					</Typography>
					<div
						className={clsx({
							[classes.columns]: template?.Proofs?.length > 6,
						})}>
						{template?.Proofs?.map((field: any, index: number) => {
							return (
								<div
									className={clsx(classes.labelInput, {
										[classes.column]: template?.Proofs?.length <= 6,
									})}
									key={index}>
									<div className={classes.row}>
										<Typography
											variant="subtitle2"
											className={classes.mediumEmphasis}>
											{field.name}
										</Typography>
										<Typography
											variant="caption"
											className={clsx(classes.mediumEmphasis, classes.caption)}>
											({field.type})
										</Typography>
									</div>
									<InputField
										//@ts-ignore
										value={templateFields[field?.type]}
										onChange={(e) => handleInputChange(e)}
										name={field.type}
										disabled={disabled}
									/>
								</div>
							);
						})}
					</div>
				</>
			)}
			{step === 2 && (
				<>
					{qrcodeOffer && !interactionRes.msg && (
						<div className={classes.centerContainer}>
							<Typography
								variant="h6"
								className={classes.textMargin}>
								{template.name}
							</Typography>
							<Typography
								variant="subtitle1"
								className={clsx(classes.mediumEmphasis, classes.textMargin)}>
								{t("testCredential.scanOffer")}
							</Typography>
							<QRCode
								value={qrcodeOffer}
								includeMargin={true}
								size={250}
							/>
							<a
								href={"ssi.interaction://interaction?jwt=" + qrcodeOffer}
								className={classes.btnLink}
								target="_blank"
								rel="noopener noreferrer">
								<Typography
									variant="button"
									className={classes.link}>
									{t("Login.link")}
								</Typography>
							</a>
							<DawBtn
								qrcode={qrcodeOffer ? qrcodeOffer : ""}
								text="Save credential in DAW"
								id="dawOfferCredential"
								version="outlined"
							/>
						</div>
					)}
					{interactionRes.msg && (
						<div className={classes.centerContainer}>
							<img
								src={interactionRes.error ? errorOffer : successOffer}
								className={classes.textMargin}
							/>
							<Typography
								variant="h6"
								className={classes.textMargin}>
								{interactionRes.msg}
							</Typography>
							<Typography
								variant="subtitle1"
								className={classes.mediumEmphasis}>
								{interactionRes.subtitle}
							</Typography>
						</div>
					)}
				</>
			)}
			<div className={classes.btnRow}>
				{!interactionRes.msg && (
					<div className={classes.btn}>
						<Button
							text={t("general.continue")}
							width="100%"
							onClick={() => {setLoading(true); openOfferSocket()}}
							loading={loading}
							disabled={
								Object.values(templateFields).some(
									(value) => value === undefined || value === ""
								) || qrcodeOffer
									? true
									: false
							}
						/>
					</div>
				)}
				{interactionRes.success && (
					<div className={classes.btn}>
						<Button
							text={t("general.continue")}
							width="100%"
							onClick={() =>
								history.push(`/credentials/${template.id}/testrequest`)
							}
						/>
					</div>
				)}
				{interactionRes.error && (
					<>
						<div className={clsx(classes.btn, classes.btnMargin)}>
							<Button
								text={t("general.done")}
								width="100%"
								version="secondary"
								onClick={() => history.push(`/credentials/${template.id}`)}
							/>
						</div>
						<div className={classes.btn}>
							<Button
								text={t("general.retry")}
								width="100%"
								version="primary"
								onClick={() => {openOfferSocket()}}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const mapState = (state: RootState) => ({
	template: state.credentials.template,
	proofs: state.proofs.proofs,
	permissions: state.login.permissions,
	token: state.login.token,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connector(TestTemplateOffer);
