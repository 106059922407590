import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import {
  Divider,
} from '@material-ui/core';
import { useLocation } from 'react-router';
import { Credential } from '../commons/types'

import Breadcrumbs from '../commons/components/Breadcrumbs';
import CredentialStatusCard from './CredentialStatusCard';

interface CustomizedState {
  pickedCredential: Credential
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface NewCredentialProps extends PropsFromRedux { }

const NewCredential: FC<NewCredentialProps> = () => {
  const [pickedCred, setPickedCred] = useState<Credential>()
  const location = useLocation()
  const state = location.state as CustomizedState

  useEffect(() => {
    if (state) {
      const { pickedCredential } = state
      setPickedCred(pickedCredential)
    }
  }, [state])

  return (
    <div >

      <Breadcrumbs />
      <Divider />

      <CredentialStatusCard
        pickedCredential={pickedCred}
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(NewCredential)
