import { AppDispatch } from "../store";
import i18next from "i18next";

const ERROR = "ERROR";
const t = i18next.t.bind(i18next)

const errorHandler = (err: any, dispatch: AppDispatch) =>{
        if (err?.response?.status === 401) {
            localStorage.removeItem("mainContact")
            localStorage.removeItem("token")
            localStorage.removeItem("permissions")
            window.location.reload();
        } else if (err?.response?.data?.frontendCode) {
            dispatch({ 
                type: ERROR, 
                payload: {
                    code: err.response.data.frontendCode,
                    msg: t('error.'+ err.response.data.frontendCode), 
                },
            });                   
        } else {
            dispatch({ 
                type: ERROR,  
                payload: {
                    code: '0x1002',
                    msg: t('error.0x1002')
                } 
            });
    }
    return;
};

export default errorHandler;