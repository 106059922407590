import API from '../api'
import { AppDispatch } from "../store";
import errorHandler from '../actions/errorHandler'
import successHandler from './successHandler';

export const getAdminList = (data: number) => async (dispatch: AppDispatch) =>  {
  let response 
  try {
    response = await API.get('/api/admins/' + data)
    dispatch({ type: 'ADMINS_LOADED', payload: response.data })
  } catch(e: any) {
    errorHandler(e, dispatch)
  }
}

export const getAdmin = (data: string) => async (dispatch: any) =>  {
  let response 
  try {
    response = await API.get('/api/admin/' + data)
    dispatch({ type: 'ADMIN_LOADED', payload: response.data })
  } catch(e) {
    errorHandler(e, dispatch)
  }
}

export const getAdminProfilePicture = (data: number) => async (dispatch: any) => {
  let response
  try {
    response = await API.download('/api/profile/maincontact/' + data)
      dispatch({ type: 'ADMIN_PROFILE_PICTURE_LOADED', payload: response.data })
    } catch(e) {
      errorHandler(e, dispatch)
    }
  }

  export const enableDisableAdmin = (data: Object) => async (dispatch: any) => {
    let response
    try {
        response = await API.patch('/api/admin',  data)
        dispatch({ type: 'ADMIN_ENABLED_OR_DISABLED', payload: response.data })
      } catch(e) {
        errorHandler(e, dispatch)
      }
    }

    export const createAdmin = (data: Object) => async (dispatch: any) => {
      let response
      try {
        response = await API.post('/api/admin',  data)
        return response.data
        } catch(e) {
          errorHandler(e, dispatch)
        }
      }

      export const editAdminDraft = (data: Object) => async (dispatch: any) => {
        let response
        try {
          response = await API.patch('/api/admindraft',  data)
            dispatch({ type: 'ADMIN_DRAFT_EDITED', payload: response.data })
          } catch(e) {
            errorHandler(e, dispatch)
        }
      }

      export const deleteAdmin = (data: any) => async (dispatch: any) => {
        let response
        try {
          response = await API.delete('/api/admin',  data)
            dispatch({ type: 'ADMIN_DELETED', payload: response.data })
            successHandler({ type: 'ADMIN_DELETED' }, dispatch, {name: data.name})
          } catch(e) {
            errorHandler(e, dispatch)
        }
      }

      export const getOffer = (data: Object) => async (dispatch: any) => {
        let response
        try {
          response = await API.post('/api/createoffer/' + process.env.REACT_APP_API_VERSION,  data)
          dispatch({ type: 'OFFER_LOADED', payload: response.data, data: data })
          } catch(e) {
            errorHandler(e, dispatch)
        }
      }

      export const sendOffer = (data: Object) => async (dispatch: any) => {
        let response
        try {
          response = await API.post('/api/createoffer/'+ process.env.REACT_APP_API_VERSION,  data)
            dispatch({ type: 'EMAIL_SENT', payload: response.data, data: data })
          } catch(e) {
            errorHandler(e, dispatch)
        }
      }

      export const resetDestinationEmail = () => async (dispatch: any) => {
        dispatch({ type: 'DESTINATION_EMAIL_RESET' })
      }

      export const resetQrCode = () => async (dispatch: any) => {
        dispatch({ type: 'QRCODE_RESET' })
      }

      export const updateAdminPermissions = (data: Object) => async (dispatch: any) => {
        let response
        try {
          response = await API.patch('/api/admin/permission',  data)
            dispatch({ type: 'ADMIN_PERMISSIONS_UPDATED', payload: response.data })
          } catch(e) {
            errorHandler(e, dispatch)
        }
      }


