import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import Button from '../commons/components/Button'
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

const useStyles = makeStyles((theme?: any) => ({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: theme.spacing(8, 8),
        width: "523px",
        height: "276px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "276px",
            textAlign: "center",
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        width: "420px",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            marginRight: "unset",
            flexDirection: "column",
            alignItems: "flex-end",
            alignSelf: "flex-end",
            width: "100%"
        },
    },
    btnMargin: {
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: theme.spacing(4),
        },

    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    }
}))

interface DeleteModalProps {
    open?: boolean,
    setShowModal: (arg: boolean) => void
    handleDelete: (id: number, name: string) => void
    id: number
    name: string
}


const DeleteModal: FC<DeleteModalProps> = ({
    open = true,
    setShowModal,
    handleDelete,
    id,
    name
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (

        <Dialog
            open={open}
            classes={{ paper: classes.dialogContainer }}
        >
            <Typography variant="h6">
                {t('DeleteModal.title')}
            </Typography>
            <Typography
                variant="subtitle1"
                className={classes.mediumEmphasis}
            >
                {t('proofs.deleteText')}
                <b>{name}</b>?
            </Typography>
            <div className={classes.btnRow}>
                <div className={classes.btn}>
                    <Button
                        size={matches ? "small" : "large"}
                        text={t('DeleteModal.btnDisable')}
                        version="secondary"
                        width="100%"
                        onClick={() => { handleDelete(id, name); setShowModal(false) }}

                    />
                </div>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button
                        size={matches ? "small" : "large"}
                        text={t('DeleteModal.btnCancel')}
                        version="secondary"
                        onClick={() => setShowModal(false)}
                        width="100%"
                    />
                </div>
            </div>
        </Dialog>

    )
}

export default DeleteModal