import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Divider,
} from '@material-ui/core';
import { useLocation } from 'react-router';
import { Proof } from '../commons/types';
import { makeStyles } from '@material-ui/styles';

import Breadcrumbs from '../commons/components/Breadcrumbs';
import ProofStatusCard from './ProofStatusCard';

const useStyles = makeStyles((theme?: any)=>({
  divider: {
      marginBottom: theme.spacing(6)
  }
}))

interface CustomizedState {
  pickedProof: Proof
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface NewProofProps extends PropsFromRedux {
  inModal?: boolean
}

const NewProof: FC<NewProofProps> = ({
  inModal
}) => {
  const [pickedProof, setPickedProof] = useState<Proof>()
  const location = useLocation()
  const state = location.state as CustomizedState
  const classes = useStyles()
  
  useEffect(()=>{
    if (state) {
      const { pickedProof } = state
      setPickedProof(pickedProof)
    }
  }, [state])
  
  return (
    <div >
        {!inModal && 
          <>
            <Breadcrumbs />
            <Divider className={classes.divider}/>
          </>
        }
        <ProofStatusCard 
            pickedProof={pickedProof}
        />
    </div>
  );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(NewProof)
