import { Typography, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { ButtonTypeMap } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme?: any) => ({
	root: {
		height: "40px",
		width: (styleProps: styleProps) => styleProps.width,
		minWidth: "165px",
		[theme.breakpoints.down("sm")]: {
			minWidth: "unset",
			width: "inherit",
		},
	},
	contained: {
		background: theme.palette.onSurface.highEmphasis,
		color: theme.palette.text.secondary,
		"&:hover": {
			background: theme.palette.primary[800],
		},
		"&:focus": {
			background: theme.palette.primary[700],
			outline: "none",
		},
		"&:active": {
			background: theme.palette.onSurface.highEmphasis,
		},
	},
	outlined: {
		border: (styleProps: styleProps) =>
			styleProps.tertiary
				? `2px solid ${theme.palette.borderColor.onSurface}`
				: `2px solid ${theme.palette.onSurface.highEmphasis}`,
		color: theme.palette.onSurface.highEmphasis,
		"&:hover": {
			borderColor: (styleProps: styleProps) =>
				styleProps.tertiary
					? `2px solid ${theme.palette.borderColor.onSurface}`
					: theme.palette.primary[800],
			color: theme.palette.primary[800],
		},
		"&:focus": {
			borderColor: (styleProps: styleProps) =>
				styleProps.tertiary
					? `2px solid ${theme.palette.borderColor.onSurface}`
					: theme.palette.primary[700],
			color: theme.palette.primary[700],
			outline: "none",
		},
		"&:active": {
			borderColor: (styleProps: styleProps) =>
				styleProps.tertiary
					? `2px solid ${theme.palette.borderColor.onSurface}`
					: theme.palette.onSurface.highEmphasis,
			color: theme.palette.onSurface.highEmphasis,
		},
		"&.Mui-disabled": {
			background: "transparent!important",
		},
	},
	text: {
		color: theme.palette.onSurface.highEmphasis,
		"&:hover": {
			color: theme.palette.primary[800],
		},
		"&:focus": {
			color: theme.palette.primary[700],
			outline: "none",
		},
		"&:active": {
			color: theme.palette.onSurface.highEmphasis,
		},
		"&.Mui-disabled": {
			background: "transparent!important",
		},
	},
	sizeLarge: {
		width: "170px",
		height: "58px",
	},
	sizeSmall: {
		width: "120px",
		height: "36px",
	},
	disabled: {
		"&.Mui-disabled": {
			background: theme.palette.onSurface.btnDisabled,
			color: theme.palette.onSurface.disabled,
		},
	},
	textContainer: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
	},
}));

type Version = "primary" | "secondary" | "tertiary" | "text";

type styleProps = {
	tertiary: boolean;
	width?: string;
};

interface ButtonProps {
	size?: ButtonTypeMap["props"]["size"];
	version?: Version;
	text: string;
	disabled?: boolean;
	loading?: boolean;
	onClick?: (arg?: any) => void;
	fullWidth?: boolean;
	width?: string;
	style?: any;
	textStyle?: any;
	id?: string;
	icon?: string;
	jwt?: string;
}

const Btn: FC<ButtonProps> = ({
	size = "small",
	version = "primary",
	text = "",
	disabled = false,
	loading = false,
	onClick,
	fullWidth,
	width,
	style,
	textStyle,
	id,
	icon,
	jwt,
}) => {
	const styleProps =
		version === "tertiary"
			? { tertiary: true, width: width }
			: { tertiary: false, width: width };
	const classes = useStyles(styleProps);
	const { t } = useTranslation();

	const whichVariant = (): ButtonTypeMap["props"]["variant"] => {
		if (version === "primary") {
			return "contained";
		} else if (version === "secondary" || version === "tertiary") {
			return "outlined";
		} else if (version === "text") {
			return "text";
		}
	};

	return (
		<Button
			data-jwt={jwt}
			size={size}
			fullWidth={fullWidth}
			variant={whichVariant()}
			disableElevation
			classes={{
				contained: classes.contained,
				outlined: classes.outlined,
				text: classes.text,
				sizeLarge: classes.sizeLarge,
				sizeSmall: classes.sizeSmall,
				disabled: classes.disabled,
				root: classes.root,
			}}
			disabled={disabled}
			onClick={onClick}
			style={style}
			id={id}>
			{loading && version !== "text" ? (
				<CircularProgress
					color="inherit"
					size={20}
				/>
			) : (
				<div className={classes.textContainer}>
					{icon && <img src={icon} />}
					<Typography
						variant="button"
						style={textStyle}>
						{loading && version === "text" ? t("general.loading") : text}
					</Typography>
				</div>
			)}
		</Button>
	);
};

export default Btn;
