import {
    Card,
    Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import illustrationTutorial from './assets/illustration-tutorial.png'

import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any) => ({
    container: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(6),
    },
    firstTime: {
        width: "567px",
        height: "309px",
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7),
            height: "257px",
            width: "100%",
        },
    },
    classic: {
        width: "316px",
        height: "207px",
        padding: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        [theme.breakpoints.down(300)]: {
            padding: theme.spacing(5),
        },
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis,
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    imgFirst: {
        height: "100%",
        marginLeft: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            height: "60%",
        }
    },
    img: {
        height: "60%",
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            height: "100%",
        },
        [theme.breakpoints.down(300)]: {
            height: "60%",
        },
    },
    imgContainer: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only('md')]: {
            marginLeft: theme.spacing(11),
        },
        [theme.breakpoints.between('xs')]: {
            marginLeft: theme.spacing(7),
        },
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            width: "70%",
        }
    },
    firstTimebtn: {
        width: "209px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    notFirstTimebtn: {
        width: "150px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    }
}))


interface TutorialCardProps {
    firstTime: boolean
}

const TutorialCard: FC<TutorialCardProps> = ({
    firstTime
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Card className={clsx(classes.container, {
            [classes.firstTime]: firstTime,
            [classes.classic]: !firstTime
        })}
            elevation={2}
        >
            <div className={classes.textContainer}>
                {firstTime ? <>
                    <Typography
                        variant="h5"
                        className={classes.highEmphasis}
                    >
                        {t('dashboard.tutorialTitleFirst')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        className={classes.mediumEmphasis}
                    >
                        {t('dashboard.tutorialSubtitleFirst')}
                    </Typography>
                </>
                    :
                    <>
                        <Typography
                            variant="subtitle1"
                            className={classes.highEmphasis}
                        >
                            {t('dashboard.tutorialTitle')}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.mediumEmphasis}
                        >
                            {t('dashboard.tutorialSubtitle')}
                        </Typography>
                    </>
                }
                <div className={clsx({
                    [classes.firstTimebtn]: firstTime,
                    [classes.notFirstTimebtn]: !firstTime,
                })}>
                    <a 
                        href="https://docs.sideos.io/doc/tutorials" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{textDecoration: "none"}}
                    >
                    <Button
                        text={t('dashboard.tutorialBtn')}
                        version="tertiary"
                        width="100%"
                        size={firstTime ? "large" : "medium"}
                    />
                    </a>
                </div>
            </div>
            <div className={clsx({
                [classes.imgContainer]: !firstTime,
            })}
            >
                <img
                    alt="illustration of a girl learning"
                    src={illustrationTutorial}
                    className={clsx({
                        [classes.imgFirst]: firstTime,
                        [classes.img]: !firstTime,
                    })}
                />
            </div>
        </Card>
    )
}

export default TutorialCard