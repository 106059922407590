import {
    Divider,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme?: any) => ({
    row: {
        display: "flex",
        width: "522px",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "center",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: "85%",
            justifyContent: "space-around",
            alignItems: "flex-start"
          }
    },
    button: {
        width: "36px",
        height: "36px",
        border: `2px solid ${theme.palette.onSurface.mediumEmphasis}`,
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
          }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize:"10px",
            marginTop: theme.spacing(2),
            maxWidth: "90px"
          }
    },
    divider: {
        height: "2px",
        width: "30px",
        marginRight: 0,
        backgroundColor: theme.palette.onSurface.mediumEmphasis,
        [theme.breakpoints.down('sm')]: {
            width: "0",
          }
    },
    step: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
          }
    },
    buttonActive: {
        border: `2px solid ${theme.palette.primary[600]}`,
    },
    buttonPrev: {
        border: `2px solid ${theme.palette.primary[600]}`,
        backgroundColor: theme.palette.primary[600],
        "&:hover h6": {
            color: theme.palette.primary[600]
        }
    },
    textActive: {
        color: theme.palette.primary[600],
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize:"10px",
            marginTop: theme.spacing(2)
          }
    },
    textPrev: {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary[600]
        }
    },
    dividerActive: {
        backgroundColor: theme.palette.primary[600]
    },
    stepsRow:{
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            textAlign: "center"
          }
    },
    downloadRow:{
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
          }
    },
    disabled: {
        color: theme.palette.onSurface.disabled,
    },
    disabledBorder: {
        border: `2px solid ${theme.palette.onSurface.disabled}`,
    },
    disabledDivider: {
        backgroundColor: theme.palette.onSurface.disabled,
    }
}))

interface TestTemplateStepperProps {
    step: number
    setStep: (step: number) => void
    credential: any
    disabled: boolean
}

const TestTemplateStepper: FC<TestTemplateStepperProps> = ({
    step,
    credential,
    disabled
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <div className={classes.row}>
            <div 
                className={clsx(classes.step, classes.downloadRow)} 
                onClick={()=>!disabled && history.push(`/credentials/${credential.id}/testoffer`, {step: 1})}
            >
            <IconButton disabled={disabled} className={clsx(classes.button, {
                [classes.buttonActive]: step === 1,
                [classes.buttonPrev]: step !== 1,
                [classes.disabledBorder]: disabled
            })}>
                <Typography 
                    variant="subtitle2" 
                    className={clsx({
                        [classes.textActive]: step === 1,
                        [classes.textPrev]: step !== 1,
                        [classes.disabled]: disabled
                    })}
                >
                    1
                </Typography>
            </IconButton>        
            <Typography 
                variant="body2" 
                className={clsx(classes.textActive, {
                    [classes.disabled]: disabled
                })} 
            >
                {t('testCredential.step1')}
            </Typography>
       </div>
       <div className={classes.stepsRow}>
        <div 
            className={classes.step} 
            onClick={()=>!disabled && history.push(`/credentials/${credential.id}/testoffer`, {step: 2})}
        >
            <Divider 
                className={clsx(classes.divider, {
                    [classes.dividerActive]: step !== 1,
                    [classes.disabledDivider]: disabled
                })} 
                variant="fullWidth"
            />
            <IconButton disabled={disabled} className={clsx(classes.button, {
                [classes.buttonActive]: step === 2,
                [classes.buttonPrev]: step === 3,
                [classes.disabledBorder]: disabled
            })}>
                <Typography 
                    variant="subtitle2"
                    className={clsx({
                        [classes.textActive]: step === 2,
                        [classes.textPrev]: step === 3,
                        [classes.disabled]: disabled
                    })}
                >
                    2
                </Typography>
            </IconButton>
           
        </div>
        <Typography 
                variant="body2" 
                className={clsx(classes.text, {
                    [classes.textActive]: step !== 1,
                    [classes.disabled]: disabled
                })}
            >
                {t('testCredential.step2')}
            </Typography>
        </div>
        <div className={classes.stepsRow}>
        <div className={classes.step} onClick={()=>!disabled && history.push(`/credentials/${credential.id}/testrequest`)}>
            <IconButton disabled={disabled} className={clsx(classes.button, {
                [classes.buttonActive]: step === 3,
                [classes.disabledBorder]: disabled
            })}>
                <Typography variant="subtitle2" className={clsx({
                    [classes.textActive]: step === 3,
                    [classes.disabled]: disabled
                })}>
                    3
                </Typography>
            </IconButton>
        </div>
        <Typography 
                variant="body2" 
                className={clsx(classes.text, {
                    [classes.textActive]: step === 3,
                    [classes.disabled]: disabled
                })}
            >
                {t('testCredential.step3')} 
            </Typography>
            </div>
        </div>
    )
}

export default TestTemplateStepper