import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import {useHistory} from "react-router-dom";


import backIcon from './assets/back-icon.svg'

import IconBtn from './IconButton';

const useStyles = makeStyles((theme?: any)=>({
    backbtnContainer: {
        marginLeft: theme.spacing(5)
    }
}))

interface BackButtonProps {
}

const BackButton: FC<BackButtonProps> = () => {
    const classes = useStyles()

    const history = useHistory();

    const handleClick = () => {
        history.goBack()
    }

    return (
        <div className={classes.backbtnContainer}>
            <IconBtn
                onClick={handleClick}
                version="secondary"
                iconSecondary={backIcon}
            />   
        </div>   
    )
}

export default BackButton