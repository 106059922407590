import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    getCustomer,
    updateCustomer,
    deleteCustomer,
    updateCustomerStatus,
} from '../actions/onboarding';
import {
    Divider,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { isFlag } from '../commons/utils';
import { hasPermissions } from '../commons';
import { plans } from '../commons/plans';

import DisableModal from './DisableModal';
import Breadcrumbs from '../commons/components/Breadcrumbs'
import CustomerStatusCard from './CustomerStatusCard';
import CustomerInfoCard from './CustomerInfoCard';
import MainContactInfoCard from './MainContactInfoCard';
import TemplatesSmallTableCard from './TemplatesSmallTableCard';
import AdminsSmallTableCard from './AdminsSmallTableCard';
import OnboardingSmallTableCard from './OnboardingSmallTableCard';
import DeleteModal from './DeleteModal';
import { Customer } from '../commons/types';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    disabled: {
        color: theme.palette.onSurface.disabled
    },
    divider: {
        marginBottom: theme.spacing(6)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface AdminProfileProps extends PropsFromRedux { }

const AdminProfile: FC<AdminProfileProps> = ({
    customer,
    getCustomer,
    updateCustomer,
    updateCustomerStatus,
    deleteCustomer,
    qrcode,
    emailSent,
    permissions,
    mainContact,
    offerResent
}) => {
    const classes = useStyles()
    // @ts-ignore
    let { customerId } = useParams()
    const history = useHistory()
    const ACCEPTED = 4
    const DISABLED = 1
    const [openModal, setOpenModal] = useState(false)
    const [disabledStatusCardInput, setDisabledStatusCardInput] = useState(true)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [fields, setFields] = useState({
        companyName: "",
        wallet: {
            id: 0
        },
        name: "",
        lastName: "",
        email: "",
        plan: {
            id: 0
        }
    })
    const [cust, setCust] = useState<Customer | undefined>()

    useEffect(()=>{
        //@ts-ignore
        if (mainContact?.Customer?.tenant && mainContact?.Customer?.TenantId) {
            //@ts-ignore
            setCust(customer?.Subtenants?.[0])
        //@ts-ignore
        } else if (customer?.Multitenant && customer?.Multitenant.length !== 0) {
            //@ts-ignore
            setCust(customer?.Multitenant?.[0])
        } else {
            setCust(customer)
        }
    }, [customer])

    useEffect(() => {
        if (customerId && !qrcode.data) {
            getCustomer(customerId)
        }
    }, [customerId, getCustomer, qrcode, offerResent])

    useEffect(() => {
        if (emailSent) {
            getCustomer(customerId)
        }
    }, [emailSent])

    useEffect(() => {
        setFields({
            companyName: cust?.name || "",
            //@ts-ignore
            wallet: cust?.Wallet || "",
            name: cust?.MainAdmin?.[0]?.name || "",
            lastName: cust?.MainAdmin?.[0]?.lastName || "",
            email: cust?.MainAdmin?.[0]?.email || "",
            //@ts-ignore
            plan: plans[cust?.PlanId] || ""
        })
    }, [cust])

    const handleCancel = () => {
        setOpenModal(false)
    }

    const handleAffirmative = () => {
        updateCustomerStatus({
            name: cust?.name,
            CustomerId: cust?.id,
            disable: isFlag(cust?.status, DISABLED) ? false : true
        })
        setOpenModal(false)
    }

    const handleEditCustomerDraft = () => {
        updateCustomer({
            CustomerId: cust?.id,
            companyName: fields.companyName,
            name: fields.name,
            lastName: fields.lastName,
            email: fields.email,
            WalletId: !cust?.Wallet?.id ? fields.wallet?.id : null,
            toUpgrade: fields.plan?.id ? fields.plan?.id : 1
        })
        setDisabledStatusCardInput(true)
    }

    const handleInputChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleSelect = (name: string, value: any) => {
        setFields({ ...fields, [name]: value })
    }

    const handleDelete = () => {
        setShowDeleteModal(true)
    }

    const onDelete = async () => {
        const response = await deleteCustomer({ CustomerId: cust?.id })
        if (response?.status === 200) {
            history.push("/onboarding")
        }
    }

    return (
        <div className={clsx(classes.container)}>
            <Breadcrumbs 
                item={cust} 
                //@ts-ignore
                mt={cust?.Tenant ? {name: cust?.Tenant?.name, id: cust?.Wallet?.CustomerId} : undefined} 
            />
            <Divider className={classes.divider} />
            {
                cust?.MainAdmin?.[0]?.status === ACCEPTED ?
                    <>
                        <CustomerInfoCard
                            setOpenModal={setOpenModal}
                            customer={cust}
                        />
                        <MainContactInfoCard
                            customer={cust}
                        />
                        <AdminsSmallTableCard
                            customer={cust}
                        />
                        {
                            cust?.tenant && 
                                <OnboardingSmallTableCard 
                                    //@ts-ignore
                                    customers={customer?.Subtenants}
                                    disabled={isFlag(cust?.status, DISABLED) ? true : false}
                                />
                        }
                        <TemplatesSmallTableCard
                            //@ts-ignore
                            customer={cust.tenant ? customer?.Customers?.[0] : customer}
                            disabled={isFlag(cust?.status, DISABLED) ? true : false}
                        />
                        {openModal && <DisableModal
                            showTAndC={hasPermissions(permissions, {admin: ["O"]}) ? true : false}
                            open={openModal}
                            onCancel={handleCancel}
                            onAffirmative={handleAffirmative}
                            companyName={customer?.name}
                            disabled={isFlag(customer?.status, DISABLED) ? true : false}
                        />}
                    </>               
                    :
                    <CustomerStatusCard
                        customer={cust}
                        fields={fields}
                        handleInputChange={handleInputChange}
                        handleSave={handleEditCustomerDraft}
                        handleDelete={handleDelete}
                        disabledInput={disabledStatusCardInput}
                        setDisabledInput={setDisabledStatusCardInput}
                        handleSelect={handleSelect}
                    />
            }
            {showDeleteModal && 
            (hasPermissions(permissions, {admin: ["O"], customers: ["DO"], mainContacts: ["DO"]}) || 
            hasPermissions(permissions, {tenant: ["O"], customers: ["D"], mainContacts: ["D"]})) &&
                <DeleteModal
                    open={showDeleteModal}
                    setShowModal={setShowDeleteModal}
                    handleDelete={onDelete}
                    id={customer?.id}
                    name={customer?.name}
                />
            }
        </div>
    );
}

const mapState = (state: RootState) => ({
    customer: state.onboarding.customer,
    mainContact: state.login.mainContact,
    emailSent: state.onboarding.emailSent,
    qrcode: state.onboarding.qrcode,
    permissions: state.login.permissions,
    offerResent: state.onboarding.offerResent
})

const mapDispatch = {
    getCustomer: (customerId: number) => (getCustomer(customerId)),
    updateCustomer: (data: Object) => (updateCustomer(data)),
    updateCustomerStatus: (data: Object) => (updateCustomerStatus(data)),
    deleteCustomer: (data: Object) => (deleteCustomer(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(AdminProfile)
