import {
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import general from './assets/notification-general.svg'
import info from './assets/notification-info.svg'
import warning from './assets/notification-warning.svg'
import TimeAgo from 'react-timeago'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme?: any)=>({
    title: {
        fontWeight: 500,
        fontFamily: 'Poppins',
        maxWidth: "230px"
    },
    icon: {
        margin: theme.spacing(3, 7, 'auto', 7)
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "122px",
        background: theme.palette.primary.hover,
        color: theme.palette.text.primary,
        padding: theme.spacing(4, 4, 3, 0),
        borderBottom: `1px solid ${theme.palette.surface.overlay}`,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "stretch"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        width: "230px",
        padding: 0,
        margin: 0,
        color: theme.palette.onSurface.highEmphasis,
        fontFamily: theme.typography.body2.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        "& div": {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            width: "230px",
        },
        "& div div": {
            width: "230px",
        },
        "& p": {
            padding: 0,
            margin: 0,
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            color: theme.palette.onSurface.highEmphasis,
            fontFamily: theme.typography.body2.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,
        },
        "& a": {
            width: "",
            display: "inline-block",
            flexWrap: "nowrap",
            alignSelf: "flex-end",
            marginBottom: theme.spacing(1),
            color: theme.palette.primary[600],
            fontFamily: theme.typography.button.fontFamily,
            fontSize: theme.typography.button.fontSize,
            fontWeight: theme.typography.button.fontWeight,
            lineHeight: theme.typography.button.lineHeight,
            letterSpacing: theme.typography.button.letterSpacing,
        }
    },
    time: {
        color: theme.palette.onSurface.mediumEmphasis,
        width: "fit-content",
        wordWrap: "normal",
        position: "fixed",
        right: theme.spacing(4),
    },
    cta: {
        textTransform: "none",
        textAlign: "end",
        marginBottom: theme.spacing(4),
        cursor: "pointer"
    }
}))

type NotificationProps = {
    notification: {
        id: number
        title: string
        text: string
        summary: string
        createdAt: string
        status: number
        type: number
    }
    handleRead: (id: number) => Promise<void>
}

const Notification: FC<NotificationProps> = ({notification, handleRead}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <div className={classes.icon}>
                    {notification.type === 0 && <img src={general} alt="general notification icon"/> }
                    {notification.type === 1 && <img src={info} alt="info notification icon"/> }               
                    {notification.type === 2 && <img src={warning} alt="warning notification icon"/>}                
                </div>
                <div className={classes.column}>
                    <Typography variant="subtitle1" className={classes.title}>
                        {notification.title}
                    </Typography>
                    <div dangerouslySetInnerHTML={{__html: notification.summary}}>                   
                    </div>
                </div>
                <div>
                    <Typography variant="caption" className={classes.time}>
                        <TimeAgo date={Date.parse(notification.createdAt)}/>                    
                    </Typography>
                </div>
            </div>
                <Typography className={classes.cta} variant="button" onClick={()=>handleRead(notification.id)}>
                    {notification.type === 2 ? t('notifications.dismiss') : t('notifications.gotit')}
                </Typography>
        </div>          
    )
}

export default Notification