import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Paper,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isFlag } from '../commons/utils';
import { parseDateAndTime } from '../commons';
import { Customer } from '../commons/types'
import { Link } from 'react-router-dom';

import StatusBadge from '../commons/components/StatusBadge';
import Search from '../commons/components/Search';
import SmallTable from '../commons/components/SmallTable';
import TableDateAndTime from '../commons/components/TableDateAndTime';
import CollapseTable from '../commons/components/CollapseTable';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: theme.spacing(4),
        }
    },
    title: {
        fontWeight: theme.typography.bold.fontWeight,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
        }
    },
    searchContainer: {
        width: "25%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    tableContainer: {
        height: "340px",
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis,
        textDecoration: "none"
    },
    description: {
        color: theme.palette.onSurface.disabled
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface TemplatesSmallTableCardProps extends PropsFromRedux {
    customer: Customer
    disabled: boolean
}

const TemplatesSmallTableCard: FC<TemplatesSmallTableCardProps> = ({
    customer,
    disabled
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const INUSE = 4
    const DRAFT = 1
    const [matches, setMatches] = useState<{ [name: string]: string | number }[]>([])
    const [input, setInput] = useState("")
    const [templates, setTemplates] = useState<any>([])

    const columns = {
        createdAt: t('customerProfile.created'),
        id: t('customerProfile.id'),
        type: t('customerProfile.credType'),
        name: t('customerProfile.credName'),
        status: t('customerProfile.status')
    }

    useEffect(() => {
        const templatesArr: any = []
        customer?.MainContacts?.map((mc: any) => {
            mc.Templates?.map((template: any) => {
                templatesArr.push(template)
                return templatesArr
            })
            return templatesArr
        })
        setTemplates([...templatesArr])
    }, [customer])

    const renderStatus = (template: any) => {
        if (isFlag(template.status, DRAFT)) {
            return t('statusText.draft')
        } else if (isFlag(template.status, INUSE)) {
            const companiesNo = template.companies ? t('statusText.used') + t('statusText.companiesNo', { number: template.companies }) : t('statusText.used')
            return companiesNo
        } else {
            return t('statusText.unused')
        }
    }

    const renderSearchMatches = (matches: [], input: string) => {
        setInput(input)
        setMatches(matches)
    }

    const checkExpandableValue = (item: any) => {
        return isFlag(item.status, INUSE) ? true : false
    }

    return (
        <div className={clsx(classes.container)}>
            <Paper elevation={0} >
                <div className={classes.titleContainer}>
                    <Typography
                        variant="h6"
                        className={
                            clsx(classes.highEmphasis, classes.title)
                        }
                    >
                        {t('customerProfile.templates')}
                    </Typography>
                    <div className={classes.searchContainer}>
                        <Search
                            rows={templates}
                            renderSearchMatches={renderSearchMatches}
                        />
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <SmallTable
                        rows={input !== "" ? matches : templates}
                        columns={columns}
                        tableCells={{
                            createdAt: (item: any) => {
                                return <TableDateAndTime dateAndTime={parseDateAndTime(item.createdAt)} />
                            },
                            status: (item: any) => {
                                return (
                                    <StatusBadge
                                        status={renderStatus(item)}
                                    />
                                )
                            },
                        }}
                        disabled={disabled}
                        expandable={true}
                        checkExpandableValue={(item: Credential) => checkExpandableValue(item)}
                        collapseElement={(item) =>
                            <CollapseTable
                                titles={[
                                    { prop: "Proofs", title: t('onboarding.proofs') },
                                    { prop: "description", title: t('onboarding.description') }
                                ]}
                                item={item}
                                tableCells={{
                                    Proofs: (item: any) => {
                                        return (item?.Proofs.map((proof: any) => {
                                            return (
                                                <p>
                                                    <Link
                                                        to={`/claims/${proof.id}`}
                                                        key={proof.id}
                                                        className={classes.mediumEmphasis}
                                                    >
                                                        <Typography
                                                            variant="button"
                                                            className={classes.mediumEmphasis}
                                                        >
                                                            {proof.name}
                                                        </Typography>
                                                    </Link>
                                                </p>
                                            )
                                        })
                                        )
                                    },
                                    description: (item: any) => {
                                        return (
                                            <Typography
                                                variant="body1"
                                                className={classes.description}
                                            >
                                                {item.description}
                                            </Typography>
                                        )
                                    }
                                }}
                            />
                        }
                    />
                </div>
            </Paper>
        </div>
    );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(TemplatesSmallTableCard)
