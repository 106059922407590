import API from '../api'
import { AppDispatch } from "../store";

export const dispatchTrialError = (data: Object) => async (dispatch: AppDispatch) =>  {
    dispatch({ type: 'TRIAL_ERROR', payload: data })
}

export const resetTrialError = () => async (dispatch: AppDispatch) =>  {
    dispatch({ type: 'RESET_TRIAL_ERROR' })
}

export const pollingUpgrade = (CustomerId: number, PlanId: string, active: boolean) => async (dispatch: AppDispatch) =>  {
    let response 
    try {
      let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/upgrade/' + CustomerId)
      if (active) {
        url.searchParams.set("PlanId", PlanId)
        url.searchParams.set("active", "true")
        response = await API.get(url.toString())
      } else {
        response = await API.get(url.toString())
      }
      return response.data
    } catch(e: any) {
        return e?.response?.status ? e?.response?.status : "what"
    }
  }

  export const pollingUpgradeStorage = (CustomerId: number, PlanId: string) => async (dispatch: AppDispatch) =>  {
      let response 
      try {
        let url = new URL((process.env.REACT_APP_API_URL || "http://localhost:3200")+'/api/upgraded/' + CustomerId)
          url.searchParams.set("PlanId", PlanId)
          response = await API.get(url.toString())
        return response.data
      } catch(e: any) {
          return e?.response?.status ? e?.response?.status : "what"
      }
    }