import {
    Divider,
    Paper,
    Snackbar,
    Typography,
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router';
import {
    getTemplate,
} from '../actions/credentials';
import { resetToken } from '../actions/wallets';
import { getSettingsInfo } from '../actions/settings';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../commons/components/Breadcrumbs';
import TestTemplateStepper from './TestTemplateStepper';
import TestTemplateRequest from './TestTemplateRequest';
import TestTemplateOffer from './TestTemplateOffer';
import TestTokenModal from './TestTokenModal';
import TokenModal from '../commons/components/TokenModal';
import BackButton from '../commons/components/BackButton';

import warningIcon from './assets/warning-icon.svg'
import { hasPermissions } from '../commons';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    paperContainer: {
        minHeight: "456px",
        height: "auto",
        padding: theme.spacing(7),
        paddingBottom: theme.spacing(11),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(7),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    btnRow: {
        position: "absolute",
        bottom: theme.spacing(7),
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            marginRight: "unset",
            flexDirection: "column",
            width: "100%"
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: theme.spacing(4),
        }
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    columns: {
        columns: "2 auto",
    },
    column: {
        width: "47%",
    },
    textMargin: {
        marginBottom: theme.spacing(4)
    },
    link: {
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.onSurface.highEmphasis}`,
        width: "286px",
        height: "42px",
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      btnLink: {
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(3),
        }
      },
      centerContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          margin: theme.spacing(8, 0)
      },
      snackBtn: {
          width: "136px",
          display: "flex",
          justifyContent: "center",
          cursor: "pointer"
      },
      snackContainer: {
          display: "flex",
      },
      warningIcon: {
          marginRight: theme.spacing(6)
      },
      backBtnContainer: {
          position: "absolute",
          right: theme.spacing(7),
          [theme.breakpoints.down('sm')]: {
              display: "none"
          }
      }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface TestTemplateProps extends PropsFromRedux {
}

const TestTemplate: FC<TestTemplateProps> = ({
    getTemplate,
    template,
    resetToken,
    mainContact,
    settingsInfo,
    getSettingsInfo,
    permissions
}) => {
    const classes = useStyles()
    // @ts-ignore
    let { credentialId } = useParams()
    const location = useLocation()
    const [step, setStep] = useState(1)
    let loc = location.pathname.split("/")
    const [openTestTokenModal, setOpenTestTokenModal] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        getTemplate(credentialId)
    }, [getTemplate, credentialId])

    useEffect(()=>{
        if (settingsInfo?.maincontact?.Customer?.Wallet?.accessToken) {
            setOpenTestTokenModal(false)
            setDisabled(false)
        } else {
            setOpenTestTokenModal(true)
            setDisabled(true)
        }
    }, [settingsInfo])

    useEffect(()=>{
        if (loc[loc.length-1] === "testrequest") {
            setStep(3)
        }
    }, [loc])

    const handleTokenReset = async () => {
        const response = await resetToken({
            WalletId: settingsInfo?.maincontact?.Customer?.Wallet?.id,
        })
        if (response?.data) {
            {(hasPermissions(permissions, {
                admin: ["O"],
                mainContacts: ["RO","R"],
                customers: ["RO","R"],
                plans: ["RO"],
                wallets: ["RO","R"]
            }) ||
            hasPermissions(permissions, {
                mainContacts: ["R"],
                customers: ["R"],
                plans: ["R"],
                wallets: ["R"]
            })) && getSettingsInfo(mainContact.id)}
        }
        setOpenTestTokenModal(false)
        setOpenSnack(false)
    }

    return (
        <div className={classes.container}>

            <Breadcrumbs item={template} text={t('testCredential.breadcrumbText')}/>
            <Divider />

        <Paper elevation={0} className={classes.paperContainer}>
                <TestTemplateStepper 
                    step={step} 
                    setStep={setStep} 
                    credential={template}
                    disabled={disabled}
                    />
                <div className={classes.backBtnContainer}>
                    <BackButton />
                </div>
            {
                loc[loc.length-1] === "testrequest" &&
                    <TestTemplateRequest />
            }
            {
                loc[loc.length-1] === "testoffer" &&
                    <TestTemplateOffer 
                        step={step}
                        setStep={setStep}
                        disabled={disabled}
                    />
            }
        </Paper>
        <TestTokenModal
            open={openTestTokenModal}
            onCancel={() => {setOpenTestTokenModal(false); mainContact.main && setOpenSnack(true)}}
            handleTokenReset={() => handleTokenReset()}
            mainContact={mainContact}
        />
        <TokenModal />
        <Snackbar 
            open={openSnack}
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom"
            }}
            message={<div className={classes.snackContainer}>
                <img src={warningIcon} className={classes.warningIcon}/>
                <Typography variant="body2">
                    {t('testCredential.snackbarWarning')}
                </Typography>
                </div>
            }
            action={<div onClick={()=>handleTokenReset()} className={classes.snackBtn}>
                    <Typography variant="button">
                        {t('testCredential.snackbarAction')}
                    </Typography>
                </div>
            }
        />
        </div>
    )
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    template: state.credentials.template,
    proofs: state.proofs.proofs,
    permissions: state.login.permissions,
    settingsInfo: state.settings.settingsInfo
})

const mapDispatch = {
    getTemplate: (TemplateId: number) => getTemplate(TemplateId),
    resetToken: (data: Object) => resetToken(data),
    getSettingsInfo: (data: number) => (getSettingsInfo(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(TestTemplate) 