import {
    InputBase,
    IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import searchIcon from './assets/search-icon.svg'
import arrowRight from './assets/arrow-right.svg'
import clearIcon from './assets/close-icon.svg'

const useStyles = makeStyles((theme?: any) => ({
    input: {
        height: "40px",
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        borderRadius: theme.shape.sharpBorderRadius,
        margin: theme.spacing(6, 0, 6, 0),
        padding: theme.spacing(4),
        color: theme.palette.onSurface.mediumEmphasis,
        "&.MuiInputBase-root:focus-within": {
            border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.hover,
            border: `1px solid ${theme.palette.primary[600]}`
        },
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(6, 0, 6, 0),
        }
    },
    icon: {
        marginRight: theme.spacing(5)
    },
    row: {
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    iconBtnContainer: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
        }
    },
    iconBtn: {
        background: theme.palette.onSurface.btnDisabled
    },
    clearIcon: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}))


interface SearchBEProps {
    handleSearch: (input: string) => void
}

const SearchBE: FC<SearchBEProps> = ({
    handleSearch
}) => {

    const classes = useStyles()
    const { t } = useTranslation()
    const [input, setInput] = useState("")

    const clearSearch = () => {
        setInput("")
        handleSearch("")
    }

    const clear = <div
        onClick={() => clearSearch()}
        className={classes.clearIcon}
    >
        <img src={clearIcon} alt="x icon"/>
    </div>

    return (
        <div className={classes.row}>
            <InputBase
                className={classes.input}
                value={input}
                name="search"
                placeholder={t('general.search')}
                startAdornment={
                    <img
                        src={searchIcon}
                        alt="magnifying glass icon"
                        className={classes.icon}
                    />
                }
                type="string"
                onChange={(e) => setInput(e.target.value)}
                fullWidth
                endAdornment={input ? clear : null}
            />
            <div className={classes.iconBtnContainer}>
                <IconButton
                    children={<img src={arrowRight} alt="right arrow icon"/>}
                    className={classes.iconBtn}
                    onClick={() => handleSearch(input)}
                />
            </div>
        </div>
    )
}

export default SearchBE