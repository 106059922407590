import { format, parse } from 'date-format-parse';

export const parseDate = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  return format(d, "DD/MM/YYYY")
}

export const parseDateDotFormat = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  return format(d, "DD.MM.YYYY")
}

export const parseTime = (item) => {
  const date = new Date(item).toString()
  return format(date, "hh:mm:ss")
}

export const parseDateLongMonth = (item) => {
  const d = parse(item, "YYYY-MM-DDTHH:mm:ss")
  const obj = {
    months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
}
  return format(d, 'MMMM DD, YYYY', { locale: obj })
}

export const dateLongMonth = (item) => {
  const obj = {
    months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
}
  return format(item, 'MMMM DD, YYYY', { locale: obj })
}

export const parseDateAndTime = (item) => {
  const date = new Date(item).toString()
  return format(date, "DD/MM/YYYY hh:mm:ss")
}

export const hasPermissions = (ownedPermissions, toCheck) => {
  for (var resource in toCheck) { // Go through all resource permissions to check
    if (ownedPermissions[resource]) {
      for (let permission of toCheck[resource]) {
        if (!ownedPermissions[resource].includes(permission)) {
          return false
          }
        }
    } else { // Does not have this resource...
      return false
    }
  }
  return true
}





