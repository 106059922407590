export const setImageFromBlob = (src: string, setSrc: (src: string)=>void) => {
    var reader = new FileReader();
    const blob = new Blob([src]);
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
        let base64data = reader.result;
        setSrc(base64data as string)
    }
}

export const validateEmail = (email: string) => {
    let regExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return regExp.test(email)
}

export function isFlag(value: any, flag: number) {
    return value & flag
}

export function freeTrial(assignedOn: string) {
    const start = new Date(assignedOn);
    const today = new Date();
    const oneDay = 1000 * 60 * 60 * 24;

    const diffInTime = today.getTime() - start.getTime();

    const diffInDays = Math.round(diffInTime / oneDay);

    return 30 - diffInDays
}

function importAll(r: any) {
    let images = {};
    //@ts-ignore
     r.keys().forEach((item: any, index: any) => { images[item.replace('./', '')] = r(item); });
    return images
   }

export const pickRandomImage = () => {
    // require context - webpack - import all the images from a specified folder
    const images = importAll(require.context('../settings/assets/securityImgs/', false, /\.(png|jpe?g|svg)$/));
    const randomImageName = Object.keys(images)[Math.floor(Math.random() * Object.keys(images).length)]
    //@ts-ignore
    const randomImage = images[randomImageName]
    return randomImage.default
}