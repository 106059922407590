import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { FC, useEffect } from 'react';
import Button from './Button'
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../store'
import { getTransactions } from "../../actions/transactions";
import clsx from "clsx";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import warningIcon from './assets/warning-icon.svg'

const useStyles = makeStyles((theme?: any)=>({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(8),
        width: "532px",
        height: "348px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "381px",
            width: "100%",
            textAlign: "center",
            padding: theme.spacing(8, 5),
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%"
        }
    },
    btnMargin: {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3), 
        }
    },
    btn: {
        width: "180px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            height: "58px"
        }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface WarningModalProps extends PropsFromRedux {
    open: boolean
    onCancel?: () => void,
    handleTokenReset?: () => void,
    customer?: any
}

const WarningModal: FC<WarningModalProps> = ({
    open=false,
    handleTokenReset,
    onCancel,
    transactions,
    getTransactions,
    customer
}) => {    
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(()=>{
        if (customer?.id !== 0) {
            getTransactions({
                startDate: '2020-01-01T00:00:00', 
                companyFilter: customer
            })
        } else {
            getTransactions({startDate: '2020-01-01T00:00:00'})
        }
    }, [getTransactions, customer])

    return (
        <Dialog 
            open={open} 
            classes={{paper: classes.dialogContainer}}
        >
            <img src={warningIcon} alt="warning icon" />
                <Typography 
                    variant="h6" 
                    className={classes.highEmphasis}
                >
                    {t('token.warningTitle')}
                </Typography>
                <Typography 
                    variant="subtitle1" 
                    className={classes.text}
                >
                    {transactions?.length <= 1 ? t('token.noTransactionsWarning') : t('token.yesTransactionsWarning')}
                </Typography>
            <div className={classes.btnRow}>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                    <Button 
                        text={t('general.Cancel')}
                        onClick={onCancel}
                        width="100%"
                        version="text"
                        size={matches ? "small" : "large"}
                    />
                </div>
                <div className={clsx(classes.btnMargin, classes.btn)}>
                <Button 
                    text={t('token.resetBtn')}
                    onClick={handleTokenReset}
                    width="100%"
                    size={matches ? "small" : "large"}
                />
                </div >

            </div>
        </Dialog>               
    )
}

const mapState = (state: RootState) => ({
    transactions: state.transactions.transactions
})

const mapDispatch = {
    getTransactions: (data: Object) => (getTransactions(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(WarningModal)
