import { useState, useEffect, FC } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  getTransactionStats,
  getTemplateStats,
  getCustomerStats,
  getAdminStats
} from '../actions/dashboard'
import type { RootState } from '../store'
import {
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { hasPermissions } from '../commons';
import {
  DatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getTransactions } from '../actions/transactions';
import useQuery from '../commons/hooks/useQuery';
import { 
  allowedPermissions, 
  incomingRole 
} from '../commons/permissions';

import Breadcrumbs from '../commons/components/Breadcrumbs'
import WelcomeCard from './WelcomeCard'
import StatsCard from './StatsCard'
import TutorialCard from './TutorialCard'
import DashboardCard from '../planonboarding/DashboardCard';
import { openModal } from '../actions/modals';

const useStyles = makeStyles((theme: any) => ({
  divider: {
    marginBottom: theme.spacing(0),
  },
  contentContainer: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  datePickerRow: {
    display: "flex",
    alignSelf: "flex-end",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(5),
    }
  },
  dateContainer: {
    width: "110px",
    margin: theme.spacing(0, 3),
  },
  textField: {
    cursor: "pointer",
    textAlign: "center",
  },
  calendarIcon: {
    marginRight: theme.spacing(3),
    cursor: "pointer"
  },
  mobileDisplay: {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    }
  },
  desktopDisplay: {
    display: "block",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    }
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>
interface DashboardProps extends PropsFromRedux { }

const Dashboard: FC<DashboardProps> = ({
  mainContact,
  adminStats,
  getAdminStats,
  getTemplateStats,
  templateStats,
  transactionStats,
  getTransactionStats,
  permissions,
  getCustomerStats,
  customerStats,
  transactions,
  getTransactions,
  openModal
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [firstTime, setFirstTime] = useState(true)
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(new Date())
  const query = useQuery()
  const upgradeSuccess = query.get("state")

  useEffect(() => {
    getTransactions({
      startDate: '2020-01-01T00:00:00',
      endDate: endDate,
      companyFilter: mainContact?.Customer
    })
  }, [mainContact])

  useEffect(() => {
    {
      (hasPermissions(permissions, {admin: ["O"], mainContacts: ["RO"]}) ||
      hasPermissions(permissions, {mainContacts: ["R"]})) &&
      getAdminStats({ beg: startDate, end: endDate })
    }
    {
      (hasPermissions(permissions, {admin: ["O"], templates: ["RO"]}) || 
      hasPermissions(permissions, {templates: ["R"]})) &&
      getTemplateStats({ beg: startDate, end: endDate })
    }
    {
      (hasPermissions(permissions, {admin: ["O"], transactions: ["RO"]}) || 
      hasPermissions(permissions, {transactions: ["R"]})) && 
      getTransactionStats({ beg: startDate, end: endDate })
    }
    {
      (hasPermissions(permissions, {admin: ["O"], customers: ["RO"]}) ||
      hasPermissions(permissions, {tenant: ["O"], customers: ["R"]})) &&
      getCustomerStats({ beg: startDate, end: endDate })
    }
  }, [startDate, endDate, getAdminStats, getTemplateStats, getTransactionStats, permissions])

  useEffect(() => {
    if (transactions?.length > 1 || adminStats?.maincontacts > 1 || templateStats?.templates) {
      setFirstTime(false)
    } else {
      setFirstTime(true)
    }
  }, [transactions, adminStats, templateStats])

  useEffect(()=>{
    if (upgradeSuccess === "succeeded") {
      openModal({modalName: "processingUpgrade"})
    }
  }, [])
  
  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <Divider className={classes.divider} />
      <div className={classes.datePickerRow}>
        <div className={classes.dateContainer}><DatePicker
          value={startDate}
          format="MMM, do yyyy"
          onChange={(date: MaterialUiPickersDate) => { setStartDate(date) }}
          variant="inline"
          disableFuture={true}
          PopoverProps={{
            elevation: 2,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          autoOk={true}
        /></div>-
        <div className={classes.dateContainer}><DatePicker
          value={endDate}
          format="MMM, do yyyy"
          onChange={(date: MaterialUiPickersDate) => { setEndDate(date) }}
          variant="inline"
          disableFuture={true}
          PopoverProps={{
            elevation: 2,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          autoOk={true}
        /></div>
      </div>
      <div className={classes.contentContainer}>
        <div>
          <WelcomeCard
            firstTime={firstTime}
            name={mainContact?.name}
          />
          {(hasPermissions(permissions, {admin: ["O"], customers: ["RO"]}) ||
            hasPermissions(permissions, {tenant: ["O"], customers: ["R"]})) &&
            <StatsCard
              titleText={t('dashboard.newCustomers')}
              btnText={t('dashboard.onboardCompany')}
              link="/onboarding"
              numberOfNew={customerStats?.customers}
              firstTime={firstTime}
              diff={customerStats?.diff}
              permissions={permissions}
              showButton={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)}
            />
          }
          <div className={classes.mobileDisplay}>
            {
              (hasPermissions(permissions, { trial: ["O"]}) || mainContact?.Customer?.PlanId === 1) &&
              (hasPermissions(permissions, {admin: ["O"], transactions: ["RO"]}) ||
              hasPermissions(permissions, {transactions: ["R"]})) &&
                <DashboardCard
                  mainContact={mainContact}
                  transactions={transactionStats.transactions}
                />
            }
          </div>
          {
            (!hasPermissions(permissions, { admin: ["O"]}) &&
            hasPermissions(permissions, {mainContacts: ["R"]})) && 
              <StatsCard
                titleText={t('dashboard.newAdmins')}
                btnText={t('dashboard.addAdmin')}
                link="/settings/admins"
                numberOfNew={adminStats?.maincontacts}
                firstTime={firstTime}
                permissions={permissions}
                showButton={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)}
              />
          }
          {(hasPermissions(permissions, {admin: ["O"], templates: ["RO"]}) ||
            hasPermissions(permissions, {templates: ["R"]})) && <StatsCard
            titleText={t('dashboard.newTemplates')}
            btnText={t('dashboard.createCredential')}
            link="/credentials"
            numberOfNew={templateStats?.templates}
            firstTime={firstTime}
            permissions={permissions}
            showButton={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)}
          />}
          {!hasPermissions(permissions, { admin: "O" }) && 
          firstTime &&
          allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) &&
            <StatsCard
              titleText={t('dashboard.proofs')}
              btnText={t('dashboard.createProof')}
              link="/claims"
              firstTime={firstTime}
              permissions={permissions}
              showButton={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)}
            />
          }
        </div>
        <div>
          {/* Diplay in desktop version  */}
          <div className={classes.desktopDisplay}>
            {
              (hasPermissions(permissions, { trial: "O" }) || mainContact?.Customer?.PlanId === 1)
              &&
              (hasPermissions(permissions, {admin: ["O"],transactions: ["RO"]}) ||
              hasPermissions(permissions, {transactions: ["R"]})) &&
                <DashboardCard
                  mainContact={mainContact}
                  transactions={transactionStats.transactions}
                />
            }
          </div>
          {!firstTime && !hasPermissions(permissions, { trial: "O" }) &&
          mainContact?.Customer?.PlanId !== 1 && 
          <StatsCard
            titleText={t('dashboard.newTransactions')}
            firstTime={firstTime}
            numberOfNew={transactionStats?.transactions}
            diff={transactionStats?.diff}
            typeDropdown={true}
            permissions={permissions}
            showButton={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)}
          />}
          {!hasPermissions(permissions, { admin: "O" }) && 
          !firstTime && 
          allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) &&
          <StatsCard
            titleText={t('dashboard.proofs')}
            btnText={t('dashboard.createProof')}
            link="/claims"
            firstTime={firstTime}
            permissions={permissions}
            showButton={allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)}
          />}
          {!hasPermissions(permissions, { admin: "O" }) &&
            <TutorialCard firstTime={firstTime} />
          }
        </div>
      </div>
    </div>
  );
}

const mapState = (state: RootState) => ({
  mainContact: state.login.mainContact,
  adminStats: state.dashboard.adminStats,
  templateStats: state.dashboard.templateStats,
  transactionStats: state.dashboard.transactionStats,
  customerStats: state.dashboard.customerStats,
  permissions: state.login.permissions,
  transactions: state.transactions.transactions,
})

const mapDispatch = {
  getAdminStats: (data?: any) => (getAdminStats(data)),
  getTemplateStats: (data?: any) => (getTemplateStats(data)),
  getTransactionStats: (data?: any) => (getTransactionStats(data)),
  getCustomerStats: (data?: any) => (getCustomerStats(data)),
  getTransactions: (data?: any) => (getTransactions(data)),
  openModal: (data: any) => (openModal(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(Dashboard)
