import {
    FormGroup,
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../commons/components/Input';
import Button from '../commons/components/Button'
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { validateEmail } from '../commons/utils';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { resetTrialError } from '../actions/trial';
import {plans} from '../commons/plans'
import { useParams } from 'react-router';
import { pp } from '../commons/assets/privacypolicy';
import { tc } from '../commons/assets/termsandconditions';
import Loading from '../commons/components/Loading';

const useStyles = makeStyles((theme?: any) => ({
    centerText: {
        textAlign: "center",
    },
    form: {
        width: "100%"
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    checkboxContainer: {
        marginBottom: theme.spacing(6)
    },
    pinkLink: {
        color: theme.palette.primary[600]
    },
    checkbox: {
        "&.MuiCheckbox-root": {
            color: theme.palette.onSurface.mediumEmphasis
        },
        "&.Mui-checked": {
            color: theme.palette.primary[600]
        },
        marginBottom: theme.spacing(3)
    },
    termsCondutions:{
        overflowY: "scroll",
        height: "158px",
        marginTop: theme.spacing(6),
        maskImage: "linear-gradient(to bottom, white 70%, transparent 100%)",
        "&::-webkit-scrollbar": {
            width: "4px",
            backgroundColor: "rgba(90, 90, 90, 0.08)",
            marginLeft: theme.spacing(5)
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: theme.shape.sharpBorderRadius,
            backgroundColor: theme.palette.onSurface.disabled,
            height:"27.65px",
            marginLeft: theme.spacing(5)
        },
        [theme.breakpoints.down('sm')]: {
           padding: theme.spacing(5)
        }
    },
    terms:{
        maxWidth: "332px",
        whiteSpace: "pre"
    },
    transparent:{
        content:'',
        width:"100%",
        height:"50px", 
        position:"fixed",
        top:0,
        background:"black", 
    },
    lilaStar:{
        color: theme.palette.primary.variant
    },
    mediumEmphasis:{
        color: theme.palette.onSurface.mediumEmphasis
    },
    divider:{
        width: "100%",
        margin: theme.spacing(6, 0, 3, 0)
    },
    planSection: {
        alignSelf: "flex-start",
        width: "100%"
    },
    planRow: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(5)
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface PersonalInfoCardProps extends PropsFromRedux {
    label?: string
    send: any
    fields: any
    handleInputChange: any
    qrcode: string
    setQrcode: (arg: string)=>void
    checkboxes: any
    setCheckboxes: (arg: any) => void
    openSocket: ()=>void
}

const PersonalInfoCard: FC<PersonalInfoCardProps> = ({
    send,
    fields,
    handleInputChange,
    qrcode,
    trialError,
    checkboxes, 
    setCheckboxes,
    resetTrialError,
    openSocket
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    // @ts-ignore
    const {planId} = useParams()
    const listInnerRef = useRef<HTMLHeadingElement>(null);
    const [bottom, setBottom] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const [error, setError] = useState({
        field: "",
        msg: ""
    })

    useEffect(()=>{
        return () => {
            resetTrialError()
        }
    }, [])

    useEffect(() => {
        if (fields?.email && !validateEmail(fields?.email)) {
            setError({field: "email", msg: t('trial.emailError')})
        } else {
            setError({field: "", msg: ""})
        }
    }, [fields?.email])
    
    useEffect(()=>{
        if (qrcode) {
            setLoading(false)
            send('SCAN')
        }
    }, [qrcode])

    useEffect(()=>{
        if (trialError?.frontendCode === "0x1005") {
            setError({field: "email", msg: t('error.0x1005')})
        } else if (trialError?.frontendCode === "0x1011") {
            setError({field: "companyName", msg: t('error.0x1011')})
        } else if (!trialError?.frontendCode) {
            setError({field: "", msg: ""})
        }
        
    }, [trialError])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
    };

    const handleContinue = () => {
        // setReset(!reset)
        setLoading(true)
        openSocket()
    }

    const onScroll = () => {
        if (listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
          if (scrollHeight - Math.ceil(scrollTop) === clientHeight) {
            setBottom(true)
          }
        }
      };

    return (
        <>
            <Typography 
                variant="h5" 
                className={classes.centerText}
            >
                {t('trial.personalInfoTitle')}
            </Typography>
            <div className={classes.form}>
                {
                    Object.keys(fields).map((field: string, index: number)=>{
                        return (
                        <div style={{width: "100%"}} key={index}>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t(`trial.${field}`)}
                            </Typography>
                            <InputField
                                value={fields[field]}
                                onChange={(e)=>handleInputChange(e)}
                                name={field}
                                error={field===error.field ? true : false}
                                errorText={field===error.field ? error.msg : ""}
                            />
                        </div>
                        )
                    })
                }
            </div> 
            <Divider 
                className={classes.divider}
            />
            <div className={classes.planSection}>
                <Typography
                    variant="subtitle2"
                    className={classes.label}
                >
                    {t(`trial.selectedPlan`)}
                </Typography>
                <div className={classes.planRow}>
                    <Typography
                        variant="subtitle1"
                        className={classes.mediumEmphasis}
                    >
                        {plans?.[planId]?.name}
                    </Typography>
                    <Button 
                        text="Change"
                        version="text"
                        onClick={()=>window.open('https://sideos.io/pricing', '_self')}
                    />
                </div>
            </div>
            <div 
                className={classes.termsCondutions}
                ref={listInnerRef}
                onScroll={() => onScroll()} 
            >
                <Typography variant="subtitle1"
                    className={classes.mediumEmphasis}
                >
                  {t('trial.termsAndConditions.termsTitle')}
                </Typography>
                <Typography variant="caption"
                    className={classes.terms}
                >
                  {tc}
                </Typography>
                <Typography variant="caption"
                    className={classes.terms}
                >
                  {pp}
                </Typography>
            </div>
            <FormGroup className={classes.checkboxContainer}>
                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={checkboxes.checkedPrivacy} 
                            onChange={handleChange} 
                            name="checkedPrivacy" 
                            required={true}
                            className={classes.checkbox}
                            disabled={ bottom === false}
                        />
                    }
                    label={
                        <Typography variant='body1'>
                            {t('trial.checkboxes.privacy.text1')}
                           <b>{t('trial.checkboxes.privacy.text2')} </b>
                           {t('trial.checkboxes.privacy.text3')}
                           <span className={classes.lilaStar}>*</span>
                        </Typography>
                    }
                />
                <Divider 
                className={classes.divider}
                />
                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={checkboxes.checkedNewsletter} 
                            onChange={handleChange} 
                            name="checkedNewsletter" 
                            className={classes.checkbox}
                        />
                    }
                    label={
                        <Typography variant='body1'>
                            {t('trial.checkboxes.newsletter')}
                        </Typography>
                    }
                />
            </FormGroup>
            <Button 
                version="secondary"
                text={t('general.continue')}
                width="100%"
                onClick={()=>handleContinue()}
                size="medium"
                disabled={error.msg === t('trial.emailError') || !checkboxes.checkedPrivacy ? true : false || !fields?.email}
                loading={loading}
            />
        </>
    )
}

const mapState = (state: RootState) => (
    {
        trialError: state.errorReducer.trialError
    }
)

const mapDispatch = {
    resetTrialError: () => (resetTrialError()),
}

const connector = connect(mapState, mapDispatch)

export default connector(PersonalInfoCard)