import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    Paper,
    Typography, 
    Divider,
    Tooltip,
} from '@material-ui/core';
import { hasPermissions, parseDate } from '../commons';
import copyIcon from './assets/copy-icon.svg'
import { useTranslation } from 'react-i18next';
import {
    getAdminProfilePicture,
    updateAdminPermissions
} from '../actions/admin';
import { Admin } from '../commons/types'
import { 
    allowedPermissions, 
    incomingRole,
    roleNames,
    getObjKey,
    ROLES,
} from '../commons/permissions';

import questionIcon from '../commons/components/assets/question-icon.png'

import Avatar from '../commons/components/Avatar'
import InputField from '../commons/components/Input';
import Toggle from '../commons/components/Toggle';
import StatusBadge from '../commons/components/StatusBadge';
import BackButton from '../commons/components/BackButton';
import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any) => ({
    adminInfoContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
        width: "100%",
        height: "432px",
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        [theme.breakpoints.down('sm')]: {
            minHeight: "432px",
            height: "auto",
        }
    },
    border: {
        width: "100%",
        height: "13px",
        borderRadius: "8px 8px 0 0"
    },
    borderEnabled: {
        background: theme.palette.gradient.purple,
    },
    borderDisabled: {
        background: theme.palette.onSurface.disabled
    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(7, 8, 6, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7, 6, 8, 6),
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis,
        marginBottom: "8px"
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    titleColumn: {
        height: "45px",
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(6),
        wordBreak: "break-all",
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(5),
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(0, 7, 8, 7),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            padding: theme.spacing(0, 6, 8, 6),
        }
    },
    column: {
        width: "47%",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    status: {
        padding: theme.spacing(0, 5),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    tooltip: {
        borderRadius: theme.shape.sharpBorderRadius,
        padding: theme.spacing(2)
    },
    rowStart: {
        display: "flex",
        justifyContent: "space-between"
    },
    inputLabel: {
        width: "196px",
        height: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(0),
        border: `1.6px solid ${theme.palette.borderColor.onSurface}`,
        color: theme.palette.onSurface.highEmphasis,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        "&:hover": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[800],
        },
        "&:focus": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.primary[700],
            outline: "none"
        },
        "&:active": {
            borderColor: `1.6px solid ${theme.palette.borderColor.onSurface}`,
            color: theme.palette.onSurface.highEmphasis,
        },
        "&.Mui-disabled": {
            background: "transparent!important",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    questionIcon: {
        width: "16px",
        height: "16px",
        marginLeft: theme.spacing(4)
    },
    permissionsRow: {
        display: "flex",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            alignItems: "center",
        }
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface AdminProfileCardProps extends PropsFromRedux {
    setOpenModal: (arg: boolean) => void
    admin: Admin
}

const AdminProfileCard: FC<AdminProfileCardProps> = ({
    admin,
    setOpenModal,
    adminProfilePicture,
    getAdminProfilePicture,
    permissions,
    mainContact,
    updateAdminPermissions,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [copyTooltip, setCopyTooltip] = useState(false)
    const [profPic, setProfPic] = useState("")

    useEffect(() => {
        if (admin.image !== null && hasPermissions(permissions, {mainContacts: ["R"]})) {
            getAdminProfilePicture(admin?.id)
        } else {
            setProfPic("")
        }
    }, [admin, getAdminProfilePicture, permissions])

    useEffect(()=>{
        if (adminProfilePicture) {
            setProfPic(adminProfilePicture)
        }
    }, [adminProfilePicture])

    const renderText = (item: number) => {
        const statusText = [
            t('statusText.none'),
            t('statusText.draft'),
            t('statusText.pending'),
            t('statusText.rejected'),
            t('statusText.accepted')
        ]
        let text = statusText[item]
        return text
    }

    const handleCopy = (e: any) => {
        navigator.clipboard.writeText(admin?.did ? admin.did : "")
        setCopyTooltip(true)
        setTimeout(() => { setCopyTooltip(false) }, 5000)
    }

    const handleToggleClick = () => {
        setOpenModal(true)
    }

    const handleChangePermission = () => {
        updateAdminPermissions({
            PermissionId: getObjKey(ROLES[incomingRole(permissions)], admin?.User?.Permissions?.[0]?.id) === "admin" 
                ? 
                ROLES[incomingRole(permissions)].viewer 
                : 
                ROLES[incomingRole(permissions)].admin,
            MainContactId: admin?.id
        })
    }
    
    return (
        <Paper className={classes.adminInfoContainer} elevation={0}>
            <div className={clsx(classes.border, {
                [classes.borderEnabled]: !admin.disable,
                [classes.borderDisabled]: admin.disable
            })}></div>
            <div className={classes.row}>
                <div className={classes.rowStart}>
                    <Avatar
                        username={admin?.name}
                        lastname={admin?.lastName}
                        size="60px"
                        fontSize="20px"
                        profilePicture={profPic}
                        disabled={admin.disable}
                    />
                    <div className={classes.titleColumn}>
                        <Typography variant="h5" className={classes.highEmphasis}>
                            {admin.name} {admin.lastName}
                        </Typography>
                        <Typography variant="overline" className={classes.mediumEmphasis}>
                            {admin.role ? admin.role : t('adminProfile.role')}
                        </Typography>
                    </div>
                </div>
                <BackButton />
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('adminsTable.did')}
                    </Typography>
                    <Tooltip
                        title={<div>{t('adminProfile.copy')}</div>}
                        open={copyTooltip}
                        placement="top-end"
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <div>
                            <InputField
                                disabled={true}
                                value={admin.did || ""}
                                icon={copyIcon}
                                onIconClick={handleCopy}
                            />
                        </div>
                    </Tooltip>
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('adminProfile.assigned')}
                    </Typography>
                    <InputField
                        disabled={true}
                        value={parseDate(admin.assignedOn) || ""}
                    />
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('adminProfile.email')}
                    </Typography>
                    <InputField
                        disabled={true}
                        value={admin.email || ""}
                    />
                </div>
                <Divider
                    orientation="vertical"
                    flexItem
                />
                <div className={classes.column}>
                <div>
                    <div className={classes.permissionsRow}>
                            <Typography 
                                variant="subtitle2" 
                                className={classes.label}
                            >
                                {t('settings.admin.permissions')}                   
                            </Typography>
                            <Tooltip
                                title={<div>{t('adminStatus.questionIconTooltip')}</div>}
                                classes={{ tooltip: classes.tooltip }}
                                placement="right-end"
                            >
                                <img 
                                    src={questionIcon} 
                                    alt="questionmark icon" 
                                    className={classes.questionIcon} 
                                />
                            </Tooltip>
                        </div>
                        <div className={classes.permissionsRow}>
                            <InputField 
                                //@ts-ignore
                                value={admin.main ? roleNames?.main?.name : roleNames[getObjKey(ROLES[incomingRole(permissions)], admin?.info?.PermissionId ? admin?.info?.PermissionId : admin?.User?.Permissions?.[0]?.user_permission?.PermissionId)].name}
                                disabled={true}
                            />
                            {mainContact.id !== admin.id && mainContact.main ? 
                                <Button 
                                    //@ts-ignore
                                    text={roleNames[getObjKey(ROLES[incomingRole(permissions)], admin?.User?.Permissions?.[0]?.id)]?.name === "Admin" ? t('settings.admin.toViewer') : t('settings.admin.toAdmin')} 
                                    version="text"
                                    onClick={()=>handleChangePermission()}
                                /> : <></>
                            }
                        </div>
                    </div> 
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('adminProfile.enable')}
                    </Typography>
                    <div className={classes.status}>
                        <Typography
                            variant="subtitle1"
                            className={classes.label}
                        >
                            {
                                admin.disable
                                    ?
                                    t('adminProfile.disabled')
                                    :
                                    t('adminsTable.enabled')
                            }
                        </Typography>
                        <Toggle
                            checked={!admin.disable}
                            onClick={handleToggleClick}
                            //@ts-ignore
                            disabled={
                                !allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) ||
                                mainContact?.id === admin.id ||
                                admin.main
                                ? 
                                true
                                : 
                                false
                            }
                        />
                    </div>
                    <Divider />
                    <Typography
                        variant="subtitle2"
                        className={classes.label}
                    >
                        {t('adminProfile.offer')}
                    </Typography>
                    <div className={classes.status}>
                        <StatusBadge
                            status={renderText(admin.status)}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
}

const mapState = (state: RootState) => ({
    adminProfilePicture: state.admin.adminProfilePicture,
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    getAdminProfilePicture: (adminId: number) => (getAdminProfilePicture(adminId)),
    updateAdminPermissions: (data: any) => (updateAdminPermissions(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(AdminProfileCard)
