import { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { connect, ConnectedProps } from 'react-redux'
import {
    AppBar,
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { RootState } from '../store'
import { getNotifications } from '../actions/notification';
import { hasPermissions } from '../commons';
import clsx from 'clsx';

import menuIcon from './assets/sidebarIcons/menu-icon.svg'
import menuIconGray from './assets/sidebarIcons/menu-icon-gray.svg'

import MenuItemLogo from './MenuItemLogo';
import Avatar from '../commons/components/Avatar';
import SmallDropdown from '../commons/components/SmallDropdown';
import NotificationsBell from '../commons/components/NotificationsBell';
import Notifications from '../commons/components/Notifications'
import Button from '../commons/components/Button'

const useStyles = makeStyles((theme?: any) => ({
    unset: {
        position: "fixed",
        paddingLeft: "30px",
    },
    header: {
        height: "48px",
        background: theme.palette.background.default,
        color: theme.palette.onSurface.highEmphasis,
        borderBottom: `1px solid ${theme.palette.surface.overlay}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(11),
        paddingRight: theme.spacing(5),
    },
    row: {
        display: "flex",
        alignItems: "center"
    },
    openDrawer: {
        paddingLeft: theme.spacing(15),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(5),
        }
    },
    trialHeader: {
        backgroundColor: theme.palette.primary[700]
    },
    trialText: {
        color: theme.palette.onPrimary.highEmphasis
    },
    trial: {
        display: "flex",
        alignItems: "center",
    },
    trialMargin: {
        margin: theme.spacing(0, 7)
    },
    trialDivider: {
        backgroundColor: theme.palette.onPrimary.disabled,
        height: "20px"
    },
    hideOnSmall: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    hideMdUp: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface HeaderProps extends PropsFromRedux {
    username: string
    lastname: string
    setOpenMobileDrawer: (openMobileDrawer: boolean) => void
    openMobileDrawer: boolean
    openDrawer: boolean
    profilePicture: any
    companyLogo: any
    companyName: string
    counter: string
}

const Header: FC<HeaderProps> = ({
    username,
    lastname,
    setOpenMobileDrawer,
    openMobileDrawer,
    getNotifications,
    notifications,
    token,
    profilePicture,
    permissions,
    openDrawer,
    companyName,
    mainContact
}) => {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const [openSmallDropdown, setOpenSmallDropdown] = useState(false)
    const [openNotifications, setOpenNotifications] = useState(false)
    const [anchorEl, setAnchorEl] = useState()
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState()

    useEffect(() => {
        if (
            token && 
            !hasPermissions(permissions, { admin: "O" }) &&
            hasPermissions(permissions, {notifications: "R"})
        ) {
            getNotifications()
        }
    }, [token, getNotifications, permissions])

    const handleOpen = (e: any) => {
        setOpenSmallDropdown(!openSmallDropdown)
        setAnchorEl(e.currentTarget)
    }

    const handleOpenNotifications = (e: any) => {
        setOpenNotifications(!openNotifications)
        setNotificationsAnchorEl(e.currentTarget)
    }

    const logout = () => {
        localStorage.removeItem("mainContact")
        localStorage.removeItem("token")
        localStorage.removeItem("permissions")
        window.location.reload();
    }

    const menuItems = [
        { name: t('headerDropdown.settings'), function: () => history.push("/settings/profilesettings") },
        { name: t('headerDropdown.signout'), function: () => logout() }
    ]

    return (
        <AppBar
            classes={{ positionFixed: classes.unset }}
            className={clsx(classes.header, {
                [classes.openDrawer]: openDrawer,
                [classes.trialHeader]: hasPermissions(permissions, { trial: "O"}) || mainContact?.Customer?.PlanId === 1
            })}
            elevation={0}
        >
            <div className={classes.hideOnSmall}>
                <div >
                    <Typography variant="overline" className={clsx({
                        [classes.trialText]: hasPermissions(permissions, { trial: "O"}) || mainContact?.Customer?.PlanId === 1 
                    })}>
                        {companyName} - {t('Header.greeting', { username: username })}
                    </Typography>
                </div>
                <div className={classes.row}>
                    {
                        (hasPermissions(permissions, { trial: "O"}) || mainContact?.Customer?.PlanId === 1) &&
                        <div className={classes.trial}>
                            <Button
                                text={t('trial.upgrade')}
                                version="tertiary"
                                style={{ borderColor: "rgba(255, 255, 255, 0.38)" }}
                                textStyle={{ color: "#fff" }}
                                onClick={()=>history.push("/settings/billing/plans")}
                            />
                            <Divider
                                orientation='vertical'
                                className={clsx(classes.trialMargin, classes.trialDivider)}
                            />
                        </div>
                    }
                    <div onClick={(e) => handleOpenNotifications(e)}>
                        {
                            !hasPermissions(permissions, { admin: ["O"] }) &&
                            hasPermissions(permissions, {notifications: ["R"]})
                            &&
                            <NotificationsBell
                                content={notifications.length}
                                permissions={permissions}
                                mainContact={mainContact}
                            />
                        }
                    </div>
                    {
                        !hasPermissions(permissions, { admin: ["O"] }) &&
                        hasPermissions(permissions, {notifications: ["R"]})
                        &&
                        <Notifications
                            notifications={notifications}
                            open={openNotifications}
                            anchorEl={notificationsAnchorEl}
                            setOpen={setOpenNotifications}
                        />
                    }
                    <div onClick={(e) => handleOpen(e)} >
                        <Avatar
                            username={username}
                            lastname={lastname}
                            profilePicture={profilePicture}
                            size="25px"
                            fontSize="10px"
                        />
                    </div>
                    <SmallDropdown
                        open={openSmallDropdown}
                        setOpen={setOpenSmallDropdown}
                        menuItems={menuItems}
                        anchorEl={anchorEl}
                    />
                </div>
            </div>
            
            <div className={classes.hideMdUp}>
                <div onClick={(e) => handleOpenNotifications(e)}>
                    {
                        !hasPermissions(permissions, { admin: "O" })
                        &&
                        <NotificationsBell 
                            content={notifications.length} 
                            permissions={permissions} 
                            mainContact={mainContact}
                        />
                    }
                </div>
                {
                    !hasPermissions(permissions, { admin: "O" })
                    &&
                    <Notifications
                        notifications={notifications}
                        open={openNotifications}
                        anchorEl={notificationsAnchorEl}
                        setOpen={setOpenNotifications}
                    />
                }
                <MenuItemLogo
                    icon={hasPermissions(permissions, { trial: "O" }) || mainContact?.Customer?.PlanId === 1
                    ? 
                    menuIcon : menuIconGray}
                    handleClick={() => setOpenMobileDrawer(!openMobileDrawer)}
                    open={openMobileDrawer}
                    transform
                    inMobile={true}
                />
                </div>
        </AppBar>
    )
}

const mapState = (state: RootState) => ({
    token: state.login.token,
    permissions: state.login.permissions,
    notifications: state.notifications.notifications,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    getNotifications: () => (getNotifications()),
}

const connector = connect(mapState, mapDispatch)

export default connector(Header)