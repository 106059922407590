import {useState, useEffect} from 'react'

const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event) => {
        const e = event || window.event;
        if (showExitPrompt) {
            // Cancel the event
            e.preventDefault();
            if (e) {
              // Legacy method for cross browser support
              e.returnValue = ''; 
            }
            // Legacy method for cross browser support
            return ''; 
          };
        }
}

export default function useExitPrompt(bool: boolean) {
    const [showExitPrompt, setShowExitPrompt] = useState(bool)

    window.onload = function() {
      initBeforeUnLoad(showExitPrompt);
    };

    // Re-Initialize the onbeforeunload event listener
    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
    }, [showExitPrompt]);

    return [showExitPrompt, setShowExitPrompt]
}