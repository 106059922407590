import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC } from 'react';
import {
    getAdmin,
    enableDisableAdmin,
    editAdminDraft,
    deleteAdmin,
} from '../actions/admin';
import {
    Paper,
    Typography,
    Divider,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { parseDateAndTime } from '../commons';
import { useTranslation } from 'react-i18next';
import { isFlag } from '../commons/utils';
import { Link } from 'react-router-dom';
import { getObjKey, incomingRole, roleNames, ROLES } from '../commons/permissions';

import StatusBadge from '../commons/components/StatusBadge';
import Search from '../commons/components/Search';
import SmallTable from '../commons/components/SmallTable';
import TableDateAndTime from '../commons/components/TableDateAndTime';
import DisableDialog from './DisableDialog';
import Breadcrumbs from '../commons/components/Breadcrumbs'
import CollapseTable from '../commons/components/CollapseTable';
import AdminProfileCard from './AdminProfileCard'
import AdminStatusCard from './AdminStatusCard';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    credentialsTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: theme.spacing(4),
        }
    },
    credentialsTitle: {
        fontWeight: theme.typography.bold.fontWeight,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
        }
    },
    searchContainer: {
        width: "25%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    titleRow: {
        height: "36px",
        background: theme.palette.primary[50],
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(6)
    },
    detailRow: {
        height: "66px",
        background: theme.palette.primary.hover,
        display: "flex",
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(3)
    },
    test: {
        height: "340px"
    },
    link: {
        textDecoration: "none"
    },
    description: {
        color: theme.palette.onSurface.disabled
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

type AdminParams = {
    adminId: string;
  };

interface AdminProfileProps extends PropsFromRedux { }

const AdminProfile: FC<AdminProfileProps> = ({
    getAdmin,
    editAdminDraft,
    enableDisableAdmin,
    deleteAdmin,
    admin,
    emailSent,
    qrcode,
    permissions
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    let { adminId } = useParams<AdminParams>()
    const history = useHistory()
    const ACCEPTED = 4
    const INUSE = 4
    const DRAFT = 1
    const [matches, setMatches] = useState<{ [name: string]: string | number }[]>([])
    const [input, setInput] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [disabledStatusCardInput, setDisabledStatusCardInput] = useState(true)
    const [fields, setFields] = useState({
        name: "",
        lastName: "",
        email: "",
        permission: null
    })
    const columns = {
        createdAt: t('credentialsTable.createdOn'),
        id: t('credentialsTable.id'),
        type: t('credentialsTable.type'),
        name: t('credentialsTable.name'),
        status: t('credentialsTable.status')
    }

    useEffect(() => {
        if (!qrcode.data) {
            getAdmin(adminId)
        }
    }, [adminId, getAdmin, qrcode])

    useEffect(() => {
        if (emailSent) {
            getAdmin(adminId)
        }
    }, [emailSent, getAdmin, adminId])

    useEffect(() => {
        setFields({
            name: admin.name,
            lastName: admin.lastName,
            email: admin.email,
            //@ts-ignore
            permission: admin.main ? roleNames?.main?.name : roleNames[getObjKey(ROLES[incomingRole(permissions)], admin?.info?.PermissionId ? admin?.info?.PermissionId : admin?.User?.Permissions?.[0]?.user_permission?.PermissionId)]
        })
    }, [admin, permissions])

    const renderStatus = (template: any) => {
        if (isFlag(template.status, DRAFT)) {
            return t('statusText.draft')
        } else if (isFlag(template.status, INUSE)) {
            const companiesNo = template.companies ? t('statusText.used') + t('statusText.companiesNo', { number: template.companies }) : t('statusText.used')
            return companiesNo
        } else {
            return t('statusText.unused')
        }
    }

    const renderSearchMatches = (matches: [], input: string) => {
        setInput(input)
        setMatches(matches)
    }

    const onCancel = () => {
        setOpenModal(false)
    }

    const onAffirmative = () => {
        enableDisableAdmin({
            MainContactId: admin.id,
            disable: !admin.disable
        })
        setOpenModal(false)
    }

    const handleEditAdminDraft = () => {
        editAdminDraft({
            MainContactId: admin.id,
            name: fields.name,
            lastName: fields.lastName,
            email: fields.email,
            //@ts-ignore
            PermissionId: fields.permission && ROLES[incomingRole(permissions)][getObjKey(roleNames, fields.permission)]
        })
        setDisabledStatusCardInput(true)
    }

    const handleInputChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleSelect = (name: string, value: string) => {
        setFields({ ...fields, [name]: value })
    }

    const handleAdminDelete = async () => {
        await deleteAdmin({ MainContactId: admin.id, name: admin?.name + " " + admin?.lastName })
        history.push("/settings/admins")
    }

    const checkExpandableValue = (item: any) => {
        return isFlag(item.status, INUSE) ? true : false
    }

    return (
        <div className={clsx(classes.container)}>
            <Breadcrumbs item={admin} />
            <Divider />
            {
                isFlag(admin?.status, ACCEPTED) ?
                    <>
                        <AdminProfileCard
                            setOpenModal={setOpenModal}
                            admin={admin}
                        />
                        <Paper elevation={0} >
                            <div className={classes.credentialsTitleContainer}>
                                <Typography
                                    variant="h6"
                                    className={
                                        clsx(classes.highEmphasis, classes.credentialsTitle)
                                    }
                                >
                                    {t('adminProfile.credentialsTitle')}
                                </Typography>
                                <div className={classes.searchContainer}>
                                    <Search
                                        rows={admin?.Templates}
                                        renderSearchMatches={renderSearchMatches}
                                    />
                                </div>
                            </div>
                            <div className={classes.test}>
                                <SmallTable
                                    rows={input !== "" ? matches : admin?.Templates}
                                    columns={columns}
                                    tableCells={{
                                        createdAt: (item: any) => {
                                            return <TableDateAndTime dateAndTime={parseDateAndTime(item.createdAt)} />
                                        },
                                        status: (item: any) => {
                                            return (
                                                <StatusBadge
                                                    status={renderStatus(item)}
                                                />
                                            )
                                        },
                                    }}
                                    disabled={admin.disable}
                                    expandable={true}
                                    checkExpandableValue={(item: Credential) => checkExpandableValue(item)}
                                    collapseElement={(item) =>
                                        <CollapseTable
                                            titles={[
                                                { prop: "Proofs", title: t('onboarding.proofs') },
                                                { prop: "description", title: t('onboarding.description') }
                                            ]}
                                            item={item}
                                            tableCells={{
                                                Proofs: (item: any) => {
                                                    return (item?.Proofs.map((proof: any) => {
                                                        return (
                                                            <p>
                                                                <Link
                                                                    to={`/claims/${proof.id}`}
                                                                    key={proof.id}
                                                                    className={clsx(classes.mediumEmphasis, classes.link)}
                                                                >
                                                                    <Typography
                                                                        variant="button"
                                                                        className={classes.mediumEmphasis}
                                                                    >
                                                                        {proof.name}
                                                                    </Typography>
                                                                </Link>
                                                            </p>
                                                        )
                                                    })
                                                    )
                                                },
                                                description: (item: any) => {
                                                    return (
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.description}
                                                        >
                                                            {item.description}
                                                        </Typography>
                                                    )
                                                }
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </Paper>
                        <DisableDialog
                            open={openModal}
                            onCancel={onCancel}
                            onAffirmative={onAffirmative}
                            name={admin.name}
                            lastName={admin.lastName}
                            disabled={admin.disable}
                        />
                    </>
                    :
                    <AdminStatusCard
                        admin={admin}
                        fields={fields}
                        handleInputChange={handleInputChange}
                        handleSelect={handleSelect}
                        handleSave={handleEditAdminDraft}
                        handleDelete={handleAdminDelete}
                        disabledInput={disabledStatusCardInput}
                        setDisabledInput={setDisabledStatusCardInput}
                    />
            }
        </div>
    );
}

const mapState = (state: RootState) => ({
    admin: state.admin.admin,
    emailSent: state.admin.emailSent,
    qrcode: state.admin.qrcode,
    permissions: state.login.permissions
})

const mapDispatch = {
    getAdmin: (adminId: string) => (getAdmin(adminId)),
    editAdminDraft: (data: Object) => (editAdminDraft(data)),
    enableDisableAdmin: (data: Object) => (enableDisableAdmin(data)),
    deleteAdmin: (data: Object) => (deleteAdmin(data)),
}

const connector = connect(mapState, mapDispatch)

export default connector(AdminProfile)
