import { combineReducers } from "redux"
import login from "./login"
import notifications from "./notifications"
import settings from "./settings"
import admin from "./admin"
import errorReducer from "./errorReducer"
import successReducer from "./successReducer"
import credentials from "./credentials"
import wallets from "./wallets"
import onboarding from "./onboarding"
import proofs from "./proofs"
import transactions from "./transactions"
import dashboard from "./dashboard"
import modals from "./modals"

const rootReducer = () => combineReducers({
    login,
    notifications,
    settings,
    admin,
    credentials,
    errorReducer,
    successReducer,
    wallets,
    onboarding,
    proofs,
    transactions,
    dashboard,
    modals
});

export default rootReducer
