import { Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../../store'
import { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import check from './assets/check-icon.svg'


const useStyles = makeStyles((theme?: any) => ({
    snackbarContainer: {
        position: "fixed",
        bottom: 0,
        zIndex: 2147483648,
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(5),
            width: "95%"
        }
    },
    snackbar: {
        "& .MuiSnackbarContent-root": {
            height: "48px",
            backgroundColor: theme.palette.common.black,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            [theme.breakpoints.down('md')]: {
                height: "fit-content",
                display: "flex",
                padding: theme.spacing(5),
                flexWrap: "nowrap"
            }
        },
    },
    root: {
        position: "relative",
        justifySelf: "flex-end",
        marginBottom: theme.spacing(4)
    },
    row: {
        display: "flex",
        "& img": {
            filter: "brightness(0) saturate(100%) invert(97%) sepia(20%) saturate(4%) hue-rotate(163deg) brightness(103%) contrast(101%)",
            marginRight: theme.spacing(4),
            height: "18px"
        }
    },
    msg: {
        overflowWrap: "normal"
    },
    customPlan: {
        "& .MuiSnackbarContent-root": {
            background: theme.palette.gradient.purple,
        }
    },
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface PlanUpgradeSuccessSnackbarProps extends PropsFromRedux {
}

const PlanUpgradeSuccessSnackbar: FC<PlanUpgradeSuccessSnackbarProps> = () => {
    const classes = useStyles()
    const [newPlan, setNewPlan] = useState("")
    const { t } = useTranslation()

    useEffect(()=>{
        const newPlan = localStorage.getItem("newPlan")
        if (newPlan) {
            setNewPlan(newPlan)
        }
    }, [])

    const handleClose = () => {
        setNewPlan("")
        localStorage.removeItem("newPlan")
    }

    const getText = () => {
        if (parseInt(newPlan) === 2) {
            return t('upgradeSnackbar.2')
        } else if (parseInt(newPlan) === 3) {
            return t('upgradeSnackbar.3')
        } else if (parseInt(newPlan) === 4) {
            return t('upgradeSnackbar.4')
        } else {
            return t('upgradeSnackbar.5')
        }
    }

    return (
        <div className={classes.snackbarContainer}>
            <Snackbar
                open={newPlan ? true : false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                message={
                    <div className={classes.row}>
                        <img src={check} alt="check-icon" />
                        <Typography variant="body2" className={classes.msg}>
                            {getText()}
                        </Typography>
                    </div>
                }
                className={clsx(classes.snackbar, {
                    [classes.customPlan]: parseInt(newPlan) === 4
                })}
                classes={{ root: classes.root }}
                autoHideDuration={7000}
                onClose={() => handleClose()}
            />
        </div>
    )
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(PlanUpgradeSuccessSnackbar)