import { connect, ConnectedProps } from 'react-redux'
import {
    Typography,
    Dialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { RootState } from '../store'
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FC } from 'react';
import Button from '../commons/components/Button'
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import upgradeIcon from './assets/icons/upgrade-icon.svg'

const useStyles = makeStyles((theme?: any) => ({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(8),
        width: "532px",
        height: "312px",
        color: theme.palette.onSurface.highEmphasis,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            minHeight: "381px",
            width: "100%",
            textAlign: "center",
            padding: theme.spacing(8, 5),
        }
    },
    btnRow: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%"
        }
    },
    btnMargin: {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3), 
        }
    },
    btn: {
        width: "210px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            height: "58px"
        }
    },
    text: {
        color: theme.palette.onSurface.mediumEmphasis,
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    btnLink: {
        textDecoration: "none"
    }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface ContactModalProps extends PropsFromRedux {
    open: boolean,
    setOpen: any
}

const ContactModal: FC<ContactModalProps> = ({
    open,
    setOpen
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <Dialog open={open} classes={{paper: classes.dialogContainer}}>
                <img src={upgradeIcon} alt="warning icon" />
                    <Typography 
                        variant="h6" 
                        className={classes.highEmphasis}
                    >
                        {t('billing.contactUpgrade')}
                    </Typography>
                    <Typography 
                        variant="subtitle1" 
                        className={classes.text}
                    >
                        {t('billing.customFeatures')}
                    </Typography>
                <div className={classes.btnRow}>
                    <div className={clsx(classes.btnMargin, classes.btn)}>
                        <Button 
                            text={t('general.Close')}
                            width="100%"
                            version="secondary"
                            size={matches ? "medium" : "large"}
                            onClick={()=>setOpen(false)}
                        />
                    </div>
                    <div className={clsx(classes.btn)}>
                        <a href="mailto:sales@sideos.io" className={classes.btnLink}>
                            <Button 
                                text={t('billing.contactSales')}
                                width="100%"
                                size={matches ? "medium" : "large"}
                                onClick={()=>setOpen(false)}
                            />
                         </a>
                    </div >
                </div>
            </Dialog>
        </>
    );
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)

export default connector(ContactModal)
