import {
    Paper,
    Typography,
    Divider,
    Tooltip
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '../commons/types'
import { 
    allowedPermissions,
    incomingRole
} from '../commons/permissions';
import { resendOffer } from '../actions/onboarding';
import { hasPermissions } from '../commons';

import copyIcon from './assets/copy-icon.svg'

import InputField from '../commons/components/Input';
import Button from '../commons/components/Button'
import StatusBadge from '../commons/components/StatusBadge';
import ResendOfferModal from './ResendOfferModal';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        height: "376px",
        padding: theme.spacing(8),
        marginTop: theme.spacing(0),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis,
        fontSize: "18px",
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    column: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    status: {
        padding: theme.spacing(5),
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing(6)
    },
    btnContainer: {
        position: "absolute",
        bottom: theme.spacing(7),
        right: theme.spacing(7),
        width: "248px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            position: "relative",
            right: theme.spacing(0),
            bottom: theme.spacing(0),
            margin: theme.spacing(5,0)
        }
    },
    tooltip: {
        borderRadius: theme.shape.sharpBorderRadius,
        padding: theme.spacing(2)
    },
}))


type PropsFromRedux = ConnectedProps<typeof connector>

interface MainContactInfoCardProps extends PropsFromRedux {
    customer: Customer
}

const MainContactInfoCard: FC<MainContactInfoCardProps> = ({
    customer,
    permissions,
    mainContact,
    resendOffer
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [copyTooltip, setCopyTooltip] = useState(false)
    const [openResendOfferModal, setOpenResendOfferModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const renderText = (item: number) => {
        const statusText = [
            t('statusText.none'),
            t('statusText.draft'),
            t('statusText.pending'),
            t('statusText.rejected'),
            t('statusText.accepted')
        ]
        let text = statusText[item]
        return text
    }

    const handleCopy = (e: any) => {
        navigator.clipboard.writeText(customer?.MainAdmin?.[0]?.did ? customer.MainAdmin[0].did : "")
        setCopyTooltip(true)
        setTimeout(() => { setCopyTooltip(false) }, 5000)
    }

    const resendOnboardingOffer = async () => {
        setLoading(true)
        await resendOffer({
            CustomerId: customer?.id,
            MainContactId: customer?.MainAdmin?.[0]?.id
        })
        setLoading(false)
        setOpenResendOfferModal(false)
    }

    return (
        <Paper elevation={0} className={classes.container}>
            <div className={classes.titleRow}>
                <Typography
                    variant="h5"
                    className={classes.highEmphasis}
                >
                    {t('customerProfile.globalAdmin')}
                </Typography>
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('customerProfile.name')}
                        </Typography>
                        <InputField
                            disabled={true}
                            value={customer?.MainAdmin?.[0]?.name}
                            name="name"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('customerProfile.lastname')}
                        </Typography>
                        <InputField
                            disabled={true}
                            value={customer?.MainAdmin?.[0]?.lastName}
                            name="name"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('customerProfile.email')}
                        </Typography>
                        <InputField
                            disabled={true}
                            value={customer?.MainAdmin?.[0]?.email}
                            name="email"
                        />
                    </div>
                </div>
                <Divider
                    orientation="vertical"
                    flexItem
                />
                <div className={classes.statusColumn}>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('customerProfile.did')}
                        </Typography>
                        <Tooltip
                            title={<div>{t('adminProfile.copy')}</div>}
                            open={copyTooltip}
                            placement="top-end"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <div>
                                <InputField
                                    disabled={true}
                                    value={customer?.MainAdmin?.[0]?.did}
                                    name="did"
                                    icon={copyIcon}
                                    onIconClick={handleCopy}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    {
                        customer?.MainAdmin?.[0]?.status &&
                        <>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('customerProfile.offerStatus')}
                            </Typography>
                            <div className={classes.status}>
                                <StatusBadge
                                    status={renderText(customer?.MainAdmin[0]?.status)}
                                />
                                {
                                    allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true) &&
                                    (!customer?.TenantId && hasPermissions(permissions, {admin: ["O"], customers: ["RO"], wallets: ["RO"], mainContacts: ["EO","RO"], templates: ["RO"], proof_template: ["RO"], proofs: ["RO"]}) ||
                                    hasPermissions(permissions, {tenant: ["O"], customers: ["R"], wallets: ["R"], mainContacts: ["E","R"], templates: ["R"], proof_template: ["R"], proofs: ["R"]}))
                                    ?
                                    <Button 
                                        text={t('adminProfile.mainAdminBtn')}
                                        version="tertiary" 
                                        onClick={()=>setOpenResendOfferModal(true)}
                                    />                          
                                    :
                                    null
                                }
                            </div>
                        </>
                    }

                </div>
            </div>
            <ResendOfferModal 
                open={openResendOfferModal} 
                closeModal={()=>setOpenResendOfferModal(false)}
                resendOffer={()=>resendOnboardingOffer()}
                loading={loading}
            />
        </Paper>
    )
}

const mapState = (state: RootState) => ({
    permissions: state.login.permissions,
    mainContact: state.login.mainContact
})

const mapDispatch = {
    resendOffer: (data: Object) => (resendOffer(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(MainContactInfoCard)