import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { RootState } from '../store'
import { FC, Fragment } from 'react';
import {
  Typography,
  Divider,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { hasPermissions } from '../commons';
import {
  deleteProof,
  getProofs,
} from '../actions/proofs';
import { isFlag } from '../commons/utils';
import { parseDateAndTime } from '../commons';
import { Proof } from '../commons/types'
import { allowedPermissions, incomingRole } from '../commons/permissions';

import trashcanIcon from './assets/trashcan-icon-mediumE.svg'

import Search from '../commons/components/Search';
import Button from '../commons/components/Button'
import SimpleTable from '../commons/components/SimpleTable';
import TableDateAndTime from '../commons/components/TableDateAndTime'
import StatusBadge from '../commons/components/StatusBadge';
import Breadcrumbs from '../commons/components/Breadcrumbs';
import CollapseTable from '../commons/components/CollapseTable'
import RadioBtns from '../commons/components/RadioBtns';
import DeleteModal from './DeleteModal';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  containerColumn: {
    height: "auto"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  btnRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "160px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  name: {
    color: theme.palette.onSurface.highEmphasis
  },
  nameContainer: {
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  rightBtn: {
    marginLeft: theme.spacing(5),
    width: "200px",
    [theme.breakpoints.down('sm')]: {
      width: "50%",
      marginLeft: theme.spacing(2),
    }
  },
  searchRow: {
    width: "77%",
    paddingRight: theme.spacing(5)
  },
  disabled: {
    color: theme.palette.onSurface.disabled
  },
  titleRow: {
    height: "36px",
    background: theme.palette.primary[50],
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(6)
  },
  mediumEmphasis: {
    color: theme.palette.onSurface.mediumEmphasis
  },
  link: {
    textDecoration: "none"
  },
  description: {
    color: theme.palette.onSurface.disabled
  }
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface CustomizedState {
  newProof: number
}

interface ProofsProps extends PropsFromRedux { }

const Proofs: FC<ProofsProps> = ({
  permissions,
  mainContact,
  proofs,
  getProofs,
  deleteProof
}) => {
  const classes = useStyles()
  let history = useHistory()
  const { t } = useTranslation()
  const [matches, setMatches] = useState<Proof[]>([])
  const [input, setInput] = useState("")
  const [checked, setChecked] = useState<string>('all')
  const [showModal, setShowModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState<any>(null)
  const [nameToDelete, setNameToDelete] = useState<any>(null)
  const [newProofId, setNewProofId] = useState(0)
  const location = useLocation()
  const state = location.state as CustomizedState
  const radioGroupTitle = t('origin.sortTitle')
  const sideosBtnValues = [
    { value: 'all', translation: t('origin.all') },
    { value: 'public', translation: t('origin.public') },
    { value: 'private', translation: t('origin.private') }
  ]
  const customerBtnValues = [
    { value: 'all', translation: t('origin.all') },
    { value: 'created', translation: t('origin.created') },
    { value: 'picked', translation: t('origin.picked') }
  ]
  const INUSE = 4

  const columns = {
    createdAt: t('proofs.createdOn'),
    id: t('proofs.id'),
    name: t('proofs.name'),
    type: t('proofs.type'),
    status: t('proofs.status')
  }

  useEffect(() => {
    if (state) {
      const { newProof } = state
      setNewProofId(newProof)
      setTimeout(() => {
        setNewProofId(0)
      }, 1000)
    }
    return () => setNewProofId(0)
  }, [state])

  useEffect(() => {
    getProofsBasedOnCheck()
  }, [mainContact, checked, getProofs])

  const getProofsBasedOnCheck = () => {
    if (checked === 'all') {
      getProofs(mainContact?.Customer?.id)
    } else {
      getProofs(mainContact?.Customer?.id, checked)
    }
  }

  const renderStatus = (proof: Proof) => {
    if (isFlag(proof.status, INUSE)) {
      const companiesNo = proof.credentials ? t('statusText.used') + t('statusText.companiesNo', { number: proof.credentials }) : t('statusText.used')
      return companiesNo
    } else {
      return t('statusText.unused')
    }
  }

  const renderSearchMatches = (matches: [], input: string) => {
    setInput(input)
    setMatches(matches)
  }

  const handleCellClick = (proof: any) => {
    hasPermissions(permissions, {
      proofs: ["R"], 
      mainContacts: ["R"], 
      contexts: ["R"]}) && history.push(`/claims/${proof.id}`)
  }

  const onRadioBtnChange = (e: any) => {
    setChecked(e.target.value)
  }

  const onDeleteProof = (proofName: string, proofId: number) => {
    setIdToDelete(proofId)
    setNameToDelete(proofName)
    setShowModal(true)
  }

  const handleDelete = async (id: number, name: string) => {
    const response = await deleteProof({ ProofId: id, name: name })
    if (response?.status === 200) {
      getProofsBasedOnCheck()
    }
  }

  const rowAction = (item: any) => {
    return (
      <>
      {
        hasPermissions(permissions, {proofs: ["D"]}) ? 
          <Tooltip
            title={<Fragment>{t('proofs.tooltipDelete')}</Fragment>}
            placement="bottom"
          >
            <img
              src={trashcanIcon}
              alt="trashcan icon"
              onClick={() => onDeleteProof(item.name, item.id)}
            />
          </Tooltip>
          :
          <></>
      }
      </>
    )
  }

  const handleRedirection = () => {
    if (hasPermissions(permissions, { "admin": "O" })) {
      history.push("/claims/new")
    } else {
      history.push("/claims/repository")
    }
  }

  const checkExpandableValue = (item: Proof) => {
    return isFlag(item.status, INUSE) ? true : false
  }

  return (
    <div className={clsx(classes.container)}>

      <Breadcrumbs />
      <Divider />

      <div className={clsx({
        [classes.row]: allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)
      })}>
        <Search
          rows={proofs}
          renderSearchMatches={renderSearchMatches}
        />
        {hasPermissions(permissions, {proofs: ["W","R"]}) && 
          <div className={classes.btnRow}>
            <Button
              text={t('proofs.addBtn')}
              version="secondary"
              fullWidth
              size="medium"
              onClick={handleRedirection}
            />
          </div>
        }
      </div>
      <RadioBtns
        title={radioGroupTitle}
        btnValues={hasPermissions(permissions, { "admin": "O" }) ? sideosBtnValues : customerBtnValues}
        defaultValue={checked}
        onChange={(e) => onRadioBtnChange(e)}
      />
      <SimpleTable
        handleCellClick={(item) => handleCellClick(item)}
        rowAction={(item: any) => rowAction(item)}
        expandable={true}
        highlight={newProofId}
        checkExpandableValue={(item: Proof) => checkExpandableValue(item)}
        columns={columns}
        rows={input !== "" ? matches : proofs}
        tableCells={{
          createdAt: (item: any) => {
            return (
              <TableDateAndTime
                dateAndTime={parseDateAndTime(item.createdAt)}
              />
            )
          },
          status: (item: any) => {
            return (
              <Tooltip 
                title={isFlag(item.status, INUSE) ? t('proofs.statusTooltip') : ""}
                placement="top-start"
              >
                <div>
                <StatusBadge
                  status={renderStatus(item)}
                />
                </div>
              </Tooltip>
            )
          },
          type: (item: any) => {
            return (
              <div className={classes.nameContainer}>
                <Typography
                  variant="body2"
                  className={classes.name}
                  onClick={() => handleCellClick(item)}
                >
                  {item.type}
                </Typography>
              </div>
            )
          },
        }}
        collapseElement={(item) =>
          <CollapseTable
            titles={[
              { prop: "Context", title: t('proofs.context') },
              { prop: "description", title: t('proofs.description') }
            ]}
            item={item}
            tableCells={{
              Context: (item: any) => {
                return (
                  <a
                    href={item.Context?.context?.split(' ')[1]}
                    key={item.Context?.id}
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      variant="button"
                      className={classes.mediumEmphasis}
                    >
                      {item.Context?.type}
                    </Typography>
                  </a>
                )
              },
              description: (item: any) => {
                return (
                  <Typography
                    variant="body1"
                    className={classes.description}
                  >
                    {item.description}
                  </Typography>
                )
              }
            }}
          />
        }
      />
      <DeleteModal
        open={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
        id={idToDelete}
        name={nameToDelete}
      />
    </div>
  );
}

const mapState = (state: RootState) => ({
  permissions: state.login.permissions,
  mainContact: state.login.mainContact,
  proofs: state.proofs.proofs
})

const mapDispatch = {
  getProofs: (CustomerId: number, param?: string) => (getProofs(CustomerId, param)),
  deleteProof: (data: Object) => (deleteProof(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(Proofs)
