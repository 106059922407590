import {
    Paper,
    Typography,
    Divider,
    Tooltip
} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'
import { makeStyles } from '@material-ui/styles';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Credential } from '../commons/types'
import {
    getTemplateTypes,
    deleteTemplate,
    createTemplate,
    resetNewTemplate,
    updateTemplateToDraft,
    editTemplate,
    editTemplateSharing,
} from '../actions/credentials';
import { isFlag } from '../commons/utils';
import { hasPermissions } from '../commons';
import { getProofs } from '../actions/proofs'; 
import { Proof } from '../commons/types';
import clsx from 'clsx';
import { setEditingMode } from '../actions/dashboard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { sideosTheme } from "../themes/sideosTheme";
import { 
    allowedPermissions, 
    incomingRole 
} from '../commons/permissions';

import plusIcon from './assets/plus-icon.svg'
import plusIconGray from './assets/plus-icon-gray.svg'
import questionIcon from './assets/icon-question-circle.png'
import deleteIcon from './assets/trashcan-icon.svg'
import deleteIconDisabled from './assets/trashcan-icon-disabled.svg'

import InputField from '../commons/components/Input';
import Button from '../commons/components/Button'
import IconBtn from '../commons/components/IconButton';
import InputSelect from '../commons/components/InputSelect'
import RadioBtns from '../commons/components/RadioBtns';
import StatusBadge from '../commons/components/StatusBadge';
import DeleteModal from './DeleteModal';
import BackButton from '../commons/components/BackButton';
import CreateProofModal from './CreateProofModal';

const useStyles = makeStyles((theme?: any) => ({
    container: {
        minHeight: "456px",
        height: "auto",
        padding: theme.spacing(7),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(7),
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            minHeight: "456px",
            height: "auto",
            padding: theme.spacing(6),
        }
    },
    edit: {
        border: `1px solid ${theme.palette.onSurface.highEmphasis}`,
    },
    label: {
        color: theme.palette.common.inputLabel,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    highEmphasis: {
        color: theme.palette.onSurface.highEmphasis
    },
    columnsRow: {
        display: "flex",
        justifyContent: "space-between",
        height: "70%",
        marginBottom: theme.spacing(11),
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginBottom: theme.spacing(5),
        }
    },
    column: {
        width: "47%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        }
    },
    statusColumn: {
        width: "47%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom: theme.spacing(6)
        }
    },
    btnRow: {
        display: "flex",
        alignSelf: "flex-end",
        paddingTop: theme.spacing(6),
        position: "absolute",
        bottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(3),
            flexDirection: "column",
            position: "relative",
            width: "100%"
        }
    },
    btn: {
        width: "210px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0),
        },
    },
    editBtn: {
        width: "234px",
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            width: "100%",
            paddingLeft: theme.spacing(0),
        }
    },
    titleRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            wordBreak: "break-word"
        }
    },
    iconBtn: {
        marginTop: theme.spacing(6),
        width: "fit-content",
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0)
        }

    },
    radio: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            marginTop: theme.spacing(0),
        }
    },
    sharing: {
        display: "flex",
        flexDirection: "column",
    },
    questionIcon: {
        display: "flex",
        alignItems: "baseline"
    },
    tooltipIcon: {
        height: "16px",
        marginLeft: theme.spacing(4),
        filter: theme.palette.filter.toBlack
    },
    status: {
        padding: theme.spacing(5),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5)
        }
    },
    origin: {
        color: theme.palette.onSurface.disabled
    },
    iconBtns: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
        }

    },
    proofRow: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    proofDeleteIcon: {
        marginLeft: theme.spacing(5)
    },
    proofDeleteIconBlack: {
        filter: theme.palette.filter.toBlack,
    },
    selectOther: {
        display: "flex"
    },
    plusIcon: {
        marginRight: theme.spacing(4)
    },
    proofContainer: {
        width: "100%",
        position: "relative"
    },
    row: {
        display: "flex",
        alignItems: "baseline"
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.disabled,
        marginLeft: theme.spacing(2)
    }
}))

type CreateTemplate = {
    name?: string
    public?: boolean
    TemplateId?: number
    proofIds?: number[]
    draft?: boolean
    type?: string
    description?: string
}

type Fields = {
    name?: string
    public?: boolean
    proofs?: (Proof | string)[] | undefined
    type?: string
    description?: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface CredentialStatusCardProps extends PropsFromRedux {
    credential?: Credential
    disabled?: boolean
    pickedCredential?: Credential
    setDisabled?: (arg: boolean) => void
}

const CredentialStatusCard: FC<CredentialStatusCardProps> = ({
    getTemplateTypes,
    getProofs,
    mainContact,
    templateTypes,
    proofs,
    permissions,
    credential,
    disabled,
    pickedCredential,
    newTemplate,
    setDisabled,
    deleteTemplate,
    createTemplate,
    resetNewTemplate,
    updateTemplateToDraft,
    editTemplate,
    editTemplateSharing,
    newProof,
    setEditingMode
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const [fields, setFields] = useState<Fields>({
        type: "",
        name: "",
        proofs: [""],
        description: "",
        public: false
    })
    const [disableComplete, setDisableComplete] = useState(false)
    const [disableDraft, setDisableDraft] = useState(false)
    const [other, setOther] = useState(false)
    const [openProofModal, setOpenProofModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [templateIdToDelete, setTemplateIdToDelete] = useState<any>(null)
    const [templateNameToDelete, setTemplateNameToDelete] = useState<any>(null)
    const [editMode, setEditMode] = useState(false)
    const [btnValue, setBtnValue] = useState<string>('private')
    const [currentProofIndex, setCurrentProofIndex] = useState<undefined|number>()
    const [proofTypes, setProofTypes] = useState<[]|string[]>([])
    const btnValues = [
        { value: 'public', translation: t('credentials.public') },
        { value: 'private', translation: t('credentials.private') }
    ]
    const matches = useMediaQuery(sideosTheme.breakpoints.down('sm'));

    const DRAFT = 1
    const PUBLIC = 2
    const INUSE = 4

    useEffect(() => {
        if (newProof.id === 0) {
            hasPermissions(permissions, {templates: ["R"]}) && 
            getTemplateTypes(mainContact?.Customer?.id)
            hasPermissions(permissions, {
                proofs: ["R"],
                mainContacts: ["R"],
                contexts: ["R"]
            }) && 
            getProofs(mainContact?.Customer?.id)
        }
    }, [mainContact, getTemplateTypes, newProof, getProofs])

    useEffect(()=>{
        // @ts-ignore
        if (newProof?.id && !proofTypes.includes(newProof?.type)) {
            handleSelectProof("proof", newProof, currentProofIndex)
        }
    }, [newProof])

    useEffect(() => {
        if (
            !fields.type ||
            !fields.name ||
            fields?.proofs?.[0] === ""
        ) {
            setDisableComplete(true)
        } else {
            setDisableComplete(false)
        }
    }, [fields])

    useEffect(() => {
        if (hasPermissions(permissions, { "admin": "O" }) && fields.public === true) {
            setDisableDraft(true)
        } else {
            setDisableDraft(false)
        }
    }, [fields, permissions])

    useEffect(() => {
        if (credential || pickedCredential) {
            let inputValues = credential ? credential : pickedCredential
            let proofsTypesArr = proofTypes
            if (inputValues) {
                setFields({
                    type: inputValues.type,
                    name: inputValues.name,
                    proofs: inputValues.Proofs.length !== 0 ? inputValues?.Proofs : [""],
                    description: inputValues.description,
                    public: isFlag(inputValues.status, PUBLIC) ? true : false
                })
                inputValues.Proofs?.length > 0 && inputValues.Proofs?.map((proof: Proof)=>{
                        //@ts-ignore
                    if (!proofsTypesArr.includes(proof?.type)) {
                        //@ts-ignore
                        return proofsTypesArr.push(proof.type)
                    } else {
                        return proofsTypesArr
                    }
                })
                setProofTypes([...proofsTypesArr])
            }
        }
    }, [credential, pickedCredential])

    useEffect(() => {
        if (newTemplate?.id) {
            history.push({ pathname: "/credentials", state: { newTemplate: newTemplate.id } })
        }
        return () => {
            resetNewTemplate()
        }
    }, [newTemplate, history, resetNewTemplate])

    useEffect(() => {
        if (!disabled) {
            setEditingMode(true)
        }
        return () => {
            setEditingMode(false)
        }
    }, [disabled])

    const handleInputChange = (e: any) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const handleSelect = (name: string, value: string) => {
        setFields({ ...fields, [name]: value })
    }

    const handleSelectProof = (name: string, value: any, index?: number) => {
        const proofsArr = fields.proofs
        const proofsTypesArr = proofTypes
        if (proofsArr && index !== undefined) {
            proofsArr[index] = value
            proofsTypesArr[index] = value.type
            setFields({ ...fields, proofs: proofsArr })
            setProofTypes([...proofsTypesArr])
        }
    }

    const addProof = (proof?: string) => {
        if (fields?.proofs) {
            setFields({ ...fields, proofs: [...fields?.proofs, ""] })
        }
    }

    const removeProof = (index: number) => {
        const proofsArr = fields.proofs
        const proofsTypesArr = proofTypes
        if (proofsArr) {
            proofsArr.splice(index, 1)
            proofsTypesArr.splice(index, 1)
            setFields({ ...fields, proofs: proofsArr })
            setProofTypes([...proofsTypesArr])
        }
    }

    const onRadioBtnChange = (e: any) => {
        if (credential) {
            editTemplateSharing({ TemplateId: credential?.id, public: e.target.value === 'public' ? true : false })
        } else {
            setBtnValue(e.target.value)
            setFields({ ...fields, public: e.target.value === 'public' ? true : false })
        }
    }

    const onDeleteCredential = (templateName: string, templateId: number) => {
        setTemplateIdToDelete(templateId)
        setTemplateNameToDelete(templateName)
        setShowModal(true)
    }

    const handleTemplateDelete = async (id: number, name: string) => {
        const response = await deleteTemplate({ TemplateId: id, name: name })
        if (response?.status === 200) {
            history.push("/credentials")
        }
    }

    const handleSave = (e: any, draft?: boolean) => {
        setEditingMode(false)
        const templateToCreate: CreateTemplate = {
            name: fields.name,
            // @ts-ignore
            type: typeof fields.type === "object" ? fields.type.type : fields.type,
            description: fields.description,
        }
        if (fields?.proofs?.length !== 0) {
            templateToCreate.proofIds = fields?.proofs?.map((proof, index) => {
                // @ts-ignore
                if (!proof) {
                    return ""
                } else {
                    // @ts-ignore
                    return proof.id
                }
            }).filter((proofId) => proofId !== "")
        }
        if (hasPermissions(permissions, { "admin": "O" }) && fields.public === true) {
            templateToCreate.public = true
        }
        if (draft) {
            templateToCreate.draft = true
        }
        if (pickedCredential) {
            templateToCreate.TemplateId = pickedCredential.id
        }
        createTemplate(templateToCreate)
        setEditMode(false)
    }

    const handleEdit = (e: any, draft?: boolean) => {
        const templateToCreate: CreateTemplate = {
            TemplateId: credential?.id,
            name: fields.name,
            // @ts-ignore
            type: typeof fields.type === "object" ? fields.type.type : fields.type,
            description: fields.description,
        }
        if (fields?.proofs?.length !== 0) {
            templateToCreate.proofIds = fields?.proofs?.map((proof, index) => {
                // @ts-ignore
                if (!proof) {
                    return ""
                } else {
                    // @ts-ignore
                    return proof.id
                }
            }).filter((proofId) => proofId !== "")
        }
        if (hasPermissions(permissions, { "admin": "O" }) && fields.public === true) {
            templateToCreate.public = true
        }
        if (draft) {
            templateToCreate.draft = true
        }
        editTemplate(templateToCreate)
        setDisabled && setDisabled(true)
        setEditMode(false)
    }

    const handleEditCredential = () => {
        updateTemplateToDraft({ TemplateId: credential?.id })
        setDisabled && setDisabled(false)
        setEditMode(true)
    }

    const renderStatus = () => {
        if (credential) {
            return isFlag(credential?.status, DRAFT)
                ?
                t('statusText.draft')
                :
                (isFlag(credential?.status, INUSE)
                    ? t('statusText.used') : t('statusText.unused'))
        }
    }

    const newProofCTA = <div
        className={classes.selectOther}
    >
        <img
            src={plusIconGray}
            alt="plus icon"
            className={classes.plusIcon}
        />
        <Typography variant="body2">
            {t('credentials.newProof')}
        </Typography>
    </div>

    return (
        <>
        {matches && openProofModal ? <CreateProofModal
                open={openProofModal}
                onCancel={() => setOpenProofModal(false)}
                permissions={permissions}
                inMobile={true}
            />
        :
        <Paper elevation={0} className={clsx(classes.container, {
            [classes.edit]: !disabled
        })}>
            <div className={classes.titleRow}>
                <div>
                    <Typography
                        variant="h5"
                        className={classes.highEmphasis}
                    >
                        {credential ? credential.name : t('credentials.createTitle')}
                    </Typography>

                </div>
                
                <div className={classes.iconBtns}>
                    {
                        credential &&
                        hasPermissions(permissions, {templates: ["D"]}) &&
                        <IconBtn
                            iconDisabled={deleteIconDisabled}
                            onClick={() => onDeleteCredential(credential.name, credential.id)}
                            iconSecondary={deleteIcon}
                            version="secondary"
                            disabled={isFlag(credential?.status, INUSE) ? true : false}
                        />
                    }
                    <BackButton />
                </div>
            </div>
            <div className={classes.columnsRow}>
                <div className={classes.column}>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('credentials.type')}
                        </Typography>
                        <InputSelect
                            menuItems={templateTypes}
                            placeholder="Select a type"
                            renderValue="type"
                            selectOther={true}
                            other={other}
                            setOther={setOther}
                            setValue={handleSelect}
                            name="type"
                            value={fields.type || ""}
                            disabled={disabled}
                            otherElement={<>{t('general.other')}</>}
                        />
                        {
                            other &&
                            <>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.label}
                                >
                                    {t('credentials.newtype')}
                                </Typography>
                                <InputField
                                    value={fields?.type || ""}
                                    onChange={handleInputChange}
                                    name="type"
                                />
                            </>
                        }
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('credentials.name')}
                        </Typography>
                        <InputField
                            value={fields?.name || ""}
                            onChange={handleInputChange}
                            name="name"
                            disabled={disabled}
                        />
                    </div>
                    {
                        fields?.proofs?.map((proof, index) => {
                            return (
                                <div>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {t('credentials.proof', { number: index + 1 })}
                                    </Typography>
                                    <div className={classes.proofRow}>
                                        <div className={classes.proofContainer} onClick={()=>setCurrentProofIndex(index)}>
                                            <InputSelect
                                                menuItems={proofs}
                                                placeholder="Select Proof"
                                                renderValue="name"
                                                setValue={handleSelectProof}
                                                name="proof"
                                                value={proof}
                                                index={index}
                                                disabled={disabled}
                                                selectOther={true}
                                                otherElement={newProofCTA}
                                                setOther={setOpenProofModal}
                                                disabledOptionsCheck={proofTypes}
                                            />
                                        </div>
                                        {(fields?.proofs && fields?.proofs?.length > 1) &&
                                            <img
                                                src={!disabled ? deleteIcon : deleteIconDisabled}
                                                className={clsx(classes.proofDeleteIcon, { [classes.proofDeleteIconBlack]: !disabled })}
                                                onClick={() => !disabled && removeProof(index)}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        })

                    }
                    <Tooltip
                        title={<Fragment>{t('credentials.tooltipProof')}</Fragment>}
                        placement='bottom-start'
                    >
                        <div className={classes.iconBtn}>
                            <IconBtn
                                iconDisabled={plusIconGray}
                                iconSecondary={plusIcon}
                                version="secondary"
                                onClick={addProof}
                                disabled={disabled}
                            />
                        </div>
                    </Tooltip>
                </div>
                <Divider
                    orientation="vertical"
                    flexItem
                />
                <div className={classes.statusColumn}>
                    <div className={classes.row}>
                        <Typography
                            variant="subtitle2"
                            className={classes.label}
                        >
                            {t('credentials.description')}
                        </Typography>
                        <Typography
                            variant="caption"
                            className={classes.mediumEmphasis}
                        >
                            ({t('general.optional')})
                        </Typography>
                    </div>
                    <InputField
                        multiline={true}
                        rows={5}
                        onChange={handleInputChange}
                        name="description"
                        value={fields?.description || ""}
                        disabled={disabled}
                        maxLength={256}
                        helperText={t('general.maxDescSize')}
                    />
                    {credential &&
                        <div>
                            <Typography
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {t('credentialsTable.status')}
                            </Typography>
                            <div className={classes.status}>
                                <StatusBadge
                                    status={renderStatus()}
                                />
                            </div>
                            <Divider />
                            {!hasPermissions(permissions, { "admin": "O" }) &&
                                <>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        Origin
                                    </Typography>
                                    <div className={classes.status}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.origin}
                                        >
                                            {credential.origin ? t('origin.picked') : t('origin.created')}
                                        </Typography>
                                    </div>
                                    <Divider />
                                </>
                            }
                        </div>
                    }
                    {hasPermissions(permissions, { "admin": "O" }) &&
                        <>
                            <div className={classes.sharing}>
                                <div className={classes.questionIcon}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.label}
                                    >
                                        {t('credentials.share')}
                                    </Typography>
                                    <Tooltip
                                        title={<Fragment>{t('credentials.tooltipSharing')}</Fragment>}
                                        placement="bottom-start"
                                    >
                                        <img
                                            src={questionIcon}
                                            alt="question mark inside of a circle icon"
                                            className={classes.tooltipIcon}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={classes.radio}>
                                    <RadioBtns
                                        btnValues={btnValues}
                                        onChange={(e) => onRadioBtnChange(e)}
                                        btnValue={fields.public ? 'public' : btnValue}
                                        disabled={
                                            isFlag(credential?.status, DRAFT) || 
                                            editMode || 
                                            (hasPermissions(permissions, { "admin": "O" }) && !hasPermissions(permissions, {
                                              templates: ["E","R"],
                                              proof_template: ["R"],
                                              proofs: ["R"],
                                              mainContacts: ["R"],
                                              customers: ["R"]
                                            })) ? true : false
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={classes.btnRow}>
                {disabled ?
                    <>
                    {
                        credential && 
                        hasPermissions(permissions, {templates: ["R"], proof_template: ["R"], proofs: ["R"]}) &&
                        allowedPermissions(['viewer'], incomingRole(permissions), mainContact?.User?.Permissions?.[0].id, true)&&
                        <div className={classes.editBtn}>
                            <Button 
                                text="Test credential"
                                onClick={()=>history.push(`/credentials/${credential.id}/testoffer`)}
                                width="100%"
                                version="secondary"
                                disabled={fields?.proofs?.length === 1 && !fields?.proofs?.[0]}
                            />
                        </div>
                    }
                   {hasPermissions(permissions, {
                        templates: ["E","R"],
                        proof_template: ["R"],
                        proofs: ["R"],
                        mainContacts: ["R"],
                        customers: ["R"]
                    }) &&
                        <Tooltip 
                            title={isFlag(credential?.status, INUSE) ? t('credentials.tooltipEditing') : ""}
                            placement="top-start"
                        >
                            <div className={classes.editBtn}>
                                <Button
                                    text={t('general.Edit')}
                                    version="secondary"
                                    width="100%"
                                    onClick={() => handleEditCredential()}
                                    disabled={isFlag(credential?.status, INUSE) ? true : false}
                                />
                            </div>
                        </Tooltip>
                    }
                    </>
                    :
                    <>
                        <div className={classes.btn}>
                            <Button
                                width="100%"
                                text={t('credentials.draft')}
                                version="secondary"
                                disabled={disableDraft}
                                onClick={(e) => { credential?.id ? handleEdit(e, true) : handleSave(e, true) }}
                            />
                        </div>
                        <div className={classes.btn}>
                            <Button
                                width="100%"
                                text={t('credentials.complete')}
                                version="primary"
                                disabled={disableComplete}
                                onClick={credential?.id ? handleEdit : handleSave}
                            />
                        </div>
                    </>
                }
            </div>
            {openProofModal && <CreateProofModal
                open={openProofModal}
                onCancel={() => setOpenProofModal(false)}
                permissions={permissions}
            />}
            {showModal && <DeleteModal
                open={showModal}
                setShowModal={setShowModal}
                handleTemplateDelete={handleTemplateDelete}
                templateId={templateIdToDelete}
                credentialName={templateNameToDelete}
            />}
        </Paper>
        }
        </>
    )
}

const mapState = (state: RootState) => ({
    mainContact: state.login.mainContact,
    templateTypes: state.credentials.templateTypes,
    proofs: state.proofs.proofs,
    permissions: state.login.permissions,
    newTemplate: state.credentials.newTemplate,
    newProof: state.proofs.newProof
})

const mapDispatch = {
    getProofs: (CustomerId: number, param?: string) => (getProofs(CustomerId, param)),
    getTemplateTypes: (CustomerId: number) => getTemplateTypes(CustomerId),
    deleteTemplate: (data: Object) => (deleteTemplate(data)),
    createTemplate: (data: Object) => (createTemplate(data)),
    resetNewTemplate: () => (resetNewTemplate()),
    updateTemplateToDraft: (data: Object) => updateTemplateToDraft(data),
    editTemplate: (data: Object) => editTemplate(data),
    editTemplateSharing: (data: Object) => editTemplateSharing(data),
    setEditingMode: (data: boolean) => (setEditingMode(data))
}

const connector = connect(mapState, mapDispatch)

export default connector(CredentialStatusCard)